import React from 'react';

const ChevronDown = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill="#1C1B1F"
                d="M12 14.65c-.117 0-.229-.017-.337-.05a.605.605 0 01-.288-.2L6.85 9.9a.766.766 0 01-.2-.525c0-.2.075-.375.225-.525.15-.133.325-.2.525-.2s.375.067.525.2L12 12.925l4.1-4.075c.133-.15.3-.221.5-.213.2.008.375.08.525.213.15.15.225.329.225.537 0 .208-.075.38-.225.513l-4.5 4.5a.605.605 0 01-.287.2c-.109.033-.221.05-.338.05z"
            ></path>
        </svg>
    );
};

export default ChevronDown;