import React, { PureComponent } from "react"; 
import { Slide } from '@ct-design/lib/Animation'
import classNames from "classnames";
import SheetHeader from "./SheetHeader";
import Sticky from '@ct-design/lib/Sticky';
import { SheetProps } from "./type";

class Sheet extends PureComponent<SheetProps> {
  static defaultProps: { children: null; isOpen: boolean; title: string; subtitle: string; onClose: (event: any) => void; enableAnimation: boolean; showCloseButton: boolean; secondaryAction: string; secondaryActionClick: () => void; subHeaderComponent: null; enableShadow: boolean; };

  componentDidMount(){
    document.body?.classList?.add('Sheet--open');
  }

  componentWillUnmount(){
    document.body?.classList?.remove('Sheet--open');
  }

  override render() {
    const {
      overrideFake
    } = this.props;
    const sheetClassList = classNames("Sheet", this.props.sheetClassName);

    const SheetHTML = (
      <div className={ sheetClassList }>
        <Sticky position="top" overrideFake={ overrideFake }>
          { this.props.showDefaultHeader &&
             <SheetHeader
              title={ this.props.title }
              onClose={ this.props.onClose }
              showCloseButton={ this.props.showCloseButton }
              secondaryAction={ this.props.secondaryAction }
              secondaryActionClick={ this.props.secondaryActionClick }
              enableShadow={ this.props.enableShadow }
            />
          }
          {this.props.subHeaderComponent}
        </Sticky>
        <div className={classNames("Sheet__content", this.props.sheetContentClass)} >{this.props.children}</div>
      </div>
    );
    if (this.props.enableAnimation) {
      return (
        <Slide
          in={ this.props.isOpen }
          timeout={ { enter: 300, exit: 300 } }
          mountOnEnter={ true }
          unmountOnExit={ true }
          appear={ true }
          onExited={ this.props.onExited }
        >
          {SheetHTML}
        </Slide>
      );
    }
    if (this.props.isOpen) {
      return SheetHTML;
    }
    return null;
  }
}


Sheet.defaultProps = {
  children: null,
  isOpen: false,
  title: "",
  subtitle: "",
  onClose: (event) => {},
  enableAnimation: true,
  showCloseButton: true,
  secondaryAction: "",
  secondaryActionClick: () => {},
  subHeaderComponent: null,
  enableShadow: true
};

export default Sheet;
