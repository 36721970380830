import { Theme } from "../../../token/theme";
import { TypographyVariant } from "../../Typography/type";
import { TabSize, TabSizeType } from "../type";

export const getLabelVariant = ({ size }: { size: TabSizeType }) => {
  switch (size) {
    case TabSize.SMALL: {
      return TypographyVariant.B2;
    }
    case TabSize.MEDIUM: {
      return TypographyVariant.B1;
    }
    case TabSize.LARGE: {
      return TypographyVariant.B1;
    }
    default: {
      return TypographyVariant.B2;
    }
  }
};

export const getTabLabelColor = ({
  isSelected,
  theme,
}: {
  isSelected: boolean;
  theme: Theme;
}) => {
  if (isSelected) {
    return theme.color.tab.selectedPrimaryLabel;
  } else {
    return theme.color.tab.nonSelectedPrimaryLabel;
  }
};

export const getTabStyles = ({
  isSelected,
  theme,
  tabWidth,
}: {
  isSelected: boolean;
  theme: Theme;
  tabWidth?: string;
}) => {
  if (isSelected) {
    return {
      borderBottomColor: theme.color.border.primary,
      borderBottomWidth: theme.border.width.lg,
      width: tabWidth || "auto",
    };
  } else {
    return {
      borderBottomWidth: theme.border.width.none,
      width: tabWidth || "auto",
    };
  }
};

export const getCounterStyles = ({ theme }: { theme: Theme }) => {
  return {
    backgroundColor: theme.color.chip.selectedPrimaryLabel,
  };
};
