import React, { createContext, forwardRef, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getModalStyles, getStyleModal } from './style';
import { ModalProps } from './type';
import useTheme from '../../hooks/useTheme';
import { ForwardRefComponentWithStyle } from '../../@types';
import Portal from '../Portal';
import FocusLock from '../FocusLock';
import Transition from '../Transition';
import Backdrop from './Backdrop';
import useMergeRefs from '../../hooks/useMergeRefs';
import Container from '../Container/Container';
import ModalContext from './ModalContext';

type PolymorphicModal = ForwardRefComponentWithStyle<'main' | 'section' | 'article' | 'div', ModalProps>;
const StyledModal = styled.div<{ size: ModalProps['size'] }>(({ theme, size = 'MEDIUM' }) =>
	getModalStyles({ theme, size })
);

const Modal = forwardRef(
	(
		{
			as,
			children,
			insidePortal = true,
			open = false,
			onClose,
			onKeyDown,
			hideCrossIcon,
			footerAlignment,
			size = 'MEDIUM',
			allowBackdropClose = true,
			width,
			blur = true,
			backDropComponent,
			...rest
		},
		forwardedRef
	) => {
		const theme = useTheme();
		const modalRef = useRef<HTMLDivElement | null>(null);

		const handleRef = useMergeRefs(forwardedRef, modalRef);

		useEffect(() => {
			if (modalRef.current) {
				modalRef.current.scrollTop = 0;
			}
		}, [open]);

		const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
			if (event.target !== event.currentTarget) {
				return;
			}
			if (allowBackdropClose && onClose) {
				onClose(event);
			}
		};

		const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
			if (onKeyDown) {
				onKeyDown(event);
			}
			if (event.key !== 'Escape') {
				return;
			}

			event.stopPropagation();
			if (onClose) {
				onClose(event);
			}
		};

		if (!open || !React.isValidElement(children)) {
			return null;
		}

		// TODO Focus trap breaks height sequence
		return (
			<Portal disablePortal={!insidePortal}>
				<FocusLock>
					<Container position='fixed' zIndex={1300} css={{ inset: 0 }} {...rest}>
						<StyledModal size={size} as={as} theme={theme} ref={handleRef} onKeyDown={handleKeyDown}>
							{(backDropComponent || (
								<Backdrop open={open} blur={blur} transitionDuration={350} onClick={handleBackdropClick} />
							))}
							<Transition
								type={size === 'FULL SCREEN' ? 'slide' : 'fade'}
								slideDirection='up'
								in={open}
								timeout={350}
							>
								<Container css={getStyleModal({ theme, size, blur })}>
									<ModalContext.Provider value={{ footerAlignment, hideCrossIcon, onClose, size }}>
										{React.cloneElement(children, { onClose, ...children.props })}
									</ModalContext.Provider>
								</Container>
							</Transition>
						</StyledModal>
					</Container>
				</FocusLock>
			</Portal>
		);
	}
) as PolymorphicModal;

export default Modal;
