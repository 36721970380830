import { ReactNode } from 'react';

export enum TypographyVariant {
	HD1 = 'HD1',
	HD2 = 'HD2',
	HD3 = 'HD3',
	HM1 = 'HM1',
	HM2 = 'HM2',
	HM3 = 'HM3',
	HM4 = 'HM4',
	B1 = 'B1',
	B2 = 'B2',
	B3 = 'B3',
	B4 = 'B4',
	P1 = 'P1',
	P2 = 'P2',
	P3 = 'P3',
	L1 = 'L1',
	L2 = 'L2',
	L3 = 'L3',
	OVERLINE = 'OVERLINE',
	TAG = 'TAG',
}

export type TypographyVariantType = `${TypographyVariant}`;

export enum TypographyColor {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
  HEADING = "heading",
  SUBHEADING = "subheading",
  SUCCESS = "success",
  ALERT = "alert",
  WARNING = "warning",
  DISABLED = "disabled",
  LINK = "link",
  NEUTRAL = "neutral",
  PLACEHOLDER = "placeholder",
}

export type TypographyColorType = `${TypographyColor}`;

export interface TypographyProps {
	children?: ReactNode;
	variant: TypographyVariantType;
	color?: TypographyColorType;
	isStriked?: boolean;
	isClickable?: boolean;
	onClick?: () => void;
	className?: string;
	colorCode?: string;
	lineClamp?: number;
	dataTestId?: string
}
