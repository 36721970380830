import querystring from 'querystring'
import { checkInDomain, isServer } from './appAgent'
import dayjs from 'dayjs'
import { FLIGHT_CONFIRMED_MESSAGE } from '@constants/flights/errors'
import { ONWARD_DIR_ARROW } from '@constants/flights'
import { checkInternalCTUser } from './flights'

type filterCouponDataParamType = {
    data: Array<any>
    key: string
}
export const typeOf = obj => {
    let detv = typeof obj
    const isObject = value => value === 'object'
    const isArray = value => Object.prototype.toString.call(value) === '[object Array]'

    if (isObject(detv)) {
        if (obj) {
            if (isArray(obj)) {
                detv = 'array'
            }
        } else {
            detv = 'null'
        }
    }
    return detv
}

export const getLanguageMeta = () => {
    let domain
    if (isServer()) {
        domain = global.hostname
    } else {
        domain = path(['location', 'hostname'], window) // window.location.hostname;
    }
    switch (domain) {
        case 'www.cleartrip.ae':
        case 'qa2.cleartrip.ae':
            return { locale: 'AE', currency: 'AED', country: 'UAE', symbol: 'AED' }
        case 'bh.cleartrip.com':
            return { locale: 'BH', currency: 'BHD', country: 'BHR', symbol: 'BHD' }
        case 'om.cleartrip.com':
            return { locale: 'OM', currency: 'OMR', country: 'OMN', symbol: 'OMR' }
        case 'kw.cleartrip.com':
            return { locale: 'KW', currency: 'KWD', country: 'KWT', symbol: 'KWD' }
        case 'qa.cleartrip.com':
            return { locale: 'QA', currency: 'QAR', country: 'QAT', symbol: 'BHD' }
        case 'www.cleartrip.sa':
        case 'qa2.cleartrip.sa':
            return { locale: 'SA', currency: 'SAR', country: 'KSA', symbol: 'SAR' }
        case 'qa2.cleartrip.com':
            return { locale: 'IN', currency: 'INR', country: 'IN', symbol: '₹' }
        case 'me.cleartrip.com':
            return { locale: 'ME', currency: 'USD', country: 'ME', symbol: '$' }
        case 'qa2new.cleartrip.com':
            return { locale: 'IN', currency: 'INR', country: 'IN', symbol: '₹' }
        case 'www.cleartrip.me':
            return { locale: 'ME', currency: 'USD', country: 'ME', symbol: '$' }
        case 'www.cleartrip.eu':
            return { locale: 'EU', currency: 'EUR', country: 'EUR', symbol: '€' }
        case 'www.cleartrip.pl':
            return { locale: 'EU', currency: 'EUR', country: 'EUR', symbol: '€' }
        default:
            return { locale: 'IN', currency: 'INR', country: 'IN', symbol: '₹' }
    }
}

export const getCurrencyByLocale = () => {
    return { locale: 'IN', currency: 'INR', country: 'IN', symbol: 'Rs.' }
}

export const formatCurrency = (value: string | number) => {
    if (value && typeOf(value) === 'string') {
        value = value.replace(/,/g, '')
    }

    const formatted = parseInt(value, 10).toLocaleString(getCurrencyByLocale('INR'), {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0
    })
    return formatted
}

export const path = (p, o) => {
    const reducerFunction = (xs, x) => {
        return xs && xs[x] ? xs[x] : null
    }
    return p.reduce(reducerFunction, o)
}

export const isFeatureEnabled = () => {
    if (isServer()) {
        return {}
    }

    let data = path(['offerJson'], window) || []

    let currentTime = new Date()

    let currentOffset = currentTime.getTimezoneOffset()

    let ISTOffset = 330 // IST offset UTC +5:30

    let ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000)

    const { locale } = getLanguageMeta()
    if (locale === 'IN') {
        let obj = {}
        for (let index = 0; index < data.length; index++) {
            let config = data[index]
            if (new Date(config.time.from) <= ISTTime && new Date(config.time.to) >= ISTTime) {
                obj = { ...config }
                break
            }
        }
        return obj
    } else {
        return { travelsafeEnabled: true }
    }
}

export function getCookie(name) {
    if (!isServer()) {
        if (path(['cookie'], document)) {
            const nameEQ = name + '='
            const ca = document.cookie.split(';')
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i]
                while (c.charAt(0) == ' ') c = c.substring(1, c.length)
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
            }
            return ''
        } else {
            return ''
        }
    }
}

export function getUserAuthValues() {
    const [email, profileName, gender, photo, userId] = decodeURIComponent(getCookie('userid') || '').split('|')
    return {
        email,
        profileName,
        gender,
        photo,
        userId
    }
}

export const isUserSignedIn = () => {
    let userObject = getUserAuthValues() || {}
    let usermiscVal = decodeURIComponent(getCookie('usermisc') || '').split('|')
    let signedIn = usermiscVal.includes('SIGNED_IN') && userObject.userId && userObject.userId.length > 0 ? true : false
    return signedIn
}

export const setCookie = (name, value, days) => {
    if (isServer()) {
        return
    }
    let expires
    if (days) {
        const date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = `; expires=${date.toGMTString()}`
    } else {
        expires = ''
    }
    document.cookie = `${name}=${value}${expires}; path=/`
}

export const toQueryString = (object, sep = '&', eq = '=') => {
    return querystring.stringify(object, sep, eq)
}

export const isEmpty = obj => {
    if (typeof obj == 'string') return obj == '' ? true : false

    if (obj instanceof Date) {
        return false
    }
    // null and undefined are "empty"
    if (obj == null) {
        return true
    }

    //handle array and obj
    if (typeof obj === 'object' && Object.keys(obj).length === 0) {
        return true
    }
}

export const capitalizeFirstLetter = (value: string) => {
    if (value && isNaN(Number(value))) {
        return value[0].toUpperCase() + value.slice(1)
    }
    return value
}

export function capitalizeFirstCharInString(str: string): string {
    if (typeof str !== 'string') {
        return str
    }

    // Split the input string into words using regular expression
    const words = str.split(/\s+/)

    // Capitalize the first character of each word
    const capitalizedWords = words?.map(word => {
        if (word?.length === 0) {
            return '' // Handle empty words
        } else if (word?.length === 1) {
            return word.toUpperCase() // Capitalize single-letter words
        } else {
            const firstChar = word[0].toUpperCase()
            const restOfWord = word?.slice(1).toLowerCase()
            return firstChar + restOfWord
        }
    })

    // Join the capitalized words back into a single string
    return capitalizedWords.join(' ')
}

export const replaceUnderscoreWithSpace = (value: string) => {
    if (!isEmpty(value) && isNaN(Number(value))) {
        return value.replace('_', ' ')
    }
    return value
}

export const fromQueryString = (queryString, sep = '&', eq = '=') => {
    return querystring.parse(queryString, sep, eq)
}

const writeCookieUtmInMinutes = (k, v, expiry) => {
    if (isServer()) {
        return
    }
    if (expiry > 0) {
        // Set expiry time in minutes
        let date = new Date()
        date.setTime(date.getTime() + expiry * 60 * 1000)
        document.cookie = k + '=' + v + '; expires=' + date + '; path=/'
    } else if (!expiry || expiry === '') {
        document.cookie = `${k}=${v};path="/";`
    }
    return null
}

export const writeCookieUtm = (k, v, expiryInDays) => {
    if (isServer()) {
        return
    }
    if (expiryInDays > 0) {
        const now = new Date()
        const nextDate = now.setDate(now.getDate() + expiryInDays)
        const expiresDate = new Date(nextDate)
        document.cookie = `${k}=${v}; path=/; expires=` + expiresDate
    } else if (!expiryInDays || expiryInDays === '') {
        document.cookie = `${k}=${v};path="/";`
    }

    return null
}

export const readParam = (name, options = {}) => {
    if (isServer()) {
        return
    }
    const queryString = window.location.search.split('?')
    const urlHash = fromQueryString(queryString[1] ? queryString[1] : '')
    const params = urlHash ? urlHash : {}

    const cookieValue = getCookie(name || options.alias)
    const value = params[name] || params[options.alias]

    // if value present in param then return that, otherwise check in cookie
    if (value) {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        if (name === 'utm_source' && value === 'GoogleHPA') {
            writeCookieUtmInMinutes(name, value, 30)
        } else {
            writeCookieUtm(name, value, options.expiry || 1)
        }
        return value
    } else {
        if (cookieValue) {
            return cookieValue
        }
    }
}

export const deepEqual = (x, y) => {
    const ok = Object.keys,
        tx = typeof x,
        ty = typeof y
    return x && y && tx === 'object' && tx === ty
        ? ok(x).length === ok(y).length && ok(x).every(key => deepEqual(x[key], y[key]))
        : x === y
}

/** Returns whether the value is a function. Acts as a type guard. */
export const isFunction = value => {
    return typeof value === 'function'
}

/**
 * Safely invoke the function with the given arguments,
 * if it is indeed a function, and return its value.
 */
export const safeInvoke = (func = undefined, ...args) => {
    return isFunction(func) && func(...args)
}

export const isValidEmail = (email: string) => {
    const pattern = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    return pattern.test(email)
}

export const jsonObjectToFormData = (obj: Object) => {
    const keyArray = Object.keys(obj).map(key => {
        return `${key}=${obj[key]}`
    })
    return keyArray.join('&')
}

export const gstValidator = (number, expression) => {
    const regex = new RegExp(expression)
    let matched = false
    if (number.length === 15) {
        matched = regex.test(number) // eslint-disable-line
    }
    return matched
}

export const setQueryIfApp = () => {
    let ios = path(['iosData', 'app-agent'], window)
    let android = path(['androidData', 'app-agent'], window)
    if (ios === 'iPhoneApp') {
        return '&isMobileApp=true&deviceType=ios&appType=webView'
    } else if (android === 'AndroidApp') {
        return '&isMobileApp=true&deviceType=android&appType=webView'
    } else {
        return ''
    }
}

export const removeFromArray = (array, value) => {
    const index = array.indexOf(value)
    const newArray = array.slice()
    if (index > -1) {
        newArray.splice(index, 1)
        return newArray
    }
    return array
}

export const intToString = value => {
    var suffixes = ['', 'k', 'm', 'b', 't']
    var suffixNum = Math.floor(('' + value).length / 3)
    var shortValue = parseFloat((suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2))
    if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1)
    }
    return shortValue + suffixes[suffixNum]
}

export const isLiteralObject = function (a) {
    return !!a && a.constructor === Object
}

export const handleHomePageBannerClick = url => {
    window.location.href = url
}

export const isTravelSafeBannerEnabled = (travelSafeCountryList = [], searchType = {}, to = '', from = '') => {
    if (checkInDomain()) {
        return travelSafeCountryList?.includes(searchType[to]) || travelSafeCountryList?.includes(searchType[from])
    } else {
        return true
    }
}

export const dispatchNewRelic = ({ action_name, payload }) => {
    if (typeof newrelic == 'object') {
        newrelic.addPageAction(action_name, {
            channel: 'PWA',
            isNextFlow: true,
            isCtLoginUser: checkInternalCTUser(),
            ssrEnabledCookie: getCookie('ssrEnabled') || 'b',
            version:'v1',
            ...(window?.androidData || {}),
            ...(window?.iosData || {}),
            ...payload
        })
    }
}

/**
 * Formats an epoch time as a human-readable date and time using Day.js.
 *
 * @param {number} epochTime - The epoch time in milliseconds to be formatted.
 * @param {string} [dateFormat='DD MMM'] - The format for the date. Defaults to 'DD MMM'.
 * @param {string} [timeFormat='HH:mm'] - The format for the time. Defaults to 'HH:mm'.
 *
 * @returns {Object} An object containing the formatted date and time as 'date' and 'time' properties
 */
export function formatEpochTime(epochTime: number, dateFormat = 'DD MMM', timeFormat = 'HH:mm') {
    // Check if epochTime is not a number or is invalid
    if (isNaN(epochTime) || epochTime <= 0) {
        return {
            date: '01 Jan',
            time: '00:00'
        }
    }

    // Convert epochTime to a Day.js object
    const date = dayjs(epochTime)

    // Check if the conversion resulted in an invalid date
    if (!date.isValid()) {
        return {
            date: '01 Jan',
            time: '00:00'
        }
    }

    // Format the date and time using the specified formats
    const formattedDate = date.format(dateFormat)
    const formattedTime = date.format(timeFormat)

    return {
        date: formattedDate,
        time: formattedTime
    }
}
export function isNextDate(date1: string, date2: string, dateFormat: string) {
    const date1Obj = dayjs(date1, { format: dateFormat })
    const date2Obj = dayjs(date2, { format: dateFormat })

    return date2Obj.isAfter(date1Obj)
}
export function getQueryParams(url: string) {
    const paramArr = url.slice(url.indexOf('?') + 1).split('&')
    const params: Record<string, any> = {}
    paramArr.map(param => {
        const [key, val] = param.split('=')
        params[key] = decodeURIComponent(val)
    })
    return params
}

export const priceCeil = (value: number, fractionEnabled?: boolean): number => {
    if (fractionEnabled) {
        return value
    }
    return Math.ceil(value)
}

export const getFlightTripStr = (
    isMulticity: boolean,
    sourceCity: string,
    isRoundtrip: boolean,
    destinationCity: string,
    multicitySourceCity: string,
    multicityDestinationCity: string
) => {
    return !isMulticity
        ? FLIGHT_CONFIRMED_MESSAGE.replace('{sourceCity}', sourceCity)
              .replace('{arrow}', `${isRoundtrip ? '⇄' : ONWARD_DIR_ARROW}`)
              .replace('{destinationCity}', destinationCity)
        : FLIGHT_CONFIRMED_MESSAGE.replace('{sourceCity}', multicitySourceCity)
              .replace('{arrow}', `${ONWARD_DIR_ARROW}`)
              .replace('{destinationCity}', multicityDestinationCity)
}

export const newCouponExp = (abtest: any) => {
    return true

    // if (abtest?.New_Coupon_Experience === 'a' || abtest?.New_Coupon_Experience === 'c') {
    //     return true
    // } else {
    //     return false
    // }
}

export const filterCouponData = ({ data = [], key = '' }: filterCouponDataParamType) => {
    if (isEmpty(key) || !Array.isArray(data)) {
        return data
    }
    const filteredArray = data.filter(item => {
        return !isEmpty(item?.[key])
    })
    return filteredArray
}

export const utilServerSideDeviceDetection = context => {
    const isServer = !!context.req
    const userAgent: string = isServer ? context.req.headers['user-agent'] : navigator.userAgent
    const isLine = /\bLine\//i.test(userAgent) || false
    const isMobile = /(iPad|iPhone|Android|Mobile)/i.test(userAgent) || false
    const rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(wv|.0.0.0)']

    const regex = new RegExp(`(${rules.join('|')})`, 'ig')
    const isInApp = Boolean(userAgent.match(regex))

    return {
        isMobile,
        isLine,
        isInApp,
        userAgent
    }
}

export async function writeClipboardText(text: string) {
    try {
        await navigator.clipboard.writeText(text)
    } catch (error) {
        console.error(error)
    }
}

export const getPrice = (flight, fareType, property) => flight?.priceBreakup?.[fareType]?.[property] || 0

export const calculateDomRtFlightPrices = (selectedFlights, fareType) => {
    const basePrice =
        getPrice(selectedFlights[0], fareType, 'ctDisplayBasePrice') +
        getPrice(selectedFlights[1], fareType, 'ctDisplayBasePrice')
    const taxesAndFees =
        getPrice(selectedFlights[0], fareType, 'ctDisplayTax') + getPrice(selectedFlights[1], fareType, 'ctDisplayTax')
    const totalPrice = getPrice(selectedFlights[0], fareType, 'pr') + getPrice(selectedFlights[1], fareType, 'pr')

    return { basePrice, taxesAndFees, totalPrice }
}

export const safeGetObject = obj => {
    try {
        // Check if it's a valid object and not null or undefined
        return obj && typeof obj === 'object' ? obj : {}
    } catch (error) {
        // If any error occurs, return an empty object
        console.error('Error while processing object:', error)
        return {}
    }
}
