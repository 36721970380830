import styled, { css } from 'styled-components';
import { getStyledRadioStyles } from './style';
import { BaseRadioProps, StyledRadioProps } from './type';

export const BaseRadio = styled.input.attrs({ type: 'radio' })<BaseRadioProps>`
	margin: 0px;
	cursor: pointer;
	opacity: 0;
	position: absolute;
	z-index: 1;
	${({ theme, cursor }) => {
		return css`
			height: ${theme.size[5]};
			width: ${theme.size[5]};
			cursor: ${cursor};
		`
	}}
`;

export const StyledRadio = styled.div<StyledRadioProps>(
	({ backgroundColor, cursor, borderColor, borderWidth, theme }) =>
		getStyledRadioStyles({ backgroundColor, cursor, borderColor, borderWidth, theme })
);
