import React from 'react';

const HiddenIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <g style={{ mixBlendMode: 'soft-light' }}>
                <mask
                    id="mask0_2279_907"
                    style={{ maskType: 'alpha' }}
                    width="40"
                    height="40"
                    x="0"
                    y="0"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M0 0H40V40H0z"></path>
                </mask>
                <g mask="url(#mask0_2279_907)">
                    <path
                        fill="#fff"
                        d="M26.292 21.624l-1.875-1.875c.25-1.389-.146-2.631-1.187-3.728-1.042-1.098-2.313-1.522-3.813-1.272l-1.875-1.875a6.333 6.333 0 011.166-.375c.39-.083.82-.125 1.292-.125 1.89 0 3.493.66 4.812 1.98 1.32 1.32 1.98 2.923 1.98 4.812 0 .472-.042.91-.125 1.312a5.268 5.268 0 01-.375 1.146zm5.291 5.167l-1.833-1.708a18.264 18.264 0 002.813-2.647 14.828 14.828 0 002.104-3.27c-1.39-2.806-3.382-5.035-5.98-6.688C26.09 10.826 23.195 9.999 20 9.999c-.805 0-1.597.056-2.375.167-.778.111-1.541.278-2.291.5l-1.959-1.958a16.719 16.719 0 013.23-.917A19.412 19.412 0 0120 7.499c3.917 0 7.445 1.077 10.584 3.23 3.138 2.153 5.416 4.965 6.833 8.437a18.588 18.588 0 01-2.375 4.187 17.251 17.251 0 01-3.459 3.438zm1.334 9.667l-6.709-6.709c-.86.334-1.812.598-2.853.792a18.29 18.29 0 01-3.355.292c-3.916 0-7.438-1.077-10.563-3.23-3.125-2.152-5.41-4.965-6.853-8.437a18.499 18.499 0 012.374-4.125 18.065 18.065 0 013.209-3.292L3.542 7.083l1.75-1.792 29.416 29.417-1.791 1.75zm-23-22.959a16.767 16.767 0 00-2.562 2.522 14.91 14.91 0 00-2.022 3.145c1.39 2.806 3.382 5.035 5.979 6.687 2.598 1.653 5.494 2.48 8.688 2.48.75 0 1.5-.063 2.25-.189.75-.124 1.39-.256 1.917-.395l-2.084-2.166a6.482 6.482 0 01-1 .27 5.68 5.68 0 01-1.083.105c-1.889 0-3.493-.66-4.812-1.98-1.32-1.32-1.98-2.923-1.98-4.812 0-.333.035-.688.105-1.063a6.35 6.35 0 01.27-1.02l-3.666-3.584z"
                    ></path>
                </g>
            </g>
        </svg>
    );
};

export default HiddenIcon;