import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <mask
        id="mask0_226_5845"
        style={{ maskType: "alpha" }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_226_5845)">
        <path
          fill="#fff"
          fillOpacity="0.8"
          d="M15.315 18a.672.672 0 01-.48-.197.61.61 0 01-.205-.453v-5.852h-.727a1.61 1.61 0 01-1.155-.46 1.476 1.476 0 01-.48-1.106V6c0-1.047.354-1.955 1.062-2.724.708-.768 1.598-1.182 2.671-1.24v15.31a.615.615 0 01-.206.457.672.672 0 01-.48.197zm-8.156 0a.672.672 0 01-.48-.197.612.612 0 01-.205-.454v-6.752A3.105 3.105 0 014.716 9.57 2.809 2.809 0 014 7.658V2.656c0-.174.069-.327.206-.458A.673.673 0 014.687 2c.182 0 .342.066.48.198a.616.616 0 01.205.457v4.999h1.102V2.655c0-.173.068-.326.206-.457A.672.672 0 017.16 2c.182 0 .342.066.479.198a.616.616 0 01.206.457v4.999h1.101V2.655c0-.173.069-.326.206-.457A.672.672 0 019.632 2c.182 0 .342.066.48.198a.616.616 0 01.206.458v5.002c0 .731-.239 1.368-.715 1.91a3.164 3.164 0 01-1.758 1.034v6.747a.612.612 0 01-.206.454.673.673 0 01-.48.197z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
