import { getApacheCookie, getUserAuthValues } from "@utils/flights"
import { apiGetHelper } from "src/utils/helper"
import abResponseData from '@constants/abResponseData.json'
import { getApiDomain } from '@utils/helper'
import { defaultCookieValues } from "@constants/flights"

export const getAbConfig = async () => {
  const apache = getApacheCookie()
  const { email = "" } = getUserAuthValues()
  let DOMAIN = getApiDomain()
  const URL = `${DOMAIN}/source/abtest/getPWAconfig?userid=${email}&apache_cookie=${apache}&utmz=`
  const response = await apiGetHelper(URL)
  return response
}

export const getAbConfigServer = async (email: string, apache: string) => {
  const isProdPath = process.env.NEXT_PUBLIC_CT_ENV === 'production' ? 'prod' : 'staging'

  let domainForSSr = isProdPath === 'prod' ? 'https://www.cleartrip.com' : 'https://qa2new.cleartrip.com'

  const URL = `${domainForSSr}/source/abtest/getPWAconfig?userid=${email}&apache_cookie=${apache}&utmz=`
  const response = await apiGetHelper(URL)
  return response
}
