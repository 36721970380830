import React from 'react';

const PublishSuccess = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='49' height='48' fill='none' viewBox='0 0 49 48'>
            <circle cx='24.5' cy='24' r='24' fill='#11A670'></circle>
            <path
                fill='#fff'
                d='M17.52 22.953a2 2 0 10-2.828 2.828l2.828-2.828zm4.243 7.071l-1.414 1.414a2 2 0 002.828 0l-1.414-1.414zm12.728-9.9a2 2 0 00-2.828-2.828l2.828 2.829zm-19.799 5.657l5.657 5.657 2.828-2.828-5.657-5.657-2.828 2.828zm8.485 5.657l11.314-11.313-2.828-2.829L20.349 28.61l2.828 2.828z'
            ></path>
        </svg>
    );
};

export default PublishSuccess;
