export type BorderRadius = Readonly<{
	0: '0px';
	2: '2px';
	4: '4px';
	6: '6px';
	8: '8px';
	12: '12px';
	16: '16px';
	28: '28px'
	'50P': '50%';
}>;

export type BorderWidth = Readonly<{
	none: '0px';
	sm: '1px';
	md: '1.5px';
	lg: '2px';
}>;

export type BorderStyle = Readonly<{
	solid: 'solid';
	dotted: 'dotted';
}>;

export type Border = {
	radius: BorderRadius;
	width: BorderWidth;
	style: BorderStyle;
};

const border: Border = {
	radius: {
		0: '0px',
		2: '2px',
		4: '4px',
		6: '6px',
		8: '8px',
		12: '12px',
		16: '16px',
		28: '28px',
		'50P': '50%',
	},
	width: {
		none: '0px',
		sm: '1px',
		md: '1.5px',
		lg: '2px',
	},
	style: {
		solid: 'solid',
		dotted: 'dotted',
	},
};

export default border;
