import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <mask
        id="mask0_902_40634"
        style={{ maskType: "alpha" }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_902_40634)">
        <path
          fill="#fff"
          fillOpacity="0.8"
          fillRule="evenodd"
          d="M7.06 16.99a7.394 7.394 0 002.942.593 7.364 7.364 0 002.951-.593 7.644 7.644 0 004.038-4.05 7.393 7.393 0 00.593-2.942 7.364 7.364 0 00-.593-2.952 7.673 7.673 0 00-4.04-4.038A7.372 7.372 0 0010 2.416c-1.04 0-2.02.197-2.941.592a7.64 7.64 0 00-4.049 4.04 7.371 7.371 0 00-.592 2.953c0 1.04.197 2.02.592 2.94a7.642 7.642 0 001.63 2.42 7.647 7.647 0 002.42 1.63zm4.87-2.363c.087.04.191.06.311.06.152 0 .272-.04.36-.12a.43.43 0 00.144-.3.508.508 0 00-.108-.372l-1.344-2.028c-.232-.36-.472-.612-.72-.756a1.455 1.455 0 00-.373-.158c.317-.048.601-.132.853-.25.432-.2.76-.488.984-.864.181-.304.284-.652.308-1.044h.808c.144 0 .252-.036.324-.108a.368.368 0 00.12-.288.383.383 0 00-.12-.3c-.072-.072-.18-.108-.324-.108h-.86A1.804 1.804 0 0012 7.307a1.66 1.66 0 00-.32-.36h1.472c.144 0 .252-.036.324-.108a.383.383 0 00.12-.3c0-.12-.04-.216-.12-.288-.072-.072-.18-.108-.324-.108H7.177a.485.485 0 00-.336.108.39.39 0 00-.108.288c0 .128.036.228.108.3.08.072.192.108.336.108h2.148c.576 0 1.024.108 1.344.324.255.171.428.411.52.72H7.178a.485.485 0 00-.336.108.405.405 0 00-.108.3c0 .12.036.216.108.288.08.072.192.108.336.108h4.068c-.062.418-.234.738-.516.96-.328.264-.824.396-1.488.396H7.213a.515.515 0 00-.36.12.454.454 0 00-.12.336c0 .136.04.248.12.336.088.08.208.12.36.12h1.32c.24 0 .444.028.612.084.168.048.32.136.456.264.144.12.28.284.408.492l1.572 2.376c.048.072.096.14.144.204a.527.527 0 00.204.144z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
