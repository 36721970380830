import { css } from "styled-components";
import { CSSObject } from "styled-components";
import { StyledTypographyProps } from "./type";

export const getStyledTypographyStyles = ({
  fontSize,
  fontWeight,
  lineHeight,
  color,
  textDecoration,
  display,
  webkitLineClamp,
  webkitBoxOrient,
  overflow,
}: StyledTypographyProps) => {
  return css`
		font-size: ${fontSize};
		font-weight: ${fontWeight};
		line-height: ${lineHeight};
		color: ${color};
		text-decoration: ${textDecoration};
		display: ${display};
		overflow: ${overflow};
		-webkit-line-clamp: ${webkitLineClamp};
		-webkit-box-orient: ${webkitBoxOrient};
	`;
};
