import React from "react";
import { Typography, Container } from "../..";
import { IconPosition } from "../../../@types";
import useTheme from '@ct-design/hooks/useTheme';
import { StyledCounter } from "../StyledCounter";
import { TabSize } from "../type";
import {
  getLabelVariant,
  getTabLabelColor,
  getTabStyles,
  getCounterStyles,
} from "./style";
import { StyledFlatTab } from "./StyledFlatTab";
import { FlatTabProps } from "./type";

const FlatTab: React.FC<FlatTabProps> = ({
  label,
  size = TabSize.SMALL,
  showIcon = false,
  iconPosition = IconPosition.LEFT,
  Icon = undefined,
  showCounter = false,
  count = 0,
  isSelected = false,
  onClick,
  tabWidth,
  id,
  showCounterAsTag = false,
}) => {
  const theme = useTheme();
  const tabStyles = getTabStyles({ isSelected, theme, tabWidth });
  const counterStyles = getCounterStyles({ theme });

  return (
      <Container onClick={onClick} position="relative" id={id}>
          <StyledFlatTab {...tabStyles}>
              <Container display="flex" alignItems="center" justifyContent="center">
                  {showIcon && iconPosition === IconPosition.LEFT && (
                      <Container
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          paddingRight={theme.spacing[2]}
                      >
                          {Icon}
                      </Container>
                  )}
                  <Typography variant={getLabelVariant({ size })} colorCode={getTabLabelColor({ isSelected, theme })}>
                      {label}
                  </Typography>
                  {showIcon && iconPosition === IconPosition.RIGHT && (
                      <Container
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          paddingLeft={theme.spacing[2]}
                      >
                          {Icon}
                      </Container>
                  )}
                  {showCounter && (
                      <Container
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          paddingLeft={theme.spacing[2]}
                      >
                          {showCounterAsTag ? (
                              count ? (
                                  <StyledCounter {...counterStyles}>
                                      <Typography variant="TAG" color="neutral">
                                          {count}
                                      </Typography>
                                  </StyledCounter>
                              ) : null
                          ) : (
                              <Typography
                                  variant={getLabelVariant({ size })}
                                  colorCode={getTabLabelColor({ isSelected, theme })}
                              >
                                  <StyledCounter>{count}</StyledCounter>
                              </Typography>
                          )}
                      </Container>
                  )}
              </Container>
          </StyledFlatTab>
      </Container>
  )
};

export default FlatTab;
