import { getFormattedDate } from '@root/ui/utils/dateHelper'
import { ProcessedData } from './types'
import { formatCurrency, getUserAuthValues, isEmpty, isUserSignedIn } from '@utils/genralUtils'
import { convertToHHMMFormat, getFormattedDateCfw } from '@utils/date/dateUtils'
import { listOfJourneyKeys, LOGGED_IN } from '@constants/flights/cfw'
import { checkInternalCTUser, checkInternalFKUser } from '@utils/flights'

export const processQueryParams = (router: any, data: any): ProcessedData => {
    const processedData: ProcessedData = {
        isIntl: false,
        srpFlow: '',
        departDate: '',
        returnDate: '',
        source: '',
        destination: '',
        sourceHeader: '',
        destinationHeader: '',
        travellers: {
            adults: 0,
            children: 0,
            infants: 0
        },
        tripType: '',
        flightClass: '',
        dx: '',
        isCfw: false,
        carrier: '',
        sft: ''
    }

    const isIntl = router?.route?.indexOf('international') > 0 || data.intl == 'y' ? true : false
    processedData.isIntl = isIntl
    processedData.srpFlow = data.srpFlow
    processedData.departDate = decodeURIComponent(getFormattedDate(data.depart_date, 'DD/MM/YYYY', 'D/M/YYYY'))
    processedData.returnDate = data.return_date
        ? decodeURIComponent(getFormattedDate(data.return_date, 'DD/MM/YYYY', 'D/M/YYYY'))
        : ''
    processedData.source = data.from
    processedData.destination = data.to
    processedData.sourceHeader = data.from_header || data.from
    processedData.destinationHeader = data.to_header || data.to

    processedData.travellers = {
        adults: Number(data.adults),
        children: Number(data.childs),
        infants: Number(data.infants)
    }

    processedData.tripType = processedData.returnDate ? 'roundtrip' : 'oneway'

    if (data.carrier) {
        processedData.carrier = data.carrier
    }
    if (data.sft) {
        processedData.sft = data.sft
    }
    processedData.flightClass = data.class
    processedData.dx = data.dx || ''
    processedData.isCfw = data.isCfw === 'true' ? true : false

    return processedData
}

export const getFlightInfo = (card, metaData) => {
    const { summary, travelOptionId } = card || {}
    const { firstDeparture, lastArrival, stops, timelineText, totalDuration, flights = [] } = summary || {}
    const firstFlight = flights?.[0] || {}
    const departureTime = firstDeparture?.airport?.time
    const arrivalTime = lastArrival?.airport?.time
    const isMultiAirline = checkMultiAirlineCase(summary.flights)
    const flightNumber = `${firstFlight.airlineCode}-${firstFlight.flightNumber}`
    const { airlineCode } = firstDeparture || {}
    const airlineName = metaData?.airlineDetail?.[airlineCode]?.name || ''
    return {
        flightNumber,
        airlineCode,
        airlineName,
        isMultiAirline,
        departureTime,
        arrivalTime,
        stops,
        timelineText,
        totalDuration,
        travelOptionId
    }
}

export const getFaresInfo = (cardDetail, subTravelOptions, fares) => {
    let finalFareObjectList = []
    const { subTravelOptionIds = [], travelOptionId = '' } = cardDetail || {}
    subTravelOptionIds.forEach(subTravelOptionId => {
        const {
            fareList = [],
            cheapestFareId = '',
            subTravelOptionKey = ''
        } = subTravelOptions[subTravelOptionId] || {}
        const fareObjectList = fareList.map(fare => {
            const { fareId = '', benefits, benefitTags = {}, displayText = {} } = fare || {}
            const isCheapest = cheapestFareId === fareId
            return {
                ...(fares?.[fareId] || {}),
                displayText,
                benefits,
                benefitTags,
                subTravelOptionId,
                travelOptionId,
                isCheapest,
                subTravelOptionKey
            }
        })
        finalFareObjectList = [...finalFareObjectList, ...fareObjectList]
    })
    return finalFareObjectList
}

export const getCheapestFareInCard = (cardsList: any[], subTravelOptions: any, fares: any) => {
    if (Array.isArray(cardsList)) {
        return cardsList.map(card => {
            const fareList = getFaresInfo(card, subTravelOptions, fares)
            return fareList.find(fare => {
                const { isCheapest } = fare || {}
                return isCheapest
            })
        })
    }
    return []
}

export const getCouponCallout = (couponDetails, showPerAdultPricing) => {
    if (isEmpty(couponDetails)) return { couponCallout: '', couponCode: '' }
    const { adultCouponMessage, couponCode, discountAmount, discountedPrice, message } = couponDetails || {}
    let couponMessage = (showPerAdultPricing ? adultCouponMessage : message) || ''
    if (couponMessage) {
        couponMessage = couponMessage
            .replace('{couponCode}', couponCode)
            .replace('{discountAmount}', formatCurrency(discountAmount))
        return { couponCallout: couponMessage, couponCode }
    }
    return { couponCallout: '', couponCode }
}

export const getPricingDetails = ({ strikeOffDetails, totalPrice }) => {
    let displayPrice = totalPrice
    let strikeOffPrice = strikeOffDetails?.strikeOffPrice
    if (!isEmpty(strikeOffPrice)) {
        displayPrice = strikeOffDetails.displayPrice
    }
    return { displayPrice, strikeOffPrice }
}

export const getFareAttributes = ({ fare, showPerAdultPricing }) => {
    if (isEmpty(fare)) return {}
    let {
        brand: title,
        pricing,
        benefits,
        benefitTags,
        subTravelOptionId,
        travelOptionId,
        fareId,
        displayText,
        subTravelOptionKey,
        fareCategory
    } = fare || {}
    const pricingObject = showPerAdultPricing ? pricing.perAdultPricing : pricing.totalPricing
    const { couponDetails, strikeOffDetails, totalBaseFare, totalTax, totalPrice } = pricingObject
    const { couponCallout, couponCode } = getCouponCallout(couponDetails, showPerAdultPricing)
    const { displayPrice, strikeOffPrice } = getPricingDetails({
        strikeOffDetails,
        totalPrice
    })
    return {
        fareId,
        title,
        strikeOffPrice,
        showPerAdultPricing,
        displayPrice,
        couponCallout,
        couponCode,
        totalBaseFare,
        totalTax,
        totalPrice,
        benefits,
        benefitTags,
        subTravelOptionId,
        travelOptionId,
        displayText,
        subTravelOptionKey,
        pricing,
        fareCategory
    }
}

export const getSummarisedListOfFares = ({ card, faresToDisplay, showPerAdultPricing }) => {
    return faresToDisplay.map(fare => {
        return getFareAttributes({ fare, showPerAdultPricing })
    })
}

export const selectedFlightsDetails = ({
    selectedFlight,
    metaData,
    subTravelOptions,
    fares,
    showPerAdultPricing
}: {
    selectedFlight: any
    metaData: any
    subTravelOptions: any
    fares: any
    showPerAdultPricing: boolean
}) => {
    if (isEmpty(selectedFlight)) return {}
    const { departureTime, arrivalTime, airlineCode, isMultiAirline, travelOptionId } = getFlightInfo(
        selectedFlight,
        metaData
    )
    const fareData = getFaresInfo(selectedFlight, subTravelOptions, fares) || []
    let cheapestFare = fareData.find(fare => fare?.isCheapest)
    if (isEmpty(cheapestFare)) {
        cheapestFare = fareData[0]
    }
    const { displayPrice, strikeOffPrice, fareId } = getFareAttributes({
        fare: cheapestFare,
        showPerAdultPricing
    })
    const formatedDepartureTime = convertToHHMMFormat(departureTime)
    const formatedArrivalTime = convertToHHMMFormat(arrivalTime)
    return {
        displayPrice,
        strikeOffPrice,
        formatedDepartureTime,
        formatedArrivalTime,
        airlineCode,
        isMultiAirline,
        travelOptionId,
        fareId
    }
}

export const getMasterTuppleProps = ({
    selectedFlights,
    metaData,
    subTravelOptions,
    fares,
    showPerAdultPricing,
    travelOptionIdsToFareIdsMap
}: {
    selectedFlights: any[]
    metaData: any
    subTravelOptions: any
    fares: any
    showPerAdultPricing: boolean
    travelOptionIdsToFareIdsMap: any
}) => {
    const [onwardFlight, returnFlight] = selectedFlights || []
    const { isSpecialRT, fareId: specialRTFareId = '' } = getSpecialRTFareCase(
        onwardFlight,
        returnFlight,
        travelOptionIdsToFareIdsMap
    )

    const onwardLegData = selectedFlightsDetails({
        selectedFlight: onwardFlight,
        metaData,
        subTravelOptions,
        fares,
        showPerAdultPricing
    })
    const {
        displayPrice: onwardFlightPrice,
        strikeOffPrice: onwardStrikeOffPrice,
        formatedDepartureTime: onwardFormatedDepartureTime,
        formatedArrivalTime: onwardFormatedArrivalTime,
        airlineCode: onwardAirlineCode,
        isMultiAirline: isOnwardMultiAirline,
        travelOptionId: onwardTravelOptionId,
        fareId: onwardFareId
    } = onwardLegData || {}

    const returnLegData = selectedFlightsDetails({
        selectedFlight: returnFlight,
        metaData,
        subTravelOptions,
        fares,
        showPerAdultPricing
    })
    const {
        displayPrice: returnFlightPrice,
        strikeOffPrice: returnStrikeOffPrice,
        formatedDepartureTime: returnFormatedDepartureTime,
        formatedArrivalTime: returnFormatedArrivalTime,
        airlineCode: returnAirlineCode,
        isMultiAirline: isReturnMultiAirline,
        travelOptionId: returnTravelOptionId,
        fareId: returnFareId
    } = returnLegData || {}

    let totalFare = 0
    let totalStrikeOffPrice = 0
    let actualFlightPrice = onwardFlightPrice + returnFlightPrice
    if (isSpecialRT && specialRTFareId) {
        const selectedfare = fares?.[specialRTFareId]
        const { displayPrice: specialRTPrice } = getFareAttributes({
            fare: selectedfare,
            showPerAdultPricing
        })
        totalFare = specialRTPrice
        totalStrikeOffPrice = actualFlightPrice
    } else {
        totalFare = actualFlightPrice
        totalStrikeOffPrice = 0
        // if BE powers strikeoff in future, use this.
        // totalStrikeOffPrice = onwardStrikeOffPrice || returnStrikeOffPrice
        //     ? (onwardStrikeOffPrice ? onwardStrikeOffPrice : onwardFlightPrice) +
        //       (returnStrikeOffPrice ? returnStrikeOffPrice : returnFlightPrice)
        //     : 0
    }

    return {
        totalFare,
        totalStrikeOffPrice,
        onwardFlightPrice,
        onwardFormatedDepartureTime,
        onwardFormatedArrivalTime,
        onwardAirlineCode,
        onwardTravelOptionId,
        isOnwardMultiAirline,
        onwardFareId,
        returnFlightPrice,
        returnFormatedDepartureTime,
        returnFormatedArrivalTime,
        returnAirlineCode,
        isReturnMultiAirline,
        returnTravelOptionId,
        returnFareId,
        isSpecialRT,
        specialRTFareId
    }
}

export const getUnselectedFiltersObj = (filterData: any) => {
    return {
        ...filterData,
        stops: {
            ...(filterData?.stops || {}),
            selectedValues: []
        },
        airlineFilter: {
            ...(filterData?.airlineFilter || {}),
            selectedValues: []
        },
        departureTimeFrom: [
            { ...(filterData?.departureTimeFrom?.[0] || {}), selectedValues: [] },
            { ...(filterData?.departureTimeFrom?.[1] || {}), selectedValues: [] }
        ]
    }
}

export const getSpecialRTFareCase = (onwardFare: any, returnFare: any, travelOptionIdsToFareIdsMap: any) => {
    const {
        brand: onwardBrand,
        fareCategory: onwardFareCategory,
        fareGroup: onwardFareGroup,
        travelOptionId: onwardTravelOptionId
    } = onwardFare || {}
    const {
        brand: returnBrand,
        fareCategory: returnFareCategory,
        fareGroup: returnFareGroup,
        travelOptionId: returnTravelOptionId
    } = returnFare || {}
    let isSpecialRT = false
    if (
        onwardBrand &&
        onwardFareCategory &&
        onwardFareGroup &&
        onwardBrand === returnBrand &&
        onwardFareCategory === returnFareCategory &&
        onwardFareGroup === returnFareGroup
    ) {
        const key = `[${onwardTravelOptionId}, ${returnTravelOptionId}]`
        const { fareId } =
            travelOptionIdsToFareIdsMap?.[key]?.[onwardFareGroup]?.[onwardFareCategory]?.[onwardBrand] || {}
        isSpecialRT = fareId ? true : false
        return { isSpecialRT, fareId }
    }
    return { isSpecialRT, fareId: '' }
}

export const getPaxDetails = (paxCriteria: any[]) => {
    let [adults, children, infants] = [0, 0, 0]
    if (isEmpty(paxCriteria) || !Array.isArray(paxCriteria)) {
        return [adults, children, infants]
    }
    paxCriteria.forEach((pax: any) => {
        if (pax?.type === 'ADT') adults = pax.count
        if (pax?.type === 'CHD') children = pax.count
        if (pax?.type === 'INF') infants = pax.count
    })
    return [adults, children, infants]
}

export const checkMultiAirlineCase = (flights: any[]) => {
    const multiAirlinePairsExclude = [
        ['I5', 'IX'],
        ['IX', 'I5']
    ]
    let isMultiAirline = false
    let airlineCode = flights?.[0]?.airlineCode || ''
    flights.forEach(flight => {
        if (
            airlineCode !== flight.airlineCode &&
            !multiAirlinePairsExclude.some(pair => pair[0] === airlineCode && pair[1] === flight.airlineCode)
        ) {
            isMultiAirline = true
        }
    })
    return isMultiAirline
}

export const getSearchIntentDetails = (searchIntent: { J1: any; J2: any }) => {
    const { J1, J2 } = searchIntent
    const J1from = J1?.origin
    const J1to = J1?.destination
    const { paxCriteria } = J1
    const J1travelDate = getFormattedDateCfw(J1?.departDate).toUpperCase()

    const J2from = J2?.origin
    const J2to = J2?.destination
    const J2travelDate = getFormattedDateCfw(J2?.departDate).toUpperCase()

    return { J1from, J1to, J1travelDate, J2from, J2to, J2travelDate, paxCriteria }
}

export const findKey = (obj: { [x: string]: any }, targetKey: string, results = []) => {
    for (let key in obj) {
        if (key === targetKey) {
            results.push(obj[key])
        } else if (typeof obj[key] === 'object') {
            findKey(obj[key], targetKey, results)
        }
    }
    return results
}

export const createCopunsPayload = ({
    selectedFlights,
    subTravelOptions,
    fares,
    travelOptionsIdsFareIdsMap,
    searchIntent,
    dataId
}: {
    selectedFlights: any[]
    subTravelOptions: any
    fares: any
    travelOptionsIdsFareIdsMap: { [x: string]: { [x: string]: any } }
    searchIntent: any
    dataId: string
}) => {
    const requestedIds = selectedFlights.map((flight: { travelOptionId: any }) => flight.travelOptionId)
    const key = `[${requestedIds.join(', ')}]`
    const travelOptions = selectedFlights.map((flight: { travelOptionId: any }) => {
        const { travelOptionId } = flight || {}
        const listOfFares = getFaresInfo(flight, subTravelOptions, fares) || []
        return {
            [travelOptionId]: {
                travelOptionId,
                fareIds: listOfFares.map((fare: any) => {
                    const { fareId, fareGroup = '', fareCategory = '' } = fare || {}
                    return {
                        fareId,
                        fareGroup,
                        fareCategory
                    }
                })
            }
        }
    })
    let travelOptionIdsToFareIdsMap = [{}]
    if (requestedIds.length > 1 && travelOptionsIdsFareIdsMap?.[key]) {
        travelOptionIdsToFareIdsMap[0] = {
            [key]: {
                travelOptionIds: requestedIds,
                fareIds: findKey(travelOptionsIdsFareIdsMap[key], 'fareId').map(fareId => {
                    const { fareGroup = '', fareCategory = '' } = fares?.[fareId] || {}
                    return {
                        fareId,
                        fareGroup,
                        fareCategory
                    }
                })
            }
        }
    }
    const payload = {
        dataId,
        searchIntent,
        couponIntents: {
            requestedIds: [requestedIds],
            travelOptions,
            travelOptionIdsToFareIdsMap
        }
    }
    return { key, payload }
}

const getSelectedCardsIndex = (selectedFares: any[], cards: { [x: string]: any[] }) => {
    let selectedCardsIndex: any = []
    selectedFares.forEach((fare, index) => {
        const card = cards[listOfJourneyKeys[index]]?.find(card => card.travelOptionId === fare.travelOptionId) || {}
        selectedCardsIndex.push(card?.cardId)
    })
    return selectedCardsIndex
}

export const getCreateCallPayloadForCfw = (selectedFares: any[], searchData: {}, defaultCoupon = '') => {
    const { searchId, searchIntent, cards: resultCardsList, travelOptionIdsToFareIdsMap, fares } = searchData || {}
    const loggedInStatus = isUserSignedIn() ? LOGGED_IN : ''
    const { userId = '' } = getUserAuthValues() || {}
    const sectors = listOfJourneyKeys
        .map((key, index) => {
            const sectorData = searchIntent[key]
            if (isEmpty(sectorData)) {
                return ''
            }
            const { origin = '', destination = '', departDate, cabin, paxCriteria = [] } = sectorData
            const { count, type } = paxCriteria
            return {
                index: index + 1,
                origin: origin,
                destination: destination,
                departDate: departDate,
                cabinType: cabin,
                journeyId: key,
                paxInfos: paxCriteria.map(pax => ({
                    paxType: pax.type,
                    paxCount: pax.count
                }))
            }
        })
        .filter(sector => !isEmpty(sector))
    let payload = {
        searchId: searchId,
        queryContext: {
            searchIntents: {
                sectors
            },
            travelOptionMap: {}
        },
        responseContext: {
            domain: 'IN',
            currency: 'INR',
            responseVersion: 'V1'
        },
        demandContext: {
            userId,
            loggedInStatus
        },
        metaDataContext: {
            defaultCoupon,
            abFeatures: [],
            trackingDetails: {
                selectedCardListId: []
            }
        }
    }
    const {
        subTravelOptionId: subTravelOptionIdJ1,
        fareId: fareIdJ1,
        pricing: pricingJ1,
        travelOptionId: travelOptionIdJ1
    } = selectedFares?.[0] || {}
    payload.queryContext.travelOptionMap = {
        J1: {
            subTravelOptions: [
                {
                    subTravelOptionId: subTravelOptionIdJ1,
                    fareId: fareIdJ1,
                    price: pricingJ1?.totalPricing?.totalPrice || ''
                }
            ],
            travelOptionId: travelOptionIdJ1
        }
    }
    if (!isEmpty(selectedFares?.[1])) {
        const { fareId: specialRTFareId, isSpecialRT } = getSpecialRTFareCase(
            selectedFares[0],
            selectedFares[1],
            travelOptionIdsToFareIdsMap
        )
        if (!isSpecialRT) {
            const {
                subTravelOptionId: subTravelOptionIdJ2,
                fareId: fareIdJ2,
                pricing: pricingJ2,
                travelOptionId: travelOptionIdJ2
            } = selectedFares[1]
            payload.queryContext.travelOptionMap = {
                ...payload.queryContext.travelOptionMap,
                J2: {
                    subTravelOptions: [
                        {
                            subTravelOptionId: subTravelOptionIdJ2,
                            fareId: fareIdJ2,
                            price: pricingJ2?.totalPricing?.totalPrice
                        }
                    ],
                    travelOptionId: travelOptionIdJ2
                }
            }
        } else {
            const selectedfare = fares?.[specialRTFareId] || {}
            const { totalPrice, travelOptionId, subTravelOptionId } = getFareAttributes({
                fare: selectedfare,
                showPerAdultPricing: false
            })
            const farePricingObject = {
                subTravelOptions: [
                    {
                        subTravelOptionId,
                        fareId: specialRTFareId,
                        price: totalPrice / 2
                    }
                ],
                travelOptionId
            }

            payload.queryContext.travelOptionMap = {
                ...payload.queryContext.travelOptionMap,
                J1: farePricingObject,
                J2: farePricingObject
            }
        }
    }
    payload.metaDataContext.trackingDetails.selectedCardListId = getSelectedCardsIndex(selectedFares, resultCardsList)
    return payload
}

export const getServicesDataCacheKey = (data: any) => {
    const { travelOptions = {}, benefits = {}, baggageAllowances = {} } = data || {}
    let cardIdToBenefitsMap = {
        ...travelOptions
    }
    return {
        cardIdToBenefitsMap,
        benefits,
        baggageAllowances
    }
}

export const replacePlaceholders = (str: string, placeholderMap: any) => {
    const placeholderRegex = /\{(\w+)\}/g

    return str.replace(placeholderRegex, (match, placeholderName) => {
        const value = placeholderMap[placeholderName]
        return value !== undefined ? value : match
    })
}

export const getCouponDisplayAndDiscountValue = ({
    selectedFares,
    showPerAdultPricing,
    isRT,
    couponData,
    cardKey,
    travelOptionIdsToFareIdsMap,
    fares
}) => {
    try {
        let couponDiscountDisplayValue = ''
        let fareKey = ''
        if (!isRT && selectedFares?.[0]) {
            let { couponCallout = '', couponCode } = getFareAttributes({ fare: selectedFares[0], showPerAdultPricing })
            return { couponDiscountDisplayValue: couponCallout, discountAmount: 0, couponCode }
        }
        if (selectedFares.length === 2) {
            const { fareId: onwardFareId } = selectedFares[0] || {}
            const { fareId: returnFareId } = selectedFares[1] || {}
            const cardData = couponData?.[cardKey] || {}
            const { isSpecialRT, fareId: specialRTFareId } = getSpecialRTFareCase(
                selectedFares[0],
                selectedFares[1],
                travelOptionIdsToFareIdsMap
            )
            if (isSpecialRT) {
                const { fareId } = fares?.[specialRTFareId]
                fareKey = `[${fareId}]`
            } else {
                fareKey = `[${onwardFareId}, ${returnFareId}]`
            }
            const { couponDetails, perAdultCouponDetails } = cardData?.[fareKey] || {}
            const couponObj = (showPerAdultPricing ? perAdultCouponDetails : couponDetails) || {}
            const { discountAmount, couponCode, message, adultCouponMessage } = couponObj || {}
            if (!isEmpty(couponObj)) {
                couponDiscountDisplayValue = (showPerAdultPricing ? adultCouponMessage : message)
                    .replace('{discountAmount}', formatCurrency(discountAmount))
                    .replace('{couponCode}', couponCode)
            }
            return { couponDiscountDisplayValue, discountAmount, couponCode }
        }
        return { couponDiscountDisplayValue: '', discountAmount: 0, couponCode: '' }
    } catch (e) {
        return { couponDiscountDisplayValue: '', discountAmount: 0, couponCode: '' }
    }
}

export const isCfwFlow = ({ isLoginMandatory = false, variant = '' }) => {
    const isUserLoggedIn = isLoginMandatory ? isUserSignedIn() : true
    const cfwVariants = ['v3', 'v4']
    const isCfwVariant = cfwVariants.includes(variant)
    const isInternalUser = checkInternalCTUser() || checkInternalFKUser()
    return (isUserLoggedIn && isCfwVariant) || isInternalUser
}

export const isPerAdultPricing = (paxCriteria: any[]) => {
    let [adults, children, infants] = getPaxDetails(paxCriteria)
    if (adults + children + infants > 1) {
        return true
    }
    return false
}
