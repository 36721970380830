import { forwardRef, useRef, useState, MouseEvent, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { getSelectStyles } from './style';
import { SelectProps } from './type';
import { ForwardRefComponentWithStyle, OwnProps } from '../../@types';
import TextField from '../TextField';
import useTheme from '../../hooks/useTheme';
import Dropdown from '../Dropdown';
import Container from '../Container/Container';
import ChipArrowDown from '../../assets/svg/chipArrowDown';
import Popover from '../Popover';

type PolymorphicSelect = ForwardRefComponentWithStyle<'div', SelectProps>;
const StyledSelect = styled.div<OwnProps<PolymorphicSelect>>(({ css, ...rest }) =>
	getSelectStyles({ ...rest, ...css })
);

const Select = forwardRef(
	(
		{
			as,
			children,
			optionList = [],
			selectedOption,
			onSelect,
			error,
			disabled = false,
			width = '',
			label = '',
			...rest
		},
		forwardedRef
	) => {
		const theme = useTheme();
		const [open, setOpen] = useState(false);
		const displayRef = useRef<HTMLElement>();
		const [displayNode, setDisplayNode] = useState<HTMLElement>();
		const [menuMinWidthState, setMenuMinWidthState] = useState(width);

		const handleDisplayRef = useCallback((node) => {
			displayRef.current = node;

			if (node) {
				setDisplayNode(node.node);
			}
		}, []);

		const anchorElement = displayNode?.parentNode as HTMLElement;

		useEffect(() => {
			if (open && displayNode) {
				setMenuMinWidthState(`${anchorElement.clientWidth}px`);
				displayRef.current?.focus();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [displayNode]);

		useEffect(() => {
			if (open) {
				setTimeout(() => displayRef.current?.focus(), 0);
			}
		}, [open, displayRef.current]);
		// set width of option list same as parent container
		// also it should
		const handleClick = (e: MouseEvent<HTMLElement>) => {
			if (!disabled) {
				setOpen(!open);
				e.stopPropagation();
			}
		};

		const handleClose = (e) => {
			if (!disabled) {
				setOpen(false);
				e.stopPropagation();
			}
		};

		const handleDropdownSelect = (key: string) => {
			if (!disabled) {
				if (onSelect) {
					const dropDown = optionList.find((option) => option.key === key) || { key, label: '' };
					onSelect(dropDown);
				}
				setOpen(false);
			}
		};

		// Avoid performing a layout computation in the render method.
		let menuMinWidth = menuMinWidthState;

		if (displayNode) {
			menuMinWidth = `${anchorElement.clientWidth}px`;
		}

		return (
			<>
			<StyledSelect as={as} ref={forwardedRef} theme={theme} {...rest}>
				<TextField
					ref={handleDisplayRef}
					disabled={disabled}
					select
					label={label}
					width={width}
					value={selectedOption?.label}
					error={error}
					onClick={handleClick}
					suffix={
						!disabled && (
							<Container>
								<ChipArrowDown color={open ? '#3366CC' : '#1A1A1A'} />
							</Container>
						)
					}
				/>
				</StyledSelect>
				<Popover
					anchorEl={anchorElement}
					open={open}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					minWidth={menuMinWidth}
				>
					<Dropdown
						optionList={optionList}
						showDropdown={open}
						width={width}
						onClick={handleDropdownSelect}
					/>
				</Popover>
			</>
		);
	}
) as PolymorphicSelect;

export default Select;
