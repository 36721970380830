import { css } from 'styled-components';
import { Theme } from '../../../token/theme';
import { StyledRadioProps } from './type';

export const getStyledRadioStyles = ({
	backgroundColor,
	cursor,
	borderWidth,
	borderColor,
	theme,
}: StyledRadioProps & { theme: Theme }) => {
	return css`
		position: absolute;
		cursor: ${cursor};
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: ${backgroundColor};
		border-radius: ${theme.border.radius['50P']};
		border-color: ${borderColor};
		border-width: ${borderWidth};
		border-style: ${theme.border.style.solid};
		display: flex;
		align-items: center;
		justify-content: center;

		&::after {
			content: '';
			background-color: ${theme.color.background.neutral};
			height: ${theme && theme.size['2.5']};
			width: ${theme && theme.size['2.5']};
			border-radius: ${theme.border.radius['50P']};
		}
	`;
};
