import axios from 'axios'
import { getCookie, isEmpty, path } from '@utils/genralUtils'
import { buildVariant, isFkEnv } from '@root/configs/Whitelabel'
import { getOSType, isServer } from './appAgent'
import { readCookie } from './flights'
import { DOMAIN } from '@root/ui/constants'
import { GET_CALL, POST_CALL, DELETE_CALL, PUT_CALL, LOCAL_BASE_URL } from '@api/trips/tripConstants'

const getAppAgentForFk = () => {
    let appAgent = 'PWA'
    if (!isServer()) {
        const deviceType = getOSType()
        if (deviceType === 'android') {
            appAgent = 'AndroidApp'
        }
        if (deviceType === 'ios') {
            appAgent = 'iPhoneApp'
        }
    }
    return appAgent
}

export const apiGetorDeleteHelper = async (
    URL,
    contentType,
    method = 'get',
    requestHeaders = {},
    signal: AbortSignal
) => {
    try {
        let contentTypeText = 'text/plain;charset=UTF-8'
        let appAgent = ''
        if (contentType) {
            contentTypeText = contentType
        }
        if (isFkEnv) {
            appAgent = getAppAgentForFk()
        }
        let headers = {
            Accept: 'application/json',
            r_lang: getCookie('lp') || '',
            'Content-Type': contentTypeText,
            'Preferred-Language': getCookie('lp') || '',
            X_CT_SOURCETYPE: 'MOBILE',
            'X-traffic-source': buildVariant.toLowerCase(),
            'app-agent': appAgent,
            ...window.androidData,
            ...window.iosData,
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0'
        }
        headers = {
            ...headers,
            ...requestHeaders
        }
        let getDeviceIdCookie = getCookie('ct-dvid')
        let responseData
        if (method === 'get') {
            responseData = await axios.get(URL, {
                headers
            })
        } else {
            responseData = await axios.delete(URL, {
                headers,
                signal
            })
        }
        return responseData
    } catch (e) {
        if (e.response.status === 404) return e.response
        console.log(e)
    }
}

export const apiPostHelper = async (URL, PAYLOAD, isPWA = true, contentType, requestHeaders) => {
    try {
        const token = readCookie('tkn')
        let contentTypeText = 'application/json; charset=UTF-8, text/html'
        const params = new Proxy(new URLSearchParams(path(['location', 'search'], window)), {
            get: (searchParams, prop) => searchParams.get(prop)
        })
        const deviceType =
            params?.isAndroidApp === 'true' ? 'AndroidApp' : params?.isIosApp === 'true' ? 'iPhoneApp' : 'PWA'
        let appAgent = 'PWA'
        if (isFkEnv) {
            appAgent = getAppAgentForFk()
        } else {
            if (deviceType === 'AndroidApp') {
                appAgent = 'AndroidApp'
            } else if (deviceType === 'iPhoneApp') {
                appAgent = 'iPhoneApp'
            }
        }

        if (contentType) {
            contentTypeText = contentType
        }
        let headers = isPWA
            ? {
                  // 'X-CT-SOURCETYPE': 'mobile',
                  'app-agent': appAgent,
                  domain: process.env.webpackEnv === 'development' ? 'qa2.cleartrip.com' : 'www.cleartrip.com',
                  new_tkn: token,
                  'Content-Type': contentTypeText
              }
            : {
                  new_tkn: token,
                  'app-agent': 'DESKTOP'
              }

        // if (requestHeaders) {
        //   headers['x-ct-caller-app'] = 'mobile'
        // } else {
        //   headers['X-CT-SOURCETYPE'] = 'pwa'
        // }

        headers = { ...headers, ...requestHeaders }

        let axiosUrl = URL
        if (window.location.origin.indexOf('0.0.0.0') > -1) {
            axiosUrl = `${LOCAL_BASE_URL}${URL}`
        }
        const responseData = await axios.post(axiosUrl, PAYLOAD, {
            headers
        })
        return responseData
    } catch (e) {
        // setSentryError(e)
        return {
            error: e
        }
    }
}

export const apiPutHelper = async (URL, PAYLOAD, isPWA = true, contentType, requestHeaders) => {
    try {
        const token = readCookie('tkn')
        let appAgent = 'PWA'
        let contentTypeText = 'application/json; charset=UTF-8, text/html'
        if (contentType) {
            contentTypeText = contentType
        }
        if (isFkEnv) {
            appAgent = getAppAgentForFk()
        }
        let headers = isPWA
            ? {
                  // 'X-CT-SOURCETYPE': 'mobile',
                  'app-agent': appAgent,
                  domain: process.env.webpackEnv === 'development' ? 'qa2.cleartrip.com' : 'www.cleartrip.com',
                  new_tkn: token,
                  'Content-Type': contentTypeText
              }
            : {
                  new_tkn: token
              }

        // if (requestHeaders) {
        //   headers['x-ct-caller-app'] = 'mobile'
        // } else {
        //   headers['X-CT-SOURCETYPE'] = 'pwa'
        // }

        headers = { ...headers, ...requestHeaders }

        let axiosUrl = URL
        if (window.location.origin.indexOf('0.0.0.0') > -1) {
            axiosUrl = `${LOCAL_BASE_URL}${URL}`
        }
        const responseData = await axios.put(axiosUrl, PAYLOAD, {
            headers
        })
        return responseData
    } catch (e) {
        // setSentryError(e)
        return {
            error: e
        }
    }
}

export const apiGetParamsHelper = async (URL, params, contentType) => {
    try {
        let contentTypeText = 'text/plain;charset=UTF-8'
        let appAgent = 'PWA'
        if (contentType) {
            contentTypeText = contentType
        }
        if (isFkEnv) {
            appAgent = getAppAgentForFk()
        }
        const responseData = await axios.get(URL, {
            headers: {
                Accept: 'application/json',
                r_lang: getCookie('lp') || '',
                'Content-Type': contentTypeText,
                X_CT_SOURCETYPE: 'MOBILE',
                'X-traffic-source': buildVariant.toLowerCase(),
                'app-agent': appAgent,
                ...window.androidData,
                ...window.iosData,
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: '0'
            },
            params
        })
        return responseData
    } catch (e) {
        if (e.response.status === 404) return e.response
        console.log(e)
    }
}
export const apiPutOrPostHelper = async (URL, PAYLOAD, contentType, requestHeaders, method = 'post') => {
    try {
        let contentTypeText = 'application/json; charset=UTF-8, text/html'
        if (contentType) {
            contentTypeText = contentType
        }
        let appAgent = 'PWA'
        let headers = {
            Accept: 'application/json',
            'Content-Type': contentTypeText,
            'Preferred-Language': getCookie('lp') || '',
            X_CT_SOURCETYPE: 'MOBILE',
            'X-traffic-source': buildVariant.toLowerCase(),
            'app-agent': appAgent,
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0'
        }
        if (typeof window !== 'undefined') {
            headers = { ...headers, ...window.androidData, ...window.iosData }
        }
        if (!isEmpty(requestHeaders)) {
            headers = {
                ...headers,
                ...requestHeaders
            }
        }
        let responseData
        if (method === 'post') {
            responseData = await axios.post(URL, PAYLOAD, {
                headers
            })
        } else {
            responseData = await axios.put(URL, PAYLOAD, {
                headers
            })
        }

        return responseData
    } catch (e) {
        return {
            error: e
        }
    }
}

export const apiGetHelper = async (URL, contentType, requestHeaders, signal: AbortSignal) => {
    try {
        let contentTypeText = 'text/plain;charset=UTF-8'
        if (contentType) {
            contentTypeText = contentType
        }
        let appAgent = 'PWA'
        let headers = {
            Accept: 'application/json',
            r_lang: getCookie('lp') || '',
            'Content-Type': contentTypeText,
            'Preferred-Language': getCookie('lp') || '',
            'Cache-Control': 'no-cache',
            'X-traffic-source': buildVariant.toLowerCase(),
            Pragma: 'no-cache',
            Expires: '0',
            X_CT_SOURCETYPE: 'MOBILE',
            'app-agent': appAgent
        }
        if (typeof window !== 'undefined') {
            headers = { ...headers, ...window.androidData, ...window.iosData }
        }
        if (!isEmpty(requestHeaders)) {
            headers = {
                ...headers,
                ...requestHeaders
            }
        }
        const responseData = await axios.get(URL, {
            headers,
            signal
        })
        return responseData
    } catch (e) {
        //setSentryError(e)
        return e
    }
}
export const httpAxios = (url, method = 'GET', data, isPWA = true, header_value = false, caller = false) => {
    const token = readCookie('tkn')
    let instance
    const headers = isPWA
        ? {
              'X-CT-SOURCETYPE': 'mobile',
              'app-agent': 'PWA',
              domain: process.env.webpackEnv === 'development' ? 'qa2.cleartrip.com' : 'www.cleartrip.com',
              new_tkn: token
          }
        : {
              new_tkn: token
          }
    if (caller) {
        headers.caller = 'account'
    }

    const config = { timeout: 120000, headers }
    let axiosUrl = url
    if (window.location.origin.indexOf('0.0.0.0') > -1) {
        axiosUrl = `${DOMAIN}${url}`
    }

    if (method.toLowerCase() === GET_CALL) {
        instance = axios.get(axiosUrl, { params: data, headers })
    } else if (method.toLowerCase() === POST_CALL) {
        instance = axios.post(axiosUrl, data, config)
    } else if (method.toLowerCase() === DELETE_CALL) {
        instance = axios.delete(axiosUrl, { params: data, headers })
    } else if (method.toLowerCase() === PUT_CALL) {
        instance = axios.put(axiosUrl, data, config)
    }
    return instance
}

export const keyDataReducer = (data, key) => {
    let returnDetails = null
    if (data && key) {
        const keysArray = key.split('.') || []
        returnDetails = keysArray.reduce((init, verticalName) => {
            if (!init) {
                return
            }
            const newInit = init && Object.prototype.hasOwnProperty.call(init, verticalName) ? init[verticalName] : null
            return newInit
        }, data)
    }
    return returnDetails
}

export const extractTripDetails = (data, tripDetails) => {
    let returnDetails = null
    if (data && tripDetails) {
        returnDetails = keyDataReducer(data, tripDetails)
    }
    return returnDetails
}

export const getApiDomain = () => {
    const domain = typeof window !== 'undefined' ? path(['location', 'hostname'], window) : ''
    if (!isEmpty(domain)) {
        return window.location.origin
    }
    return 'https://www.cleartrip.ae'
    // switch (domain) {
    //     case 'localhost':
    //     case '0.0.0.0':
    //     case 'www.cleartrip.ae':
    //     case 'me.cleartrip.ae':
    //         return
    //     default:
    //         return 'https://www.cleartrip.ae'
    // }
}

export const getDomain = (isSsr: boolean, host: string) => {
    let env = process.env.APP_ENV || 'production'
    let domain = isSsr ? host : window.location.origin
    switch (env) {
        case 'dev':
            return isSsr ? 'https://me.cleartrip.ae' : host || window.location.origin
        case 'production':
            return isSsr ? host : window.location.origin
        case 'qa':
            return 'https://me.cleartrip.ae'
        default:
            return 'https://www.cleartrip.ae'
    }
}

export const getStealDealDomain = () => {
    let env = process.env.APP_ENV || 'production'
    switch (env) {
        case 'dev':
            return 'https://qa2new.cleartrip.com'
        case 'production':
            return 'https://www.cleartrip.com'
        case 'qa':
            return 'https://qa2new.cleartrip.com'
        default:
            return 'https://www.cleartrip.com'
    }
}
export const getHomePageDomain = () => {
    let env = process.env.APP_ENV || 'production'
    switch (env) {
        case 'dev':
            return 'https://qa2new.cleartrip.com'
        case 'production':
            return 'https://www.cleartrip.com'
        case 'qa':
            return 'https://qa2new.cleartrip.com'
        default:
            return 'https://www.cleartrip.com'
    }
}
