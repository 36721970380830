import React from 'react';

interface BackProps {
    height?: number;
    width?: number;
}

const Back: React.FC<BackProps> = ({ height, width }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' viewBox='0 0 24 24'>
            <mask
                id='mask0_2275_17759'
                style={{ maskType: 'alpha' }}
                width='24'
                height='24'
                x='0'
                y='0'
                maskUnits='userSpaceOnUse'
            >
                <path fill='#D9D9D9' d='M0 0H24V24H0z'></path>
            </mask>
            <g mask='url(#mask0_2275_17759)'>
                <path
                    fill='#1C1B1F'
                    d='M11.1 19.1l-6.45-6.476a.697.697 0 01-.212-.288.922.922 0 010-.675.697.697 0 01.212-.287L11.1 4.9a.822.822 0 01.525-.213c.2-.008.375.063.525.213a.753.753 0 01.025 1.075l-5.3 5.275H18.5c.2 0 .375.07.525.212a.71.71 0 01.225.538.706.706 0 01-.225.537.74.74 0 01-.525.213H6.875l5.3 5.3a.736.736 0 01.212.512.69.69 0 01-.212.538.733.733 0 01-.537.225.734.734 0 01-.538-.225z'
                ></path>
            </g>
        </svg>
    );
};

Back.defaultProps = {
    height: 24,
    width: 24,
};
export default Back;
