import { Formik, FormikHelpers, FormikValues } from 'formik';
import { OwnFormProps } from './type';
import { getValues, getValueObject } from './FormHelper';
import { compare } from 'fast-json-patch';

function Form<T extends FormikValues>({
	children,
	initialValues,
	onSubmit,
	validationSchema,
	initialErrors,
	initialStatus,
	initialTouched,
	onReset,
	validate,
	initialObjectMapping,
}: OwnFormProps<T>) {
	const handleSubmit = (values: T, formikHelpers: FormikHelpers<T>) => {
		let valuesObject;
		let initialValueObject = initialObjectMapping || {};
		if (initialObjectMapping) {
			valuesObject = getValueObject(initialObjectMapping, values);
		} else {
			initialValueObject = getValues(initialValues);
			valuesObject = getValues(values);
		}
		const patchValues = compare(initialValueObject, valuesObject);
		onSubmit(values, formikHelpers, valuesObject, patchValues);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
			onReset={onReset}
			initialStatus={initialStatus}
			initialTouched={initialTouched}
			validate={validate}
			initialErrors={initialErrors}
		>
			{({ handleSubmit, resetForm }) => {
				return (
					<form onSubmit={handleSubmit} onReset={() => resetForm()}>
						{children}
					</form>
				);
			}}
		</Formik>
	);
}

export default Form;
