import { getUltraSDKModule } from '.'
// import FKPlatform from "fk-platform-sdk/web"

let fkPlatformInstance: any = null
export const getFKPlatformInstance = async (name = 'cleartrip.flights') => {
    if (!fkPlatformInstance) {
        let FKPlatform: any = await getUltraSDKModule()
        fkPlatformInstance = new FKPlatform(name)
        // console.log('initialised', fkPlatformInstance)
    }
    return fkPlatformInstance
}
