import React from 'react';

interface DustbinProps {
    height?: number;
    width?: number;
    fill?: string;
}

const Dustbin: React.FC<DustbinProps> = ({ height, width, fill }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            fill='none'
            viewBox='0 0 24 24'
        >
            <mask
                id='mask0_906_85730'
                style={{ maskType: 'alpha' }}
                width='24'
                height='24'
                x='0'
                y='0'
                maskUnits='userSpaceOnUse'
            >
                <path fill='#D9D9D9' d='M0 0H24V24H0z'></path>
            </mask>
            <g mask='url(#mask0_906_85730)'>
                <path
                    fill={fill}
                    d='M7.3 20.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 015.5 18.7V6h-1V4.5H9v-.875h6V4.5h4.5V6h-1v12.7c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H7.3zM17 6H7v12.7c0 .067.033.133.1.2s.133.1.2.1h9.4c.067 0 .133-.033.2-.1s.1-.133.1-.2V6zM9.4 17h1.5V8H9.4v9zm3.7 0h1.5V8h-1.5v9zM7 6v13V6z'
                ></path>
            </g>
        </svg>
    );
};

Dustbin.defaultProps = {
    height: 24,
    width: 24,
    fill: '#3366CC',
};

export default Dustbin;
