import {
    Compartment,
    SelectedAncillariesType,
    SelectedBaggageType,
    SelectedSeatType
} from '@components/Addons/addonsType'
import { seatType } from '@constants/flights/seatMapConstants'
import {
    Ancillary,
    AncillaryDetailsType,
    BaggageAncillaryType,
    BaggageDetailType,
    BaggageInfoType,
    BaggageListType,
    DeckType,
    FlightType,
    JourneyAncillaryType,
    MealInfo,
    PriceRangeType,
    SeatType,
    SegmentAncillaryType,
    StrikeOffDetailsType,
    UserSelectedAncillariesType,
    UserSelectedMealDetail
} from '@root/types'
import { isEmpty } from '@utils/genralUtils'
import React, { Dispatch, SetStateAction } from 'react'
import UpgradeLogo from '@assests/svg/ctUpgrade'
import { Container } from '@ct-design/lib'
import UpgradeIcon from '@assests/png/ctUpgradeIcon.png'
import Image from 'next/image'
import { AddonsType, JourneySegmentsType } from './addonsTypes'
import { OriginDestinationInfoType } from '@root/types/CommonTypes'
import ClearChoicePlusIcon from '@assests/svgs/AddonSvgs/ClearChoicePlusIcon'
import ClearChoiceMaxIcon from '@assests/svgs/AddonSvgs/ClearChoiceMaxIcon'

export const BAGGAGE = 'BAGGAGE'
export const SEAT = 'SEAT'
export const MEAL = 'MEAL'
export const INSURANCE = 'INSURANCE'
export const ONWARD = 'ONWARD'
export const RETURN = 'RETURN'
export const ADD = 'ADD'
export const TRAVELLERS = 'travellers'
export const VEG = 'Veg'
export const NON_VEG = 'Non-Veg'
export const ALL = 'All'
export const FLIGHT_NOT_AVAILABLE = 'FLIGHT_NOT_AVAILABLE'
export const CT_UPGRADE = 'CT_UPGRADE'
export const FREE_MEAL = 'FREE_MEAL'
export const FREE_SEAT = 'FREE_SEAT'
const PLACEHOLDER = '{BENEFIT_TYPE}'
export const ADD_MEAL_API_ERROR = 'Oops, your meal could not be selected. Please try again.'
export const REMOVE_MEAL_API_ERROR = 'Oops, your meal could not be removed. Please try again.'
export const ADD_SEAT_API_ERROR = 'Oops, your seat could not be selected. Please try again.'
export const REMOVE_SEAT_API_ERROR = 'Oops, your seat could not be removed. Please try again.'
export const ADD_BAGGAGE_API_ERROR = 'Oops, your baggage could not be selected. Please try again.'
export const REMOVE_BAGGAGE_API_ERROR = 'Oops, your baggage could not be removed. Please try again.'
export const ADD_INSURANCE_API_ERROR = 'Oops, your insurance could not be selected. Please try again.'
export const REMOVE_INSURANCE_API_ERROR = 'Oops, your insurance could not be removed. Please try again.'

export const ctUpgradeBenefitKeyMap = {
    [SEAT]: FREE_SEAT,
    [MEAL]: FREE_MEAL
}
export const getPriceRanges = (priceType: Set<number>) => {
    let i = 0
    const priceRange = []
    const sortedPriceRange = Array.from(priceType).sort((a, b) => {
        return (a ?? 0) - (b ?? 0)
    })
    if (sortedPriceRange[0] === 0) {
        priceRange.push({ l: 0, r: 0 })
        sortedPriceRange.shift()
    } else {
        priceRange.push({ l: -1, r: -1 })
    }
    while (i < 4 && i < sortedPriceRange.length) {
        priceRange.push({ l: sortedPriceRange[i], r: sortedPriceRange[i] })
        i++
    }
    if (sortedPriceRange[i]) {
        const l = sortedPriceRange[i]
        const r = sortedPriceRange[sortedPriceRange.length - 1]
        priceRange.push({ l, r })
    }
    return priceRange
}

export const getSegmentKey = (segment: { originDestinationInfo: OriginDestinationInfoType }) => {
    const { originDestinationInfo = {} as OriginDestinationInfoType } = segment || {}
    const { from = '', to = '' } = originDestinationInfo || {}
    return from + '_' + to
}

export const getSortedPriceRange = (compartments: Compartment[] = []) => {
    const priceTypes: Set<number> = new Set<number>()
    compartments.forEach(compartment => {
        compartment?.rows?.forEach(row => {
            row.seatDetails.forEach(seat => {
                const { price, strikeOffDetails } = seat
                const { displayAmount } = strikeOffDetails || {}
                if (!isEmpty(displayAmount)) {
                    priceTypes.add(displayAmount!)
                } else {
                    priceTypes.add(price)
                }
            })
        })
    })
    return getPriceRanges(priceTypes)
}

export const getSeatType = ({
    isAvailable,
    isSelected,
    isEmergencyRow
}: {
    isAvailable: boolean
    isSelected: boolean
    isEmergencyRow: boolean
}) => {
    if (!isAvailable) {
        return seatType.UNAVAILABLE
    }
    if (isSelected) {
        return seatType.SELECTED
    }
    if (isEmergencyRow) {
        return seatType.XL
    }
    return seatType.AVAILABLE
}

export const getColorIndex = (range: PriceRangeType[], price: number) => {
    let colorIndex = 0
    range.every((priceRange, idx) => {
        if (price >= priceRange.l && price <= priceRange.r) {
            colorIndex = idx
            return false
        }
        return true
    })
    return colorIndex
}

export const getBoxShadow = (clientHeight: number, scrollHeight: number, scrollTop: number) => {
    const isBottom = clientHeight === scrollHeight - scrollTop && scrollTop !== 0
    const isTop = scrollTop === 0 && clientHeight !== scrollHeight
    const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop

    let boxShadow = 'none'
    const top = 'inset 0px 5px 10px -8px rgb(200 200 200 / 1)'
    const bottom = 'inset 0px -5px 10px -8px rgb(200 200 200 / 1)'

    if (isTop) {
        boxShadow = bottom
    } else if (isBetween) {
        boxShadow = `inset 0px 5px 8px -8px rgb(200 200 200 / 1), inset 0px -5px 8px -8px rgb(200 200 200 / 1)`
    } else if (isBottom) {
        boxShadow = top
    }
    return boxShadow
}

export const updateBoxShadow = (
    ref: React.RefObject<HTMLDivElement>,
    currentShadow: number | string,
    callback: Dispatch<SetStateAction<string>>
) => {
    try {
        const shadow = getBoxShadow(
            ref.current?.clientHeight || 0,
            ref.current?.scrollHeight || 0,
            ref.current?.scrollTop || 0
        )
        if (shadow !== currentShadow) {
            callback(shadow)
        }
    } catch (e) {
        console.log(e)
    }
}
export const checkIfSeatPresent = (segment: SegmentAncillaryType) => {
    const { seatAncillaries } = segment || {}
    const totalRows = seatAncillaries?.aircraftLayout?.lowerDeck?.totalRows || 0
    const isSeatPresent = totalRows > 0
    return isSeatPresent
}

export const checkIfMealPresent = (segment: SegmentAncillaryType) => {
    const { mealAncillaries } = segment || {}
    const isMealPresent = mealAncillaries?.mealInfos?.length ? true : false
    return isMealPresent
}

export const checkIfBaggagePresent = (segment: BaggageDetailType) => {
    const { baggageInfos } = segment || {}
    const isBaggagePresent = baggageInfos?.length ? true : false
    return isBaggagePresent
}

const getSegmentObj = (
    segment: BaggageDetailType | SegmentAncillaryType,
    isJourneyLevelSegment: boolean,
    journeyFlight = {} as FlightType
) => {
    const { originDestinationInfo, flight = journeyFlight } = (segment as SegmentAncillaryType) || {}
    const isMealPresent = isJourneyLevelSegment ? false : checkIfMealPresent(segment as SegmentAncillaryType)
    const isSeatPresent = isJourneyLevelSegment ? false : checkIfSeatPresent(segment as SegmentAncillaryType)
    const isBaggagePresent = isJourneyLevelSegment ? checkIfBaggagePresent(segment as BaggageDetailType) : false
    const { to = '', from = '' } = originDestinationInfo || {}
    const { airlineCode = '' } = flight || {}
    const ancillariesPresentInSegment = []
    if (isMealPresent) ancillariesPresentInSegment.push(MEAL)
    if (isSeatPresent) ancillariesPresentInSegment.push(SEAT)
    if (isBaggagePresent) ancillariesPresentInSegment.push(BAGGAGE)
    const segmentDetails = {
        title: `${from} → ${to}`,
        key: `${from}_${to}`,
        ancillariesPresentInSegment,
        airlineCode
    }
    return segmentDetails
}

export const getSegmentsInJourney = (journeyData: JourneyAncillaryType[], currentAddonOpened: AddonsType) => {
    const segments: Array<Array<JourneySegmentsType>> = [[], []]
    if (isEmpty(journeyData)) return segments
    const isJourneyLevelAncillary = currentAddonOpened === BAGGAGE
    journeyData.forEach((journey, idx) => {
        const {
            segmentAncillaries = [],
            baggageAncillary = {} as BaggageAncillaryType,
            flight = {} as FlightType
        } = journey || {}
        const { baggageDetails = [] } = baggageAncillary || {}
        if (isJourneyLevelAncillary) {
            baggageDetails?.forEach(segment => {
                const segmentObj = getSegmentObj(segment, true, flight)
                segments[idx].push(segmentObj)
            })
        } else {
            segmentAncillaries?.forEach((segmentAncillary, index) => {
                const segmentObj = getSegmentObj(segmentAncillary, false)
                segments[idx].push(segmentObj)
            })
        }
    })
    return segments
}

export const getJourneyLevelData = (ancillaryData: Ancillary) => {
    const journeyData: Array<JourneyAncillaryType> = []
    const { tripAncillariesResponse = [] } = ancillaryData || {}
    const journeyDataObj = tripAncillariesResponse?.filter(tripAncillary => {
        return tripAncillary?.fareSubType === 'RETAIL'
    })
    if (isEmpty(journeyDataObj)) return journeyData
    const { ancillaryDetails = {} as AncillaryDetailsType } = journeyDataObj[0] || {}
    const { journeyAncillaries = [] } = ancillaryDetails || {}
    return journeyAncillaries
}

const fastUrl = `https://ui.cltp.co/images/logos/air-logos/`

export const getTabImage = (flight: any, isDisabled: boolean) => {
    const imageUrl = `${fastUrl}${flight}_2x.png`
    switch (flight) {
        case '6E':
        case 'I5':
        case 'G8':
        case 'AI':
        case 'UK':
        case 'SG':
        case 'QP':
        case '9I':
            return isDisabled ? (
                <img
                    src={`${`${fastUrl}${flight}_2x.png`}`}
                    width="24"
                    height="24"
                    alt={flight}
                    className="br-4"
                    style={{ filter: 'grayscale(1)' }}
                />
            ) : (
                <img src={`${`${fastUrl}${flight}_2x.png`}`} className="br-4" width="24" height="24" alt={flight} />
            )
        default:
            return <img src={`${`${fastUrl}DEFAULT_2x.png`}`} className="br-4" width="24" height="24" alt={flight} />
    }
}

export const getLabel = ({ title, benefits }) => {
    if (isEmpty(benefits)) {
        return title
    }
    if (benefits.includes(CT_UPGRADE)) {
        return (
            <Container className="flex flex-center flex-middle ">
                {title}
                <Container
                    className="flex flex-center flex-middle p-relative h-4 w-4 br-12 ml-2"
                    backgroundColor="#FF4F17"
                >
                    <Image src={UpgradeIcon} alt="" className="" height={8} width={8} />
                </Container>
            </Container>
        )
    }
}

export const getSeatsData = (journeyData: JourneyAncillaryType[]) => {
    const obj: {
        [key: string]: DeckType
    } = {}
    journeyData.forEach(journey => {
        const { segmentAncillaries = [] } = journey
        segmentAncillaries.forEach(segment => {
            const { seatAncillaries } = segment || {}
            const key = getSegmentKey(segment)
            obj[key] = seatAncillaries?.aircraftLayout?.lowerDeck || ({} as DeckType)
        })
    })
    return obj
}

export const getMealsData = (journeyData: JourneyAncillaryType[]) => {
    const obj: {
        [key: string]: MealInfo[]
    } = {}
    journeyData.forEach(journey => {
        const { segmentAncillaries = [] } = journey
        segmentAncillaries?.forEach(segment => {
            const { mealAncillaries } = segment || {}
            const key = getSegmentKey(segment)
            obj[key] = mealAncillaries?.mealInfos || []
        })
    })
    return obj
}

export const getBaggageData: any = (journeyData: JourneyAncillaryType[]) => {
    const obj: {
        [key: string]: BaggageInfoType[]
    } = {}
    journeyData.forEach(journey => {
        const { baggageAncillary } = journey || {}
        const { baggageDetails = [] } = baggageAncillary || {}
        baggageDetails?.forEach(segment => {
            const { baggageInfos } = segment || {}
            const key = getSegmentKey(segment)
            obj[key] = baggageInfos || []
        })
    })
    return obj
}

export const getInsuranceData = insuranceObj => {
    const { insurances = [] } = insuranceObj || {}
    return insurances
}

const isIterable = (array: any) => {
    return !isEmpty(array) && Array.isArray(array)
}

// remove once backend starts sending whole object in selected Ancillaries
const getCompleteAncillaryDetails = ({ selectedSegment, ancillaryList, ancillaryType, journeyLevelAncillaryData }) => {
    let isFound = false
    let ancillaryDetailsList: any[] = []
    if (isIterable(journeyLevelAncillaryData)) {
        journeyLevelAncillaryData.forEach(journey => {
            const { baggageAncillary, segmentAncillaries } = journey || {}
            if (ancillaryType === BAGGAGE) {
                const { baggageDetails } = baggageAncillary || {}
                if (isIterable(baggageDetails)) {
                    baggageDetails.forEach(segment => {
                        const key = getSegmentKey(segment)
                        if (key === selectedSegment) {
                            const { baggageInfos } = segment || {}
                            if (isIterable(baggageInfos)) {
                                ancillaryDetailsList = ancillaryList.map(selectedBaggage => {
                                    return baggageInfos.find(baggageInfo => {
                                        return baggageInfo.baggageCode === selectedBaggage.baggageCode
                                    })
                                })
                            }
                        }
                    })
                }
            } else if (ancillaryType === SEAT) {
                if (isIterable(segmentAncillaries)) {
                    segmentAncillaries.forEach(segment => {
                        const segmentKey = getSegmentKey(segment)
                        if (selectedSegment === segmentKey) {
                            const { compartments } = segment?.seatAncillaries?.aircraftLayout?.lowerDeck || {}
                            if (isIterable(compartments)) {
                                ancillaryList.forEach(selectedSeat => {
                                    compartments.forEach(compartment => {
                                        const { rows } = compartment
                                        if (isIterable(rows)) {
                                            rows.forEach(row => {
                                                const { seatDetails, rowId } = row
                                                if (isIterable(seatDetails)) {
                                                    seatDetails.forEach(seatObj => {
                                                        if (
                                                            selectedSeat.rowId + '' + selectedSeat.columnId ===
                                                            seatObj.seatNumber
                                                        ) {
                                                            ancillaryDetailsList.push({
                                                                ...seatObj,
                                                                rowId: Number(rowId)
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                })
                            }
                        }
                    })
                }
            } else if (ancillaryType === MEAL) {
                if (isIterable(segmentAncillaries)) {
                    segmentAncillaries.forEach(segment => {
                        const segmentKey = getSegmentKey(segment)
                        if (selectedSegment === segmentKey) {
                            const { mealInfos } = segment?.mealAncillaries || {}
                            if (isIterable(mealInfos)) {
                                ancillaryDetailsList = ancillaryList.map(selectedMeal => {
                                    return mealInfos.find(meal => {
                                        return meal.code === selectedMeal.mealCode
                                    })
                                })
                            }
                        }
                    })
                }
            }
        })
    }
    return isEmpty(ancillaryDetailsList) ? ancillaryList : ancillaryDetailsList
}

export const getSelectedAncillary = (
    userSelectedAncillary: UserSelectedAncillariesType,
    journeyLevelAncillaryData: JourneyAncillaryType[]
) => {
    const selectedAncillaries: {
        seat: { [key: string]: SeatType[] }
        meal: { [key: string]: MealInfo[] }
        baggage: { [key: string]: BaggageInfoType[] }
    } = { seat: {}, meal: {}, baggage: {} }
    if (isEmpty(userSelectedAncillary)) {
        return selectedAncillaries
    }
    const { userSelectedJourneyAncillaries } = userSelectedAncillary
    if (Array.isArray(userSelectedJourneyAncillaries) && !isEmpty(userSelectedJourneyAncillaries)) {
        userSelectedJourneyAncillaries.forEach(journeyAncillaries => {
            const { userSelectedBaggageAncillaries, userSelectedSegmentAncillaries } = journeyAncillaries
            if (Array.isArray(userSelectedBaggageAncillaries)) {
                userSelectedBaggageAncillaries.forEach(baggageAncillary => {
                    const { baggageDetails } = baggageAncillary
                    if (Array.isArray(baggageDetails)) {
                        const key = getSegmentKey(baggageAncillary)
                        selectedAncillaries.baggage[key] = getCompleteAncillaryDetails({
                            selectedSegment: key,
                            ancillaryList: baggageDetails,
                            ancillaryType: BAGGAGE,
                            journeyLevelAncillaryData
                        })
                    }
                })
            }
            if (Array.isArray(userSelectedSegmentAncillaries)) {
                userSelectedSegmentAncillaries.forEach(segmentAncillary => {
                    const { mealDetails, seatDetails, segmentOriginDestinationInfo } = segmentAncillary
                    const { from, to } = segmentOriginDestinationInfo
                    const key = from + '_' + to
                    if (Array.isArray(mealDetails)) {
                        selectedAncillaries.meal[key] = getCompleteAncillaryDetails({
                            selectedSegment: key,
                            ancillaryList: mealDetails,
                            ancillaryType: MEAL,
                            journeyLevelAncillaryData
                        })
                    }
                    if (Array.isArray(seatDetails)) {
                        selectedAncillaries.seat[key] = getCompleteAncillaryDetails({
                            selectedSegment: key,
                            ancillaryList: seatDetails,
                            ancillaryType: SEAT,
                            journeyLevelAncillaryData
                        })
                    }
                })
            }
        })
    }
    return selectedAncillaries
}

export const isSeatSelected = ({
    seat,
    segmentSelected,
    seatSegments
}: {
    seat: SeatType
    segmentSelected: string
    seatSegments: { [key: string]: SeatType[] }
}) => {
    const selectedSeatsList = seatSegments?.[segmentSelected]
    let isSelected = false
    if (isIterable(selectedSeatsList)) {
        isSelected = !isEmpty(
            selectedSeatsList.find(selectedSeat => {
                return selectedSeat.seatNumber === seat.seatNumber
            })
        )
    }
    return isSelected
}

export const getBaggageCount = ({
    baggageObj,
    selectedBaggage
}: {
    baggageObj: BaggageInfoType
    selectedBaggage: BaggageInfoType[]
}) => {
    let count = 0
    if (isIterable(selectedBaggage)) {
        selectedBaggage.forEach(baggage => {
            if (baggage.baggageCode === baggageObj.baggageCode) {
                count++
            }
        })
    }
    return count
}

export const getMealCount = ({
    mealObj,
    selectedMeals
}: {
    mealObj: MealInfo
    selectedMeals: UserSelectedMealDetail[]
}) => {
    let count = 0
    if (isIterable(selectedMeals)) {
        selectedMeals.forEach(meal => {
            if (meal.code === mealObj.code || meal.mealCode === mealObj.code) {
                count++
            }
        })
    }
    return count
}

export const createAncillaryPayload = (
    journeyData: JourneyAncillaryType[] = [],
    selectedAncillaries: SelectedAncillariesType
) => {
    const payload = {
        updateRequestList: [
            {
                operationType: 'UPDATE',
                type: 'ANCILLARY',
                timestamp: '',
                userSelectedJourneyAncillaries: []
            }
        ]
    }
    journeyData.forEach(journeyObj => {
        const { segmentAncillaries = [], baggageAncillary = {} as BaggageAncillaryType } = journeyObj
        const { baggageDetails = [] } = baggageAncillary || {}
        const journeyKey = getSegmentKey(journeyObj)
        const journeyObject = {
            userSelectedBaggageAncillaries: [],
            journeyOriginDestinationInfo: { ...journeyObj.originDestinationInfo },
            userSelectedSegmentAncillaries: [],
            isSegmentLevelBaggage: journeyObj.baggageAncillary && journeyObj.baggageAncillary.segmentLevelBaggage
        }
        segmentAncillaries?.forEach(segment => {
            const segmentKey = getSegmentKey(segment)
            const segmentObject = {
                segmentOriginDestinationInfo: {
                    ...segment.originDestinationInfo
                },
                mealDetails: [],
                seatDetails: []
            }
            const selectedMeals = selectedAncillaries?.meal?.[segmentKey] || []
            selectedMeals.forEach((meal, index) => {
                if (!isEmpty(meal)) {
                    const mealsObj = { mealCode: '', mealInfo: '', paxIndex: 0 }
                    mealsObj.mealCode = meal.code
                    mealsObj.mealInfo = meal.description
                    mealsObj.paxIndex = Number(index + 1)
                    segmentObject.mealDetails.push(mealsObj)
                }
            })
            const selectedSeats = selectedAncillaries?.seat?.[segmentKey] || []
            selectedSeats.forEach((seat, index) => {
                if (!isEmpty(seat)) {
                    const seatsObj = {}
                    seatsObj.rowId = seat.rowId
                    seatsObj.columnId = seat.columnId
                    seatsObj.paxIndex = Number(index + 1)
                    segmentObject.seatDetails.push(seatsObj)
                }
            })
            journeyObject.userSelectedSegmentAncillaries.push(segmentObject)
        })

        baggageDetails?.forEach(baggageObj => {
            const segmentKey = getSegmentKey(baggageObj)
            const baggageObject = {
                originDestinationInfo: { ...baggageObj.originDestinationInfo },
                baggageDetails: []
            }
            const baggageList = selectedAncillaries?.baggage?.[segmentKey] || []
            baggageList.forEach((baggage, index) => {
                if (!isEmpty(baggage)) {
                    const baggageObj = {}
                    baggageObj.baggageCode = baggage.baggageCode
                    baggageObj.baggageInfo =
                        baggage.baggageInfo || baggage.baggageList?.[0].quantity + ' ' + baggage.baggageList?.[0].unit
                    baggageObj.paxIndex = Number(index + 1)
                    baggageObject.baggageDetails.push(baggageObj)
                }
            })
            journeyObject.userSelectedBaggageAncillaries.push(baggageObject)
        })
        payload.updateRequestList[0].userSelectedJourneyAncillaries.push(journeyObject)
    })
    payload.updateRequestList[0].timestamp = new Date().getTime()
    return payload
}

export const getPaxCountForAncillary = userData => {
    const { paxInfo: { adults, children } = { adults: 0, children: 0 } } = userData?.travellerMeta || {}
    return adults + children
}

export const getSelectedInsurances = insurances => {
    return insurances.filter(insurance => {
        const { insuranceDetails } = insurance
        return insuranceDetails?.[0]?.selected
    })
}

export const getInsuranceTotalPrice = insurances => {
    let totalPrice = 0
    insurances?.[0]?.insuranceDetails?.forEach(insuranceItem => {
        totalPrice += insuranceItem.totalPremiumAmount
    })
    return totalPrice
}

export const createInsurancePayload = selectedInsuranceList => {
    const selectedInsurances = selectedInsuranceList.map(insurance => {
        const { insuranceType, insuranceDetails } = insurance
        const { provider, endDate, startDate } = insuranceDetails[0] || {}
        return { insuranceType, provider, endDate, startDate }
    })
    const payload = {
        updateRequestList: [
            {
                operationType: 'ADD',
                type: 'INSURANCE',
                timestamp: new Date().getTime(),
                selectedInsurances
            }
        ]
    }
    return payload
}

export const getTextForAncillary = (currentAddonOpened: string) => {
    let ancillary = ''
    switch (currentAddonOpened) {
        case SEAT:
            ancillary = 'seats'
            break
        case MEAL:
            ancillary = 'meals'
            break
        case BAGGAGE:
            ancillary = 'additional baggage'
            break
        case INSURANCE:
            ancillary = 'insurance'
            break
        default:
            ancillary = 'add-ons'
    }
    return ancillary
}

export const getTotalItineraryPrice = priceSummary => {
    const { finalPricing } = priceSummary || {}
    const { amount, strikeOffDetails } = finalPricing || {}
    if (isEmpty(strikeOffDetails)) {
        return amount
    }
    const { displayAmount } = strikeOffDetails
    return displayAmount
}

export const getStrikeOffPrice = priceSummary => {
    const { finalPricing } = priceSummary || {}
    const { strikeOffDetails } = finalPricing || {}
    if (isEmpty(strikeOffDetails)) {
        return ''
    }
    const { strikeOffAmount } = strikeOffDetails
    return strikeOffAmount
}

export const getUpgradeBenefitsBySegment = (ancilliaryData: Ancillary) => {
    const { journeyAncillaries } = ancilliaryData?.tripAncillariesResponse?.[0]?.ancillaryDetails || []
    const ctUpgradeSegmentDetails = {}
    if (!isEmpty(journeyAncillaries) && Array.isArray(journeyAncillaries)) {
        journeyAncillaries.forEach(journey => {
            const { segmentAncillaries } = journey || {}
            const { benefitInfos } = journey || {}
            if (Array.isArray(segmentAncillaries)) {
                segmentAncillaries.forEach(segment => {
                    const segmentKey = getSegmentKey(segment)
                    const ctUpgradeData = benefitInfos?.find((benefit: { benefitType: any }) => {
                        const { benefitType } = benefit
                        return benefitType === CT_UPGRADE
                    })
                    ctUpgradeSegmentDetails[segmentKey] = ctUpgradeData || {}
                })
            }
        })
    }
    return ctUpgradeSegmentDetails
}

export const getCtUpgradeBenefits = ({
    upgradeBenefitsBySegment,
    selectedAddonTab,
    segmentSelected
}: {
    upgradeBenefitsBySegment: Object
    selectedAddonTab: AddonsType
    segmentSelected: string
}) => {
    let ctUpgradeCallout: (string | JSX.Element)[] = []
    let isCtUpgrade = false
    try {
        const ctUpgradeData = upgradeBenefitsBySegment[segmentSelected]
        const { benefits } = ctUpgradeData || {}

        if (!isEmpty(benefits)) {
            benefits.forEach((benefit: { benefitName: any; displayMessage: any }) => {
                const { benefitName, displayMessage } = benefit
                if (benefitName === ctUpgradeBenefitKeyMap[selectedAddonTab]) {
                    isCtUpgrade = true
                    ctUpgradeCallout = replaceJSX(
                        displayMessage,
                        PLACEHOLDER,
                        <UpgradeLogo className="ml-1" style={{ marginTop: '2px' }} />
                    )
                }
            })
        }
        return { isCtUpgrade, ctUpgradeCallout }
    } catch (ex) {
        console.log(ex)
        return { isCtUpgrade, ctUpgradeCallout }
    }
}

export const getAncillariesSelectedPerSegment = ({
    onwardSectors,
    returnSectors,
    selectedAncillaries,
    paxCount,
    requiredData,
    upgradeBenefitsBySegment
}) => {
    try {
        let isPartialMealSelection = false
        let isPartialSeatSelection = false
        let isPartialCtUpgradeSelection = false
        let isPartialCtUpgradeSelectiononSeat = false
        let isPartialCtUpgradeSelectiononMeal = false
        const isSeatRequired = requiredData.includes(SEAT)
        const isMealRequired = requiredData.includes(MEAL)
        let missingSeatsCount = 0
        let ctUpgradeSectors: (String | null)[] = []
        if (!isEmpty(upgradeBenefitsBySegment)) {
            ctUpgradeSectors = Object.keys(upgradeBenefitsBySegment)
        }
        const onwardSegmentsData = onwardSectors?.map(sector => {
            const { key, title, ancillariesPresentInSegment = [], airlineCode } = sector || {}
            const isSeatIncluded = ancillariesPresentInSegment?.includes(SEAT)
            const isMealIncluded = ancillariesPresentInSegment?.includes(MEAL)
            const selectedMealCount = selectedAncillaries?.meal?.[key]?.length || 0
            const selectedSeatCount = selectedAncillaries?.seat?.[key]?.length || 0
            if (isSeatRequired && isSeatIncluded && selectedSeatCount < paxCount) {
                isPartialSeatSelection = true
                missingSeatsCount += paxCount - selectedSeatCount
                if (ctUpgradeSectors.includes(key)) {
                    const { isCtUpgrade } = getCtUpgradeBenefits({
                        upgradeBenefitsBySegment,
                        selectedAddonTab: SEAT,
                        segmentSelected: key
                    })
                    if (isCtUpgrade) {
                        isPartialCtUpgradeSelection = true
                        isPartialCtUpgradeSelectiononSeat = true
                    }
                }
            }
            if (isMealRequired && isMealIncluded && selectedMealCount < paxCount) {
                isPartialMealSelection = true
                if (ctUpgradeSectors.includes(key)) {
                    const { isCtUpgrade } = getCtUpgradeBenefits({
                        upgradeBenefitsBySegment,
                        selectedAddonTab: MEAL,
                        segmentSelected: key
                    })
                    if (isCtUpgrade) {
                        isPartialCtUpgradeSelection = true
                        isPartialCtUpgradeSelectiononMeal = true
                    }
                }
            }
            return { title, paxCount, selectedMealCount, selectedSeatCount, airlineCode, ancillariesPresentInSegment }
        })

        const returnSegmentsData = returnSectors?.map(sector => {
            const { key, title, ancillariesPresentInSegment = [], airlineCode } = sector || {}
            const isSeatIncluded = ancillariesPresentInSegment?.includes(SEAT)
            const isMealIncluded = ancillariesPresentInSegment?.includes(MEAL)
            const selectedMealCount = selectedAncillaries?.meal?.[key]?.length || 0
            const selectedSeatCount = selectedAncillaries?.seat?.[key]?.length || 0
            if (isSeatRequired && isSeatIncluded && selectedSeatCount < paxCount) {
                isPartialSeatSelection = true
                missingSeatsCount += paxCount - selectedSeatCount
                if (ctUpgradeSectors.includes(key)) {
                    const { isCtUpgrade } = getCtUpgradeBenefits({
                        upgradeBenefitsBySegment,
                        selectedAddonTab: SEAT,
                        segmentSelected: key
                    })
                    if (isCtUpgrade) {
                        isPartialCtUpgradeSelection = true
                        isPartialCtUpgradeSelectiononSeat = true
                    }
                }
            }
            if (isMealRequired && isMealIncluded && selectedMealCount < paxCount) {
                isPartialMealSelection = true
                if (ctUpgradeSectors.includes(key)) {
                    const { isCtUpgrade } = getCtUpgradeBenefits({
                        upgradeBenefitsBySegment,
                        selectedAddonTab: MEAL,
                        segmentSelected: key
                    })
                    if (isCtUpgrade) {
                        isPartialCtUpgradeSelection = true
                        isPartialCtUpgradeSelectiononMeal = true
                    }
                }
            }
            return { title, paxCount, selectedMealCount, selectedSeatCount, airlineCode, ancillariesPresentInSegment }
        })
        return {
            onwardSegmentsData,
            returnSegmentsData,
            isPartialMealSelection,
            isPartialSeatSelection,
            missingSeatsCount,
            isPartialCtUpgradeSelection,
            isPartialCtUpgradeSelectiononSeat,
            isPartialCtUpgradeSelectiononMeal
        }
    } catch (ex) {
        console.log(ex)
        return {
            onwardSegmentsData: [],
            returnSegmentsData: [],
            isPartialMealSelection: false,
            isPartialSeatSelection: false,
            missingSeatsCount: 0,
            isPartialCtUpgradeSelection: false,
            isPartialCtUpgradeSelectiononSeat: false,
            isPartialCtUpgradeSelectiononMeal: false
        }
    }
}

export const getActualAncillaryPrice = (ancillary: SeatType | MealInfo) => {
    const { price, strikeOffDetails } = ancillary || {}
    const { displayAmount, strikeOffAmount } = strikeOffDetails || ({} as StrikeOffDetailsType)
    const finalPrice = !isEmpty(displayAmount) ? displayAmount : price
    return { finalPrice, strikeOffAmount }
}

export const getSelectedSeatsDetails = (selectedSeats: SelectedSeatType, selectedSegment: string) => {
    const selectedSeatsList = selectedSeats[selectedSegment] || []
    const seatNumbers: String[] = []
    let totalPriceOfSeatsInSegment = 0
    selectedSeatsList.forEach(seat => {
        const { seatNumber } = seat || {}
        const { finalPrice } = getActualAncillaryPrice(seat)
        totalPriceOfSeatsInSegment += finalPrice
        seatNumbers.push(seatNumber)
    })
    return { selectedSeatNumbers: seatNumbers, totalPriceForSegment: totalPriceOfSeatsInSegment }
}

export const replaceJSX = (str: string, find: string, replace: JSX.Element) => {
    let parts = str.split(find)
    let result = []
    for (let i = 0; i < parts.length; i++) {
        result.push(parts[i])
        if (i < parts.length - 1) result.push(replace)
    }
    return result
}

export const getFreeSeatsAndMealsData = (journeyLevelAncillaryData: JourneyAncillaryType[]) => {
    let isSeatFree = false
    let isMealFree = false
    try {
        journeyLevelAncillaryData.forEach(journey => {
            journey.benefitInfos.forEach(tripBenefits => {
                tripBenefits.benefits.forEach(benefit => {
                    if (benefit?.supplyBenefitType === 'SEAT' && !benefit?.details?.chargeable) {
                        isSeatFree = true
                    } else if (benefit?.supplyBenefitType === 'MEAL' && !benefit?.details?.chargeable) {
                        isMealFree = true
                    }
                })
            })
        })
    } catch (e) {}
    return { isSeatFree, isMealFree }
}

export const stringSplitter = (
    inputString: string,
    splitBasis = ' ',
    processSplitString: (value: string, index: number, array: string[]) => void
) => {
    if (isEmpty(inputString) || typeof inputString !== 'string') return ''
    const splitString = inputString.split(splitBasis)
    if (typeof processSplitString === 'function') {
        return splitString.map(processSplitString)
    } else {
        return splitString.join(splitBasis)
    }
}

export const getFlexibilityIcon = (itineraryData: any) => {
    const addonCard = itineraryData?.ADDON_CARDS?.addOnCards?.find(addOnCard => {
        return addOnCard?.selected
    })
    const flexibilityType = addonCard?.fareCardMessage?.title
    if (flexibilityType === 'ClearChoice Plus') {
        return <ClearChoicePlusIcon />
    } else if (flexibilityType === 'ClearChoice Max') {
        return <ClearChoiceMaxIcon />
    }
    return null
}
