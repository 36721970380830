import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../configureStore";

export interface AmendmentDataState {
    isAmendment: boolean
}

const initialState: AmendmentDataState = {
    isAmendment: false  
}


export const amendmentDataSlice = createSlice({
    name: 'amendment',
    initialState,
    reducers: {
        
        setAmendment: (state, action) => {
            state.isAmendment = action.payload
        }
    },
    
})

const getAmendmentData = (state: RootState) => state.amendment

const { reducer, actions } = amendmentDataSlice
const { setAmendment } = actions || {}

export { setAmendment,getAmendmentData}

export default reducer;
