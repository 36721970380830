import React from 'react'
import useTheme from '../../hooks/useTheme'
import StyledTypography from './StyledTypography/StyledTypography'
import { getTypographyStyles } from './style'
import { TypographyColor, TypographyProps } from './type'

const Typography: React.FC<TypographyProps> = ({
    children = null,
    variant,
    color = TypographyColor.PRIMARY,
    isClickable = false,
    isStriked = false,
    onClick = () => {},
    className = '',
    colorCode = '',
    lineClamp,
    dataTestId = ''
}) => {
    const theme = useTheme()
    const {
        color: typographyColor,
        cursor,
        textDecoration,
        fontSize,
        fontWeight,
        lineHeight,
        display,
        webkitLineClamp,
        webkitBoxOrient,
        overflow
    } = getTypographyStyles({
        theme,
        variant,
        color,
        isClickable,
        isStriked,
        colorCode,
        lineClamp
    })

    return (
        <div onClick={isClickable ? onClick : undefined}>
            <StyledTypography
                color={typographyColor}
                cursor={cursor}
                textDecoration={textDecoration}
                fontSize={fontSize}
                lineHeight={lineHeight}
                fontWeight={fontWeight}
                display={display}
                webkitLineClamp={webkitLineClamp}
                webkitBoxOrient={webkitBoxOrient}
                overflow={overflow}
                className={className}
                data-testid={dataTestId ? dataTestId : undefined}
            >
                {children}
            </StyledTypography>
        </div>
    )
}

export default Typography
