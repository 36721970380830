let appMobilePrefix = ''

const path = (p, o) => {
    const reducerFunction = (xs, x) => {
        return xs && xs[x] ? xs[x] : null
    }
    return p.reduce(reducerFunction, o)
}
if (typeof window !== 'undefined') {
    const pathname = path(['location', 'pathname'], window)
    if (pathname.indexOf('/m/flights') > -1) {
        appMobilePrefix = '/m'
    }
}

export const appRoutePrefix = appMobilePrefix

export const arrowLeftToRight = '→'

export const ONWARD_DIR_ARROW = arrowLeftToRight

export const MAX_ADULT_SELECTION = 9
export const MIN_ADULT_SELECTION = 1

export const emiDataReqCount = {
    oneWay: 15,
    roundTrip: 10
}

export const FARE_TYPE = {
    REGULAR_FARE: 'Regular',
    STUDENT_FARE: 'Student',
    SENIOR_CITIZEN_FARE: 'Senior Citizen',
    ARMED_FORCES_FARE: 'Army'
}
export const LESS_THAN_ZERO = 'LESS_THAN_ZERO'
export const LESS_THAN_THIRTY = 'LESS_THAN_THIRTY'
export const ARRIVAL_DEPARTURE_OVERLAP_TIME = 0
export const ARRIVAL_DEPARTURE_THRESHOLD = 30

export const NAVBAR_ACC_VAL = 'myAccount'
export const NAVBAR_HOME_VAL = 'home'
export const NAVBAR_TRIPS_VAL = 'myTrips'
export const NAVBAR_HOTELS_VAL = 'hotels'
export const NAVBAR_OFFER_VAL = 'offer'
export const WALLET_INFO_URL = '/wallet'
export const TRIP_LIST_URL = '/accounts/tripslist'

export const CLEARTRIP = 'CLEARTRIP'
export const FLIPKART = 'FLIPKART'
export const FARE_CARDS = 'FARE_CARDS'
export const FLIGHT_DETAILS = 'FLIGHT_DETAILS'
export const GIFT_VOUCHER = 'GIFT_VOUCHER'
export const MEDICANCEL = 'MEDICANCEL'
export const SUPERCOINS = 'SUPERCOINS'
export const CLEAR_ADVANTAGE = 'CLEAR_ADVANTAGE'

export const FARE_BLOCK_TEMPLATE = 'FARE_BLOCK_TEMPLATE'
export const SCRIPT_LOADER = 'SCRIPT_LOADER'

export const SHEET_TRANSITION_DELAY = 200

export enum NavBarItemIndex {
    NAVBAR_HOME_VAL = 0,
    NAVBAR_TRIPS_VAL = 1,
    NAVBAR_OFFER_VAL = 2,
    NAVBAR_ACC_VAL = 3
}

export const navItemsList = [
    {
        label: 'Flights',
        value: NAVBAR_HOME_VAL
    },
    {
        label: 'Hotels',
        value: NAVBAR_HOTELS_VAL
    },
    {
        label: 'Offers',
        value: NAVBAR_OFFER_VAL
    },
    {
        label: 'You',
        value: NAVBAR_ACC_VAL
    }
]

export const INTERNAL_COMPANY_LIST = ['cleartrip.com', 'flipkart.com', 'myntra.com']

export const defaultCookieValues = {
    gh42: 'a',
    gh45: 'c',
    m_ff: 'a',
    show_conv_fee: 'a',
    ctUpgrade: 'c',
    ghV40: 'b',
    gh54: 'a',
    vasExp5: 'c',
    mealImage: 'b',
    otpless: 'd',
    intlTravelIns: 'c',
    ptc_fare: 'b',
    vasGH8: 'b',
    gh62: 'a',

    test: 'b',
    ptbm: 'b',
    ptbmi: 'b',
    ptbme: 'b',
    is_Bento_Itn_User: 'a',
    is_Bento_Itn_Me_User: 'a',
    home_page: 'd',
    ghV7_m: 'a',
    testGlobal: 'a',
    gh32: 'a',
    ghV41: 'b',
    hi_five: 'a',
    card_tokenization: 'b',
    tk_home: 'b',
    tk_itin: 'b',
    highRps: 'b',
    login_init: 'a',
    inlineLogin_nudge: 'a',
    appAdoptionSrp: 'a',
    newHighRps: 'b',
    show_fk_sso: 'a',
    show_fk_sso_pwa: 'a',
    auto_detect_mobile: 'b',
    axisLoyalty: 'b',
    next_exp_v2: 'b',
    ff_Intl: 'c',
    h_itinerarypwa: 'b',
    ff_Nudges: 'a',
    New_Coupon_Experience: 'a',
    aerobridge_v2: 'd',
    appAdoptionBanner: 'b',
    intl_revamp: 'a',
    scV4: 'a',
    stealDeal_Exp: 'a',
    intlflexmax: 'a',
    gh64: 'a',
    seatCallout: 'c'
}

type MonthConfig = Record<string, string>
export const monthConfig: MonthConfig = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec'
}

export const PER_ADULT = 'per adult'

export const TAP_TO_VIEW_TOTAL_PRICE = 'Tap to view total price'
