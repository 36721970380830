import axios from "axios"
import dayjs from "dayjs"
import { getLanguageMeta } from "@ui/utils"
import { utmGetUserPrefCurrency } from "@utils/flights"
import logError from "@utils/logError"
import { DOMAIN } from "@ui/constants"
import {
  getApiDomain,
} from "src/utils/helper";
export async function flexiSearchFetch(inputData) {
  const payload = createFlexiSearchObj(inputData)
  let responseData
  await axios
    .post(`/flights/flexisearchV2`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      responseData = response
    })
    .catch((error) => {
      responseData = { data: calculateInitDateRange(inputData.departDate, "-") }
      logError(error)
    })
  return responseData
}

export function createFlexiSearchObj(searchQuery) {
  if (!searchQuery.departDate) {
    if (searchQuery.dx) {
      searchQuery.departDate = dayjs()
        .add(parseInt(searchQuery.dx), "days")
        .format("DD/MM/YYYY")
    } else {
      searchQuery.departDate = dayjs().add(7, "days").format("DD/MM/YYYY")
    }
  }
  return {
    searchParams: {
      from: searchQuery.source,
      to: searchQuery.destination,
      depart_date: searchQuery.departDate,
      adults: searchQuery.travellers.adults.toString(),
      childs: searchQuery.travellers.children.toString(),
      infants: searchQuery.travellers.infants.toString(),
      class: searchQuery.flightClass,
      intl: searchQuery.isIntl ? "y" : "n",
      sd: Date.now().toString(),
      page: "loaded",
      airline: "",
      carrier: "",
      sellingCountry: utmGetUserPrefCurrency()
        ? utmGetUserPrefCurrency().substr(0, 2)
        : getLanguageMeta().locale,
    },
    journey: "onward",
    depart_date_range: calculateDateRange(searchQuery.departDate),
    return_date_range: "",
    filters: {
      onward: {},
    },
  }
}

function calculateDateRange(departDate) {
  const departureDate = dayjs(departDate, "DD/MM/YYYY")
  const currentDate = dayjs(dayjs().format("DD/MM/YYYY"), "DD/MM/YYYY")
  let startDateRange = departureDate.diff(currentDate, "days")
  startDateRange = startDateRange > 90 ? 90 : startDateRange
  const endDateRange = 90

  const startDate = dayjs(departDate, "DD/MM/YYYY")
    .subtract(startDateRange, "days")
    .format("DD-MM-YYYY")
  const endDate = dayjs(departDate, "DD/MM/YYYY")
    .add(endDateRange, "days")
    .format("DD-MM-YYYY")
  return `${startDate}_${endDate}`
}

export function calculateInitDateRange(departDate, defaultPrice) {
  let dateRange = calculateDateRange(departDate)
  const dateArray = []
  const flexiDateObj = {}
  const flexiDateArray = []

  dateRange = dateRange.split("_")

  let startDate = new Date(dayjs(dateRange[0], "DD-MM-YYYY"))
  const endDate = new Date(dayjs(dateRange[1], "DD-MM-YYYY"))

  // Create Date Array
  while (startDate <= endDate) {
    dateArray.push(dayjs(startDate).format("DD-MM-YYYY"))
    const newDate = startDate.setDate(startDate.getDate() + 1)
    startDate = new Date(newDate)
  }

  dateArray.forEach((date, index) => {
    const obj = {}
    obj.date = date
    obj.pr = defaultPrice
    flexiDateArray.push(obj)
  })

  flexiDateObj.onward = flexiDateArray

  return flexiDateObj
}

export function getValidFlexiData(data) {
  let flexiCount = 0
  data.onward.map((item) => {
    if (item.pr !== "-") {
      flexiCount += 1
    }
    return true // for es-lint
  })
  return flexiCount
}
