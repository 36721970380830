import type { CSSObject } from 'styled-components';
import { CSSWithTheme } from '../../@types';

export const getSelectStyles = ({
    width,
    height,
    theme,
    ...rest
}: CSSWithTheme): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    position: 'relative',
});