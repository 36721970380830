import dayjs from "dayjs";

import customParseFormat from "dayjs/plugin/customParseFormat";

import isBetween from "dayjs/plugin/isBetween";
import logError from "@utils/flights/logError";

dayjs.extend(customParseFormat);

dayjs.extend(isBetween);
export function getFormattedDate(
  date,
  outputFormat = "",
  inputFormat = ""
) {
  let newDate = "invalid date";
  try {
    newDate = date
      ? dayjs(date, inputFormat).format(outputFormat)
      : dayjs().format(outputFormat);
  } catch (e) {
    newDate = "invalid date";
    logError(e);
  }
  return newDate;
}
