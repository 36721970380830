import { apiGetHelper, getApiDomain, apiPutOrPostHelper } from 'src/utils/helper'
import { isEmpty } from '@ui/utils'
import dayjs from 'dayjs'

export const resolveKeysInObjectsArrays = (p, o) => {
    const reducerFunction = (xs, x) => {
        return xs && xs[x] ? xs[x] : null
    }
    return p.reduce(reducerFunction, o)
}
const _createSegmentParmas = (key, sectors, fare, miniRuleFlag, idx, journeyType, freeBaggageInfo) => {
    const segmentArray = []
    let sectorObj = sectors[key]
    const { flights: { segments = [] } = {} } = sectorObj || {}
    const airlineNumArr = []
    let newCount = 0
    segments.forEach((segment, index) => {
        const {
            arrival: { timestamp: arrivalTimeStamp, airportCode: arrivalAirportCode } = {},
            departure: { timestamp: departureTimestamp, airportCode: departureAirportCode } = {},
            flightNumber,
            fk,
            oa
        } = segment || {}

        let segmentObj = {}
        if (miniRuleFlag) {
            if (airlineNumArr.indexOf(flightNumber) !== -1) {
                newCount--
            }

            let fareBase
            let baseClass
            if (!isEmpty(journeyType)) {
                if (journeyType === 'depart') {
                    fareBase = fare && fare.mi_ofb && fare.mi_ofb.split(',')[idx]
                    baseClass = fare.bc.split(',')[idx]
                } else if (journeyType === 'return') {
                    fareBase = fare && fare.mi_rfb && fare.mi_rfb.split(',')[idx]
                    baseClass = fare.bc.split(',')[idx]
                }
            } else {
                if (idx === 1 && fare && fare.fb && fare.fb.split(',').length === 1) {
                    fareBase = fare && fare.fb && fare.fb.split(',')[0]
                    baseClass = fare.bc.split(',')[idx]
                } else {
                    fareBase = fare && fare.fb && fare.fb.split(',')[newCount]
                    baseClass = fare.bc.split(',')[newCount]
                }
            }
            airlineNumArr.push(flightNumber)
            newCount++
            segmentObj = {
                bc: baseClass,
                fb: fareBase,
                al: flightNumber.split('-')[0],
                dd: `${dayjs(departureTimestamp).format('DD-MM-YYYY')}`,
                sup: fk.split(`_${segment.flightNumber}`)[0]
            }
        } else {
            segmentObj = {
                ad: `${dayjs(arrivalTimeStamp).format('YYYY-MM-DD HH:mm')}`,
                al: flightNumber.split('-')[0],
                dd: `${dayjs(departureTimestamp).format('YYYY-MM-DD HH:mm')}`,
                oa,
                bc: fare.bc.split(',')[idx]
            }
        }
        let segmentArrObj = {
            ...segmentObj,
            arr: arrivalAirportCode,
            dep: departureAirportCode,
            fn: flightNumber.split('-')[1]
        }
        if (freeBaggageInfo && fare.fb) {
            segmentArrObj = { ...segmentArrObj, fareBasisCode: fare.fb }
        }
        if (fare.fk) {
            segmentArrObj = { ...segmentArrObj, fareKey: fare.fk }
        }
        segmentArray.push(segmentArrObj)
    })

    return segmentArray
}
export const freeBaggageInfoPayloadHelper = ({
    cardDetails,
    jsons,
    sectorKeys,
    sectors,
    searchQuery,
    type = 'normal'
}) => {
    const { isIntl: intl, flightClass: classType = 'Economy' } = searchQuery || {}
    const { adults, children, infants } = searchQuery.travellers || {}
    const { priceBreakup: { fare = {}, SPECIAL_FARE = {} } = {} } = cardDetails || {}

    const {
        searchType: { disp_currency, sellingCountry }
    } = jsons || {}

    var queryObj = {
        adt: adults,
        chd: children,
        inf: infants,
        intl: intl === 'n' ? false : true,
        scr: disp_currency,
        sct: sellingCountry,
        stype: 'B2C'
    }

    var fareObj = {
        ctype:
            classType.toLowerCase() === 'economy'
                ? 'E'
                : classType.toLowerCase() === 'premium economy'
                ? 'P'
                : classType.toLowerCase() === 'business'
                ? 'B'
                : classType === 'PREMIUM_ECONOMY'
                ? 'P'
                : classType,
        fk: type === 'specialFare' ? SPECIAL_FARE.fk : fare.fk,
        mi: false, // TODO: value is missing in nodejs response
        ncib: fare.ncib
    }
    if (fare.fareGroupInformation && fare.fareGroupInformation.comboFareBasisCode) {
        fareObj = {
            ...fareObj,
            combo_fbc: fare.fareGroupInformation.comboFareBasisCode
        }
    }

    let segmentArrFinal = []
    sectorKeys.forEach((key, index) => {
        segmentArrFinal.push({
            seg: _createSegmentParmas(key, sectors, fare, false, index, {}, true)
        })
    })

    const payload = {
        sc: { ...queryObj },
        sol: [
            {
                fl: [...segmentArrFinal],
                fre: { ...fareObj }
            }
        ]
    }

    return payload
}

export const fareMiniRulePayloadHelper = ({ cardDetails, sectorKeys, sectors, searchQuery, jsons, journeyType }) => {
    const { isIntl: intl, source: from, destination: to } = searchQuery || {}
    const { adults, children, infants } = searchQuery.travellers || {}
    const { airlineCodes, priceBreakup = {} } = cardDetails || {}

    let { fare = {} } = priceBreakup || {}
    const {
        searchType: { disp_currency, sellingCountry }
    } = jsons || {}

    const queryObj = {
        ADT: adults,
        CHD: children,
        INF: infants,
        intl: intl,
        scr: disp_currency,
        sct: sellingCountry,
        from: sectors[Array.isArray(sectorKeys) ? sectorKeys[0] : sectorKeys].firstDeparture.airportCode,
        to: sectors[Array.isArray(sectorKeys) ? sectorKeys[0] : sectorKeys].lastArrival.airportCode,
        roundtrip: 'N'
    }

    let operatinAirline
    let basePrice

    if (!isEmpty(journeyType)) {
        basePrice = journeyType === 'depart' ? fare.mi_oadtbp : fare.mi_radtbp
        operatinAirline = journeyType === 'depart' ? airlineCodes[0] : airlineCodes[1]
    } else {
        operatinAirline = airlineCodes[0]
        basePrice = fare.adtbp
    }

    const fareParams = {
        sid: jsons.psid, // TODO : need to figure it out
        bp: basePrice,
        oa: operatinAirline,
        routed: fare.fk && fare.fk.indexOf('route_id') > -1 ? 'Y' : 'N',
        // TODO : need to figure it out
        // rs: [],
        yq: fare.fs
    }

    let osArrFinal = []
    let rsArrFinal = []
    if (Array.isArray(sectorKeys)) {
        sectorKeys.forEach((key, index) => {
            if (index === 0) {
                osArrFinal = _createSegmentParmas(key, sectors, fare, true, index)
            } else {
                rsArrFinal = _createSegmentParmas(key, sectors, fare, true, index)
            }
        })
    } else {
        // Intl RT MultiAirline Case
        osArrFinal = _createSegmentParmas(sectorKeys, sectors, fare, true, 0, journeyType)
    }

    const segmentParams = {
        os: osArrFinal,
        rs: rsArrFinal.length ? rsArrFinal : [],
        roundtrip: rsArrFinal.length ? 'Y' : 'N'
    }

    const payload = {
        ...queryObj,
        ...fareParams,
        ...segmentParams
    }

    return payload
}

export const transistPayloadHelper = ({ sectorKeys, sectors, searchQuery }) => {
    const { source = '', destination = '', returnDate = '' } = searchQuery || {}
    const onwardSectorArray = []
    const returnSectorArray = []

    sectorKeys.forEach((sector, index) => {
        if (!isEmpty(sectors[sector])) {
            const { flights: { segments = [] } = {} } = sectors[sector] || {}

            segments.forEach(segment => {
                const {
                    arrival: { timestamp: arrivalTimeStamp, airportCode: arrivalAirportCode } = {},
                    departure: { timestamp: departureTimestamp, airportCode: departureAirportCode } = {}
                } = segment || []

                const sectorObj = {
                    dep: departureAirportCode,
                    arr: arrivalAirportCode,
                    ddt: dayjs(departureTimestamp).format('YYYY-MM-DDTHH:mm:ss+00:00'),
                    adt: dayjs(arrivalTimeStamp).format('YYYY-MM-DDTHH:mm:ss+00:00')
                }
                if (index === 0) {
                    onwardSectorArray.push(sectorObj)
                } else if (index === 1) {
                    returnSectorArray.push(sectorObj)
                }
            })
        }
    })

    let payload = {
        scfrom: source,
        scto: destination,
        jt: !isEmpty(returnDate) ? 'RT' : 'OW',
        os: [...onwardSectorArray]
    }
    if (!isEmpty(returnSectorArray)) {
        payload = {
            ...payload,
            rs: [...returnSectorArray]
        }
    }

    return payload
}

export const appPrefix = () => {
    let langPrefix = ''
    let lang = 'en'
    // const LOCAL_LANG = process.env.LOCALE_LANG
    // const ENGLISH_LANG = 'en'

    const pathname = resolveKeysInObjectsArrays(['location', 'pathname'], window)

    const [, ln = lang] = (pathname || '').split('/')

    if (ln === 'ar') {
        langPrefix = '/ar'
        lang = ln
    }

    return ln === 'ar' ? `${langPrefix}` : ''
}

export const fetchTransitVisaPostApi = payload => {
    const URL = `/flights/transitvisa`
    const headers = {
        'api-version': '3.0',
        'content-type': 'application/json',
        Accept: 'application/json',
        ...window.androidData,
        ...window.iosData
    }
    const response = apiPutOrPostHelper(URL, payload, 'application/json', headers)
    return response
}

export const flightFreeBaggagePostApi = payload => {
    const URL = `/flights/freeBaggageInfo`
    const headers = {
        'api-version': '3.0',
        'content-type': 'application/json',
        Accept: 'application/json',
        ...window.androidData,
        ...window.iosData
    }
    const response = apiPutOrPostHelper(URL, payload, 'application/json', headers)
    return response
}

export const flightFareMiniRulePostApi = payload => {
    const URL = `/flights/getminirule?domain=${window.location.hostname}`
    const headers = {
        'api-version': '3.0',
        'content-type': 'application/json',
        Accept: 'application/json',
        ...window.androidData,
        ...window.iosData
    }
    const response = apiPutOrPostHelper(URL, payload, 'application/json', headers)
    return response
}
