import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../configureStore";
import { getStaticBenefits } from "src/api/appPush";

const initialState = {
    benefitsCardData: {}
}

export const fetchAppPushBenefits = createAsyncThunk(
    "appPush/benefits",
    async (data) => {
        const response = await getStaticBenefits(data);
        return response;
    }
);

export const benefitsSlice = createSlice({
    name: "benefits",
    initialState,
    reducers: {
        setBenefitsData: (state, action) => {
            state.benefitsCardData = action.payload || {}
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchAppPushBenefits.rejected, (state) => {
            state.benefitsCardData = {}
        })
    }
})

const getBenefitsData = (state: RootState) => state.benefits

const { reducer, actions } = benefitsSlice;

const { setBenefitsData } = actions || {}

export { getBenefitsData, setBenefitsData };

export default reducer;
