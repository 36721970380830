import { getGrantTokenApiHelper } from '@api/ultra'
import { isEmpty } from '@utils/genralUtils'
import { triggerNewRelicEvent } from '@utils/newRelic'
import { apiGetHelper, apiPutOrPostHelper,getDomain,getApiDomain } from 'src/utils/helper'

export const fetchComponentsController = (action: any) => {
    try {
        const { itineraryId, ssr = false, headers = '' } = action
        const URL = `${getDomain(ssr)}/itin/v7/itinerary/${itineraryId}/fetch/components`
        const response = apiGetHelper(URL, '', headers)
        return response
    } catch (e) {
        return {}
    }
}

export const fetchAncillariesController = (itineraryId: string) => {
    try {
        const URL = `/itin/v4/${itineraryId}/ancillaries/fetch?includeBaggage=true&includeMeal=true&includeSeat=true`
        const response = apiGetHelper(URL, '', '')
        return response
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const fetchUserDataController = async (itineraryId: string) => {
    try {
        const URL = `/itin/v4/itinerary/${itineraryId}/user`
        triggerNewRelicEvent('fetch_user_data_api_initiated', {
            itineraryId
        })
        const apiResponse = await getGrantTokenApiHelper(5)
        let headers = { 'grant-token': '' }
        const { grantToken } = apiResponse || {}
        console.log(grantToken, 'user api token')
        if (!isEmpty(grantToken)) {
            headers['grant-token'] = grantToken
            triggerNewRelicEvent('fetch_user_data_api_grant_token_found', {
                itineraryId,
                grantToken
            })
        } else {
            triggerNewRelicEvent('fetch_user_data_api_grant_token_not_found', {
                itineraryId
            })
        }
        const response = apiGetHelper(URL, '', headers)
        triggerNewRelicEvent('fetch_user_data_api_called', {
            itineraryId,
            URL
        })
        return response
    } catch (e) {
        triggerNewRelicEvent('fetch_user_data_api_error', {
            itineraryId,
            error: e?.componentStack || e?.stack || e?.error?.message || e?.message || ''
        })
        console.log(e)
        return {}
    }
}

export const fetchInsurancesController = (itineraryId: string) => {
    try {
        const URL = `${getApiDomain()}/itin/v4/${itineraryId}/insurances/fetch`
        const response = apiGetHelper(URL, '', '')
        return response
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const fetchCouponListController = (itineraryId: string) => {
    try {
        const URL = `${getApiDomain()}/itin/v4/itinerary/${itineraryId}/couponList`
        const response = apiGetHelper(URL, '', {OLD_FLOW:false})
        return response
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const updateItineraryData = (payload: object, itineraryId: string) => {
    try {
        const URL = `${getApiDomain()}/itin/v4/itinerary/${itineraryId}/update`
        const response = apiPutOrPostHelper(URL, payload, 'application/json', '')
        return response
    } catch (e) {
        console.log(e)
    }
}

export const getApplyCoupon = (payload: object, itineraryId: string) => {
    try {
        const URL = `${getApiDomain()}/itin/v4/itinerary/${itineraryId}/applyCoupon`
        const response = apiPutOrPostHelper(URL, payload, 'application/json', '')
        return response
    } catch (e) {
        console.log(e)
    }
}

export const verifyBinNumber = (itineraryId:string, binNumber:string) => {
    try {
        const URL = `${getApiDomain()}/itin/v4/${itineraryId}/itinerary/applyBenefits?binNumber=${binNumber}`
        const response = apiGetHelper(URL, '', '')
        return response
    } catch (e) {
        console.log(e)
    }
}

export const initiatePrePayment = (itineraryId: string) => {
    try {
        const URL = `${getApiDomain()}/itin/v4/${itineraryId}/prepayment`
        const response = apiPutOrPostHelper(URL, undefined, 'application/json', '')
        return response
    } catch (e) {
        console.log(e)
    }
}

export const initiatePrePaymentAmend = (itineraryId: string, tripId: string) => {
    try {
        const URL = `/itin/amend/${itineraryId}/prepayment/${tripId}`
        const response = apiPutOrPostHelper(URL, undefined, 'application/json', '')
        return response
    } catch (e) {
        console.log(e)
    }
}

export const updateUserDialogue = (payload:Object, itineraryId:string) => {
    try {
        const URL = `${getApiDomain()}/itin/v4/itinerary/${itineraryId}/update`
        const response = apiPutOrPostHelper(URL, payload, 'application/json', '')
        return response
    } catch (e) {
        console.log(e)
    }
}

export const generatePayUrl = (itineraryId: string, tripId: string) => {
    try {
        const URL = `${getApiDomain()}/itin/v4/${itineraryId}/generatePayUrl?tripId=${tripId}`
        const response = apiGetHelper(URL, "", "")
        return response
    } catch (e) {
        console.log(e)
    }
}

export const generatePayUrlAmend = (itineraryId: string, tripId: string) => {
    try {
        const URL = `/itin/amend/${itineraryId}/generatePayUrl?tripId=${tripId}`
        const response = apiGetHelper(URL, '', '')
        return response
    } catch (e) {
        console.log(e)
    }
}

export const getFareFamilyInfo = (itineraryId: string, type = '') => {
    try {
        let URL = `${getApiDomain()}/itin/v4/${itineraryId}/fare-benefits-info`
        if (type) {
            URL = `${getApiDomain()}/itin/v4/${itineraryId}/fare-benefits-info?insuranceType=${type}`
        }
        const response = apiGetHelper(URL , "" , "")
        return response
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const validateGst = (tripId: string, payload: { state: string; pin_code: string; address: string }) => {
    // return resolveOnDelay(kfsMock, 2000) as any;
    // const VALIDATE_GST = `${BASE_URL}/paymentservice/api/validateGST/{_tripRef_}`;
    const URL = '' //VALIDATE_GST.replace("{_tripRef_}", tripId)
    const response = apiPutOrPostHelper(URL, payload, 'application/json', '')
    return { data: { status: 'SUCCESS', description: 'VALID_DETAILS' } }
}
