import { apiPutOrPostHelper } from 'src/utils/helper'
import { checkPlatform } from "@utils/datalayer"
import { path } from "@ui/utils";
import { getAppAgent } from "@utils/appAgent"
import { getDomain } from "@utils/helper"

export const getStaticBenefits = async (payload) => {
    try {
      const URL = `/monetisation/v1/widgets/fetch`
      const header = {
        product: 'air',
        channel: checkPlatform()?.toLowerCase(),
        caller: getDomain(true),
        'app-agent': getAppAgent()
      }
  
      const response = await apiPutOrPostHelper(URL, payload, 'application/json', header)
      return response
    } catch(err) {
      console.log('Failed to load static benefits data. Err: ', err)
      return {}
    }
}

export const getAppStoreInfo = (payload: Object) => {
  try {
      let URL = `/accounts/loginState`
      const header = {
          caller: getDomain(true),
          'app-agent': getAppAgent(),
      }
      const response = apiPutOrPostHelper(URL, payload, 'application/json', header)
      return response
  } catch (e) {
      console.log(e)
  }
}