import styled from "styled-components";
import { getStyledTypographyStyles } from "./style";
import { StyledTypographyProps } from "./type";

const StyledTypography = styled.div<StyledTypographyProps>(
  ({
    color,
    cursor,
    fontSize,
    fontWeight,
    lineHeight,
    textDecoration,
    display,
    webkitLineClamp,
    webkitBoxOrient,
    overflow,
  }) =>
    getStyledTypographyStyles({
      color,
      cursor,
      fontSize,
      fontWeight,
      lineHeight,
      textDecoration,
      display,
      webkitLineClamp,
      webkitBoxOrient,
      overflow,
    })
);

export default StyledTypography;
