import React from 'react';

const ValidTick = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
            <rect width='20' height='20' fill='#00B28A' rx='10'></rect>
            <path fill='#fff' d='M8.497 13.611L5 10.056l.874-.889 2.623 2.667 5.629-5.723.874.89-6.503 6.61z'></path>
        </svg>
    );
};

export default ValidTick;
