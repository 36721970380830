import { BoxProps } from './type';
import type { CSSObject } from 'styled-components';
import { CSSWithTheme } from '../../@types';

export const getBoxStyles = ({
	boxSize,
	theme,
	...rest
}: CSSWithTheme & Pick<BoxProps, 'theme' | 'boxSize'>): CSSObject => {
	let gap: number;

	switch (boxSize) {
		case 'xLarge':
			gap = 10;
			break;
		case 'big':
			gap = 8;
			break;
		case 'medium':
			gap = 6;
			break;
		case 'small':
			gap = 4;
			break;
		case 'tiny':
			gap = 3;
			break;
		case 'micro':
			gap = 2;
			break;
		case 'nano':
			gap = 1;
			break;
		case 'pico':
			gap = 1;
			break;
		case 'zero':
		default:
			gap = 0;
			break;
	}
	return {
		display: 'flex',
		gap: theme.spacing[gap],
		...rest,
	};
};
