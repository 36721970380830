// @ts-nocheck
// import { ThemeProvider } from '@ct-design/lib'
// import { StatsigProvider, StatsigSynchronousProvider, Statsig } from 'statsig-react'
import { onCLS, onFID, onLCP } from 'web-vitals/attribution'
import { v4 as uuidv4 } from 'uuid'

import 'setImmediate'

import { wrapper } from 'src/redux/configureStore'
import type { AppProps } from 'next/app'
import { useEffect, useRef, useState } from 'react'
import Head from 'next/head'
import Script from 'next/script'
import {
    loginPush,
    responseMapper,
    sendProfileData,
    updateProfileData
} from '@utils/flights/Clevertap/clevartapProfile'
import { isUserSignedIn } from '@utils/genralUtils'
import { invokeCustomEvent } from '@ui/utils'
import { deviceId, getCtAuth } from '@ui/deviceIdUtil'
import { getOSType, isServer, isMsiteInAppWebview } from '@utils/appAgent'
import { Provider } from 'react-redux'
// import { isStatsigIDInLocalStorageOrCookies } from '@utils/statsig.utils'
import { getCookie, delete_cookie, getScript, checkDeviceType, isEmpty } from '@ui/utils'
import { buildVariant, isCtEnv, isFkEnv } from '@root/configs/Whitelabel'
import { CLEARTRIP, FLIPKART } from '@constants/flights'
// import ScriptLoader from './ScriptLoader'
import dynamic from 'next/dynamic'
// import DownloadAppPage from '@components/DownloadFlipkarAppScreen'
import ShowToastIfOffline from '@components/ShowToastIfOffline'
import NavigationWrapper from '@components/NavigationWrapper'
import DownloadAppPage from '@components/AppDownload'
if (isFkEnv) {
    import('../styles/whitelabelStyles/flipkart.scss')
}

import { getUserProfileDetails } from '@api/homePage'
import { ThemeProvider } from '@ct-design/lib'

type AppPropsWithInitialProps = AppProps & {
    statsigStableId?: string
    statsigInitValues?: Record<string, unknown> | null
}

const App = ({ Component, statsigStableId, statsigInitValues, ...rest }: AppPropsWithInitialProps) => {
    const popStateEventRef = useRef(true)
    const [offerHook, setOfferHook] = useState({})

    const { store, ...pageProps } = wrapper.useWrappedStore(rest)
    const [isFkMsite, setIsFkMsite] = useState(false)
    const [isAppDownLoad, setIsAppDownLoad] = useState({ show: false, version: '', type: '' })
    const appVersionList = ['25.1.2', '25.2.1', '25.1.0', '25.2.2', '24.8.1']
    useEffect(() => {
        const deviceType = getOSType()
        const isFkMsiteClient = isFkEnv && deviceType !== 'android' && deviceType !== 'ios'
        if (isFkMsiteClient !== isFkMsite) {
            setIsFkMsite(isFkMsiteClient)
        }

        if (!isServer() && typeof window !== undefined) {
            window.androidData = window.MobileApp ? JSON.parse(window.MobileApp.getAppSpecificData()) : {}
            window.iosData = !isEmpty(window.iosData) ? window.iosData : {}
            if (window.androidData) {
                const appVersion = window.androidData['app-version-name']
                if (appVersion?.startsWith('24') || appVersionList.includes(appVersion)) {
                    if (typeof newrelic == 'object') {
                        newrelic.addPageAction('AppDownload', {
                            rootURL: window.location.href,
                            type: 'Show',
                            domain: window.location.hostname
                        })
                    }
                    setIsAppDownLoad({
                        show: true,
                        version: appVersion,
                        type: 'Android'
                    })
                }
            }
            if (window.iosData) {
                const appVersion = window.iosData['app-version-name']
                if (appVersion?.startsWith('24') || appVersionList.includes(appVersion)) {
                    if (typeof newrelic == 'object') {
                        newrelic.addPageAction('AppDownload', {
                            rootURL: window.location.href,
                            type: 'Show',
                            domain: window.location.hostname
                        })
                    }
                    setIsAppDownLoad({
                        show: true,
                        version: appVersion,
                        type: 'iOS'
                    })
                }
            }
        }
    }, [])

    if (typeof newrelic == 'object') {
        newrelic.setErrorHandler(function (err) {
            if (err && err.message && err.message.indexOf('Script error') !== -1) {
                return true
            } else {
                return false
            }
        })
    }
    if (!isServer() && typeof window !== undefined && !window.androidData) {
        window.androidData = window.MobileApp ? JSON.parse(window.MobileApp.getAppSpecificData()) : {}
        window.iosData = !isEmpty(window.iosData) ? window.iosData : {}
    }

    const getTheme = () => {
        return 'B2C'
        // switch (buildVariant) {
        //     case CLEARTRIP:
        //         return 'B2C'
        //     case FLIPKART:
        //         return FLIPKART
        // }
    }

    const trackWebVitals = metrics => {
        console.log(metrics)
    }

    useEffect(() => {
        // window.addEventListener('REMOVE_POPSTATE_EVENT', function() {
        //     popStateEventRef.current = false
        // });

        // window.addEventListener('ADD_POPSTATE_EVENT', function() {
        //     popStateEventRef.current = true
        // });

        const handlePopstateFn = e => {
            // if (!popStateEventRef.current) {
            //     return
            // }
            let bottomSheets = document.getElementsByClassName('backbutton_container')
            bottomSheets = [...bottomSheets]
            if (bottomSheets.length > 0) {
                const currentBottomSheet = bottomSheets[bottomSheets.length - 1]
                let bottomSheetClose = currentBottomSheet.getElementsByClassName('close__Btn')
                bottomSheetClose = bottomSheetClose[0]
                if (bottomSheetClose) {
                    bottomSheetClose.click()
                }
            }
        }

        window.addEventListener('popstate', handlePopstateFn)

        return () => window.removeEventListener('popstate', handlePopstateFn)
    }, [])

    useEffect(() => {
        console.log('first-')
        if (!isServer()) {
            onCLS(trackWebVitals)
            onFID(trackWebVitals)
            onLCP(trackWebVitals)
            const getUserDetails = async type => {
                try {
                    const response = await getUserProfileDetails()
                    const { status, data } = response || {}
                    if (status === 200) {
                        let finalPayload = responseMapper(data)
                        if (type == 'login') {
                            sendProfileData(finalPayload)
                        } else {
                            updateProfileData(finalPayload)
                        }
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            document.addEventListener('CLEVERTAP_PROFILE', function ({ detail }) {
                const { type } = detail
                getUserDetails(type)
            })
            if (isUserSignedIn() && !loginPush) {
                invokeCustomEvent('CLEVERTAP_PROFILE', { type: 'login' })
            }
            // if (isCtEnv) {
            //     deviceId()
            // }
            if (getCookie('ct-ab')) {
                delete_cookie('ct-ab')
            }
            // if (!getCookie('statsig-stableid')) {
            //     const newStableId = Statsig.getStableID()
            //     document.cookie = `statsig-stableid=${newStableId}; max-age=${365 * 24 * 60 * 60}; path=/`
            // }
            // window.Statsig = Statsig
        }
        // if ('serviceWorker' in navigator) {
        //     window.addEventListener('load', function () {
        //         navigator.serviceWorker.register('/flights-service-worker.js', { scope: '/' }).catch(err => {
        //             console.log('Service Worker Failed to Register :(', err)
        //         })
        //     })
        // }
        // offerJSON()
    }, [])
    const offerJSON = async () => {
        const env = process.env.NODE_ENV === 'production' ? 'prod' : 'staging'
        if (isFkEnv) {
            await getScript(
                `https://travel.flipkart.com/offermgmt/offerJson/${env}/offerSlotFk.js?` + new Date().getTime(),
                () => {
                    setOfferHook(Math.random()) // Hack for BBD
                }
            )
        } else {
            await getScript(
                `https://www.cleartrip.com/offermgmt/offerJson/${env}/offerSlot.js?` + new Date().getTime(),
                () => {
                    setOfferHook(Math.random()) // Hack for BBD
                }
            )
        }
    }

    // const getStatsigProviderProps = () => {
    //     let statsigProviderProps = {
    //         sdkKey:
    //             process.env.APP_ENV === 'production'
    //                 ? process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY
    //                 : process.env.NEXT_PUBLIC_STATSIG_CLIENT_DEV_KEY,
    //         initializeValues: {
    //             ...statsigInitValues
    //         },
    //         options: {
    //             environment: { tier: 'production' }
    //         }
    //     }
    //     if (statsigStableId) {
    //         statsigProviderProps.options.overrideStableID = statsigStableId
    //     }
    //     return statsigProviderProps
    // }

    return (
        <>

            <Head>
                <meta charSet="utf-8" />
                <meta name="facebook-domain-verification" content="cnt4t17cvj3kp4r3q51lgrzf5y1bo0" />
                <meta name="application-name" content="Cleartrip" />
                <meta name="full-screen" content="yes" />
                <meta name="theme-color" content="#f7f7f7" />
                <meta name="msapplication-navbutton-color" content="#000" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-title" content="Cleartrip" />
                <meta name="apple-mobile-web-app-status-bar-style" content="#000" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="msapplication-TileImage" content="//www.cleartrip.com/favicon.ico" />
                <meta name="viewport" content="width=device-width,initial-scale=1, maximum-scale=1, user-scalable=no" />
                <meta name="msapplication-starturl" content="/" />

                <link rel="dns-prefetch preconnect" href="https://ui.cltp.co" />
                <link rel="dns-prefetch preconnect" href="https://www.googletagmanager.com" />
                <link rel="dns-prefetch preconnect" href="https://www.google-analytics.com" />
                <script
                    async
                    src="/c3650cdf-216a-4ba2-80b0-9d6c540b105e58d2670b-ea0f-484e-b88c-0e2c1499ec9bd71e4b42-8570-44e3-89b6-845326fa43b6"
                    type="text/javascript"
                ></script>

                <script
                    dangerouslySetInnerHTML={{
                        __html: `window.dataLayer= []
          function getAppSpecificData(data) { 
            window.iosData = data || {} 
          }`
                    }}
                />
            </Head>

            {isAppDownLoad.show ? (
                <DownloadAppPage isIos={isAppDownLoad.type === 'iOS'} version={isAppDownLoad.version} />
            ) : (
                <>
                    <Script
                        id="gtag-base"
                        strategy="afterInteractive"
                        defer
                        dangerouslySetInnerHTML={{
                            __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID}');
                    `}}
                    />
                    <Provider store={store}>

                        <ThemeProvider theme={getTheme()}>
                            <Component {...pageProps} />
                        </ThemeProvider>
                    </Provider>

                    {/* </StatsigProvider> */}
                    <ShowToastIfOffline />

                </>
            )}


        </>
    )
}

export default App
