import styled from 'styled-components';
import { getStyledCardStyled } from './style';
import { StyledCardProps } from './type';

const StyledCard = styled.div<StyledCardProps>(
	({ borderRadius, border, borderTop, borderBottom, borderLeft, borderRight, boxShadow, borderColor, theme }) =>
		getStyledCardStyled({
			borderRadius,
			border,
			borderTop,
			borderBottom,
			borderLeft,
			borderRight,
			boxShadow,
			borderColor,
			theme,
		})
);

export default StyledCard;
