import { apiGetHelper, apiPutOrPostHelper, getDomain, getHomePageDomain } from 'src/utils/helper'
import logError from '@utils/logError'
import { checkPlatform } from '@utils/datalayer'
import { getAppAgent } from '@utils/appAgent'

const PLATFORM = checkPlatform()?.toLowerCase()

export const getUserProfileDetails = () => {
    try {
        let URL = `/accounts-api/people/v2/fetch/details`
        if (process.env.NEXT_PUBLIC_CT_ENV !== 'production') {
            URL = `/people/v2/fetch/details`
        }
        const response = apiGetHelper(URL, {}, { caller: `${window?.location?.origin}` })
        return response
    } catch (err) {
        console.log('user api err: ', err)
        logError(err)
    }
}

export const getFloaterData = async payload => {
    try {
        const URL = `/monetisation/v1/floater/fetch`
        const header = {
            product: 'air',
            channel: PLATFORM
        }
        const response = await apiPutOrPostHelper(URL, payload, 'application/json', header)
        if (response.status === 200) {
            return response.data
        }
        return Promise.reject()
    } catch (e) {
        console.error('Failed to load floater data. Err: ', e)
        return Promise.reject()
    }
}

export const floaterImpression = async payload => {
    try {
        const URL = `/monetisation/v1/floater/impression`
        const header = {
            product: 'air',
            channel: PLATFORM
        }
        const response = await apiPutOrPostHelper(URL, payload, 'application/json', header)
        if (response.status === 200) {
            return response.data
        }
    } catch (e) {
        console.log('Failed to upload floater impression data. Err: ', e)
    }
}

export const getWalletInfo = (userId: string, currency: string) => {
    try {
        const URL = `/pay/wallet?userId=${userId}&currency=${currency}`
        const header = {
            caller: getHomePageDomain()
        }
        const response = apiGetHelper(URL, 'application/json', header)
        return response
    } catch (e) {
        console.error(e)
        logError(e)
    }
}

export const getFkSuperCoinsBurnBalance = async payload => {
    try {
        const URL = `/pay/rewards/supercoins/check-balance`
        const header = {
            product: 'air',
            channel: PLATFORM
        }
        const response = await apiPutOrPostHelper(URL, payload, 'application/json', header)
        if (Number(response.status) === 200) {
            return response
        }
        return response
    } catch (err) {
        console.error(err)
        logError(err)
    }
}
export const getBranchLinkForAppPush = async payload => {
    try {
        const URL = `/accounts/loginState`

        const header = {
            caller: window?.location?.origin,
            'app-agent': getAppAgent()
        }
        const response = await apiPutOrPostHelper(URL, payload, 'application/json', header)
        // in case of logged in user the API gives 201
        if (Number(response.status) === 200 || Number(response.status) === 201) {
            return response?.data?.url
        }
        return Promise.reject()
    } catch (err) {
        console.log('Failed to create branch link with error: ', err)
        logError(err)
        return Promise.reject()
    }
}

export const fareCalendarData = async payload => {
    try {
        const URL = `/flights/v1/calendar/fares`
        const header = {
            product: 'air',
            channel: PLATFORM
        }
        const response = await apiPutOrPostHelper(URL, payload, 'application/json', header)
        if (Number(response.status) === 200) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.log('Failed to get Calendar data. Err: ', e)
    }
}

export const dateTabsData = async payload => {
    try {
        const URL = `/flights/v1/calendar/tab/fares`
        const header = {
            product: 'air',
            channel: PLATFORM
        }
        const response = await apiPutOrPostHelper(URL, payload, 'application/json', header)
        if (Number(response.status) === 200) {
            return response
        }
    } catch (e) {
        console.log('Failed to get Calendar data. Err: ', e)
    }
}
