import { CSSObject } from 'styled-components';

export enum ButtonSize {
	SMALL = 'small',
	LARGE = 'large',
}
type ButtonType = ButtonSize;

export interface BaseToggleSwitchProps {
	cursor?: CSSObject['cursor'];
	value?: Boolean;
	disabled?: Boolean;
	size?: ButtonType;
}