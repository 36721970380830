import React, { PureComponent } from "react";
import {Cross} from "@ct-design/assets/svg"
import { SheetHeaderProps } from "./type";
import Typography from "@ct-design/lib/Typography";

class SheetHeader extends PureComponent<SheetHeaderProps> {
  closeButtonClicked = () => {
    this.props.onClose("closeButton");
  }
  static defaultProps: { title: string; subtitle: string; onClose: (event: any) => void; showCloseButton: boolean; secondaryAction: string; secondaryActionClick: () => void; enableShadow: boolean; };

  
  override render() {
    const {
      enableShadow
    } = this.props;
    return (
      <div className={ `Sheet__header Sheet__whiteHeader ${enableShadow ? "Sheet__shadow" : ""}` }>
        {
          this.props.showCloseButton &&
          <span
            role="button"
            className="Sheet__close flex flex-middle flex-center"
            onClick={ this.closeButtonClicked }
          >
            <Cross width={22} height={22} crossColor={"#111112"} />
          </span>
        }
        <Typography variant={"HD1"} className="Sheet__title lh-title pl-16 fs-16 fw-600 ta-c c-neutral-900  " >
        {this.props.title}
        </Typography>

        <a
          onClick={ this.props.secondaryActionClick }
          className="Sheet__secondaryAction"
        >
          {this.props.secondaryAction}
        </a>
      </div>
    );
  }
}

SheetHeader.defaultProps = {
  title: "",
  subtitle: "",
  onClose: (event) => { },
  showCloseButton: true,
  secondaryAction: "",
  secondaryActionClick: () => { },
  enableShadow: true,
};

export default SheetHeader;
