export interface DividerProps {
    height?: number;
    type?: `${DividerType}`;
    color?: string;
    className?: string;
}

export enum DividerType {
    DASHED = 'dashed',
    DOTTED = 'dotted',
    SOLID = 'solid'
}