import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import classNames from "classnames"
import { Close, BackArrow } from "@assests/svg"
import { Overlay } from "@ct-design/lib"
import { Slide } from "@ct-design/lib/Animation"
import { BottomSheetProps, ClossIconType } from "./types"
function useScrollTop() {
  const [scrollTop, setScrollTop] = useState(0)
  const onScroll = (event) => {
    setScrollTop(event.target.scrollTop)
  }
  return [scrollTop, { onScroll }]
}
//TODO: change it according to theme and new ct design system libraray.
const BottomSheetUsingSliderFilter: FunctionComponent<BottomSheetProps> = (
  props: BottomSheetProps
) => {
  const {
    open,
    wrapperClassName,
    contentClassName,
    showCloseIconOnHeader,
    closeIconType,
    headingText = "",
    subHeaderText,
    onClose,
    children,
    closeOnOutSideClick,
    headerCta = "",
    headerCtaAction = () => {},
    showShadow = "true",
    onScroll,
  } = props
  const ref = useRef<HTMLDivElement>(null)
  const [containerHeight, setContainerHeight] = useState(
    ref?.current?.clientHeight || 44
  )
  const historyRef = useRef(false)
  const overlayBackBtnTimeoutRef = useRef(true)
  const onCloseWrapper = (e) => {
      // const removePopstateEvent = new Event('REMOVE_POPSTATE_EVENT');
      // const addPopstateEvent = new Event('ADD_POPSTATE_EVENT');
      // window.dispatchEvent(removePopstateEvent);
      window.history.back()
      // onClose(e)
      // window.dispatchEvent(addPopstateEvent);
  }
  const [scrollTop, scrollProps] = useScrollTop()
  useEffect(() => {
    setContainerHeight(ref?.current?.clientHeight + 44)
  })

  //To-do: Need to use outsideClickHandler but throwing error so used it.
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (event: any) => {
      let targetClasslist = event.target.classList
      targetClasslist = [...targetClasslist]
      if (targetClasslist?.length > 0 && targetClasslist.includes('close__Btn')) {
          return
      }
      if (ref.current && !ref.current.contains(event.target) && overlayBackBtnTimeoutRef.current) {
        onCloseWrapper()
        overlayBackBtnTimeoutRef.current = false
        setTimeout(() => {
            overlayBackBtnTimeoutRef.current = true
        }, 1000)
      }
    }

    if (closeOnOutSideClick)
      window.addEventListener("click", handleClickOutside, true)
    return () => {
      if (closeOnOutSideClick)
        window.removeEventListener("click", handleClickOutside, true)
    }
  }, [onClose])

  useEffect(() => {
    if (open && !historyRef.current) {
        let currentId = 0
        let bottomSheets = document.getElementsByClassName('backbutton_container')
        bottomSheets = [...bottomSheets]
        if (bottomSheets.length > 0) {
            currentId = bottomSheets.length
        }
        window.history.pushState({currentId}, "",  window.location.href);
        historyRef.current = true
    }
    if (!open) {
        historyRef.current = false
    }
}, [open])

  const portalContent = (
    <>
      {
        <Overlay>
          <Slide
            in={open}
            timeout={{ enter: 300, exit: 300 }}
            mountOnEnter={true}
            unmountOnExit={true}
            appear={true}
          >
            <div
              className={classNames(
                "slider bottomSheet h-100p w-100p flex flex-bottom p-relative backbutton_container",
                wrapperClassName
              )}
            >
               <div className='close__Btn' style={{pointerEvents: 'none'}} onClick={(e) => {
                e.stopPropagation()
                onClose(e)
            }}></div>
              {showCloseIconOnHeader && (
                <div
                  className="bottomSheet__header p-absolute p-1"
                  onClick={onClose}
                  style={{
                    zIndex: "999",
                    height: "32px",
                    width: "32px",
                    background: "#fff",
                    right: "16px",
                    top: ` ${window.innerHeight - containerHeight}px`,
                    borderRadius: "40px",
                  }}
                >
                  {closeIconType === ClossIconType.CROSS ? (
                    <Close height={"24"} width={"24"} color="#1A1A1A" />
                  ) : (
                    <BackArrow />
                  )}
                </div>
              )}
              <div
                {...scrollProps}
                className={classNames(
                  "bottomSheet__content bg-neutral-100 p-fixed w-100p",
                  contentClassName
                )}
                ref={ref}
                onScroll={onScroll}
                style={{
                  maxHeight: ` ${window.innerHeight - 56}px`,
                }}
              >
                <div
                  style={{
                    position: "sticky",
                    top: "0px",
                    background: "#ffffff",
                    zIndex: "100",
                    marginRight: "-16px",
                    marginLeft: "-16px",
                    boxShadow:
                      showShadow && scrollTop > 0
                        ? "0px 6px 10px rgba(0, 0, 0, 0.04)"
                        : "",
                  }}
                  className="pt-6 pb-4 px-4"
                >
                  {headingText && (
                    <div className="flex flex-between flex-middle">
                      <div className="fs-20 fw-600 lh-28  c-black-900 ">
                        {headingText}
                      </div>
                      {headerCta && (
                        <div
                          className="fw-600 lh-20 fs-14 c-link-100"
                          onClick={headerCtaAction}
                        >
                          {headerCta}
                        </div>
                      )}
                    </div>
                  )}
                  {subHeaderText && (
                    <div className="fs-14 fw-400 lh-20 c-black-900 ">
                      {subHeaderText}
                    </div>
                  )}
                </div>

                {children}
              </div>
            </div>
          </Slide>
        </Overlay>
      }
    </>
  )

  return createPortal(portalContent, document.body)
}

BottomSheetUsingSliderFilter.defaultProps = {
  children: null,
  onClose: () => {},
  closeOnOutSideClick: true,
  wrapperClassName: "",
  showCloseIconOnHeader: true,
  closeIconType: ClossIconType.CROSS,
}

export default BottomSheetUsingSliderFilter
