export type FontWeight = Readonly<{
	bold: 700;
	semibold: 600;
	medium: 500;
	normal: 400;
}>;

export type FontSize = {
	10: '10px';
	12: '12px';
	14: '14px';
	16: '16px';
	20: '20px';
	24: '24px';
	32: '32px';
	40: '40px';
	48: '48px';
};

export type LineHeight = {
	12: '12px';
	14: '14px';
	16: '16px';
	18: '18px';
	20: '20px';
	22: '22px';
	24: '24px';
	28: '28px';
	32: '32px';
	40: '40px';
	48: '48px';
	56: '56px';
};

export type Typography = {
	weight: FontWeight;
	size: FontSize;
	lineHeight: LineHeight;
};

const typography: Typography = {
	weight: {
		bold: 700,
		semibold: 600,
		medium: 500,
		normal: 400,
	},
	size: {
		10: '10px',
		12: '12px',
		14: '14px',
		16: '16px',
		20: '20px',
		24: '24px',
		32: '32px',
		40: '40px',
		48: '48px',
	},
	lineHeight: {
		12: '12px',
		14: '14px',
		16: '16px',
		18: '18px',
		20: '20px',
		22: '22px',
		24: '24px',
		28: '28px',
		32: '32px',
		40: '40px',
		48: '48px',
		56: '56px',
	},
};

export default typography;
