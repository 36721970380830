import { isServer } from '@utils/appAgent'
import { isEmpty, path, setCookie } from '@utils/genralUtils'
export const getLoginFlag = () => {
    if (isServer()) {
        return
    }
    if (path(['localStorage'], window) && typeof window.localStorage != 'undefined') {
        let getLoginFlag = JSON.parse(localStorage.getItem('ON_LOGIN_PUSH'))
        if (getLoginFlag) {
            return true
        }
        return false
    }
}
export const sendProfileData = payload => {
    if (path(['localStorage'], window) && typeof window.localStorage != 'undefined') {
        if (getLoginFlag()) {
            return
        }

        window.localStorage.setItem('ON_LOGIN_PUSH', true)
        window.clevertap.onUserLogin.push({ Site: payload })
    }
}
export const updateProfileData = payload => {
    window.clevertap.profile.push({ Site: payload })
}
export const loginPush = getLoginFlag()
export const responseMapper = data => {
    try {
        if (!isEmpty(data)) {
            if (data.travellers) {
                data = { ...data, ...data.customer }
                data.travellerDetails = data.travellers
            }
            const site = {
                Identity: data.id || data.userId || '',
                Email: data.username || '',
                Phone: `${data.countryCode}${data.mobile}`,
                Gender: data.gender || '',
                'MSG-whatsapp': true,
                'MSG-sms': true,
                'MSG-email': false,
                'MSG-push': true
            }
            for (let i = 0; i < data.travellerDetails; i++) {
                const val = data.travellerDetails[i]
                if (val.isRegistered) {
                    site.Name = `${val.personalDetails.firstName} ${val.personalDetails.lastName}`
                    site.DOB = Date(val.personalDetail.dateOfBirth)
                    break
                }
            }
            return site
        }
    } catch (e) {
        console.error(e)
    }
}
