import React, { ReactNode, useContext } from 'react';
import Box from '../../Box';
import { Theme } from '../../../token/theme';
import { ModalSizeTypes } from '../type';
import ModalContext from '../ModalContext';

const ModalContainer: React.FC<{ theme?: Theme; size?: ModalSizeTypes; children: ReactNode }> = ({ children }) => {
	const { size } = useContext(ModalContext);
	return (
		<Box
			boxSize='big'
			css={{
				padding: '24px',
				maxHeight: 'calc(100vh - 128px)',
				overflowY: 'auto',
				height: size === 'FULL SCREEN' ? '100%' : undefined,
			}}
		>
			{children}
		</Box>
	);
};

export default ModalContainer;
