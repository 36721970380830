import * as React from 'react';
import { Transition as ReactTransition, TransitionStatus } from 'react-transition-group';
import useTheme from '../../hooks/useTheme';
import useMergeRefs from '../../hooks/useMergeRefs';
import { Direction, TransitionProps } from './type';
import { styles } from './style';
import { debounce } from 'lodash';

const defaultTransform = {
	grow: 'scale(0.75, 0.5625)',
	zoom: 'scale(0)',
};

const reflow = (node: Element) => node.scrollTop;
function getTransitionProps(props, options) {
	const { timeout, easing, style = {} } = props;

	return {
		duration: style.transitionDuration ?? (typeof timeout === 'number' ? timeout : timeout[options.mode] || 0),
		easing: style.transitionTimingFunction ?? (typeof easing === 'object' ? easing[options.mode] : easing),
		delay: style.transitionDelay,
	};
}

function getTranslateValue(direction: Direction, node: HTMLElement, containerRef: HTMLElement) {
	const rect = node.getBoundingClientRect();
	const containerRect = containerRef && containerRef.getBoundingClientRect();

	const computedStyle = window.getComputedStyle(node);
	let transform = computedStyle.getPropertyValue('-webkit-transform') || computedStyle.getPropertyValue('transform');

	let offsetX = 0;
	let offsetY = 0;

	if (transform && transform !== 'none' && typeof transform === 'string') {
		const transformValues = transform.split('(')[1].split(')')[0].split(',');
		offsetX = parseInt(transformValues[4], 10);
		offsetY = parseInt(transformValues[5], 10);
	}

	if (direction === 'left') {
		if (containerRect) {
			return `translateX(${containerRect.right + offsetX - rect.left}px)`;
		}

		return `translateX(${window.innerWidth + offsetX - rect.left}px)`;
	}

	if (direction === 'right') {
		if (containerRect) {
			return `translateX(-${rect.right - containerRect.left - offsetX}px)`;
		}

		return `translateX(-${rect.left + rect.width - offsetX}px)`;
	}

	if (direction === 'up') {
		if (containerRect) {
			return `translateY(${containerRect.bottom + offsetY - rect.top}px)`;
		}
		return `translateY(${window.innerHeight + offsetY - rect.top}px)`;
	}

	// direction === 'down'
	if (containerRect) {
		return `translateY(-${rect.top - containerRect.top + rect.height - offsetY}px)`;
	}
	return `translateY(-${rect.top + rect.height - offsetY}px)`;
}

export function setTranslateValue(direction: Direction, node: HTMLElement, containerRef: HTMLElement) {
	const transform = getTranslateValue(direction, node, containerRef);

	if (transform) {
		node.style.webkitTransform = transform;
		node.style.transform = transform;
	}
}

/***
 * Transition component
 * it is based on type
 */
const Transition = React.forwardRef<any, TransitionProps>(
	(
		{
			appear = true,
			in: inProp,
			css,
			style,
			type,
			children,
			timeout,
			easing,
			containerRef,
			slideDirection = 'down',
			onEnter,
			onEntered,
			onEntering,
			onExit,
			onExited,
			onExiting,
			addEndListener,
			...other
		},
		forwardedRef
	) => {
		const theme = useTheme();
		const defaultTimeout = {
			enter: theme.transitions.duration.enteringScreen,
			exit: theme.transitions.duration.leavingScreen,
		};

		timeout = timeout || defaultTimeout;

		const nodeRef = React.useRef(null);
		//@ts-ignore
		const handleRef = useMergeRefs(nodeRef, children.ref, forwardedRef);

		const normalizedTransitionCallback = (callback) => (maybeIsAppearing) => {
			if (callback) {
				const node = nodeRef.current;

				// onEnterXxx and onExitXxx callbacks have a different arguments.length value.
				if (maybeIsAppearing === undefined) {
					callback(node);
				} else {
					callback(node, maybeIsAppearing);
				}
			}
		};

		//   const handleEntering = normalizedTransitionCallback(onEntering);

		const handleEnter = normalizedTransitionCallback((node, isAppearing) => {
			if (type === 'slide') {
				setTranslateValue(slideDirection, node, containerRef);
			}
			reflow(node); // So the animation always start from the start.

			const transitionProps = getTransitionProps(
				{ style, timeout, easing },
				{
					mode: 'enter',
				}
			);

			/**
			 * implementation for fade
			 */
			if (type === 'fade') {
				node.style.webkitTransition = theme.transitions.create('opacity', transitionProps);
				node.style.transition = theme.transitions.create('opacity', transitionProps);
			}

			/**
			 * implementation for grow
			 */
			if (type === 'grow') {
				let duration;
				// if (timeout === 'auto') {
				//   duration = theme.transitions.getAutoHeightDuration(node.clientHeight);
				//   autoTimeout.current = duration;
				// } else {
				//   duration = transitionProps.duration;
				// }

				node.style.transition = [
					theme.transitions.create('opacity', {
						duration: transitionProps.duration,
						delay: transitionProps.delay,
					}),
					theme.transitions.create('transform', {
						duration,
						delay: transitionProps.delay,
						easing: transitionProps.easing,
					}),
				].join(',');
			}
			/**
			 * implementation for zoom
			 */
			if (type === 'zoom') {
				node.style.webkitTransition = theme.transitions.create('transform', transitionProps);
				node.style.transition = theme.transitions.create('transform', transitionProps);
			}

			if (onEnter) {
				onEnter(node, isAppearing);
			}
		});

		const handleEntering = normalizedTransitionCallback((node, isAppearing) => {
			if (type === 'slide') {
				let easingValue = easing || {
					enter: theme.transitions.easing.easeOut,
					exit: theme.transitions.easing.sharp,
				};
				const transitionProps = getTransitionProps(
					{ timeout, style, easing: easingValue },
					{
						mode: 'enter',
					}
				);

				node.style.webkitTransition = theme.transitions.create('-webkit-transform', {
					...transitionProps,
				});

				node.style.transition = theme.transitions.create('transform', {
					...transitionProps,
				});

				node.style.webkitTransform = 'none';
				node.style.transform = 'none';
			}
			if (onEntering) {
				onEntering(node, isAppearing);
			}
		});

		const handleEntered = normalizedTransitionCallback(onEntered);

		const handleExiting = normalizedTransitionCallback(onExiting);

		const handleExit = normalizedTransitionCallback((node) => {
			const transitionProps = getTransitionProps(
				{ style, timeout, easing },
				{
					mode: 'exit',
				}
			);
			/**
			 * implementation for fade
			 */
			if (type == 'fade') {
				node.style.webkitTransition = theme.transitions.create('opacity', transitionProps);
				node.style.transition = theme.transitions.create('opacity', transitionProps);
			}

			/**
			 * implementation for grow
			 */
			if (type === 'grow') {
				node.style.transition = [
					theme.transitions.create('opacity', {
						duration: transitionProps.duration,
						delay: transitionProps.delay,
					}),
					theme.transitions.create('transform', {
						duration: transitionProps.duration,
						delay: transitionProps.delay,
						easing: transitionProps.easing,
					}),
				].join(',');

				node.style.opacity = 0;
				node.style.transform = 'scale(0.75, 0.5625)';
			}
			/**
			 * implementation for zoom
			 */
			if (type === 'zoom') {
				node.style.webkitTransition = theme.transitions.create('transform', transitionProps);
				node.style.transition = theme.transitions.create('transform', transitionProps);
			}
			/**
			 * TODO
			 * implementation for slide
			 */
			if(type === 'slide'){
				node.style.webkitTransition = theme.transitions.create('-webkit-transform', transitionProps);
				node.style.transition = theme.transitions.create('transform', transitionProps);
			
				setTranslateValue(slideDirection, node, containerRef);
			}

			if (onExit) {
				onExit(node);
			}
		});

		const handleExited = normalizedTransitionCallback((node) => {
			if(type === 'slide') {
				node.style.webkitTransition = '';
				node.style.transition = '';
			}
		
			if (onExited) {
			  onExited(node);
			}
		  });

		const handleAddEndListener = (next) => {
			if (addEndListener && nodeRef.current) {
				// Old call signature before `react-transition-group` implemented `nodeRef`
				addEndListener(nodeRef.current, next);
			}
		};

		const updatePosition = React.useCallback(() => {
			if (nodeRef.current) {
			  setTranslateValue(slideDirection, nodeRef.current, containerRef);
			}
		  }, [slideDirection, containerRef]);
		
		  React.useEffect(() => {
			// Skip configuration where the position is screen size invariant.
			if (inProp || slideDirection === 'down' || slideDirection === 'right') {
			  return undefined;
			}
		
			const handleResize = debounce(() => {
			  if (nodeRef.current) {
				setTranslateValue(slideDirection, nodeRef.current, containerRef);
			  }
			});
		
			window.addEventListener('resize', handleResize);
			return () => {
			//   handleResize.clear();
			  window.removeEventListener('resize', handleResize);
			};
		  }, [slideDirection, inProp, containerRef]);
		
		  React.useEffect(() => {
			if (type === 'slide' && !inProp) {
			  // We need to update the position of the drawer when the direction change and
			  // when it's hidden.
			  updatePosition();
			}
		  }, [inProp, updatePosition]);

		return (
			<ReactTransition
				appear={appear}
				in={inProp}
				nodeRef={nodeRef}
				onEnter={handleEnter}
				onEntered={handleEntered}
				onEntering={handleEntering}
				onExit={handleExit}
				onExited={handleExited}
				onExiting={handleExiting}
				addEndListener={handleAddEndListener}
				timeout={timeout}
				{...other}
			>
				{(state) => {
					let childProp = {
						style: {
							opacity: (type == 'fade' || type === 'grow') && 0,
							transform: defaultTransform[type],
							visibility: state === 'exited' && !inProp ? 'hidden' : undefined,
							...styles[type][state],
							...style,
							...children.props.style,
						},
						ref: handleRef,
					}
					if(type === 'slide'){
						childProp = {...childProp, ...children.props}
					}
					return React.cloneElement(children, childProp);
				}}
			</ReactTransition>
		);
	}
);

export default Transition;
