import React from 'react';

function EmptyRoom() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="112"
            height="112"
            fill="none"
            viewBox="0 0 112 112"
        >
            <mask
                id="mask0_639_45088"
                style={{ maskType: 'alpha' }}
                width="112"
                height="112"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H112V112H0z"></path>
            </mask>
            <g mask="url(#mask0_639_45088)">
                <path
                    fill="#B3B3B3"
                    d="M95.666 100.333V32.667c0-3.19-1.146-5.932-3.439-8.228C89.931 22.146 87.19 21 84 21h-7v6.067c0 .933-.311 1.71-.933 2.333-.623.622-1.4.933-2.334.933H53.55c-1.322 0-2.235-.544-2.74-1.633-.507-1.089-.527-2.178-.06-3.267l7.117-15.516c.466-1.011 1.088-1.75 1.866-2.217.778-.467 1.79-.7 3.034-.7h10.266c1.09 0 2.022.408 2.8 1.223.778.818 1.167 1.771 1.167 2.86v5.25h7c4.51 0 8.36 1.595 11.55 4.784 3.189 3.189 4.783 7.039 4.783 11.55v67.666h-4.666zm-72.333 0c-3.344 0-6.124-1.107-8.34-3.322-2.217-2.218-3.327-5-3.327-8.344V78.283c0-2.177.623-3.889 1.867-5.133 1.245-1.244 2.956-1.867 5.133-1.867 2.178 0 3.89.623 5.134 1.867 1.244 1.244 1.866 2.956 1.866 5.133v8.05h42v-8.05c0-2.177.623-3.889 1.867-5.133 1.245-1.244 2.956-1.867 5.133-1.867 2.178 0 3.89.623 5.134 1.867 1.244 1.244 1.867 2.956 1.867 5.133v10.384c0 3.344-1.108 6.126-3.323 8.344-2.218 2.215-5 3.322-8.344 3.322H23.333zm5.95-17.616V77c0-2.1-.739-4.044-2.216-5.833-1.478-1.79-3.5-2.956-6.067-3.5V56.583c0-2.1.739-3.889 2.216-5.366C24.695 49.739 26.483 49 28.584 49H64.75c2.1 0 3.889.739 5.367 2.217 1.477 1.477 2.216 3.266 2.216 5.366v11.084c-2.567.622-4.589 1.807-6.067 3.556C64.79 72.974 64.05 74.9 64.05 77v5.717H29.283z"
                ></path>
            </g>
        </svg>
    );
}

export default EmptyRoom;
