/* eslint-disable import/first */
//change here
import { formatCurrency } from "@ui/utils";

export const FLIGHT_NOT_AVAILABLE =
  "The flight you've selected is no more available.";

export const CREATE_ITINERARY_FAILURE_TITLE = "Something went wrong";

export const CREATE_ITINERARY_FAILURE =
  "There was an error trying to communicate with server. This is usually temporary and can be fixed by trying again.";

export const ITINERARY_NOT_FOUND =
  "Sorry, this resource does not exist on our servers. You may have followed an outdated link or entered an incorrect url.";

export const SEARCH_NO_FLIGHTS_AVAILABLE = "No flights found for this search"

export const INVALID_MISSING_PARAMETERS = "Invalid search query: ";

export const SEARCH_FLIGHT_PROCESS_ERROR = "Sorry, our system is acting up";

export const INVALID_DATE = "Looks like your dates are in the the past";

export const SEARCH_FLIGHT_SERVER_ERROR = "Sorry, our system is acting up";
export const HIGH_TRAFFIC = "Sorry, our system is acting up";

export const OFFLINE_ERROR_TITLE = "You're Offline";
export const OFFLINE_ERROR = "Please check your internet connection";
export const ITINERARY_EXPIRED_EXCEPTION = "ITINERARY_EXPIRED_EXCEPTION"
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
export const PAGE_NOT_FOUND_EXCEPTION = 'PAGE_NOT_FOUND_EXCEPTION'
export const ITINERARY_EXPIRED_ERROR = "Uh-oh! Your itinerary has expired"

export const FLIGHT_NOLONGER_AVAILABLE =
  "The flight you've selected isn't available anymore.";

export const MAX_PAX_ERROR = "Sorry, our system is acting up";

export const FLIGHT_AVAILABILITY_FAILED =
  "The flight you've selected isn't available anymore. Please try again.";

export const FLIGHT_CONFIRMED_MESSAGE =
  "Your {sourceCity} {arrow} {destinationCity} trip is booked.";

export const PAGE_NOT_FOUND_MESSAGE =
  "Whatever you're looking doesn't exist here.";

export const INVALID_URL_MESSAGE =
  "Whatever you're looking for doesn't exist here.";

// Itineray Errors

export const PRICE_CHANGE_INCREASED = (data, text) => {
  const oldFare = formatCurrency(data.otf);
  const newFare = formatCurrency(data.ntf);
  return text.replace("{oldFare}", oldFare).replace("{newFare}", newFare);
};

export const PRICE_CHANGE_DECREASED = (data, text) => {
  const oldFare = formatCurrency(data.otf);
  const newFare = formatCurrency(data.ntf);
  return text.replace("{oldFare}", oldFare).replace("{newFare}", newFare);
};
export const SCHEDULE_CHANGE =
  "Oops! Schedule change. Please review flight timings";

export const CONFIRMATION_NOT_FOUND =
  "Sorry, the itinerary you are looking for is no more available.";

export const PAYMENT_INITIALIZED =
  "Payment was already initialized for this itinerary. But, your booking didn't go through.";

export const TRIP_NOT_CONFIRMED = "Sorry, Your trip has not confirmed yet.";

export const FLIGHTS_FILTER_ZERO_RESULTS =
  "No flights to show based on the last filters";

export const FLIGHT_NO_CHECKIN_BAGGAGE_MESSAGE =
  "No free check-in bag allowed for this fare";
export const FLIGHTS_FILTER_PREAPPLIED_MESSAGE =
  "We have pre-applied some filters to give your more relavant results";

export const FLIGHTS_UNKNOWN_ERROR = "Something went wrong. Please try again";

export const MESSAGE_NEARBYAIRPORT_DEPARTING =
  "You searched for flights from {searchedDepartAirport} but selected a flight departing from {actualDepartAirport}.";
export const MESSAGE_NEARBYAIRPORT_ARRIVING =
  "You searched for flights to {searchedArrivalAirport} but selected a flight landing at {actualArrivalAirport}.";

export const TOTAL_COMBINATION_AVAILABLE = (text, count) => {
  return text.replace("{totalCombinations}", count);
};

export const INFANT_DOB_ERROR_MESSAGE =
  "Infant {count}'s date of birth has to be under 2 years at the time of travel.";
export const CHILDREN_DOB_ERROR_MESSAGE =
  "Child {count}'s date of birth has to be under 12 years at the time of travel.";
export const INFANT_DOB_MIN_DAYS_ERROR =
  "Infant should be more than 1 day old at the time of booking";
export const SENIOR_CITIZEN_DATE_ERROR =
  "Traveller needs to be above age of 60 on the day of travel to avail senior citizen fare";
export const NAME_CHARS_VALIDATION = (limit, type, validateType) => {
  return validateType.replace("{nameType}", type).replace("{limit}", limit);
};

// SRP Errors Message
export const OVERLAP_ERROR_MESSAGE =
  "Hey, these flights overlap, please change the selection";
export const THIRTY_MINS_OVERLAP_ERROR_MESSAGE =
  "Hey, these flights have less than 30 mins between departure/arrival time";

export const AIRPORT_CHANGE_MESSAGE = (airportCity) =>
  "There is a Airport change at {airportCity} in your itinerary. Please check with airline for transit visa requirements and ensure you have sufficient time to board the flight at {airportCity}.".replace(
    /\{airportCity\}/g,
    airportCity
  );

export const TERMINAL_CHANGE_MESSAGE = (isIntl) =>
  `${"You will have to change terminals while travelling. {REVIEW_TRANSIT_VISA}".replace(
    "{REVIEW_TRANSIT_VISA}",
    isIntl ? " Please check with airline for transit visa requirements" : ""
  )}`;

export const MAX_PAX_ERROR_MESSAGE = (maxPax, airline) =>
  "This airline does not allow for more than {maxPax} passengers in a single booking. We recommend splitting your booking to continue with this/{airline} fare"
    .replace("{maxPax}", maxPax)
    .replace("{airline}", airline);

export const USER_PASSWORD_NOT_MATCHING_ERROR =
  "Username & Password don't match. Please try again.";

export const FILL_USER_AND_PASSWORD_ERROR = "Please Fill Username & Password.";

export const SOMETHING_WENT_WRONG_ERROR =
  "Something went wrong. Please try again.";
export const USER_NOT_LOGGED_IN_ERROR = "User is not logged In.";
