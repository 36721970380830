import React from 'react';

const SomethingWentWrong = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='112' height='112' fill='none' viewBox='0 0 112 112'>
            <mask
                id='mask0_765_47629'
                style={{ maskType: 'alpha' }}
                width='112'
                height='112'
                x='0'
                y='0'
                maskUnits='userSpaceOnUse'
            >
                <path fill='#D9D9D9' d='M0 0H112V112H0z'></path>
            </mask>
            <g mask='url(#mask0_765_47629)'>
                <path
                    fill='#B3B3B3'
                    d='M53.667 61.484h4.666V37.101h-4.666v24.383zM56 71.867c.778 0 1.46-.272 2.044-.816.582-.545.873-1.206.873-1.984 0-.855-.291-1.555-.873-2.1-.585-.544-1.266-.816-2.044-.816s-1.46.272-2.044.816c-.582.545-.873 1.245-.873 2.1 0 .778.291 1.44.873 1.984.585.544 1.266.816 2.044.816zm5.25 22.867c-1.478 1.478-3.228 2.217-5.25 2.217s-3.772-.74-5.25-2.217L17.267 61.251c-1.478-1.478-2.217-3.228-2.217-5.25s.739-3.772 2.217-5.25L50.75 17.267c1.478-1.477 3.228-2.216 5.25-2.216s3.772.739 5.25 2.216l33.483 33.484c1.478 1.478 2.217 3.228 2.217 5.25s-.739 3.772-2.217 5.25L61.25 94.734z'
                ></path>
            </g>
        </svg>
    );
};

export default SomethingWentWrong;
