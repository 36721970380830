import { isEmpty } from '@utils/genralUtils'
import { isDomainForAutoSelectBRB } from './insurance'

export function givePaxCount(passengers: any) {
    let paxCount = 0
    if (Array.isArray(passengers)) {
        passengers.forEach(pax => {
            if (pax.type != 'INF') paxCount += 1
        })
    }
    return paxCount
}

const getFlexiflyBenefits = (vasBenefits = []) => {
    const benefitsArr = []
    if (Array.isArray(vasBenefits)) {
        vasBenefits.forEach((benefit, index) => {
            benefitsArr.push(benefit.title)
            return benefitsArr
        })
    }
    return benefitsArr
}

const getFlexiflyObj = (fightFare, vas) => {
    const {
        basePrice,
        price: flightPrice,
        taxes,
        discount,
        markup,
        convenienceFee,
        totalVat,
        vatPercentage
    } = fightFare
    const { vasDetails } = vas
    const { provider, title, benefits, totalPremiumAmount, selected, benefitGroups } = vasDetails?.[0] || {}
    return {
        basePrice,
        price: flightPrice + totalPremiumAmount,
        taxes,
        totalVat,
        vatPercentage,
        discount,
        markup,
        convenienceFee,
        totalPremiumAmount,
        fareClass: 'RETAIL',
        fareType: null,
        fareMetaMessages: [
            {
                provider,
                category: vas.vasType,
                title:
                    vas.vasType === 'DOM_CANCELATION_INSURANCE'
                        ? 'CT FlexMax'
                        : vas.vasType === 'INTL_CANCELATION_INSURANCE'
                        ? 'EzCancel'
                        : vas.vasType === 'AMEND_INSURANCE'
                        ? 'CT Flex'
                        : 'Flexifly',
                messages: getFlexiflyBenefits(benefits || benefitGroups?.[0]?.benefits)
            }
        ],
        fareKey: null,
        selected
    }
}

export const getSegmentFareObj = (segmentFares, vasAncillaries) => {
    let fares = []
    let flexifyFound = false

    vasAncillaries.map((vas, index) => {
        if (
            vas.vasType === 'AMEND_INSURANCE' ||
            vas.vasType === 'INTL_FLEXIFLY' ||
            vas.vasType === 'DOM_CANCELATION_INSURANCE' ||
            vas.vasType === 'INTL_CANCELATION_INSURANCE'
        ) {
            flexifyFound = true
            if (!fares.length) {
                fares.push(segmentFares[0])
            }
            if (vas.eligible) {
                const flexiflyObj = getFlexiflyObj(segmentFares[0], vas)
                fares.push(flexiflyObj)
            }
        }
    })
    if (!flexifyFound) {
        fares.push(segmentFares[0])
    }
    if (
        fares.length === 3 &&
        (fares[1].fareMetaMessages[0].category === 'DOM_CANCELATION_INSURANCE' ||
            fares[1].fareMetaMessages[0].category === 'INTL_CANCELATION_INSURANCE')
    ) {
        ;[fares[1], fares[2]] = [fares[2], fares[1]]
    }
    return fares
}

export const getFares = (flights, vasAncillaries) => {
    let fareBlockList = []

    let faresSum = {}
    flights.map((segment, index) => {
        if (index === 0) {
            faresSum = segment.fares[0]
        } else {
            const { basePrice: retBasePrice, price: retPrice, taxes: retTaxes } = segment.fares[0] // segment 1
            faresSum = {
                ...faresSum,
                basePrice: faresSum.basePrice + retBasePrice,
                price: faresSum.price + retPrice,
                taxes: faresSum.taxes + retTaxes
            }
        }
    })
    if (!isEmpty(vasAncillaries)) {
        fareBlockList.push(getSegmentFareObj([faresSum], vasAncillaries))
    } else {
        fareBlockList.push([faresSum])
    }

    return fareBlockList
}

export const getInsurances = vasAncillaries => {
    let availableInsurances = []
    try {
        const insuranceTypes = ['TRAVEL_INSURANCE', 'BAGGAGE_INSURANCE', 'MEDICAL_INSURANCE', 'INTL_TRAVEL_INSURANCE']
        if (Array.isArray(vasAncillaries)) {
            vasAncillaries.forEach(vas => {
                const { vasType } = vas || {}
                if (insuranceTypes.includes(vasType)) {
                    availableInsurances.push(vas)
                }
            })
        }
    } catch (e) {
        console.log(e)
    }

    return availableInsurances
}

export const getSelectedInsurance = (insurances, meta = {}) => {
    let selectedInsurances = []
    try {
        if (Array.isArray(insurances)) {
            selectedInsurances = insurances.filter(insurance => {
                const { eligible, vasDetails } = insurance || {}
                if (!eligible) {
                    return false
                }
                let isAutoSelected = false
                const { autoSelected = false, selected, provider = '' } = vasDetails?.[0] || {}
                if (selected || autoSelected) {
                    return true
                }
                if (isDomainForAutoSelectBRB() && provider?.includes('BRB')) {
                    isAutoSelected = true
                }
                if (isDomainForAutoSelectBRB() && !meta?.showCoupon) {
                    isAutoSelected = false
                }
                return isAutoSelected
            })
        }
    } catch (e) {
        console.log(e)
        selectedInsurances = []
    }
    return selectedInsurances
}

export const airportChangeHandler = ({ searchCriteria, flights }) => {
    if (!searchCriteria || !flights || !Array.isArray(flights)) {
        return {}
    }

    const { from: searchFrom, to: searchTo } = searchCriteria

    const extractAirportDetails = (flight, prefix) => {
        const details: any = {}
        flight.segments.forEach((segment, index) => {
            if (index === 0) {
                details[`${prefix}DepartureCode`] = segment.departAirport
                details[`${prefix}DepartureAirport`] = segment.departAirportIata
            }
            details[`${prefix}ArrivalCode`] = segment.arrivalAirport
            details[`${prefix}ArrivalAirport`] = segment.arrivalAirportIata
        })
        return details
    }

    const flightAirportObj = flights.reduce((acc, flight, index) => {
        const prefix = index === 0 ? 'onward' : 'return'
        return { ...acc, ...extractAirportDetails(flight, prefix) }
    }, {})

    let isTerminalChange = false
    flights.forEach(flight => {
        const { segments = [] } = flight || {}
        const nextAiportObj = { departureAirportName: '' }
        segments?.forEach(segment => {
            const { departAirportIata = '', arrivalAirportIata = '', departTerminal, arrivalTerminal } = segment || {}
            const arrivalAirportName = arrivalAirportIata?.split(',')[0]
            const departureAirportName = departAirportIata?.split(',')[0]
            if (!isTerminalChange) {
                if (
                    !isEmpty(nextAiportObj.departureAirportName) &&
                    nextAiportObj.departureAirportName !== `${departureAirportName}${departTerminal}`
                ) {
                    isTerminalChange = true
                } else {
                    nextAiportObj.departureAirportName = `${arrivalAirportName}${arrivalTerminal}`
                }
            }
        })
    })

    const isAirportChange =
        flights.length > 1
            ? searchFrom !== flightAirportObj.onwardDepartureCode ||
              searchTo !== flightAirportObj.onwardArrivalCode ||
              searchFrom !== flightAirportObj.returnArrivalCode ||
              searchTo !== flightAirportObj.returnDepartureCode
            : searchFrom !== flightAirportObj.onwardDepartureCode || searchTo !== flightAirportObj.onwardArrivalCode

    return { ...flightAirportObj, isAirportChange, isTerminalChange }
}

export const finalGvCalculation = (gvDiscountAmount, effectivePrice) => {
    try {
        if (effectivePrice > 0) {
            let finalPrice = effectivePrice - gvDiscountAmount
            if (finalPrice > 0) {
                return gvDiscountAmount
            }
            return effectivePrice
        } else {
            return 0
        }
    } catch (e) {
        return gvDiscountAmount
    }
}

export const validateShukranCard = shukranCode => {
    const shukranCodeRegex = /(?:\0|^)(\d{16})(?:\0|$)/
    const result = shukranCode.slice(0, 15)
    const output = result % 9
    const lastDigit = shukranCode.slice(15, 16)
    if (output === parseInt(lastDigit, 10) && shukranCodeRegex.test(shukranCode)) {
        // ShukranCard.setIsValidShukranCard = true
        return true
    }
    return false
}

export const createShukranPayload = fares => {
    const shukranPayload = {
        fares: [
            {
                fare_key: fares.fareKey,
                price: fares.price,
                fare_breakup: {
                    BF: fares.basePrice,
                    TAX: fares.taxes
                }
            }
        ],
        program_name: 'SHUKRAN'
    }
    return shukranPayload
}
