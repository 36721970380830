import { ThemeType } from 'ct-design/@types';
import { Theme } from '../../token/theme';
import B2BTheme from '../../token/theme/B2BTheme';
import B2CTheme from '../../token/theme/B2CTheme';
import FlipkartTheme from '../../token/theme/FlipkartTheme';
import CleartripTheme from '../../token/theme/CleartripTheme';

const getTheme = (themeType: ThemeType): Theme => {
	switch (themeType) {
		case 'B2C': {
			return CleartripTheme;
		}
		case 'B2B': {
			return B2BTheme;
		}
		case 'FLIPKART': {
			return FlipkartTheme;
		}
		default: {
			return B2CTheme;
		}
	}
};

export default getTheme;
