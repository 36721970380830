/**
 * Focus trap component traps tab navigation to container only
 * helps in trapping navigation to form or modals only
 * Implementation idea:
 * get all tabbable element
 */
/**
 * Focus trap component traps tab navigation to container only
 * helps in trapping navigation to form or modals only
 * Implementation idea:
 * get all tabbable element
 * TODO Implementation:
 * Add contain method for component update hook
 * It will check if it has active element or not in root
 * Add focusin event for only those elements
 * ! As of now only exporting method from focus-lock library
 */
import ReactFocusLock from 'react-focus-lock';
import { FocusLockProps } from './type';
import React from 'react';

const FocusLock = React.forwardRef<any, FocusLockProps>(({ restoreFocus, disabled, children }) => {
	return (
		<ReactFocusLock returnFocus={restoreFocus} disabled={disabled}  persistentFocus={false} autoFocus={false}>
			{children}
		</ReactFocusLock>
	);
});

export default FocusLock;
