import React from 'react';

const CheckboxTick = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='10'
            height='8'
            fill='none'
            viewBox='0 0 10 8'
        >
            <path
                stroke='#fff'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.2'
                d='M9 1L3.5 6.5 1 4'
            ></path>
        </svg>
    );
};

export default CheckboxTick;
