import React, { ReactNode, cloneElement, useContext } from 'react';
import Container from '../../Container';
import { Cross } from '../../../assets/svg';
import Typography from '../../Typography';
import useTheme from '../../../hooks/useTheme';
import { ModalProps } from '../type';
import ModalContext from '../ModalContext';

const ModalTitle: React.FunctionComponent<Pick<ModalProps, 'hideCrossIcon' | 'onClose'>  & { children: ReactNode}> = ({
	children,
}) => {
	const theme = useTheme();
	const { hideCrossIcon, onClose} = useContext(ModalContext)

	return (
		<Container display='flex' justifyContent='space-between'>
			<Typography variant='HM2'>{children}</Typography>
			{!hideCrossIcon && (
				<Container
					cursor='pointer'
					onClick={onClose}
					marginTop={theme.spacing[1]}
					marginRight={theme.spacing[1]}
				>
					<Cross crossColor={theme.color.text.heading} />
				</Container>
			)}
		</Container>
	);
};

export default ModalTitle;