export type Size = Readonly<{
	1: '4px';
	2: '8px';
	'2.5': '10px';
	3: '12px';
	4: '16px';
	5: '20px';
	6: '24px';
	7: '28px';
	8: '32px';
	9: '36px';
	10: '40px';
	11: '44px';
	12: '48px';
	13: '52px';
	14: '56px';
	15: '60px';
}>;

const size: Size = {
	1: '4px',
	2: '8px',
	'2.5': '10px',
	3: '12px',
	4: '16px',
	5: '20px',
	6: '24px',
	7: '28px',
	8: '32px',
	9: '36px',
	10: '40px',
	11: '44px',
	12: '48px',
	13: '52px',
	14: '56px',
	15: '60px',
};

export default size;
