import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Checkbox, OutsideClickHandler } from '@ct-design/lib';
import { DropdownProps } from './type';
import { Item } from '../../constants/index';
import { ChevronDown } from '../../assets/svg';

const Dropdown: React.FC<DropdownProps> = ({
    placeHolder,
    itemList,
    height,
    disabled,
    hasError,
    dropDownValue,
    multiSelect,
    defaultSingleSelectedIndex,
    defaultMultiSelectedIndex,
    onItemSelect,
    customWrapperClass,
    id,
    dropDownItemListWrapperClass,
    showDropDownListOnTop
}) => {
    const [focus, setFocus] = useState<boolean>(false);
    const [singleSelectIndex, setSingleSelectIndex] = useState<number>();
    const [multiSelectIndex, setMultiSelectindex] = useState<number[]>();

    useEffect(() => {
        setMultiSelectindex(defaultMultiSelectedIndex);
        setSingleSelectIndex(defaultSingleSelectedIndex);
    }, []);

    const disabledClass = classNames(
        'w-100p px-3 select c-not-allowed bs-border bg-neutral-300 br-6 ba-solid bc-neutral-500 flex flex-middle flex-between fs-14 lh-24 fw-500 c-neutral-700',
    );

    const dropdownWrapperClass = classNames(
        'w-fit-content w-100p p-relative flex ba-solid br-6 bs-border bg-neutral-100',
        {
            'bc-secondary-500': !hasError && focus,
            'bc-neutral-500': !hasError && !focus,
            'bc-error-500': hasError,
        },
        customWrapperClass,
    );

    const selectClass = classNames('o-0 w-100p px-3 p-absolute fs-16 lh-24', {
        select: dropDownValue.length <= 0,
        'py-2': dropDownValue.length !== 0,
    });

    const labelClass = classNames('w-100p p-absolute px-3 fs-14 lh-24 w-100p tt-ellipsis', {
        select: dropDownValue.length <= 0,
        'py-2': dropDownValue.length !== 0,
    });

    const chevronWrapperClass = classNames('h-100p flex flex-middle pl-1 pr-3');

    const chevronClass = classNames('chevron', {
        active: focus,
    });

    const dropdownHeadingItemClass = classNames('fs-12 lh-16 fw-600 c-neutral-700 mt-4 mb-2 px-4 tt-ellipsis');

    const dropdownItemClass = classNames(
        'fs-14 lh-24 fw-500 c-neutral-900 py-2 c-pointer px-4 dropdown-item p-relative tt-ellipsis',
    );

    const singleSelectClickHandler = (item: Item, index: number) => {
        setSingleSelectIndex(index);
        onItemSelect(item);
        setFocus(false);
    };

    const multiSelectClickhandler = (item: Item, index: number) => {
        if (multiSelectIndex) {
            const removeIndex = multiSelectIndex.indexOf(index);
            if (removeIndex !== -1) {
                const multiSelectIndexState = [...multiSelectIndex];
                multiSelectIndexState.splice(removeIndex, 1);
                setMultiSelectindex([...multiSelectIndexState]);
            } else {
                const multiSelectIndexState = [...multiSelectIndex];
                setMultiSelectindex([...multiSelectIndexState, index]);
            }
        }
        onItemSelect(item);
    };

    if (disabled) {
        return (
            <div className={`${disabledClass}`} style={{ height }}>
                <div className='flex flex-column o-hidden'>
                    <div
                        className={`${dropDownValue.length <= 0 ? 'fs-14 lh-24' : 'fs-12 lh-16'
                            } fw-500 c-neutral-700 tt-ellipsis`}
                    >
                        {placeHolder}
                    </div>
                    {dropDownValue.length > 0 && (
                        <div className='fs-14 lh-24 fw-500 c-neutral-900 tt-ellipsis'>
                            {dropDownValue}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    const dropDownItemList = focus && (
        <div className={classNames('dropdown p-absolute w-100p',  dropDownItemListWrapperClass )}>
            {itemList.map((item, index) => {
                const { key, item: dropdownItem, isHeading } = item || {};
                if (isHeading) {
                    return (
                        <div key={key} className={`${dropdownHeadingItemClass}`}>
                            {dropdownItem.toUpperCase()}
                        </div>
                    );
                }
                if (multiSelect) {
                    return (
                        <div
                            key={key}
                            className={`${dropdownItemClass} flex flex-middle`}
                            onClick={() => multiSelectClickhandler(item, index)}
                        >
                            <Checkbox
                                id={key}
                                onChange={() => { }}
                                onClick={(e) => { e.stopPropagation(); }}
                                checked={multiSelectIndex ? multiSelectIndex.includes(index) : false}
                                height={18}
                                width={18}
                            />
                            <div className='ml-3'>{dropdownItem}</div>
                        </div>
                    );
                }
                return (
                    <div
                        key={key}
                        className={`${dropdownItemClass} ${index === singleSelectIndex ? 'selected' : ''}`}
                        onClick={() => singleSelectClickHandler(item, index)}
                    >
                        {dropdownItem}
                    </div>
                );
            })}
        </div>
    )

    return (
        <OutsideClickHandler onOutsideClick={() => setFocus(false)}>
            {showDropDownListOnTop && dropDownItemList}
            <div className={`${dropdownWrapperClass}`} style={{ height:40 }}>
                <div className='w-100p p-relative flex flex-middle'>
                    <select className={`${selectClass}`}></select>
                    <label id={`${id}_label`} htmlFor={id} className={`${labelClass}`} onClick={() => setFocus(!focus)}>
                        {dropDownValue.length > 0 ? (
                            <>
                                <div className='fs-14 lh-20 fw-500 c-neutral-900 tt-ellipsis'>
                                    {Array.isArray(dropDownValue) ? dropDownValue.join(', ') : dropDownValue}
                                </div>
                            </>
                        ) : (
                            <div className='fs-14 lh-24 fw-500 c-neutral-700 tt-ellipsis'>{placeHolder}</div>
                        )}
                    </label>
                </div>
                <div key={placeHolder} id={`${id}_icon`} className={`${chevronWrapperClass}`} onClick={() => setFocus(!focus)}>
                    <div className={`${chevronClass}`} style={{ height: 24 }}>
                        <ChevronDown />
                    </div>
                </div>
            </div>
            {!showDropDownListOnTop && dropDownItemList}
        </OutsideClickHandler>
    );
};

Dropdown.defaultProps = {
    height: 52,
    hasError: false,
    disabled: false,
    multiSelect: false,
    defaultSingleSelectedIndex: -1,
    defaultMultiSelectedIndex: [],
    dropDownValue: '',
    showDropDownListOnTop: false
};

export default Dropdown;
