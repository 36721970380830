import { Theme } from '../../token/theme';

export const getDisabledRadioStyles = (theme: Theme, checked: boolean) => {
	if (checked) {
        return {
            borderWidth: theme.border.width.none,
            backgroundColor: theme.color.background.disabled,
            cursor: 'not-allowed',
        }
	} else {
        return {
            borderWidth: theme.border.width.sm,
            borderColor: theme.color.border.disabled,
            backgroundColor: theme.color.background.neutral,
            cursor: 'not-allowed',
        }
	}
};

export const getCheckedRadioStyles = (theme: Theme) => {
    return {
        borderWidth: theme.border.width.none,
        backgroundColor: theme.color.background.primary,
        cursor: 'pointer',
    }
};

export const getDefaultRadioStyles = (theme: Theme) => {
    return {
        borderWidth: theme.border.width.sm,
        borderColor: theme.color.border.defaultDark,
        backgroundColor: theme.color.background.neutral,
        cursor: 'pointer',
    }
};

export const getRadioStyles = ({ theme, checked, disabled }: { theme: Theme; checked: boolean; disabled: boolean }) => {
	if (disabled) {
		return getDisabledRadioStyles(theme, checked);
	}

	if (checked) {
		return getCheckedRadioStyles(theme);
	} else {
		return getDefaultRadioStyles(theme);
	}
};
