import type { CSSObject } from 'styled-components';
import type { ContainerProps } from './type';
type CSSObject = /*unresolved*/ any

export const getContainerStyles = ({
	alignItems,
	bottom,
	className,
	display,
	flex,
	justifyContent,
	flexWrap,
	flexDirection,
	rowGap,
	columnGap,
	left,
	position,
	right,
	top,
	zIndex,
	paddingTop,
	paddingBottom,
	paddingLeft,
	paddingRight,
	marginTop,
	marginBottom,
	marginLeft,
	marginRight,
	backgroundColor,
	cursor,
	width,
	height,
}: ContainerProps): CSSObject => {
	return {
		alignItems,
		bottom,
		display,
		flex,
		justifyContent,
		flexWrap,
		flexDirection,
		rowGap,
		columnGap,
		left,
		position,
		right,
		top,
		zIndex,
		paddingTop,
		paddingBottom,
		paddingLeft,
		paddingRight,
		marginTop,
		marginBottom,
		marginLeft,
		marginRight,
		backgroundColor,
		cursor,
		width,
		height,
	};
};
