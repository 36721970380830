import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { Close, BackArrow } from '@assests/svg'
import { Overlay } from '@ct-design/lib'
import { Slide } from '@ct-design/lib/Animation'
import { BottomSheetProps, ClossIconType } from './types'
import { useRouter } from 'next/router'
import { isServer } from '@utils/appAgent'
import { useBodyScrollLock } from '@root/hooks/useBodyScrollLock/useBodyScrollLock'

function useScrollTop() {
    const [scrollTop, setScrollTop] = useState(0)
    const onScroll = event => setScrollTop(event.target.scrollTop)
    return [scrollTop, { onScroll }]
}

type isOldPage = {
    overlayWrapperClass?: string
    closeIconZIndex?: string
}

type BottomSheetPropsWithOldPage = BottomSheetProps & isOldPage
//TODO: change it according to theme and new ct design system libraray.
const BottomSheetUsingSlider: FunctionComponent<BottomSheetPropsWithOldPage> = (props: BottomSheetPropsWithOldPage) => {
    const {
        open,
        wrapperClassName,
        contentClassName,
        showCloseIconOnHeader,
        closeIconType,
        headingText = '',
        subHeaderText,
        onClose,
        children,
        closeOnOutSideClick,
        headerCta = '',
        headerCtaAction = () => { },
        showShadow = 'true',
        headingClass = '',
        noPadding = false,
        hashUrl = '',
        bottomSheetRef = null,
        subHeaderClassName = '',
        overlayWrapperClass,
        closeIconZIndex = '999',
        customCloseIconStyle
    } = props

    useBodyScrollLock(open)

    const ref = bottomSheetRef ? bottomSheetRef : useRef<HTMLDivElement>(null)
    const [scrollTop, scrollProps] = useScrollTop()
    const [containerHeight, setContainerHeight] = useState(ref?.current?.clientHeight || 44)
    const router = useRouter()
    const [showOverlay, setShowOverlay] = useState(false)
    const historyRef = useRef(false)
    const overlayBackBtnTimeoutRef = useRef(true)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            router.beforePopState((state) => {
                state.options.scroll = false
                return true
            })
        }

        return () => {
            if (typeof window !== 'undefined') {
                router.beforePopState(() => true)
            }
        }
    }, [router])

    const onCloseWrapper = (e) => {
        // const removePopstateEvent = new Event('REMOVE_POPSTATE_EVENT');
        // const addPopstateEvent = new Event('ADD_POPSTATE_EVENT');
        // window.dispatchEvent(removePopstateEvent);
        window.history.back()
        // onClose(e)
        // window.dispatchEvent(addPopstateEvent);
    }

    // setInterval(() => {
    //     setContainerHeight(ref?.current?.clientHeight + 44)
    // }, 100)
    //To-do: Need to use outsideClickHandler but throwing error so used it.
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClickOutside = (event: any) => {
            let targetClasslist = event.target.classList
            targetClasslist = [...targetClasslist]
            if (targetClasslist?.length > 0 && targetClasslist.includes('close__Btn')) {
                return
            }
            if (ref.current && !ref.current.contains(event.target) && overlayBackBtnTimeoutRef.current) {
                onCloseWrapper(event)
                overlayBackBtnTimeoutRef.current = false
                setTimeout(() => {
                    overlayBackBtnTimeoutRef.current = true
                }, 1000)
            }
        }

        if (closeOnOutSideClick) window.addEventListener('click', handleClickOutside, true)
        return () => {
            if (closeOnOutSideClick) window.removeEventListener('click', handleClickOutside, true)
        }
    }, [onClose])

    useEffect(() => {
        if (open && !historyRef.current) {
            let currentId = 0
            let bottomSheets = document.getElementsByClassName('backbutton_container')
            bottomSheets = [...bottomSheets]
            if (bottomSheets.length > 0) {
                console.log(bottomSheets.length, 'bottomSheets 1')
                currentId = bottomSheets.length
            }
            console.log(currentId, 'currentId')
            window.history.pushState({ currentId }, "", window.location.href);
            historyRef.current = true
        }
        if (!open) {
            historyRef.current = false
        }

    }, [open])

    const addHashInUrl = hashValue => {
        const { hash, pathname, search } = (window && window.location) || {}
        if (!hash) {
            router.push(pathname + search + hashValue)
        }
    }
    const removeHashFromUrl = () => {
        const { hash, pathname, search } = (window && window.location) || {}
        if (hash === `#${hashUrl}`) {
            router.back()
            router.replace(pathname + search, '', { scroll: false })
        }
    }

    useEffect(() => {
        /*function handleHashChange(e) {
            const oldHash = e.oldURL.split('#')[1]
            if (oldHash === hashUrl) {
                onClose()
            }
        }
        if (open && hashUrl) {
            setTimeout(() => {
                addHashInUrl(`#${hashUrl}`)
            }, 100)
            window.addEventListener('hashchange', handleHashChange)
        }*/
        if (open) {
            setShowOverlay(true)
        } else {
            setTimeout(() => setShowOverlay(false), 300)
        }
        /*return function cleanup() {
            removeHashFromUrl()
            window.removeEventListener('hashchange', handleHashChange)
        }*/
    }, [open])

    const portalContent = (
        <>
            {open || showOverlay ? (
                <Overlay hashUrl={hashUrl} customClass={overlayWrapperClass}>
                    <Slide
                        in={open}
                        timeout={{ enter: 300, exit: 300 }}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        appear={true}
                    >
                        <div
                            className={classNames(
                                `slider bottomSheet h-100p w-100p flex 
                                ${closeIconType === ClossIconType.BACK ? 'flex-top' : 'flex-bottom'} 
                                p-relative flex-column flex-end backbutton_container`,
                                wrapperClassName
                            )}
                        >
                            <div className='close__Btn' style={{ pointerEvents: 'none' }} onClick={(e) => {
                                e.stopPropagation()
                                onClose(e)
                            }}></div>
                            {showCloseIconOnHeader && (
                                <div
                                    className="bottomSheet__header p-1 mr-4 ml-4 mb-2 mt-2"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (!closeOnOutSideClick) {
                                            onCloseWrapper(e)
                                        }
                                    }}
                                    style={{
                                        zIndex: closeIconZIndex,
                                        height: '32px',
                                        width: '32px',
                                        background: '#fff',
                                        right: '16px',
                                        top: ` ${window.innerHeight - containerHeight}px`,
                                        borderRadius: '40px',
                                        ...customCloseIconStyle
                                    }}
                                >
                                    {closeIconType === ClossIconType.CROSS ? (
                                        <Close height={'24'} width={'24'} color="#1A1A1A" />
                                    ) : (
                                        <BackArrow />
                                    )}
                                </div>
                            )}
                            <div
                                {...scrollProps}
                                className={classNames('bottomSheet__content bg-neutral-100 w-100p', contentClassName)}
                                ref={ref}
                                style={
                                    {
                                        // maxHeight: ` ${window.innerHeight - 56}px`
                                    }
                                }
                                data-testid="bottom_sheet_content"
                            >
                                <div
                                    style={{
                                        position: 'sticky',
                                        top: '0px',
                                        background: '#ffffff',
                                        zIndex: '100',
                                        marginRight: '-16px',
                                        marginLeft: '-16px',
                                        boxShadow: showShadow && scrollTop > 0 ? '0px 6px 10px rgba(0, 0, 0, 0.04)' : ''
                                    }}
                                    className={`${noPadding ? '' : 'pt-4 pb-4 px-4'}`}
                                >
                                    {headingText && (
                                        <div className="flex flex-between flex-middle">
                                            <div className={`fs-20 fw-600 lh-28 pt-2 c-black-900 ${headingClass}`}>
                                                {headingText}
                                            </div>
                                            {headerCta && (
                                                <div
                                                    className="fw-600 lh-20 fs-14 c-link-100 mt-2"
                                                    onClick={headerCtaAction}
                                                >
                                                    {headerCta}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {subHeaderText && (
                                        <div className={`fs-14 fw-400 lh-20 c-black-900 ${subHeaderClassName}`}>
                                            {subHeaderText}
                                        </div>
                                    )}
                                </div>

                                {children}
                            </div>
                        </div>
                    </Slide>
                </Overlay>
            ) : (
                <></>
            )}
        </>
    )

    return isServer() ? <></> : createPortal(portalContent, document.body)
}

BottomSheetUsingSlider.defaultProps = {
    children: null,
    onClose: () => { },
    closeOnOutSideClick: true,
    wrapperClassName: '',
    showCloseIconOnHeader: true,
    closeIconType: ClossIconType.CROSS
}

export default BottomSheetUsingSlider
