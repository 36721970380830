import { apiGetHelper, apiPutOrPostHelper, getApiDomain } from '@utils/helper'
// import mock from './servicesMock.json'
// import srpMock from './mock.json'

export const getSearchResult = async (query: string) => {
    try {
        const URL = `${getApiDomain()}/flight/search/v2?${query}`
        const response = await apiGetHelper(URL, 'application/json', {})
        return response
    } catch (e) {
        return {}
    }
}

// export const getSearchResult = async (query: string) => {
//     try {
//         const URL = `${getApiDomain()}/flight/search/v2?${query}`
//         const response = await new Promise(res => {
//             setTimeout(
//                 () =>
//                     res({
//                         status: 200,
//                         data: srpMock
//                     }),
//                 600
//             )
//         })
//         return response
//     } catch (e) {
//         return {}
//     }
// }

// export const getServicesData = async (payload: object) => {
//     try {
//         const URL = `${getApiDomain()}/flights/benefits`
//         const response = await new Promise(res => {
//             setTimeout(
//                 () =>
//                     res({
//                         status: 200,
//                         data: mock
//                     }),
//                 6000
//             )
//         })
//         return response
//     } catch (e) {
//         return {}
//     }
// }

export const getServicesData = async (payload: object) => {
    try {
        const URL = `/flight/benefits`
        const response = await apiPutOrPostHelper(URL, payload, 'application/json', {})
        return response
    } catch (e) {
        return {}
    }
}

export const getCouponsData = async (payload: any[]) => {
    try {
        const URL = `/flight/fetch-coupon-discount`
        const response = await apiPutOrPostHelper(URL, payload, 'application/json', {})
        // const response = {
        //     status: 200,
        //     data: couponDataMock
        // }
        // return new Promise(res => {
        //     setTimeout(() => {
        //         res(response)
        //     }, 300)
        // })
        return response
    } catch (e) {
        return {}
    }
}
