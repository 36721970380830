import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#fff"
        fillOpacity="0.8"
        fillRule="evenodd"
        d="M17.155 4.201c.126.059.267.231.281.364.3 2.771.07 5.466-1.257 7.984-.922 1.75-2.242 3.157-3.872 4.275-.338.232-.69.444-1.041.655-.244.147-.487.294-.726.447-.211.136-.385.064-.552-.049-.25-.17-.505-.338-.759-.505-.65-.428-1.299-.857-1.908-1.336-1.147-.901-2.072-2.012-2.804-3.278-.846-1.464-1.32-3.036-1.466-4.718-.095-1.089-.05-2.169.074-3.25v-.008c.029-.252.056-.489.355-.615.57-.241 1.136-.49 1.702-.738.625-.275 1.25-.55 1.88-.813C8.09 2.184 9.176 2.002 10.638 2c1.034-.011 2.33.327 3.578.89.416.188.833.372 1.25.556.565.248 1.129.497 1.689.755zm-6.756 3.42l1.36-1.321a.47.47 0 00.125-.215.49.49 0 00-.14-.492L10.447 4.26a.501.501 0 00-.338-.147.487.487 0 00-.49.475.5.5 0 00.137.343l.357.367a4.226 4.226 0 00-3.111 1.558 4.714 4.714 0 00-.378.533c-.11.186-.198.382-.282.58a4.282 4.282 0 00.096 3.378 4.247 4.247 0 003.767 2.454 4.264 4.264 0 002.22-.57 4.25 4.25 0 002.11-3.966c-.06-.78-.312-1.543-.777-2.175a4.728 4.728 0 00-.414-.503.502.502 0 00-.339-.146.487.487 0 00-.489.475.513.513 0 00.137.343c.105.108.203.221.294.34.165.233.304.484.413.748.1.267.168.545.206.829.027.28.023.564-.013.845-.045.28-.121.556-.228.82a3.79 3.79 0 01-.438.742c-.188.23-.402.439-.639.62a3.794 3.794 0 01-.75.415c-.268.099-.546.168-.83.205a3.82 3.82 0 01-.842-.012 3.803 3.803 0 01-.823-.229 3.793 3.793 0 01-.743-.438 3.827 3.827 0 01-.62-.638 3.783 3.783 0 01-.415-.752 3.804 3.804 0 01-.205-.828 3.848 3.848 0 01.012-.847 3.83 3.83 0 01.228-.823c.118-.263.266-.512.44-.742.186-.23.4-.436.635-.617.236-.168.49-.308.758-.419.266-.098.543-.167.824-.204.167-.016.335-.02.502-.015l-.691.672a.501.501 0 00-.147.338.487.487 0 00.475.49.506.506 0 00.343-.137zm1.878 1.443a.51.51 0 010 1.02H8.702a.51.51 0 110-1.02h3.575z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
