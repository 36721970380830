import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../configureStore'
import { fetchAncillaryData, fetchFareRules, fetchItinearyOffersData, fetchUserProfileData } from '@api/olditinerary'
import { airportChangeHandler, getFares, getInsurances, getSelectedInsurance } from '@utils/olditinerary/itinerary'
import { isEmpty } from '@utils/genralUtils'
import { FLIGHT_NOT_AVAILABLE, PRICE_CHANGED, PRICE_CHANGED_SEATSELL1 } from '@constants/olditinerary'
import { getInitialUserSelectedJourneyAncillaries } from '@utils/olditinerary/addons'
import { defaultCountryCode } from '@utils/olditinerary/contact'
import { createTravellersData } from '@utils/olditinerary/travellers'

const initialState: any = {
    itineraryData: {},
    itineraryOffers: {
        data: [],
        isLoading: true
    },
    faresData: {
        faresList: [],
        selectedFare: {}
    },
    insuranceData: {
        insuranceList: [],
        selectedInsurances: []
    },
    minirulesData: {
        isLoading: true,
        data: {},
        error: {}
    },
    metaData: {
        airportChangeData: {
            isAirportChange: false,
            isTerminalChange: false,
            isPopUpShown: false
        },
        priceChangeData: {
            isPriceChange: false,
            isPopUpShown: false
        }
    },
    couponData: {
        data: []
    },
    shukranData: {
        data: {}
    },
    gvData: {
        data: []
    },
    ancillaryData: {
        isLoading: true,
        data: {},
        selectedAncillaryData: {
            userSelectedJourneyAncillaries: []
        }
    },
    travellersData: {
        savedTravellersList: [],
        data: {}
    },
    contactData: {
        data: {},
        userContactData: {
            firstName: '',
            lastName: '',
            title: '',
            phone: '',
            email: '',
            countryCode: { ...defaultCountryCode() }
        },
        error: {
            phone: '',
            email: ''
        },
        isLoading: true,
        isEmailFieldDisabled: false,
        isPasswordEnabled: false
    },
    vat: {
        data: {}
    }
}

export const fetchMiniRules = createAsyncThunk(
    'itinerary/fetchMiniRules',
    async ({ itineraryId, signal }: { itineraryId: string; signal: AbortSignal }) => {
        const minirulesResponse = fetchFareRules(itineraryId, signal)
        return minirulesResponse
    }
)

export const fetchAncillary = createAsyncThunk(
    'itinerary/fetchAncillary',
    async ({ itineraryId, signal }: { itineraryId: string; signal: AbortSignal }) => {
        const response = fetchAncillaryData(itineraryId, signal)
        return response
    }
)

export const fetchUserProfile = createAsyncThunk(
    'itinerary/fetchUserProfile',
    async ({ itineraryId, signal }: { itineraryId: string; signal: AbortSignal }) => {
        const response = fetchUserProfileData(itineraryId, signal)
        return response
    }
)

export const fetchItinearyOffers = createAsyncThunk(
    'itinerary/fetchItinearyOffers',
    async ({ isRT, isIntl }: { isRT: boolean; isIntl: boolean }) => {
        try {
            const response = fetchItinearyOffersData({ isRT, isIntl })
            return response
        } catch (e) {
            console.log(e)
            logError(e)
        }
    }
)

export const itinerarySlice = createSlice({
    name: 'oldItineraryData',
    initialState,
    reducers: {
        setItineraryData: (state, action) => {
            const itineraryData = action.payload
            const {
                flights,
                vasAncillaries,
                meta,
                searchCriteria,
                couponDetails,
                giftVoucherData,
                itineraryStatus,
                passengers
            } = itineraryData || {}
            if (itineraryStatus === FLIGHT_NOT_AVAILABLE) {
                state.itineraryData = itineraryData || {}
                return
            }
            const faresList = getFares(flights, vasAncillaries)
            const insuranceList = getInsurances(vasAncillaries)
            const selectedInsurances = getSelectedInsurance(insuranceList, meta)
            if (!isEmpty(searchCriteria)) {
                let airportChange = { isAirportChange: false, isTerminalChange: false }
                airportChange = airportChangeHandler({ searchCriteria, flights })
                if (airportChange.isAirportChange) {
                    state.metaData.airportChangeData = {
                        ...airportChange,
                        isPopUpShown: false
                    }
                }
                if ([PRICE_CHANGED_SEATSELL1, PRICE_CHANGED].includes(itineraryStatus)) {
                    state.metaData.priceChangeData = {
                        isPriceChange: true,
                        isPopUpShown: false
                    }
                }
            }
            if (!isEmpty(couponDetails)) {
                state.couponData.data = couponDetails
            } else if (!isEmpty(giftVoucherData)) {
                state.gvData.data = giftVoucherData
            }
            state.itineraryData = itineraryData
            state.faresData.faresList = faresList
            state.insuranceData.insuranceList = insuranceList
            state.insuranceData.selectedInsurances = selectedInsurances
            const { mandatoryFields, travellers, validations } = createTravellersData(meta, searchCriteria, passengers)
            // savedTravellersList: [],

            state.travellersData.data = {
                mandatoryFields,
                travellers,
                validations
            }
        },
        setItineraryOffers(state, action) {
            const { payload = [] } = action
            if (!isEmpty(payload) && Array.isArray(payload)) {
                state.itineraryOffers.data = payload
                state.itineraryOffers.isLoading = false
            }
        },
        setSelectedFare(state, action) {
            state.faresData.selectedFare = action.payload
        },
        setSelectedInsurances(state, action) {
            state.insuranceData.selectedInsurances = action.payload
        },
        setAirportChangePopupStatus(state, action) {
            state.metaData.airportChangeData = {
                ...state.metaData.airportChangeData,
                isPopUpShown: action.payload
            }
        },
        setPriceChangePopupStatus(state, action) {
            state.metaData.priceChangeData = {
                ...state.metaData.priceChangeData,
                isPopUpShown: action.payload
            }
        },
        setCouponData(state, action) {
            state.couponData.data = action.payload
        },
        setShukranData(state, action) {
            state.shukranData.data = action.payload
        },
        setGvData(state, action) {
            state.gvData.data = action.payload
        },
        setAncillaryData(state, action) {
            const { isLoading, data, userSelectedJourneyAncillaries = [] } = action.payload
            state.ancillaryData.isLoading = isLoading
            state.ancillaryData.data = data
            state.ancillaryData.selectedAncillaryData = {
                userSelectedJourneyAncillaries
            }
        },
        setSelectedAncillaries(state, action) {
            state.ancillaryData.selectedAncillaryData = {
                userSelectedJourneyAncillaries: action.payload
            }
        },
        setTravellersData(state, action) {
            state.travellersData = {
                ...state.travellersData,
                data: {
                    ...state.travellersData.data,
                    travellers: {
                        ...state.travellersData.data.travellers,
                        ...action.payload
                    }
                }
            }
        },
        setContactData(state, action) {
            state.contactData = {
                ...state.contactData,
                ...action.payload
            }
        },
        setVatData(state, action) {
            state.vat.data = {
                ...state.vat,
                ...action.payload
            }
        },
        resetData() {
            return initialState
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchMiniRules.pending, (state, action) => {
            state.minirulesData.isLoading = true
            state.minirulesData.data = {}
            state.minirulesData.error = {}
        })
        builder.addCase(fetchMiniRules.fulfilled, (state, action) => {
            const { code = '', data = {} } = action.payload || { code: '', data: {} }
            if (code !== 'ERR_CANCELED') {
                state.minirulesData.isLoading = false
                state.minirulesData.data = data || {}
                state.minirulesData.error = {}
            }
        })
        builder.addCase(fetchMiniRules.rejected, (state, action) => {
            state.minirulesData.isLoading = false
            state.minirulesData.error = action.payload
            state.minirulesData.data = {}
        })
        builder.addCase(fetchAncillary.pending, state => {
            state.ancillaryData = {
                isLoading: true,
                data: {},
                selectedAncillaryData: {
                    userSelectedJourneyAncillaries: []
                }
            }
        })
        builder.addCase(fetchAncillary.fulfilled, (state, action) => {
            const { code = '' } = action.payload || { code: '' }
            if (code !== 'ERR_CANCELED') {
                const ancillaryData = action.payload?.data?.data || {}
                state.ancillaryData = {
                    isLoading: false,
                    data: ancillaryData,
                    selectedAncillaryData: {
                        userSelectedJourneyAncillaries: getInitialUserSelectedJourneyAncillaries(ancillaryData)
                    }
                }
            }
        })
        builder.addCase(fetchAncillary.rejected, (state, action) => {
            state.ancillaryData = {
                isLoading: false,
                data: {},
                selectedAncillaryData: {
                    userSelectedJourneyAncillaries: []
                }
            }
        })
        builder.addCase(fetchUserProfile.pending, state => {
            state.contactData = {
                data: {},
                userContactData: {
                    firstName: '',
                    lastName: '',
                    title: '',
                    phone: '',
                    email: '',
                    countryCode: { ...defaultCountryCode() }
                },
                error: {
                    phone: '',
                    email: ''
                },
                isLoading: true,
                isEmailFieldDisabled: false,
                isPasswordEnabled: false
            }
        })
        builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
            const { code = '', data } = action.payload || { code: '' }
            if (code !== 'ERR_CANCELED') {
                const { customer, travellers = [] } = data || {}
                const { title, firstName, lastName, email } = customer || {}
                state.contactData = {
                    data,
                    userContactData: {
                        firstName: firstName || '',
                        lastName: lastName || '',
                        title: title || '',
                        phone: '',
                        email: email || '',
                        countryCode: { ...defaultCountryCode() }
                    },
                    error: {
                        phone: '',
                        email: ''
                    },
                    isLoading: false,
                    isEmailFieldDisabled: !isEmpty(email),
                    isPasswordEnabled: false
                }
                state.travellersData.savedTravellersList = travellers || []
            }
        })
        builder.addCase(fetchUserProfile.rejected, (state, action) => {
            state.contactData = {
                data: {},
                userContactData: {
                    firstName: '',
                    lastName: '',
                    title: '',
                    phone: '',
                    email: '',
                    countryCode: { ...defaultCountryCode() }
                },
                error: {
                    phone: '',
                    email: ''
                },
                isLoading: false,
                isEmailFieldDisabled: false,
                isPasswordEnabled: false
            }
        })

        builder.addCase(fetchItinearyOffers.pending, (state, action) => {
            state.itineraryOffers = {
                data: [],
                isLoading: true
            }
        })
        builder.addCase(fetchItinearyOffers.fulfilled, (state, action) => {
            const { data = [] } = action.payload || {}
            state.itineraryOffers = {
                data,
                isLoading: false
            }
        })
        builder.addCase(fetchItinearyOffers.rejected, (state, action) => {
            state.itineraryOffers = {
                data: [],
                isLoading: false
            }
        })
    }
})

const getItineraryData = (state: RootState) => state.oldItineraryData.itineraryData
const getFaresData = (state: RootState) => state.oldItineraryData.faresData
const getItineraryOffersData = (state: RootState) => state.oldItineraryData.itineraryOffers
const getInsuranceData = (state: RootState) => state.oldItineraryData.insuranceData
const getMinirulesData = (state: RootState) => state.oldItineraryData.minirulesData
const getMetaData = (state: RootState) => state.oldItineraryData.metaData
const getCouponData = (state: RootState) => state.oldItineraryData.couponData
const getShukranData = (state: RootState) => state.oldItineraryData.shukranData
const getGvData = (state: RootState) => state.oldItineraryData.gvData
const getAncillaryData = (state: RootState) => state.oldItineraryData.ancillaryData
const getTravellersData = (state: RootState) => state.oldItineraryData.travellersData
const getContactData = (state: RootState) => state.oldItineraryData.contactData
const getVatData = (state: RootState) => state.oldItineraryData.vat

const { reducer, actions } = itinerarySlice

const {
    setItineraryData,
    setItineraryOffers,
    setSelectedFare,
    setSelectedInsurances,
    setAirportChangePopupStatus,
    setPriceChangePopupStatus,
    setCouponData,
    setShukranData,
    setGvData,
    setAncillaryData,
    setSelectedAncillaries,
    setTravellersData,
    setContactData,
    setVatData,
    resetData
} = actions

export {
    setItineraryData,
    setItineraryOffers,
    setSelectedFare,
    setSelectedInsurances,
    setAirportChangePopupStatus,
    setPriceChangePopupStatus,
    setCouponData,
    setShukranData,
    setGvData,
    setAncillaryData,
    setSelectedAncillaries,
    setTravellersData,
    setContactData,
    setVatData,
    resetData,
    getItineraryData,
    getMinirulesData,
    getFaresData,
    getItineraryOffersData,
    getInsuranceData,
    getMetaData,
    getCouponData,
    getShukranData,
    getGvData,
    getAncillaryData,
    getTravellersData,
    getContactData,
    getVatData
}

export default reducer
