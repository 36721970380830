import type { Theme } from '../../token/theme';
import { TypographyColor, TypographyColorType, TypographyVariant, TypographyVariantType } from './type';

export const getTypographyVariant = (theme: Theme, variant: TypographyVariantType) => {
	switch (variant) {
		case TypographyVariant.HD1: {
			return {
				fontSize: theme.typography.size[48],
				fontWeight: theme.typography.weight.semibold,
				lineHeight: theme.typography.lineHeight[56],
			};
		}
		case TypographyVariant.HD2: {
			return {
				fontSize: theme.typography.size[40],
				fontWeight: theme.typography.weight.semibold,
				lineHeight: theme.typography.lineHeight[48],
			};
		}
		case TypographyVariant.HD3: {
			return {
				fontSize: theme.typography.size[32],
				fontWeight: theme.typography.weight.semibold,
				lineHeight: theme.typography.lineHeight[40],
			};
		}
		case TypographyVariant.HM1: {
			return {
				fontSize: theme.typography.size[24],
				fontWeight: theme.typography.weight.semibold,
				lineHeight: theme.typography.lineHeight[32],
			};
		}
		case TypographyVariant.HM2: {
			return {
				fontSize: theme.typography.size[20],
				fontWeight: theme.typography.weight.semibold,
				lineHeight: theme.typography.lineHeight[28],
			};
		}
		case TypographyVariant.HM3: {
			return {
				fontSize: theme.typography.size[16],
				fontWeight: theme.typography.weight.semibold,
				lineHeight: theme.typography.lineHeight[24],
			};
		}
		case TypographyVariant.HM4: {
			return {
				fontSize: theme.typography.size[14],
				fontWeight: theme.typography.weight.semibold,
				lineHeight: theme.typography.lineHeight[20],
			};
		}
		case TypographyVariant.B1: {
			return {
				fontSize: theme.typography.size[16],
				fontWeight: theme.typography.weight.medium,
				lineHeight: theme.typography.lineHeight[22],
			};
		}
		case TypographyVariant.B2: {
			return {
				fontSize: theme.typography.size[14],
				fontWeight: theme.typography.weight.medium,
				lineHeight: theme.typography.lineHeight[20],
			};
		}
		case TypographyVariant.B3: {
			return {
				fontSize: theme.typography.size[12],
				fontWeight: theme.typography.weight.medium,
				lineHeight: theme.typography.lineHeight[16],
			};
		}
		case TypographyVariant.B4: {
			return {
				fontSize: theme.typography.size[10],
				fontWeight: theme.typography.weight.medium,
				lineHeight: theme.typography.lineHeight[14],
			};
		}
		case TypographyVariant.P1: {
			return {
				fontSize: theme.typography.size[16],
				fontWeight: theme.typography.weight.normal,
				lineHeight: theme.typography.lineHeight[22],
			};
		}
		case TypographyVariant.P2: {
			return {
				fontSize: theme.typography.size[14],
				fontWeight: theme.typography.weight.normal,
				lineHeight: theme.typography.lineHeight[20],
			};
		}
		case TypographyVariant.P3: {
			return {
				fontSize: theme.typography.size[12],
				fontWeight: theme.typography.weight.normal,
				lineHeight: theme.typography.lineHeight[16],
			};
		}
		case TypographyVariant.L1: {
			return {
				fontSize: theme.typography.size[16],
				fontWeight: theme.typography.weight.semibold,
				lineHeight: theme.typography.lineHeight[24],
			};
		}
		case TypographyVariant.L2: {
			return {
				fontSize: theme.typography.size[14],
				fontWeight: theme.typography.weight.semibold,
				lineHeight: theme.typography.lineHeight[20],
			};
		}
		case TypographyVariant.L3: {
			return {
				fontSize: theme.typography.size[12],
				fontWeight: theme.typography.weight.semibold,
				lineHeight: theme.typography.lineHeight[16],
			};
		}
		case TypographyVariant.OVERLINE: {
			return {
				fontSize: theme.typography.size[12],
				fontWeight: theme.typography.weight.semibold,
				lineHeight: theme.typography.lineHeight[16],
			};
		}
		case TypographyVariant.TAG: {
			return {
				fontSize: theme.typography.size[10],
				fontWeight: theme.typography.weight.semibold,
				lineHeight: theme.typography.lineHeight[12],
			};
		}
		default: {
			return {
				fontSize: theme.typography.size[20],
				fontWeight: theme.typography.weight.semibold,
				lineHeight: theme.typography.lineHeight[28],
			};
		}
	}
};

export const getTypographyColor = (theme: Theme, color: TypographyColorType) => {
	switch (color) {
		case TypographyColor.PRIMARY: {
			return theme.color.text.primary;
		}
		case TypographyColor.SECONDARY: {
			return theme.color.text.secondary;
		}
		case TypographyColor.TERTIARY: {
			return theme.color.text.tertiary;
		}
		case TypographyColor.ALERT: {
			return theme.color.text.alert;
		}
		case TypographyColor.DISABLED: {
			return theme.color.text.disabled;
		}
		case TypographyColor.HEADING: {
			return theme.color.text.heading;
		}
		case TypographyColor.LINK: {
			return theme.color.text.link;
		}
		case TypographyColor.SUBHEADING: {
			return theme.color.text.subHeading;
		}
		case TypographyColor.SUCCESS: {
			return theme.color.text.success;
		}
		case TypographyColor.WARNING: {
			return theme.color.text.warning;
		}
		case TypographyColor.NEUTRAL: {
			return theme.color.text.neutral;
		}
		default: {
			return theme.color.text.primary;
		}
	}
};

export const getLineClampStyles = (lineClamp?: number) => {
	if (lineClamp) {
		return {
			display: '-webkit-box',
			webkitLineClamp: lineClamp,
			webkitBoxOrient: 'vertical',  
			overflow: 'hidden',
		}
	}

	return {};
}

export const getTypographyStyles = ({
	theme,
	variant,
	color,
	isClickable,
	isStriked,
	colorCode,
	lineClamp
}: {
	theme: Theme;
	variant: TypographyVariantType;
	color: TypographyColorType;
	isClickable: boolean;
	isStriked: boolean;
	colorCode?: string;
	lineClamp?: number;
}) => {
	return {
		...getTypographyVariant(theme, variant),
		...getLineClampStyles(lineClamp),
		color: colorCode || getTypographyColor(theme, color),
		textDecoration: isStriked ? 'line-through' : 'none',
		cursor: isClickable ? 'pointer' : 'auto',
	};
};
