import React from "react"

const Close = ({ width = '24', height = '24', color = "#696969" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 6L12 12M12 12L6 18M12 12L6 6M12 12L18 18" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

export default Close;
