import { createContext } from "react";
import { ModalProps } from "./type";

type ModalContextProp = Pick<ModalProps, 'onClose' | 'size' | 'hideCrossIcon' | 'footerAlignment'>;
const ModalContext = createContext<ModalContextProp>({
	footerAlignment: 'end',
	hideCrossIcon: false,
	onClose: () => {},
	size: 'MEDIUM',
});

export default ModalContext;