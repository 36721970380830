import React from "react";

function Minus({height = 32, width = 32, strokeColor= "#1A1A1A"}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path stroke={strokeColor} d="M9 16h14" />
    <rect width={31} height={31} x={0.5} y={0.5} stroke={strokeColor} rx={15.5} />
  </svg>
  );
}

export default Minus;
