import { Container } from '@ct-design/lib'
import React, { useState, useEffect } from 'react'
import Toast from '@components/Toast'
import { isServer } from '@utils/appAgent'

const ShowToastIfOffline = () => {
    const [isOffline, setIsOffline] = useState(false)
    const OFFLINE_MESSAGE = 'You seem to be offline. Please check your internet connection.'

    function onlineCheck(e) {
        setIsOffline(e.type === 'offline')
    }

    useEffect(() => {
        window.addEventListener('offline', onlineCheck)
        window.addEventListener('online', onlineCheck)
        return () => {
            window.removeEventListener('offline', onlineCheck)
            window.removeEventListener('online', onlineCheck)
        }
    }, [])
    useEffect(() => {
        if (isOffline && !isServer() && typeof document !== 'undefined') {
            const htmlElement = document.querySelector('html');
            if (htmlElement && htmlElement.style) {
                htmlElement.style.filter = 'grayscale(1)';
                htmlElement.style.pointerEvents = 'none';
            }
        }
        else if (!isOffline && !isServer() && typeof document !== 'undefined') {
            const htmlElement = document.querySelector('html');
            if (htmlElement && htmlElement.style) {
                htmlElement.style.filter = '';
                htmlElement.style.pointerEvents = '';
            }
        }
    }, [isOffline])

    function renderOfflineToast() {
        return (
            <Container className="newPage">
                <Toast isVisible={true} message={OFFLINE_MESSAGE} bottomPos="80px" />
            </Container>
        )
    }
    if (isOffline) return renderOfflineToast()
    return null
}

export default ShowToastIfOffline
