import React from 'react';

const MediaDefaultPlus = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none' viewBox='0 0 48 48'>
            <mask
                id='mask0_2279_1153'
                style={{ maskType: 'alpha' }}
                width='48'
                height='48'
                x='0'
                y='0'
                maskUnits='userSpaceOnUse'
            >
                <path fill='#D9D9D9' d='M0 0H48V48H0z'></path>
            </mask>
            <g mask='url(#mask0_2279_1153)'>
                <path
                    fill='#B3B3B3'
                    d='M24 37.5c-.333 0-.608-.108-.825-.325a1.118 1.118 0 01-.325-.825v-11.2h-11.2c-.333 0-.608-.108-.825-.325A1.118 1.118 0 0110.5 24c0-.333.108-.608.325-.825.217-.217.492-.325.825-.325h11.2v-11.2c0-.333.108-.608.325-.825.217-.217.492-.325.825-.325.333 0 .608.108.825.325.217.217.325.492.325.825v11.2h11.2c.333 0 .608.108.825.325.217.217.325.492.325.825 0 .333-.108.608-.325.825a1.118 1.118 0 01-.825.325h-11.2v11.2c0 .333-.108.608-.325.825A1.118 1.118 0 0124 37.5z'
                ></path>
            </g>
        </svg>
    );
};

export default MediaDefaultPlus;
