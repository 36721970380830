import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../configureStore"
import { travelSafeBannerAPI } from "@api/resultsPage"

export interface TravelSafeDataState {
  travelSafeResponse?: Record<string, any>
}
const initialState: TravelSafeDataState = {
  travelSafeResponse: {},
}

export const fetchTravelSafeData = createAsyncThunk(
  "travelSafe/fetchTravelSafeData",
  async (payload) => {
    try {
      const response = await travelSafeBannerAPI(payload)
      return response
    } catch (e) {
      return {}
    }
  }
)

export const travelSafeDataSlice = createSlice({
  name: "travelSafe",
  initialState,
  reducers: {
    setTravelSafeData: (state, action) => {
      state.travelSafeResponse.data = action.payload || {}
      
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchTravelSafeData.fulfilled, (state, action) => {
      state.travelSafeResponse = { ...action.payload, resolved: true } || {}
    })
    builder.addCase(fetchTravelSafeData.rejected, (state, action) => {
      state.travelSafeResponse = { ...action.payload, resolved: true } || {}
    })
    builder.addCase(fetchTravelSafeData.pending, (state, action) => {
      state.travelSafeResponse = {}
    })
  },
})
const getTravelSafeResponse = (state: RootState) =>
  state.travelSafe.travelSafeResponse
const getTravelSafeResponseData = (state: RootState) =>
  state.travelSafe.travelSafeResponse?.data

const { reducer, actions } = travelSafeDataSlice
const { setTravelSafeData } = actions || {}

export { setTravelSafeData, getTravelSafeResponse, getTravelSafeResponseData }

export default reducer
