import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../configureStore"
import { flexiSearchFetch } from "@api/resultsPage/flexiSearchApi"

export interface FlexiDataState {
    flexiResponse?: Record<string, any>
    loading?: boolean
}
const initialState: FlexiDataState = {
    flexiResponse: {},
    loading: false
}

export const fetchFlexiData = createAsyncThunk('flexi/fetchFlexiData', async payload => {
    try {
        const response = await flexiSearchFetch(payload)
        return response
    } catch (e) {
        return {}
    }
})

export const flexiDataSlice = createSlice({
    name: 'flexi',
    initialState,
    reducers: {
        setFlexiData: (state, action) => {
            state.flexiResponse.data = {
                ...state.flexiResponse,
                ...action.payload
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchFlexiData.fulfilled, (state, action) => {
            state.flexiResponse = action.payload || {}
            state.loading = false
        })
        builder.addCase(fetchFlexiData.rejected, (state, action) => {
            state.flexiResponse = action.payload
            state.loading = false
        })
        builder.addCase(fetchFlexiData.pending, (state, action) => {
            state.flexiResponse = {}
            state.loading = true
        })
    }
})
const getFlexiResponse = (state: RootState) => state.flexi.flexiResponse
const getFlexiResponseData = (state: RootState) => state.flexi.flexiResponse?.data
const getFlexiLoading = (state: RootState) => state.flexi.loading

const { reducer, actions } = flexiDataSlice
const { setFlexiData } = actions || {}

export { setFlexiData, getFlexiResponse, getFlexiResponseData, getFlexiLoading }

export default reducer
