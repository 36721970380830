import querystring from 'querystring'
import { DAYS, MONTHS, language } from '@ui/constants'

import { CLAMP_MIN_MAX } from '@ui/constants/errors'
import { buildVariant } from '@root/configs/Whitelabel'
import { CLEARTRIP, FLIPKART } from '@constants/flights'
import { getFkUrlParams } from '@utils/flights/search'
import { getCurrencyByLocale } from '@utils/genralUtils'
import { isServer } from '@utils/appAgent'

export const typeOf = obj => {
    let detv = typeof obj
    const isObject = value => value === 'object'
    const isArray = value => Object.prototype.toString.call(value) === '[object Array]'

    if (isObject(detv)) {
        if (obj) {
            if (isArray(obj)) {
                detv = 'array'
            }
        } else {
            detv = 'null'
        }
    }
    return detv
}

export const isValidEmail = email => {
    const pattern = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    return pattern.test(email)
}

export const isValidVPA = vpa => {
    return /^([\w.%+-]+)@([\w-]+)+$/i.test(vpa)
}

export const gstValidator = (number, expression) => {
    const regex = new RegExp(expression)
    let matched = false
    if (number.length === 15) {
        matched = regex.test(number) // eslint-disable-line
    }
    return matched
}

export const vatValidator = (number, expression) => {
    const regex = new RegExp(expression)
    let matched = false
    matched = regex.test(number) // eslint-disable-line
    return matched
}

export const pad = s => {
    return s < 10 ? `0${s}` : s
}

export const convertDate = (date, separator = '-') => {
    // Accepts "Thu Aug 10 2017 00:00:00 GMT+0530 (IST)"
    // Returns 10-08-2017
    if (date) {
        const d = new Date(date)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(separator)
    }
}
export const convertDateForTracking = (date, separator = '-') => {
    // Accepts "Thu Aug 10 2017 00:00:00 GMT+0530 (IST)"
    // Returns 2017-08-10
    if (date) {
        const d = new Date(date)
        return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join(separator)
    }
}
export const getformattedDate = date => {
    // Accepts 10-08-2017
    // Returns Wed, 10 Aug
    const nDate = new Date(date)
    return `${DAYS[nDate.getDay()]}, ${nDate.getDate()} ${MONTHS[nDate.getMonth()]}`
}

export const getTimeHM = time => {
    // Accepts 23:45:00
    // Return 23:45
    const tm = time.split(':')
    return `${tm[0]}:${tm[1]}`
}

export const formatDate = date => {
    const type = typeOf(date)
    let nDate
    if (type === 'number') {
        nDate = new Date(date)
    } else if (type === 'string') {
        nDate = new Date(parseInt(date, 10))
    } else {
        nDate = date
    }
    return `${DAYS[nDate.getDay()]}, ${pad(nDate.getDate())} ${MONTHS[nDate.getMonth()]}`
}

export const isEmpty = obj => {
    if (obj instanceof Date) {
        return false
    }
    // null and undefined are "empty"
    if (obj == null) {
        return true
    }

    const isNumber = value => Object.prototype.toString.call(value) === '[object Number]'
    const isNaN = value => isNumber(value) && value.toString() === 'NaN'

    if (isNumber(obj)) {
        return isNaN(obj)
    }

    /** Assume if it has a length property with a non-zero value
     * that that property is correct.
     */
    if (obj.length > 0) {
        return false
    }
    if (obj.length === 0) {
        return true
    }

    /** If it isn't an object at this point
     * it is empty, but it can't be anything *but* empty
     * Is it empty?  Depends on your application.
     */
    if (typeof obj !== 'object') {
        return true
    }

    /**  Otherwise, does it have any properties of its own?
     * Note that this doesn't handle
     * toString and valueOf enumeration bugs in IE < 9
     */
    const keys = Object.keys(obj)
    for (let i = 0, key = keys[i]; i < keys.length; i += 1) {
        if (hasOwnProperty.call(obj, key)) {
            return false
        }
    }
    return true
}

export function getScript(url, callback) {
    //create a script element and inject it into the page
    var script = document.createElement('script')
    script.setAttribute('fetchpriority', 'low')
    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)

    //when the new script's load event fires, execute the callback
    script.onload = callback
}

export const path = (p, o) => {
    const reducerFunction = (xs, x) => {
        return xs && xs[x] ? xs[x] : null
    }
    return p.reduce(reducerFunction, o)
}

export const getFormattedTime = (totalSeconds, stringFormat = false, stringFormatToMin = false) => {
    if (typeof totalSeconds !== 'string') {
        let seconds = totalSeconds
        const days = Math.floor(seconds / 86400)
        seconds %= 86400
        const hours = Math.floor(seconds / 3600)
        seconds %= 3600
        const minutes = Math.floor(seconds / 60)
        seconds %= 60
        if (stringFormatToMin) {
            let hr = 0
            if (days > 0 || hours > 0) {
                if (days > 0) {
                    hr += days * 24
                }
                if (hours > 0) {
                    hr += hours
                }
                if (minutes > 30) {
                    hr += 1
                }
                return `${hr}h`
            }
            return `${minutes}m`
        } else if (stringFormat) {
            return `${days > 0 ? `${days}d ` : ''}${hours > 0 ? `${hours}h ` : ''}${
                minutes > 0 ? `${minutes}m` : hours > 0 ? '0m' : ''
            }`
        }
        return { days, hours, minutes, seconds }
    } else {
        return totalSeconds
    }
}

export const escapeRegExp = str => new RegExp(str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')) // eslint-disable-line no-useless-escape

export const toQueryString = (object, sep = '&', eq = '=') => {
    return querystring.stringify(object, sep, eq)
}

export const fromQueryString = (queryString, sep = '&', eq = '=') => {
    return querystring.parse(queryString, sep, eq)
}

export const getDayDiff = day => {
    const DAY_DIFF = {
        '-1': 'yesterday',
        0: '',
        1: 'tomorrow'
    }
    let dayDiff = DAY_DIFF[day]
    if (!dayDiff && dayDiff !== '') {
        if (day < 0) {
            dayDiff = `${Math.abs(day)} days ago`
        } else {
            dayDiff = `${day} day`
        }
    }
}

/** Clamps the given number between min and max values.
 * Returns value if within range, or closest bound.
 */
export const clamp = (val, min, max) => {
    if (max < min) {
        throw new Error(CLAMP_MIN_MAX)
    }
    return Math.min(Math.max(val, min), max)
}

/** Returns whether the value is a function. Acts as a type guard. */
export const isFunction = value => {
    return typeof value === 'function'
}

/**
 * Safely invoke the function with the given arguments,
 * if it is indeed a function, and return its value.
 */
export const safeInvoke = (func = undefined, ...args) => {
    return isFunction(func) && func(...args)
}

export const isNodeEnv = env => {
    return typeof process !== 'undefined' && process.env && process.env.NODE_ENV === env
}

export const getTrainsTravellerText = travellers => {
    const type = ['adults', 'children', 'srMen', 'srWomen']
    const data = []
    for (let i = 0; i < type.length - 1; i += 1) {
        const current = type[i]
        const count = travellers[current]
        let text = ''
        if (count > 0 || current === 'srMen') {
            if (current === 'adults') {
                text = `${count} Adult${count > 1 ? 's' : ''}`
            } else if (current === 'children') {
                text += `${count} Child${count > 1 ? 'ren' : ''}`
            } else {
                const srCount = count + travellers[type[i + 1]]
                if (srCount > 0) {
                    text = `${srCount} Sr. Adult${srCount > 1 ? 's' : ''}`
                }
            }
            if (text) {
                data.push(text)
            }
        }
    }
    return data.join(', ')
}

export const getFlightsTravellersText = travellers => {
    const types = ['adults', 'children', 'infants']
    const data = []
    types.forEach(type => {
        const count = travellers[type]
        let text = ''
        if (count > 0) {
            if (type === 'adults') {
                text = `${count} Adult${count > 1 ? 's' : ''}`
            } else if (type === 'children') {
                text += `${count} Child${count > 1 ? 'ren' : ''}`
            } else {
                text += `${count} Infant${count > 1 ? 's' : ''}`
            }
        }
        if (text) {
            data.push(text)
        }
    })
    return data.join(', ')
}

export const reduceObjectToArray = obj => {
    if (isEmpty(obj)) {
        return []
    }
    return Object.keys(obj).map(key => {
        const o = obj[key]
        o.id = key
        return o
    })
}

export const toProperCase = str => {
    return (
        str &&
        str.replace(/\w\S*/g, txt => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })
    )
}

export const openInNewTab = url => {
    const win = window.open(url, '_blank')
    win.focus()
}

export const addDaysToDate = (initialDate, days) => {
    const date = new Date(initialDate.valueOf())
    date.setDate(date.getDate() + days)
    return date
}

export const softClone = data => {
    if (Array.isArray(data)) {
        return data.slice(0)
    }
    if (data) {
        return Object.create(data)
    }
}

export const getCurrency = (currency = 'INR') => {
    const langMeta = getLanguageMeta()
    currency = langMeta.currency
    return currency
}

export const getLocaleByCurrency = (currency = 'INR') => {
    const locales = {
        INR: 'en-IN',
        AED: 'en-IN',
        OMR: 'en-IN',
        KWD: 'en-IN',
        BHD: 'en-IN',
        SAR: 'en-IN'
    }
    return locales[currency]
}

export function convertNumberLanguage(num) {
    return num.toLocaleString(`${language}`, {
        style: 'decimal',
        minimumFractionDigits: 0
    })
}

export const formatCurrency = (value, currency) => {
    if (!currency) {
        currency = getCurrency()
    }

    const locale = getLocaleByCurrency(currency)
    const formatted = parseInt(value, 10).toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0
    })
    return formatted
}

export const formatCurrencyCFW = (value, currency) => {
    if (!currency) {
        currency = getCurrency()
    }

    const formatted = parseInt(value, 10).toLocaleString({
        style: 'currency',
        currency,
        minimumFractionDigits: 0
    })
    return formatted
}

export const flightStops = stop => {
    // to be moved to flights utils or multi-lingual support
    return stop === 0 ? 'non-stop' : `${stop} stop${stop > 1 ? 's' : ''}`
}

export const stripTags = str => {
    // Some api error message throwing in html tags
    if (!str || str === '') {
        return ''
    }
    const newStr = str.toString()
    return newStr.replace(/<[^>]*>/g, '\n')
}

export const constructSearchQueryUrl = searchQuery => {
    const urlParams = {
        from: searchQuery.source,
        to: searchQuery.destination,
        from_header: searchQuery.sourceHeader,
        to_header: searchQuery.destinationHeader,
        depart_date: searchQuery.departDate,
        adults: searchQuery.travellers.adults,
        childs: searchQuery.travellers.children,
        infants: searchQuery.travellers.infants,
        class: searchQuery.flightClass,
        intl: searchQuery.isIntl ? 'y' : 'n'
    }
    if (searchQuery.returnDate) {
        urlParams.return_date = searchQuery.returnDate
    }
    if (searchQuery.srpFlow) {
        urlParams.srpFlow = searchQuery.srpFlow
    }
    if (searchQuery.sft && searchQuery.sft != 'rf') {
        urlParams.sft = searchQuery.sft
    }

    switch (buildVariant) {
        case CLEARTRIP:
            return urlParams
        case FLIPKART:
            return getFkUrlParams(urlParams)
    }
}

export const reduceStringToArray = str => {
    try {
        if (isEmpty(str)) {
            return []
        } else if (typeof str === 'string') {
            return JSON.parse(str)
        }
        return str
    } catch (e) {
        return []
    }
}

export const convertToBoolean = value => {
    if (typeof value === 'boolean') {
        return value
    }
    return value === 'true'
}

export const mapOnObject = (object, iteratee) => {
    if (typeof object === 'object' && object !== null && !isEmpty(object)) {
        if (!Object.entries) {
            Object.entries = function (obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i) // preallocate the Array
                while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]
                return resArray
            }
        }
        const mapValues = []
        for (const [name, value] of Object.entries(object)) {
            mapValues.push(iteratee(value, name))
        }
        return mapValues
    }
}

export const removeFromArray = (array, value) => {
    const index = array.indexOf(value)
    const newArray = array.slice()
    if (index > -1) {
        newArray.splice(index, 1)
        return newArray
    }
    return array
}

export const pluralize = (value, text, suffix = 's') => (+value === 1 ? text : `${text}${suffix}`)

export const sortCollection = (data, key) => {
    const newData = data.slice()
    return newData.sort((a, b) => a[key].localeCompare(b[key]))
}

export const isValidDate = date => {
    return date && !isNaN(date.getTime())
}

export const calculateImageHeight = width => {
    const aspectRatio = 4.0 / 3.0
    return Math.round(width / aspectRatio)
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', 'BlackBerry', 'ChromeOS' or 'unknown'.
 *
 * @returns {String}
 */

export const getLanguageMeta = host => {
    const domain = isServer() ? host : path(['location', 'hostname'], window) // window.location.hostname;

    switch (domain) {
        case 'www.cleartrip.ae':
        case 'qa2new.cleartrip.ae':
        case 'qa2.cleartrip.ae':
        case 'me.cleartrip.ae':
        case 'uat.cleartrip.ae':
        case 'localhost:3000':
            return { locale: 'AE', currency: 'AED', country: 'UAE', symbol: 'AED' }
        case 'bh.cleartrip.com':
        case 'qa2bh.cleartrip.com':
        case 'bh.cleartrip.sa':
        case 'uat.cleartrip.bh':
        case 'www.cleartrip.bh':
            return { locale: 'BH', currency: 'BHD', country: 'BHR', symbol: 'BHD' }
        case 'om.cleartrip.com':
        case 'qa2om.cleartrip.com':
        case 'om.cleartrip.sa':
        case 'uat.cleartrip.om':
        case 'www.cleartrip.om':
            return { locale: 'OM', currency: 'OMR', country: 'OMN', symbol: 'OMR' }
        case 'kw.cleartrip.com':
        case 'qa2kw.cleartrip.com':
        case 'kw.cleartrip.sa':
        case 'uat.cleartrip.com.kw':
        case 'www.cleartrip.com.kw':
            return { locale: 'KW', currency: 'KWD', country: 'KWT', symbol: 'KWD' }
        case 'qa.cleartrip.com':
        case 'qa2qa.cleartrip.com':
        case 'qar.cleartrip.sa':
        case 'uat.cleartrip.qa':
        case 'www.cleartrip.qa':
            return { locale: 'QA', currency: 'QAR', country: 'QAT', symbol: 'BHD' }
        case 'www.cleartrip.sa':
        case 'qa2.cleartrip.sa':
        case 'me.cleartrip.sa':
        case 'uat.cleartrip.sa':
            return { locale: 'SA', currency: 'SAR', country: 'KSA', symbol: 'SAR' }
        case 'qa2.cleartrip.com':
            return { locale: 'IN', currency: 'INR', country: 'IN', symbol: '₹' }
        case 'eg.flyin.com':
            return { locale: 'FEG', currency: 'SAR', country: 'KSA', symbol: 'SAR' }
        case 'qa2.flyin.com':
        case 'www.flyin.com':
        case 'in.flyin.com':
            return { locale: 'FSA', currency: 'SAR', country: 'KSA', symbol: 'SAR' }
        case 'me.cleartrip.com':
        case 'qa2me.cleartrip.com':
            return { locale: 'ME', currency: 'USD', country: 'ME', symbol: '$' }
        case 'www.cleartrip.me':
            return { locale: 'ME', currency: 'USD', country: 'ME', symbol: '$' }
        case 'www.cleartrip.eu':
            return { locale: 'EU', currency: 'EUR', country: 'EUR', symbol: '€' }
        case 'www.cleartrip.pl':
            return { locale: 'EU', currency: 'EUR', country: 'EUR', symbol: '€' }
        default:
            return { locale: 'SA', currency: 'SAR', country: 'KSA', symbol: 'SAR' }
    }
}

export const writeCookie = (k, v, expiryInDays) => {
    const isProd = process.env.NODE_ENV === 'production'
    if (isProd) {
        if (expiryInDays > 0) {
            const now = new Date()
            const time = now.getTime()
            const secondsInDay = 24 * 3600 * 1000
            const expireTime = time + secondsInDay * expiryInDays
            now.setTime(expireTime)
            document.cookie = `${k}=${v};path="/";`
            document.cookie = `expires=${now.toGMTString()};path="/";`
        } else if (!expiryInDays || expiryInDays === '') {
            document.cookie = `${k}=${v};path="/";`
        }
    }
    return null
}

export const writeCookieUtm = (k, v, expiryInDays) => {
    if (expiryInDays > 0) {
        const now = new Date()
        const nextDate = now.setDate(now.getDate() + expiryInDays)
        const expiresDate = new Date(nextDate)
        document.cookie = `${k}=${v}; path=/; expires=` + expiresDate
    } else if (!expiryInDays || expiryInDays === '') {
        document.cookie = `${k}=${v};path="/";`
    }

    return null
}

export function getCookie(name) {
    if (typeof document !== 'undefined' && document.cookie) {
        var nameEQ = name + '='
        var ca = document.cookie.split(';')
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i]
            while (c.charAt(0) == ' ') c = c.substring(1, c.length)
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
        }
        return ''
    } else {
        return ''
    }
}

export const readParam = (name, options = {}) => {
    const queryString = window.location.search.split('?')
    const urlHash = fromQueryString(queryString[1] ? queryString[1] : '')
    const params = urlHash ? urlHash : {}

    const cookieValue = getCookie(name || options.alias)
    const value = params[name] || params[options.alias]

    // if value present in param then return that, otherwise check in cookie
    if (value) {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        writeCookieUtm(name, value, options.expiry || 1)
        return value
    } else {
        if (cookieValue) {
            return cookieValue
        }
    }
}

export function indianDateFormat(date) {
    var date = new Date(date)
    const padLeft = (str, len, pad) => {
        if (typeof len == 'undefined') {
            var len = 0
        }
        if (typeof pad == 'undefined') {
            var pad = ' '
        }

        if (len + 1 >= str.length) {
            str = Array(len + 1 - str.length).join(pad) + str
        }

        return str
    }
    let dateOfMonth = date.getDate()
    let Month = date.getMonth()
    return padLeft(dateOfMonth + '', 2, '0') + '-' + padLeft(Month + 1 + '', 2, '0') + '-' + date.getFullYear()
}

export const getFormattedExpiryDate = date => {
    let [month, year] = date.split('/')
    month = month && parseInt(month, 10)
    if (year) {
        year = `20${year}`
        year = year && parseInt(year, 10)
    }
    return { month, year }
}

export const checkDeviceType = () => {
    let deviceType = null
    if (typeof window !== 'undefined') {
        if (path(['androidData', 'app-agent'], window) && path(['androidData', 'js-version'], window)) {
            deviceType = 'AndroidApp'
        }
        if (path(['iosData', 'app-agent'], window) && path(['iosData', 'js-version'], window)) {
            deviceType = 'iPhoneApp'
        }
        if (path(['sessionStorage'], window) && typeof window.sessionStorage != 'undefined') {
            deviceType = sessionStorage.getItem('mobileApp')
        }
    }

    return deviceType
}

export const validateEmail = email => {
    const pattern = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    return pattern.test(email)
}

export function getUserAuthValues() {
    const [email, profileName, gender, photo, userId] = decodeURIComponent(getCookie('userid') || '').split('|')
    return {
        email,
        profileName,
        gender,
        photo,
        userId
    }
}

export const isUserSignedIn = () => {
    let userObject = getUserAuthValues() || {}
    let usermiscVal = decodeURIComponent(getCookie('usermisc') || '').split('|')
    let signedIn = usermiscVal.includes('SIGNED_IN') && userObject.userId && userObject.userId.length > 0 ? true : false
    return signedIn
}

export const getBitBucketBuildNumber = () => {
    if (process.env.BUILD_NO) {
        return `${new Date().getFullYear() % 100}.${process.env.BUILD_NO / 100}`
    } else {
        return `${new Date().getFullYear() % 100}`
    }
}

export function throttle(callback, delay, options = {}) {
    let flag = true
    return () => {
        if (flag) {
            flag = false
            setTimeout(() => {
                callback(options)
                flag = true
            }, delay)
        }
    }
}

export function throttleWithImmediateExecution(callback, delay, params = {}) {
    let flag = true
    return () => {
        if (flag) {
            flag = false
            callback(params)
            setTimeout(() => {
                flag = true
            }, delay)
        }
    }
}
export const capitalizeFirstLetter = string => {
    if (isNaN(Number(string))) {
        return string
            .split(' ')
            .map(item => item.charAt(0).toUpperCase() + item.slice(1))
            .join('')
    }
    return string
}
export function invokeCustomEvent(EVENT_TYPE, obj) {
    const customEvent = new CustomEvent(EVENT_TYPE, { detail: { ...obj } })
    document.dispatchEvent(customEvent)
}

export function minutesToHnM(minutes) {
    const hours = Math.floor(minutes / 60)
    const remMin = minutes % 60
    return remMin > 0 ? `${hours}h ${remMin}m` : `${hours}h`
}

export const triggerRavenEvent = (eventName, payload) => {
    try {
        const { triggerRaven, RavenWebManager } = window
        if (!triggerRaven) {
            if (RavenWebManager) {
                const ravenWebManager = new RavenWebManager()
                window.triggerRaven = ravenWebManager.triggerRaven
            }
        }
        // console.log('checkhere', eventName, payload);
        window.triggerRaven(eventName, payload)
    } catch (error) {
        console.log(error)
    }
}

export const getPlatform = () => {
    let deviceType = 'pwa'
    if (path(['androidData', 'app-agent'], window) && path(['androidData', 'js-version'], window)) {
        deviceType = 'android'
    }
    if (path(['iosData', 'app-agent'], window) && path(['iosData', 'js-version'], window)) {
        deviceType = 'ios'
    }
    return deviceType
}
export const isSessionStorageValid = () => {
    return window && window?.sessionStorage && window?.sessionStorage !== ' undefined'
}

export const htmlSanitizer = value => {
    const re = /(<([^>]+)>)/gi
    if (value.match(re)) {
        return ''
    } else {
        return value
    }
}

export const replacePlaceHolder = (text, data, replaceWithTag = false, replaceClass = '') => {
    if (!isEmpty(text) && !isEmpty(data)) {
        const placeholders = text.match(/[^{\}]+(?=})/g)
        text = text.replace(/[{}]/g, '')
        if (!isEmpty(placeholders)) {
            placeholders.forEach((item, index) => {
                if (replaceWithTag) {
                    text = text.replace(
                        placeholders[index],
                        `<span class=${replaceClass}>${data[placeholders[index]]}</span>`
                    )
                } else {
                    text = text.replace(placeholders[index], data[placeholders[index]])
                }
            })
        }
    }
    return text
}

export const getCouponCalloutNew = (
    fare,
    getAbTest,
    type = 'normal',
    query,
    isPerAdult = false,
    isAppPushExp = false
) => {
    const backupSrpOfferMessege = {
        intl: 'Get at {discountedPrice} with ICICI cards ({couponCode})',
        intlBackup: 'Get at {discountedPrice} with {couponCode}',
        dom: 'Get at {discountedPrice} with {couponCode}',
        bbdCbucket: 'Get at {discountedPrice} with ICICI cards ({couponCode})',
        bbdDbucket: 'discount applied on ICICI cards',
        bbdDbucketBackup: 'discount applied'
    }
    let message = ''
    let { coupon_detail = {} } = fare
    const { message: totalMessage = '', adultCouponMessage = '' } = coupon_detail || {}
    message = isPerAdult ? adultCouponMessage : totalMessage
    let offerText = message || ''
    // if (
    //  !isPerAdult &&
    //   query.isIntl &&
    //   !isEmpty(coupon_detail) &&
    //   !isEmpty(coupon_detail.discountedPrice)
    // ) {
    //   offerText = getAbTest.isBBD
    //     ? backupSrpOfferMessege.intl
    //     : backupSrpOfferMessege.intlBackup;
    // } TODO- Messages are BE powered no, this logic needs to be removed.

    if (!isPerAdult && offerText && type === 'highRps' && getAbTest.highRps === 'd') {
        const price = formatCurrency(coupon_detail.discountAmount || 0)
        offerText = getAbTest.isBBD
            ? `${price} ${backupSrpOfferMessege.bbdDbucket}`
            : `${price} ${backupSrpOfferMessege.bbdDbucketBackup}`
        return offerText
    } else if (!isPerAdult && offerText && type === 'highRps' && getAbTest.highRps === 'c') {
        offerText = getAbTest.isBBD ? backupSrpOfferMessege.bbdCbucket : backupSrpOfferMessege.dom
    } else if (!isPerAdult && offerText && type === 'highRps' && getAbTest.highRps === 'b') {
        offerText = getAbTest.newHighRps === 'b' ? '{discountAmount} off with {couponCode}' : message || ''
    }
    const str = offerText
    const matches = str.match(/[^{\}]+(?=})/g)

    if (!isEmpty(offerText) && !isEmpty(matches)) {
        offerText = offerText.replace(/[{}]/g, '')
        for (let i = 0; i < matches.length; i++) {
            if (!isNaN(coupon_detail[matches[i]])) {
                offerText = offerText.replace(matches[i], formatCurrency(coupon_detail[matches[i]]))
            } else {
                offerText = offerText.replace(matches[i], coupon_detail[matches[i]])
            }
        }
    } else {
        offerText = message
    }
    if (isAppPushExp && ['splFare', 'normal'].includes(type)) {
        offerText = `${offerText}, save extra on app`
    }
    if (!isEmpty(offerText) && offerText.length > 64) {
        offerText = offerText.slice(0, 64) + '...'
    }

    return offerText
}
export function delete_cookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export const isAirHomePage = () => {
    const pathname = path(['location', 'pathname'], window)
    if (pathname === '/' || pathname === '/flights') {
        return true
    }
    return false
}
export const logAuthCookieInfo = (instance, delay = 3000) => {
    if (isIOSApp()) {
        const { userId } = getUserAuthValues()
        const userMisc = getCookie('usermisc')
        const logData = {
            userId,
            userMisc,
            instance: instance,
            signedId: isUserSignedIn()
        }

        logInfo('ios-logout-debug', logData, delay)
    }
}

export const getPayWalletParams = () => {
    const userIdCookie = getCookie('userid')
    const userId = decodeURIComponent(userIdCookie).split('|')[4]
    const utmCurr = getCookie('utm_currency')
    const defaultCurObj = getCurrencyByLocale()
    let defaultCurrency = utmCurr?.split('|')[0] || defaultCurObj?.currency || ''
    return {
        userId,
        defaultCurrency
    }
}

export const isIOSApp = () => checkDeviceType() === 'iPhoneApp'
export const logInfo = (actionName, data = {}, timeout = 1000) => {
    executeWhenIdle(() => {
        if (typeof newrelic == 'object') {
            newrelic.addPageAction(actionName, { result: 'info', rootURL: window.location.href, ...data })
        }
    }, timeout)
}

export const executeWhenIdle = (callback, timeout = 0) => {
    if (window.requestIdleCallback) {
        requestIdleCallback(callback, { timeout })
    } else {
        setTimeout(callback, timeout)
    }
}
