import { Theme } from '../../../token/theme';
import { StyledCardProps } from './type';

export const getStyledCardStyled = ({
	borderRadius,
	border,
	borderTop,
	borderBottom,
	borderLeft,
	borderRight,
	boxShadow,
	borderColor,
	theme,
}: StyledCardProps & { theme: Theme }) => {
	return {
		borderRadius,
		border,
		borderTop,
		borderBottom,
		borderLeft,
		borderRight,
		boxShadow,
		borderColor,
		backgroundColor: theme.color.background.neutral,
	};
};
