import { Theme } from '.';
import colors from '../global/color';
import BaseTheme from './BaseTheme';

const CleartripTheme: Theme = {
	...BaseTheme,
	color: {
		...BaseTheme.color,
		text: {
			...BaseTheme.color.text
		},
		button: {
			...BaseTheme.color.button,
			outlinedPrimaryLabel: colors.primaryNeutral900,
			outlinedPrimaryBorder: colors.lineDarkNeutral300,
			outlinedPrimaryBg: colors.surfaceBackground,

			// outlinedSecondaryLabel: colors.bluePrimary500,
			// outlinedSecondaryBorder: colors.bluePrimary500,
			// outlinedSecondaryBg: colors.neutral100,

			// outlinedTertiaryLabel: colors.primaryNeutral900,
			// outlinedTertiaryBorder: colors.primaryNeutral900,
			// outlinedTertiaryBg: colors.neutral100,

			containedPrimaryLabel: colors.neutral100,
			containedPrimaryBg: colors.link2,

			containedSecondaryLabel: colors.neutral100,
			containedSecondaryBg: colors.orangePrimary,

			// containedTertiaryLabel: colors.primaryNeutral900,
			// containedTertiaryBg: colors.yellowSecondary,
		},
		chip: {
			...BaseTheme.color.chip,
			selectedPrimaryLabel: colors.link2,
			selectedPrimaryBorder: colors.link2,
			selectedPrimaryBg: colors.linkBg2,
		},
		tab: {
			...BaseTheme.color.tab,
			nonSelectedPrimaryLabel: colors.neutral900,
			selectedPrimaryLabel: colors.bluePrimary500,
		},
		background: {
			...BaseTheme.color.background,
			primary: colors.link2,
			secondary: colors.neutral900,
			tertiary: colors.brand,
		},
		border: {
			...BaseTheme.color.border,
			primary: colors.bluePrimary500
			
		},
	},
};

export default CleartripTheme;
