import React from 'react';

interface PlusProps {
    height?: number;
    width?: number;
    plusColor?: string;
}

const Plus: React.FC<PlusProps> = ({ height = 16, width = 16, plusColor = '#3366CC' }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            fill='none'
            viewBox='0 0 16 16'
        >
            <path
                fill={plusColor}
                d='M8.5 3.333a.5.5 0 00-1 0h1zm-1 9.334a.5.5 0 001 0h-1zM3.333 7.5a.5.5 0 000 1v-1zm9.334 1a.5.5 0 000-1v1zM7.5 3.333v9.334h1V3.333h-1zM3.333 8.5h9.334v-1H3.333v1z'
            ></path>
        </svg>
    );
};

export default Plus;
