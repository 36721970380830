import { useContext } from 'react'
import { ThemeContext } from './../lib/ThemeProvider'
// import B2CTheme from '../token/theme/B2CTheme'
import FlipkartTheme from '@ct-design/token/theme/FlipkartTheme'
import { isFkEnv } from '@root/configs/Whitelabel'
import CleartripTheme from '@ct-design/token/theme/CleartripTheme'

const useTheme = () => {
    const themeContext = useContext(ThemeContext)
    const { theme } = isFkEnv ? { theme: FlipkartTheme } : themeContext || CleartripTheme

    return theme
}

export default useTheme
