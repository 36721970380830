import React from 'react';

const ErrorCalloutIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
            <mask
                id='mask0_1088_281041'
                style={{ maskType: 'alpha' }}
                width='24'
                height='24'
                x='0'
                y='0'
                maskUnits='userSpaceOnUse'
            >
                <path fill='#D9D9D9' d='M0 0H24V24H0z'></path>
            </mask>
            <g mask='url(#mask0_1088_281041)'>
                <path
                    fill='#CC1414'
                    d='M12 17a.968.968 0 00.713-.288A.967.967 0 0013 16a.97.97 0 00-.287-.713A.97.97 0 0012 15a.967.967 0 00-.712.287A.968.968 0 0011 16c0 .283.096.52.288.712A.965.965 0 0012 17zm0 5a9.733 9.733 0 01-3.9-.788 10.092 10.092 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 012 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 012.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0112 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 013.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0122 12a9.733 9.733 0 01-.788 3.9 10.092 10.092 0 01-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0112 22zm0-9a.968.968 0 00.713-.288A.967.967 0 0013 12V8a.97.97 0 00-.287-.713A.97.97 0 0012 7a.967.967 0 00-.712.287A.968.968 0 0011 8v4c0 .283.096.52.288.712A.965.965 0 0012 13z'
                ></path>
            </g>
        </svg>
    );
};

export default ErrorCalloutIcon;