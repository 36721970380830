import { CSSObject } from 'styled-components';
import { Theme } from '../../token/theme';
import {
	CardBorderDirection,
	CardBorderDirectionType,
	CardBorderWidthType,
	CardRadius,
	CardRadiusType,
	CardShadowDirection,
	CardShadowDirectionType,
	CardShadowVariant,
	CardShadowVariantType,
} from './type';

export const getCardBorderRadius = (cornerRadius: CardRadiusType, theme: Theme) => {
	switch (cornerRadius) {
		case CardRadius.NONE: {
			return theme.border.radius[0];
		}
		case CardRadius.SMALL: {
			return theme.border.radius[6];
		}
		case CardRadius.MEDIUM: {
			return theme.border.radius[8];
		}
		case CardRadius.LARGE: {
			return theme.border.radius[12];
		}
		default: {
			return theme.border.radius[8];
		}
	}
};

export const getCardBorderWidth = (borderWidth: CardBorderWidthType, theme: Theme) => {
	switch (borderWidth) {
		case CardRadius.NONE: {
			return theme.border.width.none;
		}
		case CardRadius.SMALL: {
			return theme.border.width.sm;
		}
		case CardRadius.MEDIUM: {
			return theme.border.width.md;
		}
		case CardRadius.LARGE: {
			return theme.border.width.lg;
		}
		default: {
			return theme.border.width.md;
		}
	}
};

export const getBorderDirectionStyles = (
	borderDirection: CardBorderDirectionType,
	borderWidth: string,
	borderStyle: CSSObject['borderStyle'],
	borderColor: string
) => {
	switch (borderDirection) {
		case CardBorderDirection.DEFAULT: {
			return {
				border: `${borderWidth} ${borderStyle} ${borderColor}`,
			};
		}
		case CardBorderDirection.TOP: {
			return {
				borderTop: `${borderWidth} ${borderStyle} ${borderColor}`,
			};
		}
		case CardBorderDirection.BOTTOM: {
			return {
				borderBottom: `${borderWidth} ${borderStyle} ${borderColor}`,
			};
		}
		case CardBorderDirection.LEFT: {
			return {
				borderLeft: `${borderWidth} ${borderStyle} ${borderColor}`,
			};
		}
		case CardBorderDirection.RIGHT: {
			return {
				borderRight: `${borderWidth} ${borderStyle} ${borderColor}`,
			};
		}
		default: {
			return {
				border: `${borderWidth} ${borderStyle} ${borderColor}`,
			};
		}
	}
};

export const getCardBorderStyles = (
	cardBorderWidth: CardBorderWidthType,
	cornerRadius: CardRadiusType,
	borderDirection: CardBorderDirectionType,
	theme: Theme
) => {
	const borderRadius = getCardBorderRadius(cornerRadius, theme);
	const borderStyle = theme.border.style.solid;
	const borderColor = theme.color.border.default;
	const borderWidth = getCardBorderWidth(cardBorderWidth, theme);

	const border = getBorderDirectionStyles(borderDirection, borderWidth, borderStyle, borderColor);

	return {
		borderRadius,
		...border,
	};
};

export const getTopShadow = (shadowVariant: CardShadowVariantType, theme: Theme) => {
	switch (shadowVariant) {
		case CardShadowVariant.E1: {
			return theme.elevation.topE1;
		}
		case CardShadowVariant.E2: {
			return theme.elevation.topE2;
		}
		case CardShadowVariant.E3: {
			return theme.elevation.topE3;
		}
		case CardShadowVariant.E4: {
			return theme.elevation.topE4;
		}
		default: {
			return theme.elevation.topE2;
		}
	}
};

export const getBottomShadow = (shadowVariant: CardShadowVariantType, theme: Theme) => {
	switch (shadowVariant) {
		case CardShadowVariant.E1: {
			return theme.elevation.bottomE1;
		}
		case CardShadowVariant.E2: {
			return theme.elevation.bottomE2;
		}
		case CardShadowVariant.E3: {
			return theme.elevation.bottomE3;
		}
		case CardShadowVariant.E4: {
			return theme.elevation.bottomE4;
		}
		default: {
			return theme.elevation.bottomE2;
		}
	}
};

export const getCardShadowStyles = (
	shadowDirection: CardShadowDirectionType,
	shadowVariant: CardShadowVariantType,
	theme: Theme
) => {
	switch (shadowDirection) {
		case CardShadowDirection.TOP: {
			return getTopShadow(shadowVariant, theme);
		}
		case CardShadowDirection.BOTTOM: {
			return getBottomShadow(shadowVariant, theme);
		}
		default: {
			return getTopShadow(shadowVariant, theme);
		}
	}
};

export const getCardStyles = ({
	cornerRadius,
	borderWidth,
	showShadow,
	shadowDirection,
	shadowVariant,
	borderDirection,
	theme,
}: {
	cornerRadius: CardRadiusType;
	borderWidth: CardBorderWidthType;
	borderDirection: CardBorderDirectionType;
	showShadow: boolean;
	shadowDirection: CardShadowDirectionType;
	shadowVariant: CardShadowVariantType;
	theme: Theme;
}) => {
	return {
		boxShadow: showShadow ? getCardShadowStyles(shadowDirection, shadowVariant, theme) : 'none',
		...getCardBorderStyles(borderWidth, cornerRadius, borderDirection, theme),
	};
};
