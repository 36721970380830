import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../configureStore'
import {
    fetchComponentsController,
    fetchAncillariesController,
    fetchInsurancesController,
    fetchCouponListController,
    updateItineraryData,
    getApplyCoupon,
    getFareFamilyInfo,
    fetchUserDataController
} from 'src/api/itineraryPage'
import {
    fetchComponentsControllerN,
    fetchAncillariesControllerN,
    fetchInsurancesControllerN,
    fetchCouponListControllerN,
    updateItineraryDataN,
    getApplyCouponN,
    getFareFamilyInfoN
} from 'src/api/cfw/CommonItinApi'
import { serializeItineraryData } from '@utils/itinerary'

const initialState = {
    loading: false,
    error: {},
    itineraryData: {
        USER_DIALOGUE: {
            dialogues: [
                {
                    actions: [],
                    dialogueType: '',
                    message: {
                        type: '',
                        title: '',
                        subTitle: ''
                    }
                }
            ]
        },
        ANCILLARY: { userSelectedAncillaries: {} },
        INSURANCES: {},
        USER: {},
        COUPON: {
            applicableCouponDetails: {
                applicableCouponList: []
            },
            appliedCouponDetails: {
                couponData: [
                    {
                        amount: 0,
                        couponCode: '',
                        message: ''
                    }
                ],
                userDialogueRequired: false
            }
        },
        FARE_BREAKUP: { pricingElements: [], finalPricing: {} },
        VOUCHERS: {},
        DEAL: {}
    },
    loadingState: {
        COMPONENTS: true,
        ANCILLARY: true,
        INSURANCES: true,
        COUPON: false,
        COUPON_LIST: true,
        UPDATING: false,
        CFWINSURANCES:true
    },
    appPushData: {
        couponBannerCount: 0
    },
    itineraryId: ''
}

export const fetchFlightsData = createAsyncThunk('itinerary/fetchFlightsData', async ({ itineraryId, isCfw }: {itineraryId: string; isCfw: boolean }) => {
    const response = await fetchComponentsControllerN(itineraryId, isCfw)
    return response
})

export const fetchAncillariesData = createAsyncThunk('itinerary/fetchAncillariesData', async ({ itineraryId, isCfw }: {itineraryId: string; isCfw: boolean }) => {
    const response = await fetchAncillariesControllerN(itineraryId, isCfw)
    return response
})

export const fetchUserData = createAsyncThunk('itinerary/fetchUserData', async (itineraryId: string) => {
    const response = await fetchUserDataController(itineraryId)
    return response
})

export const fetchInsurance = createAsyncThunk('itinerary/fetchInsurance', async ({ itineraryId, isCfw }: {itineraryId: string; isCfw: boolean }) => {
    const response = await fetchInsurancesControllerN(itineraryId, isCfw)
    return response
})

export const fetchCouponList = createAsyncThunk(
    'itinerary/fetchCouponList',
    async ({ itineraryId, oldFlow, isCfw }: {itineraryId: string; oldFlow: boolean; isCfw: boolean }) => {
        const response = await fetchCouponListControllerN(itineraryId, oldFlow, isCfw)
        return response
    }
)

export const updateItinerary = createAsyncThunk(
    'itinerary/updateItinerary',
    async ({ payload, itineraryId, isCfw }: {  payload: Object; itineraryId: string, isCfw: boolean }) => {
        const response = await updateItineraryDataN(payload, itineraryId, isCfw)
        return response
    }
)

export const applyCoupon = createAsyncThunk(
    'itinerary/applyCoupon',
    async ({ payload, itineraryId, isCfw }: {  payload: Object; itineraryId: string; isCfw: boolean }) => {
        const response = await getApplyCouponN(payload, itineraryId, isCfw)
        return response
    }
)

export const fetchFareBenefits = createAsyncThunk(
    'itinerary/fetchFareBenefits',
    async ({ itineraryId, type, isCfw }: { itineraryId: string; type: any, isCfw: boolean }) => {
        const response = await getFareFamilyInfoN(itineraryId, type, isCfw)
        return response
    }
)

export const itinerarySlice = createSlice({
    name: 'itinerary',
    initialState,
    reducers: {
        setItineraryData: (state, action) => {
            state.itineraryData = action.payload
        },
        setLoadingState: (state, action) => {
            state.loadingState.CFWINSURANCES = action.payload
        },
        setItineraryId: (state, action) => {
            state.itineraryId = action.payload
        },
        setAppPushData: (state, action) => {
            state.appPushData = action.payload || {}
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchFlightsData.pending, (state, action) => {
            state.loading = true
            state.loadingState.COMPONENTS = false
        })
        builder.addCase(fetchFlightsData.fulfilled, (state, action) => {
            state.loading = false
            state.loadingState.COMPONENTS = false
            try {
                const { status, data: responseData = {} } = action.payload
                if (status === 200 && responseData?.data?.components) {
                    const resposne = serializeItineraryData(responseData.data.components)
                    state.itineraryData = {
                        ...state.itineraryData,
                        ...resposne
                    }
                } else {
                    state.error = responseData.error
                }
            } catch (e) {
                console.log(e)
            }
        })
        builder.addCase(fetchFlightsData.rejected, (state, action) => {
            state.loading = false
            state.loadingState.COMPONENTS = false
        })
        builder.addCase(fetchAncillariesData.rejected, (state, action) => {
            state.loadingState.ANCILLARY = false
        })
        builder.addCase(fetchAncillariesData.fulfilled, (state, action) => {
            try {
                const { status, data: responseData = {} } = action.payload || {}
                if (status === 200 && responseData?.data?.components) {
                    const resposne = serializeItineraryData(responseData.data.components)
                    state.itineraryData = {
                        ...state.itineraryData,
                        ...resposne
                    }
                } else {
                    state.error = responseData.error
                }
                state.loadingState.ANCILLARY = false
            } catch (e) {
                state.loadingState.ANCILLARY = false
                console.log(e)
            }
        })
        builder.addCase(fetchInsurance.rejected, (state, action) => {
            state.loadingState.INSURANCES = false
        })
        builder.addCase(fetchInsurance.fulfilled, (state, action) => {
            try {
                const { status, data: responseData = {} } = action.payload || {}
                if (status === 200 && responseData?.data?.components) {
                    const resposne = serializeItineraryData(responseData.data.components)
                    state.itineraryData = {
                        ...state.itineraryData,
                        ...resposne
                    }
                } else {
                    state.error = responseData.error
                }
                state.loadingState.INSURANCES = false
            } catch (e) {
                state.loadingState.INSURANCES = false
                console.log(e)
            }
        })
        builder.addCase(fetchUserData.fulfilled, (state, action) => {
            try {
                const { status, data: responseData = {} } = action.payload || {}
                if (status === 200 && responseData?.data?.components) {
                    const resposne = serializeItineraryData(responseData.data.components)
                    state.itineraryData = {
                        ...state.itineraryData,
                        ...resposne
                    }
                } else {
                    state.error = responseData.error
                }
            } catch (e) {
                console.log(e)
            }
        })
        builder.addCase(fetchFareBenefits.fulfilled, (state, action) => {
            try {
                const { status, data: responseData = {} } = action.payload || {}
                if (status === 200 && responseData?.data?.components) {
                    const resposne = serializeItineraryData(responseData.data.components)
                    state.itineraryData = {
                        ...state.itineraryData,
                        ...resposne
                    }
                } else {
                    state.error = responseData.error
                }
                state.loadingState.INSURANCES = false
            } catch (e) {
                state.loadingState.INSURANCES = false
                console.log(e)
            }
        })
        builder.addCase(fetchCouponList.fulfilled, (state, action) => {
            try {
                const { status, data: responseData = {} } = action.payload || {}
                if (status === 200 && responseData?.data?.components) {
                    const resposne = serializeItineraryData(responseData.data.components)
                    state.itineraryData = {
                        ...state.itineraryData,
                        ...resposne
                    }
                } else {
                    state.error = responseData.error
                }
                state.loadingState.COUPON_LIST = false
            } catch (e) {
                console.log(e)
                state.loadingState.COUPON_LIST = false
            }
        })
        builder.addCase(fetchCouponList.rejected, (state, action) => {
            state.loadingState.COUPON_LIST = false
        })

        builder.addCase(updateItinerary.pending, (state, action) => {
            state.loading = true
            state.loadingState.UPDATING = true
        })
        builder.addCase(updateItinerary.fulfilled, (state, action) => {
            try {
                const { status, data: responseData = {} } = action.payload || {}
                if (status === 200 && responseData?.data?.components) {
                    const resposne = serializeItineraryData(responseData.data.components)
                    state.itineraryData = {
                        ...state.itineraryData,
                        ...resposne
                    }
                } else {
                    state.error = responseData.error
                }
                state.loading = false
                state.loadingState.UPDATING = false
            } catch (e) {
                state.loading = false
                state.loadingState.UPDATING = false
                console.log(e)
            }
        })
        builder.addCase(updateItinerary.rejected, (state, action) => {
            state.loading = false
            state.loadingState.UPDATING = false
        })
        builder.addCase(applyCoupon.pending, (state, action) => {
            state.loadingState.COUPON = true
        })
        builder.addCase(applyCoupon.fulfilled, (state, action) => {
            try {
                const { status, data: responseData = {} } = action.payload || {}
                if (status === 200 && responseData?.data?.components) {
                    const resposne = serializeItineraryData(responseData.data.components)
                    state.itineraryData = {
                        ...state.itineraryData,
                        ...resposne
                    }
                } else {
                    state.error = responseData.error
                }
                state.loadingState.COUPON = false
            } catch (e) {
                console.log(e)
                state.loadingState.COUPON = false
            }
        })
        builder.addCase(applyCoupon.rejected, (state, action) => {
            state.loadingState.COUPON = false
        })
    }
})

const getItineraryData = (state: RootState) => state.itinerary.itineraryData
const getLoadingState = (state: RootState) => state.itinerary.loadingState
const getItineraryId = (state: RootState) => state.itinerary.itineraryId
const getItineraryState = (state: RootState) => state.itinerary
const getAppPushData = (state: RootState) => state.itinerary.appPushData

const { reducer, actions } = itinerarySlice

const { setItineraryData, setLoadingState, setItineraryId, setAppPushData } = actions

export {
    getItineraryData,
    getItineraryState,
    setItineraryData,
    getLoadingState,
    getAppPushData,
    setLoadingState,
    setItineraryId,
    setAppPushData,
    getItineraryId
}

export default reducer
