import React from "react";

function CounterPlus ({height = 32, width = 32, strokeColor= "#1A1A1A"}){
  return (
    <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 9v14m-7-7h14"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} stroke={strokeColor} />
  </svg>
  );
}

export default CounterPlus;
