import { CLEARTRIP, FLIPKART, appRoutePrefix } from '@constants/flights'
import { buildVariant } from '@root/configs/Whitelabel'
import { getLanguageMeta } from '@utils/genralUtils'

const getBasePath = () => {
    let FLIGHTS_BASE_PATH = ''
    let FLIGHTS_DOM_RESULTS = ''
    let FLIGHTS_INTL_RESULTS = ''
    switch (buildVariant) {
        case FLIPKART:
            FLIGHTS_BASE_PATH = `/travel/flights`
            FLIGHTS_DOM_RESULTS = `${FLIGHTS_BASE_PATH}/search`
            FLIGHTS_INTL_RESULTS = `${FLIGHTS_BASE_PATH}/search`
            break
        case CLEARTRIP:
        default:
            FLIGHTS_BASE_PATH = `${appRoutePrefix}/flights`
            FLIGHTS_DOM_RESULTS = `${FLIGHTS_BASE_PATH}/results`
            FLIGHTS_INTL_RESULTS = `${FLIGHTS_BASE_PATH}/international/results`
    }
    return { FLIGHTS_BASE_PATH, FLIGHTS_DOM_RESULTS, FLIGHTS_INTL_RESULTS }
}
const { FLIGHTS_BASE_PATH, FLIGHTS_DOM_RESULTS, FLIGHTS_INTL_RESULTS } = getBasePath()
export { FLIGHTS_BASE_PATH, FLIGHTS_DOM_RESULTS, FLIGHTS_INTL_RESULTS }
export const HI_FIVE_REFERRING_URL = `/hi-five`

export const FLIGHT_LOGO_URL = (logo: string, size: string) => {
    if (!logo) {
        return '//ui.cltp.co/images/logos/air-logos/DEFAULT_2x.png'
    }
    logo = logo.replace('3L', 'G9')
    return `//ui.cltp.co/images/logos/air-logos/svg_logos/${logo}.svg`
}
export const BBD_MOBILE_JSON_SERVER = (host:String) => {
    return process.env.APP_ENV == 'production'
        ? `https://ui.cltp.co/offer-banner/mobile/${host}_banner.json?${new Date().getTime()}`
        : `https://ui.cltp.co/offer-banner/mobile/${host}_banner.json?${new Date().getTime()}`
}