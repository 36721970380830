import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../configureStore";

interface airportState {
  history: Array<object>;
}
export interface SearchDataState {
  airports?: airportState;
  homePagecurrentTime?: number;
  searchQuery?: object;
  airportSearchResult?: {
    query: string;
    result: [];
  };
}
const initialState: SearchDataState = {
  airports: { history: [] },
  homePagecurrentTime: 0,
  searchQuery: {},
  airportSearchResult: {
    query: "",
    result: [],
  },
};

export const searchConfigSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchCurrentTime: (state, action) => {
      state.homePagecurrentTime = action.payload;
    },
    setAirportSearchHistory: (state, action) => {
      const filteredHistory = state.airports.history.filter((value) => {
        return value.code !== action.payload.code;
      });
      state.airports = {
        ...state.airports,
        history: [action.payload, ...filteredHistory],
      };
    },
    saveFlightSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    getFlightsSearchHistorySuccess: (state, action) => {
      state.airports = { ...state.airports, history: [...action.payload] };
    },
    resetSearch: (state, action) => {
      state.airportSearchResult = {
        query: "",
        result: [],
      };
    },
    searchSuccess: (state, action) => {
      state.airportSearchResult = {
        query: action.payload.query,
        result: action.payload.data,
      };
    },
    searchFailure: (state, action) => {
      state.airportSearchResult = {
        query: action.payload.query,
        result: [],
      };
    },
  },
});
const getAirportSearchHistory = (state: RootState) =>
  state.search.airports?.history;

const getSearchTime = (state: RootState) => state.search.homePagecurrentTime;

const getAirportSearchResult = (state: RootState) =>
  state.search.airportSearchResult;
const { reducer, actions } = searchConfigSlice;
const {
  setSearchCurrentTime,
  setAirportSearchHistory,
  saveFlightSearchQuery,
  getFlightsSearchHistorySuccess,
  resetSearch,
  searchSuccess,
  searchFailure,
} = actions || {};

export {
  setSearchCurrentTime,
  getSearchTime,
  getAirportSearchHistory,
  setAirportSearchHistory,
  saveFlightSearchQuery,
  getFlightsSearchHistorySuccess,
  resetSearch,
  getAirportSearchResult,
  searchSuccess,
  searchFailure,
};

export default reducer;
