import { isServer } from '@utils/appAgent'
import { isEmpty } from '@utils/genralUtils'
import dayjs from 'dayjs'

export const getTravellersTemplate = paxType => {
    const travellerTemplate = {
        personalDetails: {
            firstName: '',
            middleName: '',
            lastName: '',
            gender: '',
            title: '',
            dateOfBirth: '',
            birthCountry: '',
            nationality: '',
            paxType,
            id: null
        },
        docDetails: [
            {
                countryIssued: '',
                dateOfBirth: '',
                docNumber: '',
                docType: 'PASSPORT',
                expiryDate: '',
                id: 0,
                nationality: ''
            }
        ],
        errorFields: {
            firstName: '',
            middleName: '',
            lastName: '',
            gender: '',
            title: '',
            dateOfBirth: '',
            nationality: '',
            docNumber: '',
            expiryDate: '',
            countryIssued: ''
        },
        availableDocTypes: ['PASSPORT'],
        selectedDocType: 'PASSPORT'
    }
    return travellerTemplate
}
const initializeRequiredDocs = (mandatoryFields: string[]) => {
    const requiredDocs: Record<string, boolean> = {
        DOB: false,
        PASSPORT: false,
        BIRTH_COUNTRY: false,
        NATIONALITY: false
        // IQAMA: false,
        // NATIONALID: false
    }

    for (const field of mandatoryFields) {
        requiredDocs[field] = true
    }

    return requiredDocs
}

const generateMandatoryFields = (requiredDocs: Record<string, boolean>) => {
    const mandatoryFields = {
        adults: ['TITLE', 'FIRST_NAME', 'LAST_NAME'],
        children: ['TITLE', 'FIRST_NAME', 'LAST_NAME', 'DOB'],
        infants: ['TITLE', 'FIRST_NAME', 'LAST_NAME', 'DOB']
    }

    Object.keys(requiredDocs).forEach(key => {
        if (requiredDocs[key]) {
            if (!mandatoryFields.adults.includes(key)) mandatoryFields.adults.push(key)
            if (!mandatoryFields.children.includes(key)) mandatoryFields.children.push(key)
            if (!mandatoryFields.infants.includes(key)) mandatoryFields.infants.push(key)
        }
    })

    return mandatoryFields
}

const createTravellerList = (count: number, paxType: string, exsisitngTravellers) => {
    const travellerTemplate = getTravellersTemplate(paxType)
    return Array.from({ length: count }, (_, index) => {
        const selectedTraveller = exsisitngTravellers?.[index] || {}
        const {
            peopleId = '',
            firstName = '',
            gender = '',
            lastName = '',
            title = '',
            middleName = '',
            dateOfBirth = '',
            birthCountry = '',
            nationality = '',
            docDetails
        } = selectedTraveller || {}

        const { countryIssued = '', docNumber = '', expiryDate = '' } = docDetails?.[0] || {}

        return {
            ...travellerTemplate,
            personalDetails: {
                ...travellerTemplate.personalDetails,
                peopleId,
                firstName,
                gender,
                lastName,
                title,
                middleName,
                // dateOfBirth,
                birthCountry,
                nationality
            },
            docDetails: [
                {
                    ...travellerTemplate.docDetails[0],
                    countryIssued,
                    // dateOfBirth,
                    docNumber,
                    expiryDate,
                    nationality
                }
            ]
        }
    })
}

const initializeTravellers = (adults: number, children: number, infants: number, passengers) => {
    const travellers: Record<string, any[]> = {}
    let exsisitngAdultTravellers = []
    let exsisitngChildTravellers = []
    let exsisitngInfantTravellers = []
    if (Array.isArray(passengers)) {
        exsisitngAdultTravellers = passengers.filter(passenger => {
            return passenger.type === 'ADT'
        })
        exsisitngChildTravellers = passengers.filter(passenger => {
            return passenger.type === 'CHD'
        })
        exsisitngInfantTravellers = passengers.filter(passenger => {
            return passenger.type === 'INF'
        })
    }

    if (adults > 0) {
        travellers.adults = createTravellerList(adults, 'adults', exsisitngAdultTravellers)
    }
    if (children > 0) {
        travellers.children = createTravellerList(children, 'children', exsisitngChildTravellers)
    }
    if (infants > 0) {
        travellers.infants = createTravellerList(infants, 'infants', exsisitngInfantTravellers)
    }

    return travellers
}

const getValidationsData = (meta = {}) => {
    const minFirstNameLength = meta.minLastNameLength
    const maxFirstNameLength = meta.maxPaxNameLength
    const minLastNameLength = meta.minLastNameLength
    const maxLastNameLength = meta.maxPaxNameLength
    return { minFirstNameLength, maxFirstNameLength, minLastNameLength, maxLastNameLength }
}

export const createTravellersData = (meta, searchCriteria, passengers) => {
    const { paxInfo } = searchCriteria
    const { adults = 0, children = 0, infants = 0 } = paxInfo || {}

    const requiredDocs = initializeRequiredDocs(meta.mandatoryFields)
    const mandatoryFields = generateMandatoryFields(requiredDocs)
    const travellers = initializeTravellers(adults, children, infants, passengers)
    const validations = getValidationsData(meta)

    return { mandatoryFields, travellers, validations }
}

export const pad = s => {
    return s < 10 ? `0${s}` : s
}
const getYearsList = type => {
    let yearList = []
    const currYear = new Date().getFullYear()
    if (type === 'children') {
        for (let i = currYear - 12; i <= currYear - 1; i++) {
            yearList.push(i)
        }
    } else if (type === 'infants') {
        for (let i = currYear - 3; i <= currYear; i++) {
            yearList.push(i)
        }
    }
    return yearList
}
export const getDobRange = type => {
    const dobYearList = getYearsList(type)
    let maxDobYear = Math.max.apply(null, dobYearList)
    let minDobYear = Math.min.apply(null, dobYearList)
    if (isEmpty(dobYearList)) {
        maxDobYear = new Date().getFullYear() - 12
        minDobYear = new Date().getFullYear() - 120
    }
    let maxDobMonth
    let minDobMonth
    let minDobDate
    let maxDobDate
    if (maxDobYear === new Date().getFullYear()) {
        maxDobMonth = pad(new Date().getMonth() + 1)
        minDobMonth = pad(new Date().getMonth() + 1)
        minDobDate = pad(new Date().getDate() + 1)
        maxDobDate = pad(new Date().getDate())
    } else {
        maxDobMonth = pad(12)
        minDobMonth = pad(1)
        minDobDate = pad(1)
        maxDobDate = pad(31)
    }
    return {
        maxDob: `${maxDobYear}-${maxDobMonth}-${maxDobDate}`,
        minDob: `${minDobYear}-${minDobMonth}-${minDobDate}`
    }
}

const format = day => {
    const date = dayjs(day)
    const formattedDate = date.toISOString().split('T')[0]
    return formattedDate
}
const payloadFormat = day => {
    day = format(day)
    let date = `${day}T00:00:00+05:30`
    return date
}

export const makeUpdateItineraryPayload = ({ travellersData, contactData, vatData, paxInfo, itineraryId }) => {
    const { mandatoryFields, travellers } = travellersData || {}
    const { data: customerData, userContactData } = contactData || {}
    const { email, phone, countryCode } = userContactData || {}
    const { code: mobileCountryCode } = countryCode || {}
    let presentTravellers: any = {}
    Object.keys(paxInfo).forEach(key => {
        if (paxInfo[key] > 0) {
            presentTravellers = {
                ...presentTravellers,
                [key]: paxInfo[key]
            }
        }
    })

    let travDetail: any = []
    let gstDetails = []
    Object.keys(presentTravellers).forEach(key => {
        let index = 0
        let personalDetails = {}
        let docDetails = {}

        let keyMap = {
            adults: 'ADT',
            children: 'CHD',
            infants: 'INF'
        }
        const gender = {
            Mr: 'Male',
            Mrs: 'Female',
            Miss: 'Female',
            Mstr: 'Male',
            Ms: 'Female'
        }
        const mandatoryFieldsForPax = mandatoryFields?.[key] || []

        for (index = 0; index < presentTravellers[key]; index++) {
            let travDetails = travellers?.[key]?.[index]
            const {
                personalDetails: travellerPersonalDetails,
                docDetails: travellerDocDetails,
                selectedDocType
            } = travDetails || {}
            const travellerSelectedDoc = travellerDocDetails[0] || {}
            let obj = {
                id: null
            }
            obj.id = travellerPersonalDetails.peopleId ? travellerPersonalDetails.peopleId : null

            personalDetails = {
                id: travellerPersonalDetails.peopleId || null,
                firstName: travellerPersonalDetails.firstName,
                lastName: travellerPersonalDetails.lastName,
                gender: travellerPersonalDetails.gender || gender[travellerPersonalDetails.title],
                title: travellerPersonalDetails.title,
                paxType: keyMap[key]
            }

            docDetails = {
                id: 0,
                docType: selectedDocType,
                docNumber: travellerSelectedDoc.docNumber,
                expiryDate: travellerSelectedDoc.expiryDate ? payloadFormat(travellerSelectedDoc.expiryDate) : ''
            }
            if (travellerPersonalDetails.middleName) {
                personalDetails = {
                    ...personalDetails,
                    middleName: travellerPersonalDetails.middleName
                }
            }
            if (travellerPersonalDetails.dateOfBirth) {
                personalDetails = {
                    ...personalDetails,
                    dateOfBirth: payloadFormat(travellerPersonalDetails.dateOfBirth)
                }
                docDetails = {
                    ...docDetails,
                    dateOfBirth: payloadFormat(travellerPersonalDetails.dateOfBirth)
                }
            }
            if (travellerPersonalDetails.nationality) {
                personalDetails = {
                    ...personalDetails,
                    nationality: travellerPersonalDetails.nationality
                }
                docDetails = {
                    ...docDetails,
                    nationality: travellerPersonalDetails.nationality
                }
            }
            if (travellerPersonalDetails.birthCountry && travellerPersonalDetails.birthCountry) {
                personalDetails = {
                    ...personalDetails,
                    birthCountry: travellerPersonalDetails.birthCountry
                }
                docDetails = {
                    ...docDetails,
                    birthCountry: travellerPersonalDetails.birthCountry
                }
            }
            if (travellerSelectedDoc.countryIssued) {
                docDetails = {
                    ...docDetails,
                    countryIssued: travellerSelectedDoc.countryIssued
                }
            }
            obj = { ...obj, personalDetails: personalDetails }
            // if (requiredDocs.PASSPORT || requiredDocs.IQAMA || requiredDocs.NATIONALID) {
            //     obj = { ...obj, docDetails: [docDetails] }
            // }
            if (mandatoryFieldsForPax.includes('PASSPORT')) {
                obj = { ...obj, docDetails: [docDetails] }
            }
            travDetail.push(obj)
        }
    })

    let customer = !isEmpty(customerData)
        ? { ...customerData, email, mobile: phone }
        : {
              email,
              mobile: phone
          }

    if (isEmpty(customer.mobileCountryCode)) {
        customer = {
            ...customer,
            mobileCountryCode: mobileCountryCode
        }
    }
    let id = !isEmpty(customerData) ? customerData.peopleId : null

    let username = !isEmpty(email) ? email : userContactData.email

    let payload = {
        itineraryId: itineraryId,
        type: ['USER_DETAILS'],
        userDetailsRequest: {
            customer: customer,
            userDetails: {
                username,
                id,
                travellerDetails: travDetail
            }
        }
    }

    if (vatData?.trnNumber && vatData?.companyName) {
        let obj = {
            registrationNumber: vatData.trnNumber,
            holderName: vatData.companyName,
            holderAddress: vatData.address || ''
        }
        payload = {
            ...payload,
            addVatRequest: obj,
            type: [...payload.type, 'VAT']
        }
    }
    return payload
}

export const showVatBlock = () => {
    const domain = isServer() ? '' : window.location.hostname
    switch (domain) {
        case 'me.cleartrip.ae':
        case 'www.cleartrip.ae':
        case 'www.cleartrip.sa':
        case 'me.cleartrip.sa':
        case 'me.cleartrip.sa':
        case 'uat.cleartrip.sa':
        case '0.0.0.0':
            return true
        default:
            return false
    }
}
