import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../configureStore";
import { getEmiBanksList } from "src/api/emi";

const initialState = {
    emiBanksData: {},
    selectedFlightData: {}
};

  
export const fetchEmiBanksData = createAsyncThunk(
    "emiAffordability/affordability",
    async (payload) => {
        const response = await getEmiBanksList(payload);
        return response;
    }
);

export const emiAffordabilitySlice = createSlice({
    name: "emiAffordability",
    initialState,
    reducers: {
        setSelectedFlight: (state, action) => {
            state.selectedFlightData = action.payload
        },
        setEmiBanksData: (state, action) => {
            state.emiBanksData = action.payload
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchEmiBanksData.pending, (state, action) => {
            state.emiBanksData.loading = true
        })
        builder.addCase(fetchEmiBanksData.fulfilled, (state, action) => {
            if (action.payload?.data?.data && 
                action.payload?.data?.data.emi_providers) {
                state.emiBanksData = action.payload.data.data
            } else {
                state.emiBanksData = { error: true }
            }
        })
        builder.addCase(fetchEmiBanksData.rejected, (state, action) => {
            state.emiBanksData = { error: true }
        })
    }
})

const emiBanksData = (state: RootState) => state.emiAffordability.emiBanksData;

const selectedFlightData = (state: RootState) => state.emiAffordability.selectedFlightData;

const { reducer, actions } = emiAffordabilitySlice;

const { setSelectedFlight, setEmiBanksData } = actions

export { emiBanksData, selectedFlightData, setSelectedFlight, setEmiBanksData };

export default reducer;
  