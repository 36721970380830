import { FocusStyle } from './type';
import type { CSSObject } from 'styled-components';
import { CSSWithTheme } from '../../@types';

export const getTextFieldInputRootStyles = ({
	width,
	height,
	theme,
	focused,
	filled,
	color,
	select,
	disabled = false,
	...rest
}: CSSWithTheme & Pick<FocusStyle, 'focused' | 'filled' | 'select'> & {disabled?: boolean}): CSSObject => {
	const outlineColor = color || theme.color.border.default;

	return {
    position: "relative",
    display: "inline-flex",
    background: disabled
      ? theme.color.background.disabled
      : theme.color.background.neutral,
    // outline
    outlineStyle: "solid",
    outlineColor,
    outlineWidth: focused ? theme.border.width.lg : theme.border.width.sm,
    borderRadius: theme.border.radius[6],
    cursor: disabled ? "auto" : select ? "pointer" : "text",
    alignItems: "center",
    boxSizing: "border-box",
    width,
    height,
    padding: "16px",
    color: disabled ? "#808080" : "#1A1A1A",
    ...rest,
  }
};

export const getTextFieldInputBaseStyles = ({ theme, select, disabled=false }: CSSWithTheme & {select?: boolean, disabled?: boolean}): CSSObject => {
	return {
    marginTop : '12px',
		border: 0,
		background: 'none',
		outline: 0,
		'&::-webkit-input-placeholder': {
			opacity: 0,
		},
    '&[type="date"]::-webkit-calendar-picker-indicator': {
      background: 'none',
      color: 'transparent',
      height: 'auto',
      width: 'auto',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      position: 'absolute'
    },
    '&[value=""]::-webkit-datetime-edit': {
      color: 'transparent'
    },
    '&:focus::-webkit-datetime-edit': {
      color: '#000'
    },
    '&::-webkit-date-and-time-value': {
      textAlign: 'left'
    },
		boxSizing: 'border-box',
		fontSize: theme.typography.size[16],
		resize: 'none',
		cursor: disabled ? 'auto' : select? 'pointer' : 'text',
		width: '100%'
	};
};

export const getTextFieldLabelStyles = ({ theme, focused, filled }: CSSWithTheme & FocusStyle): CSSObject => {
  const transformFormula = !(focused || filled)
    ? "translate(0px, 10px) scale(1)"
    : "translate(0px, 0px) scale(0.75)"
  return {
    padding: "8px 8px 8px",
    display: "block",
    transformOrigin: "top left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,
    pointerEvents: "none",
    transform: transformFormula,
    maxWidth: "calc(100% - 24px)",
    transition: theme.transitions.create(["color", "transform", "max-width"], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeOut,
    }),
  }
};

export const getContainerStyles = (shrinked: boolean): CSSObject => {
	const padding = (shrinked) ? '24px 0px 8px 0px' : '16px 0px 16px 0px';
	return {
		// position: 'relative',
		// display: 'inline-flex',
		// width: '100%',
		// padding,
	};
};

