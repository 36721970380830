import { CheckboxTick, CheckboxMinus } from "../../assets/svg"
import useTheme from "../../hooks/useTheme"
const Checkbox = ({
  children,
  checked,
  selectAll,
  id,
  label,
  width = "18px",
  height = "18px",
  onChange,
  onClick,
  className = "",
  style,
  disabled = false,
  ...rest
}) => {
  const theme = useTheme()
  const checkBoxSize = {
    height,
    width,
  }
  return (
    <div
      style={{
        display: "flex",
        width: "fit-content",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        marginRight: theme.spacing[3],
      }}
    >
      {/* <input
        // ref={forwardedRef}
        type="checkbox"
        onChange={onChange}
        id={id}
        theme={theme}
        {...checkBoxSize}
        style={{
          position: "fixed",
          cursor: "pointer",
          opacity: 0,
          zIndex: 1,
          margin: 0,
          height: "18px",
          width: "18px",
          left: theme.spacing[4],
        }}
        //   {...rest}
      /> */}
      <div
        style={{
          borderRadius: "2px",
          background: checked
            ? theme.color.background.primary
            : theme.color.background.neutral,
          border: disabled ? '1px solid #E6E6E6' : !checked
            ? `1px solid ${theme.color.border.defaultDark}`
            : `1px solid transparent`,
          width,
          height,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "18px",
          height: "18px",
          ...style
        }}
        onClick={onChange}
        {...checkBoxSize}
        className={className}
      >
        {selectAll && checked ? <CheckboxMinus /> : <CheckboxTick />}
      </div>
      {label}
    </div>
  )
}

export default Checkbox
