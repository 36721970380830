import { checkIfAnciallyExists, checkIfAnyAncilliarySelected } from '@components/Addons/AddonsUtils'
import Analytics from '@root/ui/utils/Analytics'
import { getFormattedDate } from '@root/ui/utils/dateHelper'
import { isEmpty } from '@utils/genralUtils'
import dayjs from 'dayjs'
import { BAGGAGE, CT_UPGRADE, MEAL, SEAT, getInsuranceData } from '../addons'
import { CTCLUB, CTLOUNGE, CTVIP } from '@components/AxisLoyalty/constants'
import { convertToEpochTime } from '@utils/date/dateUtils'

export function dayDiff(days: any, to: any) {
    const dDate = dayjs(days)
    const toDate = dayjs(to)
    const diffDate = dDate.diff(toDate, 'day')
    return diffDate
}

const getCtUpgradeClevertapData = (itineraryData: any) => {
    const { ANCILLARY: fareBenefits } = itineraryData || {}
    try {
        // const { solutions } = fareBenefits?.fareBenefitsInfoResp || {}
        const { journeyAncillaries: solutions } = fareBenefits?.tripAncillariesResponse?.[0].ancillaryDetails || {}
        let upgradeBenefits = {
            OW_upgrade: 'no',
            OW_benifits: '',
            RT_upgrade: 'no',
            RT_benifits: '',
            CT_upgrade: 'no'
        }
        if (Array.isArray(solutions)) {
            solutions.forEach((journey, index) => {
                const { benefitInfos = [] } = journey
                if (Array.isArray(benefitInfos)) {
                    const ctUpgradeData = benefitInfos.find(benefit => {
                        const { benefitType = '' } = benefit || {}
                        return benefitType === CT_UPGRADE
                    })
                    if (!isEmpty(ctUpgradeData)) {
                        upgradeBenefits = { ...upgradeBenefits, CT_upgrade: 'yes' }
                    }
                    const { benefits = [] } = ctUpgradeData || {}
                    if (Array.isArray(benefits)) {
                        const ancillaryBenefits = benefits
                            .map(benefit => {
                                return benefit?.benefitName || 'NA'
                            })
                            .join(',')
                        if (ancillaryBenefits) {
                            if (index) {
                                upgradeBenefits = {
                                    ...upgradeBenefits,
                                    RT_upgrade: 'yes',
                                    RT_benifits: ancillaryBenefits
                                }
                            } else {
                                upgradeBenefits = {
                                    ...upgradeBenefits,
                                    OW_upgrade: 'yes',
                                    OW_benifits: ancillaryBenefits
                                }
                            }
                        }
                    }
                }
            })
        }
        return upgradeBenefits
    } catch (e) {
        return {
            OW_upgrade: 'N/A',
            OW_benifits: 'N/A',
            CT_upgrade: 'no'
        }
    }
}

export const getItinPageLoadOldPayload = (itineraryData: any) => {
    const {
        SEARCH_DETAILS = {},
        FLIGHT: { flights = {} } = {},
        FARE_CARDS: { fareCards = {} } = {},
        USER: { data: userData = {} } = {},
        INSURANCES = {},
        FARE_BENEFITS = {},
        REWARDS = [],
        ANCILLARY = {}
    } = itineraryData || {}
    const fareCardsAva: any[] = []
    if (!isEmpty(fareCards)) {
        fareCards.forEach((fareCard: { fareCardMessage: { title: any } }) => {
            fareCardsAva.push(fareCard?.fareCardMessage?.title)
        })
    }
    const fareCardValue = fareCardsAva.length > 1 ? fareCardsAva.join(',') : fareCardsAva[0]
    const supercoinsObj = REWARDS?.find((reward: { rewardType: string }) => reward.rewardType === 'SUPERCOINS')
    const { superCoinDetail } = supercoinsObj?.rewardsData || {}
    const { status, balanceDetails } = superCoinDetail || {}
    const { superCoinBalance, redeemableSuperCoinAmount } = balanceDetails || {}
    const owBenefits =
        FARE_BENEFITS?.fareBenefitsInfoResp?.solutions?.[0]?.benefitInfos?.filter?.(
            (val: { benefitType: string }) => val.benefitType === 'CT_UPGRADE'
        )?.[0]?.benefits ?? []
    const benfitsArr: any[] = []
    owBenefits.forEach((owBenefit: { benefitName: any }) => {
        benfitsArr.push(owBenefit.benefitName)
    })
    const owBenefitsValue = benfitsArr.length > 1 ? benfitsArr.join(',') : benfitsArr[0]

    const payload = {
        saved_travelers: userData?.travellers?.length ?? '0',
        source: 'mobile',
        Channel: 'CT',
        language: 'en',
        user_pref_currency: 'INR',
        is_Bento_User: 'yes',
        booking_type: 'NA',
        TRAVEL_INSURANCE:
            INSURANCES?.insurances?.filter?.(
                (val: { insuranceType: string }) => val.insuranceType === 'TRAVEL_INSURANCE'
            )?.length > 0
                ? 'yes'
                : 'no',
        BAGGAGE_INSURANCE:
            INSURANCES?.insurances?.filter?.(
                (val: { insuranceType: string }) => val.insuranceType === 'BAGGAGE_INSURANCE'
            )?.length > 0
                ? 'yes'
                : 'no',
        EzCancel:
            fareCards?.filter?.((val: { fareCardType: string }) => val.fareCardType === 'DOM_CANCELATION_INSURANCE')
                ?.length > 0
                ? 'yes'
                : 'no',
        Flexifly:
            fareCards?.filter?.((val: { fareCardType: string }) => val.fareCardType === 'AMEND_INSURANCE')?.length > 0
                ? 'yes'
                : 'no',
        INTL_TRAVEL_INSURANCE: 'no',
        BAGGAGE_INSURANCE_reason:
            INSURANCES?.insurances?.filter?.(
                (val: { insuranceType: string }) => val.insuranceType === 'BAGGAGE_INSURANCE'
            )?.length > 0
                ? ''
                : 'Insurance not shown due to vas experiment',
        TRAVEL_INSURANCE_provider:
            INSURANCES?.insurances?.filter?.(
                (val: { insuranceType: string }) => val.insuranceType === 'TRAVEL_INSURANCE'
            )?.[0]?.insuranceDetails?.[0]?.provider || 'NA',
        BAGGAGE_INSURANCE_provider:
            INSURANCES?.insurances?.filter?.(
                (val: { insuranceType: string }) => val.insuranceType === 'BAGGAGE_INSURANCE'
            )?.[0]?.insuranceDetails?.[0]?.provider || 'NA',
        EzCancel_provider: 'NA',
        Flexifly_provider: 'NA',
        ezcancel_plus_display:
            fareCards?.filter?.((val: { fareCardType: string }) => val.fareCardType === 'DOM_CANCELATION_INSURANCE')
                ?.length > 0
                ? 'TRUE'
                : 'FALSE',
        INTL_TRAVEL_INSURANCE_provider: 'NA',
        is_seat_available: checkIfAnciallyExists(ANCILLARY, SEAT) ? 'yes' : 'no',
        is_meal_available: checkIfAnciallyExists(ANCILLARY, MEAL) ? 'yes' : 'no',
        baggageAv: checkIfAnciallyExists(ANCILLARY, BAGGAGE) ? 'yes' : 'no',
        insuranceAv: INSURANCES?.insurances ? 'yes' : 'no',
        fare_cards_present: fareCardValue || 'NA',
        flight_length: (flights?.[0]?.totalDuration || 0) + (flights?.[1]?.totalDuration || 0),
        FF_option_selected:
            FARE_BENEFITS?.fareBenefitsInfoResp?.solutions?.[0]?.fareFamilyDetails?.[0]?.displayInformation
                ?.displayName || '',
        travel_class: flights?.[0]?.segments?.[0]?.cabinType || '',
        is_CFW_User: 'FALSE',
        ptb: 'FALSE',
        corporate_fare_available: SEARCH_DETAILS?.corpFarePresent ? 'yes' : 'no',
        supercoins_balance: new Number(superCoinBalance || 0).toLocaleString('en-IN'),
        max_supercoins_to_use: new Number(redeemableSuperCoinAmount || 0).toLocaleString('en-IN'),
        supercoins_account:
            status === 'SUCCESS' || status === 'LOW_BALANCE' || status === 'REDEEM_NOT_ELIGIBLE'
                ? 'exists'
                : 'doesnt exists'
    }
    return payload
}

export const getSuperCoinPayload = (itineraryData: any) => {
    const { REWARDS = [] } = itineraryData || {}

    const rewardData = REWARDS?.filter((reward: any) => {
        return reward.rewardType === 'SUPERCOINS'
    })?.[0]?.rewardsData
    const superCoinPayload = {
        earn_supercoin: rewardData?.superCoinDetail?.earnConfigDetails?.maxEarnCoins || 0,
        burn_supercoin: rewardData?.superCoinDetail?.balanceDetails?.redeemableSuperCoinAmount || 0,
        is_supercoin_applied: rewardData?.superCoinDetail?.userInteractionDetails?.selected ? 'yes' : 'no',
        supercoins_account: ['SUCCESS', 'LOW_BALANCE', 'REDEEM_NOT_ELIGIBLE'].includes(
            rewardData?.superCoinDetail?.status
        )
            ? 'yes'
            : 'no'
    }
    return superCoinPayload
}

export const getAncillaryPayload = (itineraryData: any) => {
    const { ANCILLARY = {}, INSURANCES = {} } = itineraryData || {}
    const insuranceData = getInsuranceData(INSURANCES)

    const ancillaryPayload = {
        seat_added: checkIfAnyAncilliarySelected(ANCILLARY, 'SEAT', insuranceData) ? 'yes' : 'no',
        meal_added: checkIfAnyAncilliarySelected(ANCILLARY, 'MEAL', insuranceData) ? 'yes' : 'no',
        baggage_added: checkIfAnyAncilliarySelected(ANCILLARY, 'BAGGAGE', insuranceData) ? 'yes' : 'no',
        insurance_added: checkIfAnyAncilliarySelected(ANCILLARY, 'INSURANCE', insuranceData) ? 'yes' : 'no'
    }
    return ancillaryPayload
}

export const getRebatePayload = (itineraryData: any) => {
    const { COUPON: { appliedCouponDetails = {} } = {} } = itineraryData || {}
    const couponCode = appliedCouponDetails?.couponData?.[0]?.couponCode || ''
    const rebatePayload = {
        flex_max_rebate:
            couponCode === CTCLUB ? 'no_benefit' : couponCode === CTLOUNGE || couponCode === CTVIP ? '1' : '',
        meal_rebate: couponCode === CTCLUB || couponCode === CTLOUNGE || couponCode === CTVIP ? '350' : '',
        seat_rebate:
            couponCode === CTCLUB ? '300' : couponCode === CTLOUNGE ? '400' : couponCode === CTVIP ? '700' : '',
        sub_payment_mode: couponCode === CTCLUB || couponCode === CTLOUNGE || couponCode === CTVIP ? 'axis' : ''
    }
    return rebatePayload
}

export const clevertapItinHelper = ({
    eventName = '',
    itineraryData,
    additionalPayloadArr = [],
    payLoad
}: {
    eventName: string
    itineraryData: any
    additionalPayloadArr?: any
    payLoad: any
}) => {
    const { SEARCH_DETAILS = {} } = itineraryData || {}
    const adultCount = SEARCH_DETAILS?.paxInfo?.adults || 0
    const childCount = SEARCH_DETAILS?.paxInfo?.children || 0
    const infantCount = SEARCH_DETAILS?.paxInfo?.infants || 0
    let itineraryId = ''
    if (typeof window !== 'undefined') {
        const locationArray = window?.location?.pathname?.split('/') || []
        itineraryId = locationArray?.[locationArray?.length - 2] || ''
    }
    let extraPayload = {}
    if (eventName === 'Air_PageLoad') {
        extraPayload = {
            dom_intl: SEARCH_DETAILS?.sectorType === 'DOM' ? 'domestic' : 'international',
            journey_type: SEARCH_DETAILS?.journeyType === 'ONE_WAY' ? 'one-way' : 'round-trip'
        }
    } else {
        extraPayload = {
            dom_intl_ui_action: SEARCH_DETAILS?.sectorType === 'DOM' ? 'domestic' : 'international',
            journey_type_uiaction: SEARCH_DETAILS?.journeyType === 'ONE_WAY' ? 'one-way' : 'round-trip'
        }
    }
    const commomPayLoad = {
        itineraryId: payLoad.itineraryId || itineraryId,
        ptc_fare: SEARCH_DETAILS?.fareType || 'N/A',
        is_ptc_fare_selected: SEARCH_DETAILS?.fareType === 'Regular fare' ? 'no' : 'yes',
        utm_source: SEARCH_DETAILS?.utmSource || 'organic',
        // utm_source: 'organic',
        aeroBridgeFlow: 'yes',
        pax_count: adultCount + childCount + infantCount,
        adult_count: adultCount,
        child_count: childCount,
        infant_count: infantCount
    }
    let finalPayload = { ...payLoad, ...extraPayload }
    additionalPayloadArr.map((addPayloadType: string) => {
        if (addPayloadType === 'SMB') {
            finalPayload = { ...finalPayload, ...getAncillaryPayload(itineraryData) }
        }
        if (addPayloadType === 'SC') {
            finalPayload = { ...finalPayload, ...getSuperCoinPayload(itineraryData) }
        }
        if (addPayloadType === 'REBATE') {
            finalPayload = { ...finalPayload, ...getRebatePayload(itineraryData) }
        }
        if (addPayloadType === CT_UPGRADE) {
            finalPayload = { ...finalPayload, ...getCtUpgradeClevertapData(itineraryData) }
        }
    })
    Analytics.event(eventName, { ...finalPayload, ...commomPayLoad })
}

export const clevertapItinLoadHelper = ({
    eventName = '',
    itineraryData,
    additionalPayloadArr = [],
    payLoad
}: {
    eventName: string
    itineraryData: any
    additionalPayloadArr?: any
    payLoad: any
}) => {
    const {
        SEARCH_DETAILS = {},
        FLIGHT: { flights = [] } = {},
        FARE_CARDS: { fareCards = {} } = {},
        GST: { data: gstData = {} } = {},
        COUPON: { appliedCouponDetails = {} } = {},
        VOUCHERS: { data: { voucherResponse: { voucherData = [] } = {} } = {} } = {}
    } = itineraryData || {}
    const airline: any[] = []
    const flightNumbers: any[] = []
    const airlineNames: any[] = []
    const couponCode = appliedCouponDetails?.couponData?.[0]?.couponCode || ''
    const isGvApplied = voucherData.length > 0
    if (!isEmpty(flights)) {
        const depDate = flights?.[0]?.segments?.[0]?.departDateTime || ''
        const returnDate = flights?.[1]?.segments?.[0]?.departDateTime || ''
        const selectedFareCard = fareCards?.filter?.((fareCard: any) => {
            return fareCard.selected === true
        })?.[0]

        flights.forEach((flight: { segments: any[] }) =>
            flight.segments.forEach((segment: { airline: any; flightNumber: any; airlineName: any }) => {
                airline.push(segment.airline)
                flightNumbers.push(segment.flightNumber)
                airlineNames.push(segment.airlineName)
            })
        )

        const airlineValue = airline.length > 1 ? airline.join(',') : airline[0]
        const flightNumbersValue = flightNumbers.length > 1 ? flightNumbers.join(',') : flightNumbers[0]
        const airlineNameValue = airlineNames.length > 1 ? airlineNames.join(',') : airlineNames[0]
        const commomPayLoad = {
            fare_type: selectedFareCard?.fareCardMessage?.title || 'N/A',
            search_id: SEARCH_DETAILS?.searchId || 'NA',
            origin: flights?.[0]?.departureAirportCode || 'NA',
            destination: flights?.[0]?.arrivalAirportCode || 'NA',
            airlineName: airlineNameValue,
            flightNumber: flightNumbersValue,
            airline: airlineValue,
            coupon_applied: couponCode ? 'yes' : 'no',
            gift_card_applied: isGvApplied ? 'yes' : 'no',
            is_gst_booking: gstData?.gstNumber ? 'yes' : 'no',
            dx: dayDiff(
                new Date(
                    getFormattedDate(flights?.[0]?.segments?.[0]?.departDateTime || '', 'MM/DD/YYYY', 'YYYY/MM/DD')
                ),
                new Date()
            ),
            departure_date: depDate ? convertToEpochTime(getFormattedDate(depDate, 'DD/MM/YYYY', 'YYYY/MM/DD')) : 'N/A',
            return_date: returnDate ? convertToEpochTime(getFormattedDate(returnDate, 'DD/MM/YYYY', 'YYYY/MM/DD')) : 'N/A',
            stay_duration:
                flights?.[1]?.segments?.[0]?.departDateTime || ''
                    ? dayDiff(
                          new Date(
                              getFormattedDate(
                                  flights?.[1]?.segments?.[0]?.departDateTime || '',
                                  'MM/DD/YYYY',
                                  'YYYY/MM/DD'
                              )
                          ),
                          new Date(
                              getFormattedDate(
                                  flights?.[0]?.segments?.[0]?.departDateTime || '',
                                  'MM/DD/YYYY',
                                  'YYYY/MM/DD'
                              )
                          )
                      )
                    : '',
            onward_layover_flight: flights?.[0]?.segments?.length > 1 ?? {} ? 'yes' : 'no',
            return_layover_flight: flights?.[1]?.segments?.length > 1 ?? {} ? 'yes' : 'no',
            onward_stops_count: flights?.[0]?.stopCount || '0',
            return_stops_count: flights?.[1]?.stopCount || '0',
            special_rt_flight_selected: SEARCH_DETAILS?.journeyType === 'SPECIAL_ROUND_TRIP' ? 'yes' : 'no'
        }
        let finalPayload = { ...payLoad }
        additionalPayloadArr.map((addPayloadType: string) => {
            if (addPayloadType === 'SMB') {
                finalPayload = { ...finalPayload, ...getAncillaryPayload(itineraryData) }
            }
            if (addPayloadType === 'SC') {
                finalPayload = { ...finalPayload, ...getSuperCoinPayload(itineraryData) }
            }
            if (addPayloadType === 'REBATE') {
                finalPayload = { ...finalPayload, ...getRebatePayload(itineraryData) }
            }
            if (addPayloadType === 'OLDPAGELOAD') {
                finalPayload = { ...finalPayload, ...getItinPageLoadOldPayload(itineraryData) }
            }
            if (addPayloadType === CT_UPGRADE) {
                finalPayload = { ...finalPayload, ...getCtUpgradeClevertapData(itineraryData) }
            }
        })
        clevertapItinHelper({
            eventName: eventName,
            itineraryData: itineraryData,
            payLoad: {
                ...finalPayload,
                ...commomPayLoad
            }
        })
    }
}
