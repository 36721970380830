import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../configureStore'
import { getSearchResult, getServicesData } from '@api/search'
import { SEARCH_FLIGHT_SERVER_ERROR } from '@constants/flights/cfw'
import { getServicesDataCacheKey } from '@utils/CFW'
import { deepclone } from '@utils/flights/results'
import { isEmpty } from '@utils/genralUtils'

const initialState = {
    searchData: {},
    isLoading: true,
    srpError: '',
    isServicesLoading: true,
    servicesData: {
        benefits: {},
        baggageAllowances: {},
        cardIdToBenefitsMap: {}
    },
    couponData: {}
}

export const fetchSrpData = createAsyncThunk('srpStore/search', async (query: string) => {
    try {
        const response = await getSearchResult(query)
        return response
    } catch (e) {
        return e
    }
})

export const fetchServicesData = createAsyncThunk('srpStore/services', async (payload: object) => {
    try {
        const response = await getServicesData(payload)
        return response
    } catch (e) {
        return e
    }
})

const mergedCardIdToBenefitsMap = (srpCardIdToBenefitsMap: any, stateCardIdToBenefitsMap: any) => {
    Object.keys(srpCardIdToBenefitsMap).forEach(key => {
        if (stateCardIdToBenefitsMap?.[key]) {
            const { subTravelOptions = [] } = srpCardIdToBenefitsMap[key] || {}
            subTravelOptions.forEach(subTravelOption => {
                const { subTravelOptionId = '', benefits = {} } = subTravelOption || {}
                const existingSubTravelOption = stateCardIdToBenefitsMap[key]?.subTravelOptions?.find(
                    subTravelOption => subTravelOption.subTravelOptionId === subTravelOptionId
                )
                if (!isEmpty(existingSubTravelOption)) {
                    Object.keys(benefits).forEach(key => {
                        const {
                            penalties: penalty = [],
                            benefits: benefitIds = [],
                            baggageAllowanceId = ''
                        } = benefits[key] || {}
                        if (isEmpty(existingSubTravelOption.benefits?.[key])) {
                            existingSubTravelOption.benefits = {
                                ...existingSubTravelOption.benefits,
                                [key]: { penalty, benefitIds, baggageAllowanceId }
                            }
                        } else {
                            // TODO, remove duplicates
                            existingSubTravelOption.benefits[key] = {
                                penalty: [...(existingSubTravelOption.benefits[key]?.penalty || []), ...penalty],
                                benefitIds: [
                                    ...(existingSubTravelOption.benefits[key]?.benefitIds || []),
                                    ...benefitIds
                                ],
                                baggageAllowanceId
                            }
                        }
                    })
                } else {
                    stateCardIdToBenefitsMap[key].subTravelOptions.push(subTravelOption)
                }
            })
        } else {
            stateCardIdToBenefitsMap[key] = srpCardIdToBenefitsMap[key]
        }
    })
    return stateCardIdToBenefitsMap
}

export const srpManager = createSlice({
    name: 'srpSeachData',
    initialState,
    reducers: {
        setError(state, action) {
            const error = action.payload
            state.isLoading = false
            state.srpError = error
        },
        setCardIdToBenefitsMap(state, action) {
            const srpCardIdToBenefitsMap = action.payload
            const stateCardIdToBenefitsMap = deepclone(state.servicesData.cardIdToBenefitsMap)
            const mergedCardIds = mergedCardIdToBenefitsMap(srpCardIdToBenefitsMap, stateCardIdToBenefitsMap)
            state.servicesData.cardIdToBenefitsMap = mergedCardIds
        },
        setCouponsData(state, action) {
            state.couponData = {
                ...state.couponData,
                ...action.payload
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchSrpData.pending, (state, action) => {
            state.isLoading = true
            state.srpError = ''
        })
        builder.addCase(fetchSrpData.fulfilled, (state, action) => {
            const { status, data } = action?.payload || {}
            if (status === 200) {
                const { benefits, baggageAllowances } = data || {}
                state.searchData = data || {}
                state.servicesData = {
                    ...state.servicesData,
                    benefits: {
                        ...state.servicesData.benefits,
                        ...benefits
                    },
                    baggageAllowances: {
                        ...state.servicesData.baggageAllowances,
                        ...baggageAllowances
                    }
                }
            } else {
                state.searchData = {}
                state.srpError = SEARCH_FLIGHT_SERVER_ERROR
            }
            state.isLoading = false
        })
        builder.addCase(fetchSrpData.rejected, (state, action) => {
            state.searchData = {}
            state.srpError = SEARCH_FLIGHT_SERVER_ERROR
            state.isLoading = false
        })

        builder.addCase(fetchServicesData.pending, (state, action) => {
            state.isServicesLoading = true
        })
        builder.addCase(fetchServicesData.fulfilled, (state, action) => {
            const { status, data } = action?.payload || {}
            if (status === 200) {
                const { cardIdToBenefitsMap, benefits, baggageAllowances } = getServicesDataCacheKey(data) || {}
                state.servicesData = {
                    ...state.servicesData,
                    benefits: {
                        ...state.servicesData.benefits,
                        ...benefits
                    },
                    baggageAllowances: {
                        ...state.servicesData.baggageAllowances,
                        ...baggageAllowances
                    },
                    cardIdToBenefitsMap: mergedCardIdToBenefitsMap(
                        cardIdToBenefitsMap,
                        deepclone(state.servicesData.cardIdToBenefitsMap)
                    )
                }
            }
            state.isServicesLoading = false
        })
        builder.addCase(fetchServicesData.rejected, (state, action) => {
            state.isServicesLoading = false
        })
    }
})

const { reducer, actions } = srpManager
const { setError, setCardIdToBenefitsMap, setCouponsData } = actions

export const getSrpSearchData = (state: RootState) => state.srpSeachData
export { setError, setCardIdToBenefitsMap, setCouponsData }

export default reducer
