import React from 'react';

const RemoveHiddenIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
            <mask
                id='mask0_2627_20546'
                style={{ maskType: 'alpha' }}
                width='16'
                height='16'
                x='0'
                y='0'
                maskUnits='userSpaceOnUse'
            >
                <path fill='#D9D9D9' d='M0 0H16V16H0z'></path>
            </mask>
            <g mask='url(#mask0_2627_20546)'>
                <path
                    fill='#fff'
                    d='M10.733 8.867L9.767 7.9c.1-.522-.05-1.01-.45-1.466-.4-.456-.917-.634-1.55-.534L6.8 4.934c.189-.09.38-.156.575-.2.194-.045.403-.067.625-.067.833 0 1.542.292 2.125.875S11 6.834 11 7.667c0 .222-.022.43-.067.625a2.827 2.827 0 01-.2.575zm2.134 2.1l-.967-.933a7.304 7.304 0 001.125-1.059c.328-.383.608-.819.842-1.308a6.555 6.555 0 00-2.392-2.675C10.436 4.33 9.278 4 8 4c-.322 0-.639.023-.95.067a6.416 6.416 0 00-.917.2L5.1 3.234A7.405 7.405 0 018 2.667c1.589 0 3.017.42 4.283 1.258a7.913 7.913 0 012.85 3.275.54.54 0 01.067.208c.011.084.017.17.017.26a1.33 1.33 0 01-.084.466 7.27 7.27 0 01-2.266 2.833zm-.134 3.633L10.4 12.3a8.03 8.03 0 01-1.175.276c-.394.06-.803.091-1.225.091-1.589 0-3.017-.42-4.283-1.258a7.914 7.914 0 01-2.85-3.275.542.542 0 01-.067-.209 1.946 1.946 0 010-.508.531.531 0 01.067-.2c.233-.5.51-.961.833-1.383a8.86 8.86 0 011.067-1.167l-1.384-1.4a.622.622 0 01-.183-.459c0-.183.067-.34.2-.474a.632.632 0 01.467-.184c.189 0 .344.062.466.184l11.334 11.333a.653.653 0 01.192.459.621.621 0 01-.192.474.632.632 0 01-.467.184.632.632 0 01-.467-.184zM3.7 5.6a7.16 7.16 0 00-.883.95 6.01 6.01 0 00-.684 1.117 6.552 6.552 0 002.392 2.675c1.039.661 2.197.992 3.475.992.222 0 .439-.014.65-.042.211-.028.428-.058.65-.092l-.6-.633a3.003 3.003 0 01-.35.075 2.327 2.327 0 01-.35.025 2.895 2.895 0 01-2.125-.875A2.895 2.895 0 015 7.667a2.39 2.39 0 01.1-.7L3.7 5.6z'
                ></path>
            </g>
        </svg>
    );
};

export default RemoveHiddenIcon;
