import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../configureStore'
import { getSearchPageResults } from 'src/api/resultsPage'
import { isEmpty } from '@utils/genralUtils'

export interface ResultDataState {
    resultResponse?: Record<string, any>
    loading: boolean
}
const initialState: ResultDataState = {
    resultResponse: {},
    loading: false
}

function parseQuery(query) {
    return Object.fromEntries(query.split('&').map(pair => pair.split('=').map(decodeURIComponent)))
}

const compareQuery = (currentQuery, searchQuery) => {
    if (!currentQuery || !searchQuery) {
        return false
    }

    const currentQueryObj = parseQuery(currentQuery) || {}
    const searchQueryObj = parseQuery(searchQuery) || {}

    return (
        currentQueryObj.from == searchQueryObj.from &&
        currentQueryObj.to == searchQueryObj.to &&
        currentQueryObj.depart_date == searchQueryObj.depart_date &&
        currentQueryObj.return_date == searchQueryObj.return_date &&
        currentQueryObj.class == searchQueryObj.class &&
        currentQueryObj.adults == searchQueryObj.adults &&
        currentQueryObj.childs == searchQueryObj.childs &&
        currentQueryObj.infants == searchQueryObj.infants &&
        currentQueryObj.intl == searchQueryObj.intl &&
        currentQueryObj.utm_currency == searchQueryObj.utm_currency
    )
}

export const fetchResultData = createAsyncThunk('result/fetchResultData', async ({ URL, flushData }, { getState }) => {
    try {
        const state: any = getState()
        if (!isEmpty(state.result.resultResponse) && !flushData) {
            const urlQuery = state.result.resultResponse?.data?.urlQuery || ''
            if (compareQuery(urlQuery, URL)) {
                return new Promise(res => {
                    setTimeout(
                        () =>
                            res({
                                ...state.result.resultResponse,
                                data: {
                                    ...state.result.resultResponse.data,
                                    urlQuery: ''
                                }
                            }),
                        70
                    )
                })
            }
        }
        const response = await getSearchPageResults(URL)
        return response
    } catch (e) {
        return {}
    }
})

export const resultDataSlice = createSlice({
    name: 'result',
    initialState,
    reducers: {
        setResultData: (state, action) => {
            state.resultResponse.data = {
                ...state.resultResponse,
                ...action.payload
            }
        },
        setResulResponse: (state, action) => {
            state.resultResponse = action.payload
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchResultData.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchResultData.fulfilled, (state, action) => {
            state.resultResponse = action.payload || {}
            state.loading = false
        })
        builder.addCase(fetchResultData.rejected, (state, action) => {
            state.resultResponse = action.payload
            state.loading = false
        })
    }
})

const getResultResponse = (state: RootState) => state.result.resultResponse
const getResultData = (state: RootState) => state.result.resultResponse?.data
const getResultsLoading = (state: RootState) => state.result.loading

const { reducer, actions } = resultDataSlice
const { setResultData, setResulResponse } = actions || {}

export { setResultData, getResultResponse, getResultData, setResulResponse, getResultsLoading }

export default reducer
