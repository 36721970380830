import { ReactNode } from 'react';

export enum ButtonSize {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
}

export enum IconPosition {
	LEFT = 'left',
	RIGHT = 'right',
}

export enum ButtonVariant {
	OUTLINE = 'outline',
	CONTAINED = 'contained',
}

export enum ButtonColor {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	TERTIARY = 'tertiary',
}

export type ButtonSizeType = `${ButtonSize}`;

export type IconPositionType = `${IconPosition}`;

export type ButtonVariantType = `${ButtonVariant}`;

export type ButtonColorType = `${ButtonColor}`;

export interface ButtonProps {
    children?: ReactNode
    size?: ButtonSizeType
    variant?: ButtonVariantType
    disabled?: boolean
    className?: string
    color?: ButtonColorType
    isFullWidth?: boolean
    loading?: boolean
    minWidth?: string
    maxWidth?: string
    onClick?: (arg: any) => void
    showLeftIcon?: boolean
    LeftIcon?: ReactNode
    showRightIcon?: boolean
    RightIcon?: ReactNode
    onLeftIconClick?: (arg: any) => void
    onRightIconClick?: (arg: any) => void
    isLoading?: boolean
    dataTestId?: string
}
