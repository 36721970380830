import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { PortalProps } from './type';
import useIsomorphicEffect from '../../hooks/useIsomorphicEffect';

const Portal = React.forwardRef<any, PortalProps>(({ children, container, disablePortal }, ref) => {
	const [mountNode, setMountNode] = React.useState<Element | null>(null);
	/**
	 * mount node
	 */
	useIsomorphicEffect(() => {
		if (!disablePortal) {
			setMountNode(container || document.body);
		}
	}, [container, disablePortal]);

    useIsomorphicEffect(() => {
		if (mountNode && !disablePortal) {
			if (typeof ref === 'function') {
                ref(mountNode);
              } else if (ref) {
                ref.current = mountNode;
              }
              return ()=>{
                if (typeof ref === 'function') {
                    ref(mountNode);
                  } else if (ref) {
                    ref.current = null;
                  }
              }
		}
	}, [ref, mountNode, disablePortal]);

	/**
	 * disable portal
	 */
	if (disablePortal) {
		return <React.Fragment>{children}</React.Fragment>;
	}
    return ReactDOM.createPortal(children, document.body)
    // TODO Fix mount node issue
	// return mountNode ? ReactDOM.createPortal(children, document.body) : mountNode;
});

export default Portal;