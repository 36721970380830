export type Spacing = Readonly<{
	0: '0px';
	1: '4px';
	2: '8px';
	3: '12px';
	4: '16px';
	5: '20px';
	6: '24px';
	7: '28px';
	8: '32px';
	9: '36px';
	10: '40px';
}>;

const spacing: Spacing = {
	0: '0px',
	1: '4px',
	2: '8px',
	3: '12px',
	4: '16px',
	5: '20px',
	6: '24px',
	7: '28px',
	8: '32px',
	9: '36px',
	10: '40px',
};

export default spacing;
