import { getOSType, isServer } from '@utils/appAgent'
import { isEmpty } from '@utils/genralUtils'

type FK_APP_VERSION_CONFIG_TYPE = {
    [key: string]: string
}
export const FK_ANDROID_APP_VERSION = '1900400'
export const FK_IOS_APP_APP_VERSION = '9.99.5'
export const FK_APP_UNSUPPORTED_VERSION = '9.99.6'

export const FK_APP_VERSION_CONFIG: FK_APP_VERSION_CONFIG_TYPE = {
    android: FK_ANDROID_APP_VERSION,
    ios: FK_IOS_APP_APP_VERSION
}
export const FK_APP_UNSUPPORTED_VERSION_CONFIG: FK_APP_VERSION_CONFIG_TYPE = {
    android: '',
    ios: ''
}

export const getUltraSDKModule = async () => {
    let FKPlatform = {}
    if (!isServer()) {
        window.FKPlatform = FKPlatform
        // if (window.FKPlatform) {
        //     FKPlatform = window.FKPlatform
        // } else {
        //     FKPlatform = (await import('fk-platform-sdk/web')).default
        //     window.FKPlatform = FKPlatform
        // }
    }
    return FKPlatform
}

export const setAppAgent = (userAgent: string) => {
    window.androidData = !isEmpty(window.androidData) ? window.androidData : {}
    window.iosData = !isEmpty(window.iosData) ? window.iosData : {}
    const deviceType = getOSType(userAgent)
    if (deviceType === 'android') {
        window.androidData = {
            ...window.androidData,
            'app-agent': 'AndroidApp'
        }
    }
    if (deviceType === 'ios') {
        window.iosData = {
            ...window.iosData,
            'app-agent': 'iPhoneApp'
        }
    }
    return deviceType
}

export const isFkApp = () => {
    try {
        if (!isServer()) {
            let FKPlatform = window.FKPlatform
            return FKPlatform?.isPlatformAvailable()
        }
    } catch (ex) {
        console.log(ex)
        return false
    }
    return false
}



function getCompleteDomain() {
    try {
        const protocol = window.location.protocol
        const hostname = window.location.hostname
        if (protocol) {
            return protocol + '//' + hostname
        }
        return hostname
    } catch (e) {
        return ''
    }
}

export const appendDomain = (url: string) => {
    if (isServer() || url.startsWith('http://') || url.startsWith('https://')) {
        return url
    }
    let domain = !isServer() ? getCompleteDomain() || '' : ''
    if (url.startsWith('/')) {
        return `${domain}${url}`
    }
    return `${domain}/${url}`
}

export const getCurrentAppVersion = (userAgent: string) => {
    try {
        const matches = userAgent.match(/\[([^\]]+)\]/) || []
        return matches[1].split('/')[2]
    } catch (ex) {
        return ''
    }
}

export const checkIfNewAppVersion = () => {
    try {
        if (isServer()) return false
        const deviceType = getOSType(navigator.userAgent)
        const appVersion = getCurrentAppVersion(navigator.userAgent)
        if (!appVersion || !deviceType) {
            return false
        }
        return (
            parseInt(appVersion) >= parseInt(FK_APP_VERSION_CONFIG[deviceType]) &&
            parseInt(appVersion) !== parseInt(FK_APP_UNSUPPORTED_VERSION_CONFIG[deviceType])
        )
    } catch (e) {
        return false
    }
}

export const isMsiteInFkAppWebView = async () => {
    try {
        const FKPlatform = await getUltraSDKModule()
        return !isServer() && FKPlatform && FKPlatform?.isPlatformAvailable()
    } catch (ex) {
        return false
    }
}
