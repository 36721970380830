export const GET_CALL = 'get'
export const POST_CALL = 'post'
export const DELETE_CALL = 'delete'
export const PUT_CALL = 'put'
export const LOCAL_BASE_URL = 'http://0.0.0.0:3012'
export const SELF_CARE_BASE_URL = '/communication/selfcare/api'
export const FETCH_TRIP_FAQS = '/account/trips/faq/'

// other endpoints
export const FETCH_EXPRESSWAY_DETAILS_URL = '/partial/account/profile'
export const UPDATE_PRIMARY_TRAVELLER = '/partial/account/primary-traveller/add'
export const UPDATE_PREFILL_STATUS = '/partial/account/other-details'
export const FETCH_WALET_DETAILS_URL = '/partial/account/wallet'
export const LOAD_MORE_URL = '/partial/account/trips/completed_cancelled'
export const SEND_EMAIL_URL = '/email'
export const SEND_EMAIL_PREFIX = '/account/trips/'

// trips endpoints
export const SIGN_IN_CHECK_URL = `${SELF_CARE_BASE_URL}/connectToAgent`
export const POLL_STATUS = `${SELF_CARE_BASE_URL}/poll-status`
export const FETCH_TRIPS_URL = `${SELF_CARE_BASE_URL}/tripList`
export const FETCH_TRIPS_DETAILS_V2 = `${SELF_CARE_BASE_URL}/v2/tripDetails/`
export const FETCH_TRIPS_DETAILS_V3 = `${SELF_CARE_BASE_URL}/v3/tripDetails/`
export const FETCH_TRIPS_DETAILS = `${SELF_CARE_BASE_URL}/tripDetails/`
export const NEW_FETCH_TRIP_FAQS = `${SELF_CARE_BASE_URL}/tripFAQs/`
export const NEW_FETCH_TRIP_STATUS = `${SELF_CARE_BASE_URL}/trip-track-status/`
export const TRIP_SUPPORT_NUMBER = `${SELF_CARE_BASE_URL}/supportNumbers`
export const FETCH_TRIP_CASE_DETAIL = `${SELF_CARE_BASE_URL}/casesList/`
export const FETCH_TRIP_PROMO_DETAIL = `${SELF_CARE_BASE_URL}/promoInfo/`

export const CASE_HISTORY_MODAL_DETAIL = `${SELF_CARE_BASE_URL}/caseHistoryAction/`
export const REFUND_CASE_CREATION = `${SELF_CARE_BASE_URL}/createCaseCallbacks/`

// cancellation endpoints
export const TRIP_CANCEL_API = `${SELF_CARE_BASE_URL}/cancel/v2/`
export const CANCEL_INFO_API = `${SELF_CARE_BASE_URL}/cancelInfo/v2/`
export const CANCEL_POLICY = `${SELF_CARE_BASE_URL}/fareRules`
export const REFUND_STATUS = `${SELF_CARE_BASE_URL}/v2/refundStatus/`
export const VALIDATE_UPI_API = `${SELF_CARE_BASE_URL}/vpa-ba/verify/`
export const CANCEL_REASONS_API = `${SELF_CARE_BASE_URL}/getCancelReasons/v2/`

// train e-catering endpoints
export const ECATERING_ORDER_DETAILS = '/ecatering/order/list'
export const ECATERING_CANCEL_ORDER = '/ecatering/order'
// Support OTP login
export const OTP_LOGIN_TRIPID = '/user/v2/auth/otp'
export const OTP_VERIFY_TRIPID = '/user/v2/auth/otp/verify'

// User Login API
export const USER_SIGN_IN = '/v2/signin'
export const USER_RESET_PASSWORD = '/v2/user/resetPassword'
export const FACEBOOK_LOGIN_API = '/account/singly/singly_api?source=web&service=https://cleartrip.com'
export const USER_SIGN_UP = '/user/v2/register'
export const GET_USER_BY_EMAIL = '/people/v2/fetch'
export const CAPTCH_VERIFY = '/user/verify/captcha'
export const AB_TEST_CONFIG_PWA = '/source/abtest/getPWAconfig'
export const AB_TEST_CONFIG_DESKTOP = '/source/abtest/getabtestconfig'

// Saved Card endpoints
export const GET_SAVED_PAYMENT_MODES = '/payment/saved/payments/mode/get/'
export const DELETE_SAVED_PAYMENT_MODES = '/payment/saved/payments/mode/delete/'

// Download e-ticket API
export const DOWNLOAD_ETICKET = `${SELF_CARE_BASE_URL}/downloadETicket`

// Wallet endpoints
export const GET_WALLET_INFO = '/pay/wallet'
export const GET_WALLET_TRANSACTIONS = '/pay/wallet/'
export const WALLET_TNC = '/html/b2c/wallet_policy.shtml'

// Download GST invoice API
export const DOWNLOAD_GST_INVOICE = `${SELF_CARE_BASE_URL}/downloadInvoice`
