import React from 'react';

export type ChipArrowDownProps = {
	color?: string;
};

const ChipArrowDown: React.FC<ChipArrowDownProps> = ({ color = '#1A1A1A' }) => {
	return (
		<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M7.9999 10.6498C7.88324 10.6498 7.7709 10.6332 7.6629 10.5998C7.55424 10.5665 7.45824 10.4998 7.3749 10.3998L2.8499 5.89984C2.71657 5.74984 2.6499 5.57484 2.6499 5.37484C2.6499 5.17484 2.7249 4.99984 2.8749 4.84984C3.0249 4.71651 3.1999 4.64984 3.3999 4.64984C3.5999 4.64984 3.7749 4.71651 3.9249 4.84984L7.9999 8.92484L12.0999 4.84984C12.2332 4.69984 12.3999 4.62884 12.5999 4.63684C12.7999 4.6455 12.9749 4.71651 13.1249 4.84984C13.2749 4.99984 13.3499 5.17884 13.3499 5.38684C13.3499 5.5955 13.2749 5.7665 13.1249 5.89984L8.6249 10.3998C8.54157 10.4998 8.4459 10.5665 8.3379 10.5998C8.22924 10.6332 8.11657 10.6498 7.9999 10.6498Z'
				fill={color}
			/>
		</svg>
	);
};

export default ChipArrowDown;
