import { isFkEnv } from "@root/configs/Whitelabel"

const path = (p, o) => {
  const reducerFunction = (xs, x) => {
    return xs && xs[x] ? xs[x] : null
  }
  return p.reduce(reducerFunction, o)
}
export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]
export const FULL_NAMES_OF_DAYS = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
}


export const FLIGHT_CLASS = [
  { code: "Economy", value: "Economy" },
  { code: "Business", value: "Business" },
  ...(isFkEnv ? [] : [{ code: "First", value: "First" }]),
  { code: "Premium Economy", value: "Premium Economy" },
]

export const FLIGHT_FARE_REFUNDABLE = "Refundable"

export const FLIGHT_FARE_NON_REFUNDABLE = "Non Refundable"

export const FLIGHT_DEPART_FILTER_HEADING = [
  "Onward Departure Time",
  "Return Departure Time",
]

export const FLIGHT_DEPART_FILTER_LABEL = {
  "0_8": { text: "Early morning", time: "12am - 8am" },
  "8_12": { text: "Morning", time: "8am - 12pm" },
  "12_16": { text: "Mid-day", time: "12pm - 4pm" },
  "16_20": { text: "Evening", time: "4pm - 8pm" },
  "20_24": { text: "Night", time: "8pm - 12pm" },
}

export const FLIGHT_ITINERARY_SC_DATE_FORMAT = "YYYY-MM-DD\\THH:mm:ss" // Itinerary incoming Search criteria date format

export const GTM_METASOURCE_EXPIRY_DAYS = 30

export const PAYMENT_CARDINFO_MIN_CHARS = 6

let hostname
let lang = "en"
try {
  hostname = path(["location", "hostname"], window)
} catch (e) {
  hostname = "www.cleartrip.com"
}
export const DOMAIN = process.env.NODE_ENV === 'production' ? '' : `http://${hostname}:3000`


export const HOST = hostname
export const language = lang
export const DEFAULT_GIFT_VOUCHER_COUNT = 3
export const APP_DOWNLOAD_LINK = "https://cleartrip.app.link/zg8puZ7l8xb"

export const UPCOMING = 'upcoming'

export const CANCELLED = 'cancelled'

export const COMPLETED = 'completed'

export const BOOKING_PENDING = 'booking pending'

export const BOOKING_FAILED = 'booking '

export const ONE_WAY = 'one-way'

export const ROUND_TRIP = 'round-trip'