import styled from 'styled-components';
import { getStyledButtonStyles } from './style';
import { StyledButtonProps } from './type';

const StyledButton = styled.button<StyledButtonProps>(
    ({
        backgroundColor,
        borderStyle,
        height,
        minWidth,
        maxWidth,
        paddingTop,
        paddingBottom,
        borderColor,
        borderWidth,
        cursor,
        theme
    }) =>
        getStyledButtonStyles({
            backgroundColor,
            borderStyle,
            height,
            minWidth,
            maxWidth,
            paddingTop,
            paddingBottom,
            borderColor,
            borderWidth,
            cursor,
            theme
        })
)

export default StyledButton;
