import { isFkEnv } from '@root/configs/Whitelabel'
import { getCurrentAppVersion } from './Ultra'

export const triggerNewRelicEvent = (actionName: string, payload = {}) => {
    try {
        if (typeof newrelic == 'object') {
            newrelic.addPageAction(actionName, {
                ...payload,
                fk_app_version: isFkEnv ? getCurrentAppVersion(navigator.userAgent) : 'N/A'
            })
        }
    } catch (e) {
        console.log(e)
    }
}
