import { getUltraSDKModule } from '@utils/Ultra'
import { getFKPlatformInstance } from '@utils/Ultra/init'
import { isServer } from '@utils/appAgent'
import { apiGetHelper } from '@utils/helper'
import { triggerNewRelicEvent } from '@utils/newRelic'
// import FKPlatform from 'fk-platform-sdk/web'

export const getGrantToken = async () => {
    try {
        let FKPlatform = await getUltraSDKModule()
        if (FKPlatform?.isPlatformAvailable()) {
            // const fkPlatformObject = new FKPlatform('cleartrip')
            const fkPlatformObject = await getFKPlatformInstance()
            if (fkPlatformObject?.clientID) {
                const response = await fkPlatformObject.getModuleHelper().getPermissionsModule().getUltraPlusToken()
                return response
            }
            return {}
        }
        return {}
    } catch (ex) {
        return ex
    }
}

export const getGrantTokenApiHelper = async (maxRetries: number) => {
    let retries = 0
    let response
    while (retries < maxRetries) {
        try {
            triggerNewRelicEvent('grant_token_api_called', {})
            response = await getGrantToken()
            triggerNewRelicEvent('grant_token_api_resolved', {})
            if (response.grantToken) {
                triggerNewRelicEvent('grant_token_api_token_found', {})
                break
            } else {
                triggerNewRelicEvent('grant_token_api_token_not_found', {})
                retries++
            }
        } catch (error) {
            triggerNewRelicEvent('grant_token_api_error', { message: error?.message })
            console.error(`Error fetching token: ${error?.message}`)
            retries++
        }
    }
    if (!response || !response.grantToken) {
        return {}
    }
    return response
}

export const getPaymentStatus = (tripId: string) => {
    try {
        const url = `/ultra/trip/${tripId}/book`
        const response = apiGetHelper(url, 'application/json', {})
        return response
    } catch (ex) {
        return ex
    }
}
