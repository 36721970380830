import { useFormikContext } from 'formik';
import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import TextField from '../../TextField';
import Radio from '../../Radio';
import Checkbox from '../../Checkbox';
import { CheckboxProps } from '../../Checkbox/type';
import Select, { SelectProps } from '../../Select';
import { TextFieldProps } from '../../TextField/type';

import ToggleSwitch from '../../SwitchButton/ToggleSwitch';

/**
 *
 * @returns
 */
export default function FormElement({ children, name }) {
  const formik = useFormikContext()

  const ref = useRef<HTMLElement>()
  /**
   * add synthetic handle change
   */
  const handleChange = useCallback(
    (e: ChangeEvent, setField?: { name: string; value: any }) => {
      if (e) {
        formik.handleChange(e)
      } else if (setField) {
        console.log(setField)
        formik.setFieldValue(
          setField.name,
          setField.value,
          formik.validateOnChange
        )
      }
    },
    [formik]
  )

  /**
   * add synthetic handle blur
   */
  const handleBlur = useCallback(
    (e: FocusEvent, setField?: { name: string; value: any }) => {
      if (e) {
        formik.handleBlur(e)
      } else if (setField) {
        if (formik.validateOnBlur) {
          formik.validateField(setField.name)
        }
      }
    },
    [formik]
  )

  useEffect(() => {
    if (
      formik.errors &&
      formik.errors[name] &&
      ref.current &&
      ref.current.focus
    ) {
      ref.current.focus()
    }
  }, [formik.errors, name])

  return children({ ...formik, handleBlur, handleChange, ref })
}
export const FormRadio = (props) => {
	const { options, labels } = props;
	return options.map(((o, index) => (
		<FormElement name={props.name}>
			{({ values, handleChange, handleBlur, errors }) => {
				return (
					<Radio
						key={o}
						id='test'
						value={values[props.name]}
						error={errors[props.name]}
						onChange={() => handleChange(null, { name: props.name, value: o })}
						onBlur={handleBlur}
						checked={o === values[props.name]}
						label={labels[index]}
						{...props}
					/>
				);
			}}
		</FormElement>
	)));
};

export const FormCheckbox = (props: CheckboxProps & { name: string }) => {
	return (
		<FormElement name={props.name}>
			{({ values, handleChange }) => {
				return (
					<Checkbox
						checked={values[props.name]}
						onChange={(e) => handleChange(null, { name: props.name, value: e.target.checked })}
						{...props}
					/>
				);
			}}
		</FormElement>
	);
};
export const FormSwitch = (props) => {
	return (
		<FormElement name={props.name}>
			{({ values, handleChange, handleBlur, errors, ref }) => {
				return (
					<ToggleSwitch 
					checked={values[props.name]}
					error={errors[props.name]}
					onChange={handleChange}
					ref={ref}
					onBlur={handleBlur}
					{...props}
					/>
				);
			}}
		</FormElement>
	)
}

export const FormInput = (props: TextFieldProps & { name: string }) => {
	
	
	return (
    <FormElement name={props.name}>
      {({ values, handleChange, handleBlur, errors, ref, disabled }) => {
        return (
          <TextField
            value={values[props.name]}
            error={errors[props.name]}
            onChange={handleChange}
            onBlur={handleBlur}
            ref={ref}
            disabled={disabled}
            {...props}
          />
        )
      }}
    </FormElement>
  )
};

// dropdown
export const FormSelect = (props: SelectProps & { name: string }) => {
	const optionsList = props.optionList;
	return (
		<FormElement name={props.name}>
			{({ values, handleChange, handleBlur, errors, ref }) => {
				const key = values[props.name];
				const selectedOption = optionsList?.find((option) => option.key === key);

				const handleSelect = (option) => {
					handleChange(null, { name: props.name, value: option.key });
				};
				return (
					<Select
						selectedOption={selectedOption}
						error={errors[props.name]}
						onSelect={handleSelect}
						onBlur={handleBlur}
						ref={ref}
						{...props}
					/>
				);
			}}
		</FormElement>
	);
};
// chip dropdown
// mutliselect
