import React, { useRef } from 'react';

interface TaRatingCircleProps {
  radius: number;
  strokeWidth: number;
  color: string;
  rating: number;
  fill?: string;
}

const TaRatingCircle: React.FC<TaRatingCircleProps> = ({  radius, strokeWidth, color, rating, fill }) => {
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - 100 * circumference;

  const viewBox = `0 0 ${radius * 2} ${radius * 2}`;

  const circleRef = useRef<SVGCircleElement>(null);
  React.useEffect(() => {
    if (circleRef.current) {
      circleRef.current.style.strokeDashoffset = offset;
    }
  }, [offset, circleRef]);
  return (
    <svg width={radius * 2 } height={radius * 2} viewBox={viewBox}>
      <circle
        ref={circleRef}
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        fill={fill}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference + ' ' + circumference}
        transform={`rotate(-90 ${radius} ${radius})`}
      />
    </svg>
  );
};


TaRatingCircle.defaultProps = {
  fill: "transparant"
}
export default TaRatingCircle;