import { deepClone } from "fast-json-patch";

// TODO I hope it works
export const getValues = (obj) => {
	const data = Object.keys(obj);

	const output = {};
	let current;

	for (const path of data) {
		current = output;

		const keys = path.split('_');
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			if (key) {
				if (!current[key]) {
					if (!Number.isNaN(parseInt(key))) {
						let _key = parseInt(key);
						if (i === keys.length - 1) {
							current[_key] = obj[path];
						} else {
							if (keys[i + 1] && !Number.isNaN(parseInt(keys[i + 1]))) {
								current[_key] = [];
							} else {
								current[_key] = {};
							}
						}
					} else {
						if (i === keys.length - 1) {
							current[key] = obj[path];
						} else {
							if (keys[i + 1] && !Number.isNaN(parseInt(keys[i + 1]))) {
								current[key] = [];
							} else {
								current[key] = {};
							}
						}
					}
				}
				current = current[key];
			}
		}
	}
	return output;
};

export const getValueObject = (initialObject, formikValues) => {
  const clonedObject = deepClone(initialObject);
  for (const [key, value] of Object.entries(formikValues)) {
    let current = clonedObject;
    const keys = key.split('/');

    for (let i = 0; i < keys.length; i++) {
      const _key = keys[i];
      
      if (!current[_key]) {
        break;
      }
      if (i === (keys.length - 1)) {
        current[_key] = value
      } else {
        current = current[_key]
      }
    }
  };
  return clonedObject;
}