import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../configureStore"
import { fetchTransitVisaPostApi } from "@api/resultsPage/flightDetailsApi"

export interface TransitVisaDataState {
  transitResponse?: Record<string, any>
}
const initialState: TransitVisaDataState = {
  transitResponse: {},
}

export const fetchTransitVisaData = createAsyncThunk(
  "transitVisa/fetchTransitVisaData",
  async (payload) => {
    try {
      const response = await fetchTransitVisaPostApi(payload)
      return response
    } catch (e) {
      return {}
    }
  }
)

export const transitVisaDataSlice = createSlice({
  name: "transitVisa",
  initialState,
  reducers: {
    setTransitVisaData: (state, action) => {
      state.transitResponse.data = {
        ...action.payload,
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchTransitVisaData.fulfilled, (state, action) => {
      state.transitResponse = action.payload || {}
    })
    builder.addCase(fetchTransitVisaData.rejected, (state, action) => {
      state.transitResponse = action.payload
    })
    builder.addCase(fetchTransitVisaData.pending, (state, action) => {
      state.transitResponse = {}
    })
  },
})
const getTransitResponse = (state: RootState) =>
  state.transitVisa.transitResponse
const getTransitResponseData = (state: RootState) =>
  state.transitVisa.transitResponse?.data

const { reducer, actions } = transitVisaDataSlice
const { setTransitVisaData } = actions || {}

export { setTransitVisaData, getTransitResponse, getTransitResponseData }

export default reducer
