import type { Theme } from '../../token/theme';
import { TypographyVariant } from '../Typography/type';
import { ButtonColor, ButtonColorType, ButtonSize, ButtonSizeType, ButtonVariant, ButtonVariantType } from './type';

export const getButtonSizeStyles = (theme: Theme, size: ButtonSizeType) => {
	switch (size) {
		case ButtonSize.SMALL: {
			return {
				height: theme.size[8],
				paddingTop: theme.spacing[1],
				paddingBottom: theme.spacing[1],
			};
		}
		case ButtonSize.MEDIUM: {
			return {
				height: theme.size[10],
				paddingTop: theme.spacing[2],
				paddingBottom: theme.spacing[2],
			};
		}
		case ButtonSize.LARGE: {
			return {
				height: theme.size[12],
				paddingTop: theme.spacing[3],
				paddingBottom: theme.spacing[3],
			};
		}
		default: {
			return {
				height: theme.size[10],
				paddingTop: theme.spacing[2],
				paddingBottom: theme.spacing[2],
			};
		}
	}
};

export const getButtonVariantStyles = (theme: Theme, variant: ButtonVariantType, disabled: boolean) => {
	if (disabled) {
		return {
			borderWidth: theme.border.width.none,
		};
	}
	switch (variant) {
		case ButtonVariant.OUTLINE: {
			return {
				borderWidth: theme.border.width.sm,
				borderStyle: theme.border.style.solid,
			};
		}
		case ButtonVariant.CONTAINED: {
			return {
				borderWidth: theme.border.width.none,
			};
		}
		default: {
			return {
				borderWidth: theme.border.width.none,
			};
		}
	}
};

export const getContainedButtonColorStyles = (theme: Theme, color: ButtonColorType, disabled: boolean) => {
	if (disabled) {
		return {
			backgroundColor: theme.color.button.containedDisabledBg,
		};
	}

	switch (color) {
		case ButtonColor.PRIMARY: {
			return {
				backgroundColor: theme.color.button.containedPrimaryBg,
			};
		}
		case ButtonColor.SECONDARY: {
			return {
				backgroundColor: theme.color.button.containedSecondaryBg,
			};
		}
		case ButtonColor.TERTIARY: {
			return {
				backgroundColor: theme.color.button.containedTertiaryBg,
			};
		}
		default: {
			return {
				backgroundColor: theme.color.button.containedPrimaryBg,
			};
		}
	}
};

export const getOutlinedButtonColorStyles = (theme: Theme, color: ButtonColorType, disabled: boolean) => {
	if (disabled) {
		return {
			backgroundColor: theme.color.button.outlinedDisabledBg,
		};
	}

	switch (color) {
		case ButtonColor.PRIMARY: {
			return {
				backgroundColor: theme.color.button.outlinedPrimaryBg,
				borderColor: theme.color.button.outlinedPrimaryBorder,
			};
		}
		case ButtonColor.SECONDARY: {
			return {
				backgroundColor: theme.color.button.outlinedSecondaryBg,
				borderColor: theme.color.button.outlinedSecondaryBorder,
			};
		}
		case ButtonColor.TERTIARY: {
			return {
				backgroundColor: theme.color.button.outlinedTertiaryBg,
				borderColor: theme.color.button.outlinedTertiaryBorder,
			};
		}
		default: {
			return {
				backgroundColor: theme.color.button.outlinedPrimaryBg,
				borderColor: theme.color.button.outlinedPrimaryBorder,
			};
		}
	}
};

export const getButtonVariantColorStyles = (
	theme: Theme,
	color: ButtonColorType,
	variant: ButtonVariantType,
	disabled: boolean
) => {
	switch (variant) {
		case ButtonVariant.CONTAINED: {
			return getContainedButtonColorStyles(theme, color, disabled);
		}
		case ButtonVariant.OUTLINE: {
			return getOutlinedButtonColorStyles(theme, color, disabled);
		}
		default: {
			return getContainedButtonColorStyles(theme, color, disabled);
		}
	}
};

export const getContainedButtonLabelColor = (color: ButtonColorType, disabled: boolean, theme: Theme) => {
	if (disabled) {
		return theme.color.button.containedDisabledLabel;
	}

	switch (color) {
		case ButtonColor.PRIMARY: {
			return theme.color.button.containedPrimaryLabel;
		}
		case ButtonColor.SECONDARY: {
			return theme.color.button.containedSecondaryLabel;
		}
		case ButtonColor.TERTIARY: {
			return theme.color.button.containedTertiaryLabel;
		}
		default: {
			return theme.color.button.containedPrimaryLabel;
		}
	}
};

export const getOutlinedButtonLabelColor = (color: ButtonColorType, disabled: boolean, theme: Theme) => {
	if (disabled) {
		return theme.color.button.outlinedDisabledLabel;
	}

	switch (color) {
		case ButtonColor.PRIMARY: {
			return theme.color.button.outlinedPrimaryLabel;
		}
		case ButtonColor.SECONDARY: {
			return theme.color.button.outlinedSecondaryLabel;
		}
		case ButtonColor.TERTIARY: {
			return theme.color.button.outlinedTertiaryLabel;
		}
		default: {
			return theme.color.button.outlinedPrimaryLabel;
		}
	}
};

export const getButtonVariantLabelColor = (
	variant: ButtonVariantType,
	color: ButtonColorType,
	disabled: boolean,
	theme: Theme
) => {
	switch (variant) {
		case ButtonVariant.CONTAINED: {
			return getContainedButtonLabelColor(color, disabled, theme);
		}
		case ButtonVariant.OUTLINE: {
			return getOutlinedButtonLabelColor(color, disabled, theme);
		}
		default: {
			return getContainedButtonLabelColor(color, disabled, theme);
		}
	}
};

export const getTypographyVariant = (size: ButtonSizeType) => {
	switch (size) {
		case ButtonSize.SMALL: {
			return TypographyVariant.HM4;
		}
		case ButtonSize.MEDIUM: {
			return TypographyVariant.HM4;
		}
		case ButtonSize.LARGE: {
			return TypographyVariant.HM3;
		}
		default: {
			return TypographyVariant.HM4;
		}
	}
};

export const getDefaultButtonStyles = (disabled: boolean) => {
	if (disabled) {
		return {
			cursor: 'not-allowed',
		};
	} else {
		return {
			cursor: 'pointer',
		};
	}
};

export const getButtonStyles = ({
    theme,
    size,
    variant,
    color,
    disabled,
    minWidth,
    maxWidth,
    isFullWidth
}: {
    theme: Theme
    size: ButtonSizeType
    variant: ButtonVariantType
    color: ButtonColorType
    disabled: boolean
    minWidth: string
    maxWidth: string
    isFullWidth: boolean
}) => {
    return {
        ...getButtonSizeStyles(theme, size),
        ...getButtonVariantStyles(theme, variant, disabled),
        ...getButtonVariantColorStyles(theme, color, variant, disabled),
        ...getDefaultButtonStyles(disabled),
        minWidth: isFullWidth ? '100%' : minWidth || 'auto',
        maxWidth: maxWidth === '' ? 'auto' : maxWidth
    }
}
