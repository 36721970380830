// import { getGrantTokenApiHelper } from '@api/ultra'
// import { isEmpty } from '@utils/genralUtils'
// import { apiGetHelper, apiPutOrPostHelper,getDomain,getApiDomain } from 'src/utils/helper'
import {
    fetchComponentsController,
    fetchAncillariesController,
    fetchInsurancesController,
    fetchCouponListController,
    updateItineraryData,
    getApplyCoupon,
    getFareFamilyInfo
} from 'src/api/itineraryPage'
import {
    fetchComponentsControllerCFW,
    fetchAncillariesControllerCFW,
    fetchInsurancesControllerCFW,
    fetchCouponListControllerCFW,
    updateItineraryDataCFW,
    getApplyCouponCFW,
    getFareFamilyInfoCFW
} from 'src/api/cfw/ItineraryPage'

export const fetchComponentsControllerN = (action: any) => {
    const { itineraryId, ssr = false, headers = {}, isCFW = action.isCfw } = action
    switch (isCFW as boolean) {
        case true:
            return fetchComponentsControllerCFW({ itineraryId, headers, isSsr: ssr })
        case false:
            return fetchComponentsController(action)
        default: return fetchComponentsController(action)
    }
}

export const fetchAncillariesControllerN = (itineraryId: string, isCFW: boolean) => {
    switch (isCFW) {
        case true:
            return fetchAncillariesControllerCFW(itineraryId)

        case false:
            return fetchAncillariesController(itineraryId)
        default: return fetchAncillariesController(itineraryId)
    }
}

export const fetchInsurancesControllerN = (itineraryId: string, isCFW: boolean) => {
    switch (isCFW) {
        case true:
            return fetchInsurancesControllerCFW(itineraryId)

        case false:
            return fetchInsurancesController(itineraryId)
        default: return fetchInsurancesController(itineraryId)
    }
}

export const fetchCouponListControllerN = (itineraryId: string, oldFlow = true, isCFW: boolean) => {
    switch (isCFW) {
        case true:
            return fetchCouponListControllerCFW(itineraryId)

        case false:
            return fetchCouponListController(itineraryId)
        default: return fetchCouponListController(itineraryId)
    }
}

export const updateItineraryDataN = (payload: object, itineraryId: string, isCFW: boolean) => {
    switch (isCFW) {
        case true:
            return updateItineraryDataCFW(payload, itineraryId)

        case false:
            return updateItineraryData(payload, itineraryId)
        default: return updateItineraryData(payload, itineraryId)
    }
}

export const getApplyCouponN = (payload: object, itineraryId: string, isCFW: boolean) => {
    switch (isCFW) {
        case true:
            return getApplyCouponCFW(payload, itineraryId)

        case false:
            return getApplyCoupon(payload, itineraryId)
        default: return getApplyCoupon(payload, itineraryId)
    }
}

export const getFareFamilyInfoN = (itineraryId: string, type: any, isCFW: boolean) => {
    switch (isCFW) {
        case true:
            return getFareFamilyInfoCFW(itineraryId, type)

        case false:
            return getFareFamilyInfo(itineraryId, type)
        default: return getFareFamilyInfo(itineraryId, type)
    }
}
