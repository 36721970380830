import { apiPutOrPostHelper } from "src/utils/helper";

export const getEmiPrice = payload => {
    const URL = '/flights/emiOffers/eligibility'

    const response = apiPutOrPostHelper(URL, payload, 'application/json')
    return response
}

export const getEmiBanksList = payload => {
    const URL = '/flights/emiOffers/affordability'

    const response = apiPutOrPostHelper(URL, payload, 'application/json')
    return response
}