import {
  Ancillaries,
  SegmentAncillary,
  createSegmentReturnType,
  UserSelectedJourneyAncillary,
  SelectedBaggageDetail,
  SelectedMealDetail,
  SelectedSeatDetail
} from "@components/Addons/addonsType"
import { isEmpty } from "@utils/genralUtils"
import { FareCardDataType } from "@root/types/FareCardTypes"

export const countTravellersExceptInfant = (paxInfo) => {
  if (paxInfo) {
    return parseInt(paxInfo.adults) + parseInt(paxInfo.children)
  }
  return 0
}

export const checkIsMultiAirline = (flight = {}) => {
  const multiAirlinePairsExclude = [
      ['I5', 'IX'],
      ['IX', 'I5']
  ]
  let isMultiAirline = false
  let airline = flight?.segments?.[0]?.airline || ''
  flight?.segments.forEach(segment => {
      if (
          airline !== segment.airline &&
          !multiAirlinePairsExclude.some(pair => pair[0] === airline && pair[1] === segment.airline)
      ) {
          isMultiAirline = true
      }
  })
  return isMultiAirline;
}

export const addonTypes = ['seat', 'meal', 'baggage', 'insurance']

export const getSelectedMealBaggage = (selectedAddonsData: SelectedBaggageDetail[] | SelectedMealDetail[], key: "baggageCode" | "mealCode" | "mealInfo" | "baggageInfo") => {
  let dataObj: any = {}
  selectedAddonsData?.forEach((item: SelectedBaggageDetail | SelectedMealDetail) => {
      const itemCode = item?.[key]
      if (!isEmpty(itemCode)) {
        if (!isEmpty(dataObj[itemCode])) {
            dataObj[itemCode].count = dataObj[itemCode].count + 1
        } else {
            dataObj[itemCode] =  {
                ...item,
                count: 1
            }
        }
      }
  })
  return dataObj
}

export const getSelectedSeat = (selectedAddonsData: SelectedSeatDetail[] = []) => {
  let dataObj: any = {}
  selectedAddonsData?.forEach((item) => {
      const key = item.rowId + item.columnId
      dataObj[key] = {
          ...item,
          count: 1,
          seatInfo: key
      }
  })
  return dataObj
}

export const constructAddonsText = (data: SelectedBaggageDetail | SelectedMealDetail,
                                    key: 'mealInfo' | 'baggageInfo', type: string = "") => {                            
  let textArr: any  = []
  Object.keys(data).forEach((item) => {
      const dataItem = data[item]
      textArr.push(`${dataItem.count}x ${type === 'baggage' ? 'Additional ' + dataItem[key] : dataItem[key]}`)
  })
  return textArr.join(', ') || ""
}

export const getTotalFlightDuration = (flightData) => {
  let totalFlightDuration = flightData?.totalDuration || 0
  if (flightData?.layOverDurations?.length) {
      totalFlightDuration += flightData.layOverDurations.reduce((a, b) => {
          return a + b
      })
  }
  return totalFlightDuration;
}

export const serializeItineraryData = (data = []) => {
  const dataObj = {}
  // Todo: Discuss use of element.data check
  data.forEach(element => {
    if (element.data || element.type === 'AGENT_PAYMENT_DETAILS' || element.type === "GST") {
        if (
            element.type === 'USER' ||
            element.type === 'GST' ||
            element.type === 'VOUCHERS' ||
            element.type === 'AGENT_PAYMENT_DETAILS'
        ) {
            dataObj[element.type] = { ...element }
        } else {
            dataObj[element.type] = element.data
        }
    }
  })
  return dataObj
}

export const getSelectedFareCard = (fareData: Partial<FareCardDataType[]> = []) => {
  let selectedFare = fareData.find((fare: Partial<FareCardDataType> = {}) => {
    return fare.selected === true
  })
  return selectedFare || {}
}

export const getSelectedFareCardCfw = (FARE_CARDS:any) => {
  const ow = FARE_CARDS?.onwardFareCards?.find((fareCard)=>{
      return fareCard?.selected === true
  })
  const rt = FARE_CARDS?.returnFareCards?.find((fareCard)=>{
      return fareCard?.selected === true
  })
  return {ow,rt}
}

export const getReviewAddonsText = (userSelectedAncillaries = {}) => {
  const { userSelectedJourneyAncillaries: journeyAncillaries  = []} = userSelectedAncillaries
  let finalObj: any = {}
  let isSelectedAddonsEmpty = true
  journeyAncillaries?.forEach(journeyLevelData => {
    const { userSelectedBaggageAncillaries = {}, userSelectedSegmentAncillaries: segmentAncillaries = [] } = journeyLevelData
    const selectedBaggage = getSelectedMealBaggage(userSelectedBaggageAncillaries?.[0]?.baggageDetails, "baggageCode")
    segmentAncillaries.forEach(segmentLevelData => {
      const { seatDetails = [], mealDetails = [], segmentOriginDestinationInfo = {} } = segmentLevelData || {}
      const selectedSeats = getSelectedSeat(seatDetails)
      const selectedMeals = getSelectedMealBaggage(mealDetails, "mealCode")
      const segmentKey = `${segmentOriginDestinationInfo.from}_${segmentOriginDestinationInfo.to}`
      if (!isEmpty(selectedBaggage) || !isEmpty(selectedSeats) || !isEmpty(selectedMeals)) {
        isSelectedAddonsEmpty = false
      }
      finalObj = {
        ...finalObj,
        [segmentKey]: {
          seat: Object.keys(selectedSeats).join(', '),
          meal: constructAddonsText(selectedMeals, "mealInfo", 'meal'),
          baggage: constructAddonsText(selectedBaggage, 'baggageInfo', 'baggage')
        }
      }
    })
  })
  return {
    selectedAddonsData: finalObj,
    isSelectedAddonsEmpty
  }
}

export const showAxisBanner = bannerData => {
  const { AXIS_LOYALTY } = bannerData || {}
  return AXIS_LOYALTY
}

export const getUpdateUserDialoguePayload = (userDialogueType: string) => {
  const payload = {
      updateRequestList: [
          {
              type: 'USER_DIALOGUE',
              userDialogueType
          }
      ]
  }
  return payload
}