import { isServer } from '@utils/appAgent'

export const isDomainForAutoSelectBRB = () => {
    if (!isServer()) {
        const domain = window.location.hostname
        switch (domain) {
            case 'www.cleartrip.ae':
            case 'qa2.cleartrip.ae':
            case 'me.cleartrip.ae':
            case 'uat.cleartrip.ae':
            case 'www.cleartrip.sa':
            case 'qa2.cleartrip.sa':
            case 'me.cleartrip.sa':
            case 'uat.cleartrip.sa':
            case 'bh.cleartrip.com':
            case 'bh.cleartrip.sa':
            case 'uat.cleartrip.bh':
            case 'www.cleartrip.bh':
            case 'om.cleartrip.com':
            case 'om.cleartrip.sa':
            case 'uat.cleartrip.om':
            case 'www.cleartrip.om':
            case 'kw.cleartrip.com':
            case 'kw.cleartrip.sa':
            case 'uat.cleartrip.kw':
            case 'www.cleartrip.kw':
            case 'qa.cleartrip.com':
            case 'qar.cleartrip.sa':
            case 'uat.cleartrip.qa':
            case 'www.cleartrip.qa':
            case '0.0.0.0':
            case 'localhost':
                return true
            default:
                return false
        }
    }
    return false
}

export const getIntlTravelInsuranceProvider = insuranceList => {
    let provider = ''
    if (Array.isArray(insuranceList)) {
        insuranceList.forEach((item: any) => {
            if (item.vasType === 'INTL_TRAVEL_INSURANCE') provider = item.vasProvider
        })
    }

    return provider
}
