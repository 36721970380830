export const styles = {
	zoom: {
		entering: {
			transform: 'none',
		},
		entered: {
			transform: 'none',
		},
	},
	fade: {
		entering: {
			opacity: 1,
		},
		entered: {
			opacity: 1,
		},
	},
	grow: {
		entering: {
			opacity: 1,
			transform: 'scale(1, 1)',
		},
		entered: {
			opacity: 1,
			transform: 'none',
		},
	},
	collapse: {},
	slide: {},
};
