import React from 'react';

const Video = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <mask
                id="mask0_2627_1220"
                style={{ maskType: 'alpha' }}
                width="16"
                height="16"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H16V16H0z"></path>
            </mask>
            <g mask="url(#mask0_2627_1220)">
                <path
                    fill="#fff"
                    d="M6.333 11L11 8 6.333 5v6zm-3.666 2.334c-.367 0-.68-.13-.942-.392A1.284 1.284 0 011.333 12V4c0-.366.13-.68.392-.941.261-.261.575-.392.942-.392h10.666c.367 0 .68.13.942.392.261.26.391.575.391.941v8c0 .367-.13.681-.39.942-.262.261-.576.392-.943.392H2.667zm0-1.334h10.666V4H2.667v8z"
                ></path>
            </g>
        </svg>
    );
};

export default Video;