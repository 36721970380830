import React from 'react';
import useTheme from '../../hooks/useTheme';
import Container from '../Container';
import Typography from '../Typography';
import { ParentLayer, Label, Input } from './BaseSwitch/BaseSwitch';
import { ButtonSize } from './BaseSwitch/type';
import Cross from './svg/Cross';
import Tick from './svg/Tick';
import { ToggleSwitchProps } from './type';

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
	id,
	label = '',
	name,
	checked,
	disabled = false,
	gap,
	onChange,
	size = ButtonSize.SMALL,
	iconVisibility,
}) => {
	const theme = useTheme();
	const showIcon = () => {
    if (iconVisibility) {
      return checked ? (
        <Tick
          crossColor={
            disabled
              ? `${theme.color.background.disabledSecondaryLight}`
              : `${theme.color.border.primary}`
          }
        />
      ) : (
        <Cross
          crossColor={
            disabled
              ? `${theme.color.background.disabledSecondaryLight}`
              : `${theme.color.border.defaultDark}`
          }
        />
      )
    }
    return null
  }
	return (
		<Container display='flex' alignItems='center' justifyContent='center' width='fit-content'>
			<label>
				<Container display='flex' alignItems='center' justifyContent='center'>
					<ParentLayer value={checked} disabled={disabled} size={size} theme={theme}>
						<Label value={checked} size={size} theme={theme}>
							<Input type='checkbox' name={name} id={id} onChange={onChange} />
							{showIcon()}
						</Label>
					</ParentLayer>

					{label && (
						<Container
							paddingLeft={`${gap}px` || theme.spacing[2]}
							display='flex'
							alignItems='center'
							justifyContent='center'
						>
							<Typography variant='B2'>{label}</Typography>
						</Container>
					)}
				</Container>
			</label>
		</Container>
	);
};

export default ToggleSwitch;
