import { CSSObject } from 'styled-components';
import { StyledButtonProps } from './type';
import { Theme } from '../../../token/theme';
type CSSObject = /*unresolved*/ any

export const getStyledButtonStyles = ({
    cursor,
    backgroundColor,
    borderStyle,
    height,
    minWidth,
    maxWidth,
    paddingTop,
    paddingBottom,
    borderColor,
    borderWidth,
    theme
}: StyledButtonProps & { theme: Theme }): CSSObject => {
    return {
        display: 'flex',
        border: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        paddingLeft: theme.spacing[3],
        paddingRight: theme.spacing[3],
        cursor,
        borderRadius: theme.border.radius[8],
        borderWidth,
        backgroundColor,
        borderStyle,
        height,
        paddingTop,
        paddingBottom,
        borderColor,
        minWidth,
        maxWidth
    }
}
