export type Elevation = Readonly<{
	bottomE1: '0px 1px 0px #E7E7E7';
	bottomE2: '0px 4px 16px rgba(26, 26, 26, 0.08)';
	bottomE3: '0px 8px 24px rgba(26, 26, 26, 0.1)';
	bottomE4: '0px 10px 32px rgba(26, 26, 26, 0.2)';
	topE1: '0px -1px 0px #E7E7E7';
	topE2: '0px -4px 16px rgba(26, 26, 26, 0.08)';
	topE3: '0px -8px 24px rgba(26, 26, 26, 0.1)';
	topE4: '0px -10px 32px rgba(26, 26, 26, 0.2)';
}>;

const elevation: Elevation = {
	bottomE1: '0px 1px 0px #E7E7E7',
	bottomE2: '0px 4px 16px rgba(26, 26, 26, 0.08)',
	bottomE3: '0px 8px 24px rgba(26, 26, 26, 0.1)',
	bottomE4: '0px 10px 32px rgba(26, 26, 26, 0.2)',
	topE1: '0px -1px 0px #E7E7E7',
	topE2: '0px -4px 16px rgba(26, 26, 26, 0.08)',
	topE3: '0px -8px 24px rgba(26, 26, 26, 0.1)',
	topE4: '0px -10px 32px rgba(26, 26, 26, 0.2)',
};

export default elevation;