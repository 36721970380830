import { setErrorState } from '@redux/manager/confirmationManager'
import { apiGetHelper } from 'src/utils/helper'

export const itineraryConfirmApi = async (itineraryId: string) => {
    try {
        const URL = `/itin/itinerary/${itineraryId}/appConfirm`
        const response = await apiGetHelper(URL, {}, {})

        if (response?.name === 'AxiosError') {
            setErrorState(true)
            throw new Error('Itinerary failed!')
        }
        return response
    } catch (e) {
        throw new Error(e)
    }
}
