import { ReactNode } from "react";

export enum CardRadius {
    NONE = 'none',
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
}

export type CardRadiusType = `${CardRadius}`;

export enum CardBorderWidth {
    NONE = 'none',
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
}

export type CardBorderWidthType = `${CardBorderWidth}`;

export enum CardShadowDirection {
    TOP = 'top',
    BOTTOM = 'bottom',
}

export type CardShadowDirectionType = `${CardShadowDirection}`;

export enum CardShadowVariant {
    E1 = 'E1',
    E2 = 'E2',
    E3 = 'E3',
    E4 = 'E4'
}

export type CardShadowVariantType = `${CardShadowVariant}`;

export enum CardBorderDirection {
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
    DEFAULT = 'default',
}

export type CardBorderDirectionType = `${CardBorderDirection}`;

export interface CardProps {
    children: ReactNode;
    borderWidth?: CardBorderWidthType;
    cornerRadius?: CardRadiusType;
    showShadow?: boolean;
    shadowDirection?: CardShadowDirectionType;
    shadowVariant?: CardShadowVariantType;
    borderDirection?: CardBorderDirectionType;
    className?: string;
}