import React, { useEffect, useRef, useState } from "react";

interface ProgressBarProps {
  progress: number;
  barColor: string;
  progressedBarColor: string;
  height?: string;
  width?: string;
  delay?: number;
  radius?: string;
}

//TODO: Add the theme to this component.
const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  radius,
  barColor,
  progressedBarColor,
  height,
  width,
  delay
}) => {
  const [style, setStyle] = useState({
    backgroundColor: progressedBarColor,
    borderRadius: radius,
    height: "100%",
    width: "0px",
    opacity: 0,
    transition: "1s ease 0.3s",
  });

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${progress}%`,
    };

    setStyle((prev) => ({
      ...prev,
      ...newStyle,
    }));
  }, delay);

  const progressStyle = {
    borderRadius: radius,
    height: height,
    width: width,
    backgroundColor: barColor,
  };

  return (
    <div style={progressStyle}>
      <div style={style}></div>
    </div>
  );
};


ProgressBar.defaultProps = {
  height: "4px",
  width: "244px",
  delay: 200,
  radius: "2px"
}
export default ProgressBar;
