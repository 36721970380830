import { getGrantTokenApiHelper } from '@api/ultra'
import { isEmpty } from '@utils/genralUtils'
import { apiGetHelper, apiPutOrPostHelper, getDomain, getApiDomain } from 'src/utils/helper'

export const fetchComponentsControllerCFW = ({
    itineraryId,
    headers,
    isSsr
}: {
    itineraryId: string
    headers: object
    isSsr: boolean
}) => {
    try {
        const URL = `${getDomain(isSsr)}/itin/v7/itinerary/${itineraryId}/fetch/components`
        const response = apiGetHelper(URL, '', headers)
        return response
    } catch (e) {
        return {}
    }
}

export const fetchAncillariesControllerCFW = (itineraryId: string) => {
    try {
        const URL = `${getApiDomain()}/itin/v7/${itineraryId}/ancillaries/fetch?includeBaggage=true&includeMeal=true&includeSeat=true`
        const response = apiGetHelper(URL, '', '')
        return response
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const fetchInsurancesControllerCFW = (itineraryId: string) => {
    try {
        const URL = `${getApiDomain()}/itin/v7/${itineraryId}/insurances/fetch`
        const response = apiGetHelper(URL, '', '')
        return response
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const fetchCouponListControllerCFW = (itineraryId: string, oldFlow = true) => {
    try {
        const URL = `${getApiDomain()}/itin/v7/itinerary/${itineraryId}/couponList`
        const response = apiGetHelper(URL, '', {})
        return response
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const updateItineraryDataCFW = (payload: object, itineraryId: string) => {
    try {
        const URL = `${getApiDomain()}/itin/v7/itinerary/${itineraryId}/update`
        const response = apiPutOrPostHelper(URL, payload, 'application/json', '')
        return response
    } catch (e) {
        console.log(e)
    }
}

export const getApplyCouponCFW = (payload: object, itineraryId: string) => {
    try {
        const URL = `${getApiDomain()}/itin/v7/itinerary/${itineraryId}/applyCoupon`
        const response = apiPutOrPostHelper(URL, payload, 'application/json', '')
        return response
    } catch (e) {
        console.log(e)
    }
}

export const validateGstCFW = (tripId: string, payload: { state: string; pin_code: string; address: string }) => {
    // return resolveOnDelay(kfsMock, 2000) as any;
    // const VALIDATE_GST = `${BASE_URL}/paymentservice/api/validateGST/{_tripRef_}`;
    const URL = '' //VALIDATE_GST.replace("{_tripRef_}", tripId)
    const response = apiPutOrPostHelper(URL, payload, 'application/json', '')
    return { data: { status: 'SUCCESS', description: 'VALID_DETAILS' } }
}

export const getFareFamilyInfoCFW = async (itineraryId: string, type = '') => {
    try {
        let URL = `${getApiDomain()}/itin/v7/${itineraryId}/fare-benefits-info`
        if (type) {
            URL = `${getApiDomain()}/itin/v7/${itineraryId}/fare-benefits-info`
        }
        const response = await apiGetHelper(URL, '', '')
        return response
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const initiatePrePaymentCFW = (itineraryId: string) => {
    try {
        const URL = `${getApiDomain()}/itin/v7/${itineraryId}/prepayment`
        const response = apiPutOrPostHelper(URL, undefined, 'application/json', '')
        return response
    } catch (e) {
        console.log(e)
    }
}

export const generatePayUrlCFW = (itineraryId: string, tripId: string) => {
    try {
        const URL = `${getApiDomain()}/itin/v7/${itineraryId}/generatePayUrl?tripId=${tripId}`
        const response = apiGetHelper(URL, '', '')
        return response
    } catch (e) {
        console.log(e)
    }
}
