import React, { FC, ReactNode } from 'react';
import { CSSTransition as ReactTransition } from 'react-transition-group';
import { TransitionProps } from "./type";
// import './style.scss';



const Slide: FC<TransitionProps> = (props) => {
    const { children } = props;
    return (    
        <ReactTransition
            in={props.in}
            timeout={props.timeout}
            mountOnEnter={props.mountOnEnter}
            unmountOnExit={props.unmountOnExit}
            appear={props.appear}
            classNames={props.fromTop ? 'slideDown' : 'slide'}
        >
            <>{children}</>
        </ReactTransition>
    );
};

Slide.displayName = 'Animate(Slide)';

export default Slide;
