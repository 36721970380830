import { forwardRef } from 'react';
import styled from 'styled-components';
import Container from '../Container';
import { getBoxStyles } from './style';
import { BoxProps } from './type';
import useTheme from '../../hooks/useTheme';
import { ForwardRefComponentWithStyle, OwnProps, IntrinsicElement, OwnPropsWithStyle, Merge } from '../../@types';

type PolymorphicBox = ForwardRefComponentWithStyle<
	IntrinsicElement<typeof Container>,
	BoxProps<OwnPropsWithStyle<typeof Container>>
>;

const StyledContainer = styled.div<OwnProps<PolymorphicBox>>(({ theme, css, boxSize, flexDirection }) =>
	getBoxStyles({ theme, boxSize, flexDirection, ...css })
);

const Box = forwardRef(({ as, children, horizontal, ...rest }, forwardedRef) => {
	const theme = useTheme();
	return (
		<StyledContainer
			flexDirection={horizontal ? 'row' : 'column'}
			theme={theme}
			as={as}
			ref={forwardedRef}
			{...rest}
		>
			{children}
		</StyledContainer>
	);
}) as PolymorphicBox;

export default Box;
