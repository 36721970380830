import React from 'react';

const Announcement = (props: any) => {
    const { isIos, version } = props || {}

    const styles = {
        container: {
            background: 'white',
            padding: '20px',
            borderRadius: '10px',
            maxWidth: '400px',
            textAlign: 'center',
            margin: '0 auto',
        },
        box: {
            marginTop: '56px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            borderRadius: '10px',
            padding: '17px',
            border: '1px solid white'
        },
        button: {
            display: 'inline-block',
            marginTop: '15px',
            padding: '16px 20px',
            backgroundColor: '#ff6600',
            color: 'white',
            textDecoration: 'none',
            borderRadius: '24px',
            fontWeight: 'bold',
            position: 'absolute',
            bottom: '4px',
            right: '0px',
            left: '0px',
            margin: '12px',
        },
    };
    function dispatchData(type: string) {
        if (typeof newrelic == 'object') {
            newrelic.addPageAction('AppDownload', {
                rootURL: window.location.href,
                type: 'Click',
                appVersion: version,
                appType: type,
                domain: window.location.hostname
            })
        }
    }
    return (
        <div >
            <div style={styles.container}>
                <h2>Hey there! 👋</h2>
                <p style={{ fontSize: '16px', paddingBottom: '12px', paddingTop: '12px' }}><strong>Cleartrip introduces a dedicated platform for ME countries on the Play Store!</strong></p>
                <p>
                    Cleartrip unveils a dedicated ME countries platform on the Play Store, streamlining travel services.
                    Catering to Middle Eastern users, it offers seamless access to flights and hotels.
                    Enhancing convenience, Cleartrip's initiative reflects its commitment to regional customers.
                </p>
                <div style={styles.box}>
                    <div>
                        <img src="https://ui.cltp.co/flights-pwa-next//public/assets/icons/cleartrip-72x72.png" />
                    </div>
                    <div> Cleartrip ME </div>

                    <div>New & Updated!</div>
                </div>

                {isIos ? (
                    <a
                        href="https://apps.apple.com/us/app/cleartrip-me-flight-hotel/id6479165589"
                        style={styles.button}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => dispatchData('ios')}
                    >
                        Go to App Store
                    </a>
                ) : (
                    <a
                        href="https://play.google.com/store/apps/details?id=me.cleartrip.android&pcampaignid=web_update"
                        style={styles.button}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => dispatchData('Android')}
                    >
                        Go to Play Store
                    </a>
                )}

            </div>
        </div>
    );
}

export default Announcement;
