import React from 'react';
import { ScrollIntoViewProps } from './type';

const ScrollIntoView: React.FC<ScrollIntoViewProps> = ({
    children,
    selector,
    scrollHorizontal,
    scrollVertical,
    className,
    refAccess,
    smooth,
}) => {
    const scrollHandle = () => {
        let ele:HTMLElement | null = null;
        if (selector)
            ele = document.getElementById(selector);
    
        if (refAccess)
            ele = refAccess.current;

        if (ele) {
            let top = 0, left = 0;
            if (scrollHorizontal?.toScroll) {
                left = scrollHorizontal.alignTo == 'left' ? ele.getBoundingClientRect().left : ele.getBoundingClientRect().right - window.innerWidth;
                left -= scrollHorizontal.offest;
            }

            if (scrollVertical?.toScroll) {
                top = scrollVertical.alignTo == 'top' ? ele.getBoundingClientRect().top : ele.getBoundingClientRect().bottom - window.innerHeight;
                top -= scrollVertical.offest;
            }

            window.scrollBy({
                top: top,
                left: left,
                behavior: smooth ? 'smooth' : 'auto',
            });
        }
    };
    
    return (
        <div onClick={scrollHandle} className={className}>
            {children}
        </div>
    );
};

ScrollIntoView.defaultProps = {
    children: null,
    selector: '',
    scrollHorizontal: {
        toScroll: false,
        alignTo: 'left',
        offest: 0,
    },
    scrollVertical: {
        toScroll: false,
        alignTo: 'top',
        offest: 0,
    },
    refAccess: undefined,
    smooth: true,

};

export default ScrollIntoView;