import { useMemo } from 'react';

const useMergeRefs = (...refs) => {
	return useMemo(() => {
		if (refs.every((ref) => ref == null)) {
			return null;
		}
		return (instance) => {
			refs.forEach((ref) => {
				if (typeof ref === 'function') {
					ref(instance);
				} else if (ref) {
					ref.current = instance;
				}
			});
		};
	}, refs);
};

export default useMergeRefs;
