import React from "react";
import classnames from "classnames";

import { CounterProps } from "./type";
import { CounterPlus, CounterMinus } from "@ct-design/assets/svg";
import Typography from "../Typography";
import Container from "../Container";
import Spacer from "../Spacer";

//TODO: will add the theming in the component.
const Counter: React.FC<CounterProps> = ({
  className,
  minValue,
  maxValue,
  value,
  onDecrement,
  onIncrement,
}) => {

  const minusClass = classnames({
    "c-not-allowed": value <= minValue,
    "c-pointer": value > minValue,
  });

  const plusClass = classnames({
    "c-not-allowed": value >= maxValue,
    "c-pointer": value < maxValue,
  });

  const handleDecrementClick = () => {
    if (value <= minValue) return;
    onDecrement();
  };

  const handleIncrementClick = () => {
    if (value >= maxValue) return;
    onIncrement();
  };

  return (
    <div className={classnames("flex flex-middle", className)}>
      <div className="flex-inline">
        <span className={minusClass} onClick={handleDecrementClick}>
          <CounterMinus
            strokeColor={value > minValue ? "#1A1A1A" : "#B3B3B3"}
          />
        </span>
      </div>
      <Spacer className="pl-4"/>
      <Container width="24px" className="flex flex-center"><Typography variant="P1">{value}</Typography></Container>
      <Spacer className="pl-4"/>
      <div className="flex-inline">
        <span className={plusClass} onClick={handleIncrementClick}>
          <CounterPlus strokeColor={value >= maxValue ? "#B3B3B3" : "#1A1A1A"} />
        </span>
      </div>
    </div>
  );
};

Counter.defaultProps = {
  minValue: 0,
  maxValue: 50,
  value: 0,
  onIncrement: () => {},
  onDecrement: () => {},
};

Counter.displayName = "Counter";

export default Counter;
