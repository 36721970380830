import { Theme } from '../../token/theme';

export const getDisabledChipStyles = (theme: Theme) => {
	return {
		backgroundColor: theme.color.background.neutral,
		borderWidth: theme.border.width.sm,
		borderColor: theme.color.border.disabled,
		borderRadius: theme.border.radius[28],
		cursor: 'not-allowed',
	};
};

export const getSelectedChipStyles = (theme: Theme) => {
	return {
		backgroundColor: theme.color.chip.selectedPrimaryBg,
		borderWidth: theme.border.width.sm,
		borderColor: theme.color.chip.selectedPrimaryBorder,
		borderRadius: theme.border.radius[28],
		cursor: 'pointer',
	};
};

export const getDefaultChipStyles = (theme: Theme) => {
	return {
		backgroundColor: theme.color.background.neutral,
		borderWidth: theme.border.width.sm,
		borderColor: theme.color.border.default,
		borderRadius: theme.border.radius[28],
		cursor: 'pointer',
	};
};

export const getChipLabelColor = ({
	isSelected,
	isDisabled,
	theme,
}: {
	isSelected: boolean;
	isDisabled: boolean;
	theme: Theme;
}) => {
	if (isDisabled) {
		return theme.color.chip.disabledPrimaryLabel;
	}

	if (isSelected) {
		return theme.color.chip.selectedPrimaryLabel;
	}

	return theme.color.chip.nonSelectedPrimaryLabel;
};

export const getCounterStyles = ({ theme }: { theme: Theme }) => {
	return {
		backgroundColor: theme.color.chip.selectedPrimaryLabel,
	};
};

export const getChipStyles = ({
	isSelected,
	isDisabled,
	showTopIcon,
	theme,
}: {
	isSelected: boolean;
	isDisabled: boolean;
	showTopIcon: boolean;
	theme: Theme;
}) => {
	const defaultChipStyles = {
		paddingTop: showTopIcon ? theme.spacing[2] : theme.spacing[2],
		paddingBottom: showTopIcon ? theme.spacing[2] : theme.spacing[2],
	};

	if (isDisabled) {
		return {
			...defaultChipStyles,
			...getDisabledChipStyles(theme),
		};
	}

	if (isSelected) {
		return {
			...defaultChipStyles,
			...getSelectedChipStyles(theme),
		};
	}

	return {
		...defaultChipStyles,
		...getDefaultChipStyles(theme),
	};
};
