import type { CSSObject } from 'styled-components';
import { CSSWithTheme } from '../../@types';
import { ModalSizeTypes, ModalSize } from './type';

const getModalSizeStyles = (size: ModalSizeTypes) => {
    switch(size){
        case ModalSize.SMALL: {
            return {
                width: '360px',
            }
        }
        case ModalSize.LARGE: {
            return {
                width: '1032px',
            }
        }
        case ModalSize.FULL_SCREEN: {
            return {
                /* decrease value of padding*/
                width: 'calc(100% - 48px)',
                height: '100%'
            }
        }
        case ModalSize.NONE: {
            return {}
        }
        case ModalSize.MEDIUM:
        default: {
            return {
                width: '504px',
            }
        }
    }
};

export const getStyleModal = ({theme, size, blur }): CSSObject =>({
    boxSizing: 'content-box',
    borderRadius: theme.spacing['3'],
    borderBottomLeftRadius: size === 'FULL SCREEN' ? 0 : theme.spacing['3'],
    borderBottomRightRadius: size === 'FULL SCREEN' ? 0 : theme.spacing['3'],
    backgroundColor: theme.color.background.neutral,
    overflowY: 'auto',
    maxHeight: `calc(100% - 64px)`,
    marginTop: size === 'FULL SCREEN' ? '64px' : 0,
    boxShadow: blur ? `rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 22px 18px` : 'none',
    ...getModalSizeStyles(size)
});

export const getModalStyles = ({ theme,size, ...rest }: CSSWithTheme & { size: ModalSizeTypes}): CSSObject => ({
    position: 'relative',
	zIndex: theme.zIndex.modal,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    ...rest
});
