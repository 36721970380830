import React from "react";
import styled from "styled-components";
import { TruncateProps } from "./type";

const Truncate = styled.p<TruncateProps>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ width, maxWidth }) => ({
        width: width,
        maxWidth: maxWidth
    })}
`

export default Truncate;
