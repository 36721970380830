import React from 'react';
import { DividerProps, DividerType } from './type';

const Divider: React.FC<DividerProps> = ({
    color,
    type = DividerType.SOLID,
    height,
    className
}) => {
    const borderTop = `${height}px ${type} ${color}`;
    return (
        <div className={className} style={{ borderTop }}></div>
    );
};


Divider.defaultProps = {
    height: 1,
    type: DividerType.SOLID,
    color: '#E7E7E7'
}

export default Divider;