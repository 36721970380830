import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../configureStore";
import { getAbConfig } from "src/api/abConfig";
import {defaultCookieValues} from '@constants/flights/index'
import { isEmpty } from "@utils/genralUtils";
export interface AbTestDataState {
  abConfigData?: Record<string, string>;
}
const initialState: AbTestDataState = {
  abConfigData: {...defaultCookieValues},
};

export const fetchAbTestData = createAsyncThunk("abConfig/abTest", async () => {
  try {
      const response = await getAbConfig()
      return response
  } catch (e) {
      return {}
  }
});

export const abConfigSlice = createSlice({
  name: "abConfig",
  initialState,
  reducers: {
    setAbData: (state, action) => {
      state.abConfigData = action.payload 
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAbTestData.fulfilled, (state, action: PayloadAction<any>) => {

      state.abConfigData = { ...defaultCookieValues, ...action.payload.data } || { ...defaultCookieValues }
    });
    builder.addCase(fetchAbTestData.rejected, (state, action) => {
      state.abConfigData = { ...defaultCookieValues }
    });
  },
});

const getAbConfigData = (state: RootState) => state.abConfig.abConfigData;

const { reducer, actions } = abConfigSlice;
const { setAbData } = actions || {};

export { setAbData, getAbConfigData };

export default reducer;
