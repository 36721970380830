import {
  getUserAuthValues,
  checkDeviceType,
  getCurrency,
  minutesToHnM,
  replacePlaceHolder,
  getLocaleByCurrency,
  mapOnObject,
  getLanguageMeta,
  readParam,
  convertDate,
} from "@ui/utils";

import logError from "@utils/flights/logError";
import { checkIfUltraContainer, getOSType, isMobileSite, isServer, sendEventsToMobileBridge } from "@utils/appAgent";
import UtmCurrency from "@utils/UtmCurrency";
import { FULL_NAMES_OF_DAYS, HOST } from "@ui/constants";
export const dataLayerGA = (payload, event) => {
  const { dataLayer = [] } = window || {};
  const { userId } = getUserAuthValues();
  const payloadData = {
    event: event,
    user_id: userId || "",
    platform: checkPlatform(),
    user_status: userId ? "LoggedIn" : "LoggedOut",
    isAmendment:window.location.pathname == '/flights/amendments/search-results'?true:false,
    ...payload,
  };
  const isApp = isMobileSite();
  if (isApp) {
    sendEventsToMobileBridge(payloadData);
  } else {
    dataLayer.push(payloadData);
  }
};

export const checkPlatform = () => {
  if (!isServer() && checkIfUltraContainer(navigator.userAgent)) {
      const deviceType = getOSType()
      if (deviceType === 'ios') {
          return 'iOS'
      }
      if (deviceType === 'android') {
          return 'Android'
      }
  }
  let platform_val = "PWA";
  let deviceType = checkDeviceType();
  if (deviceType === "iPhoneApp") {
    platform_val = "iOS";
  }
  if (deviceType === "AndroidApp") {
    platform_val = "Android";
  }

  return platform_val;
};
export const formatPrice = (
  val,
  curr,
  originalCurr,
  currencyConversionFactor
) => {
  let value = val;
  let currency = curr;
  const utmCurrencyObject = UtmCurrency.utmCurrency || currencyConversionFactor;
  const currencyPrecisionMap = UtmCurrency.currencyPrecision;
  if (!currency) {
    currency = getCurrency();
  }

  if (utmCurrencyObject != null && originalCurr !== true) {
    value = utmConversionValue(value, utmCurrencyObject);
    currency = utmCurrencyObject.code;
  }

  const locale = getLocaleByCurrency(currency);
  const fraction = getCurrencyFraction(currency, currencyPrecisionMap);
  const formatted = (
    (fraction.enabled && parseFloat(value)) ||
    parseInt(value, 10)
  ).toLocaleString(locale, {
    style: "currency",
    currency,
    ...fraction,
  });
  if (formatted && formatted?.length > 0) {
    return Number(formatted?.replace(/[^0-9\.-]+/g, ""));
  }
};

export const formatCurrencyGA = (
  val,
  curr,
  originalCurr,
  currencyConversionFactor
) => {
  let value = val;
  let currency = curr;
  const utmCurrencyObject = UtmCurrency.utmCurrency || currencyConversionFactor;
  const currencyPrecisionMap = UtmCurrency.currencyPrecision;
  if (!currency) {
    currency = getCurrency();
  }

  if (utmCurrencyObject != null && originalCurr !== true) {
    value = utmConversionValue(value, utmCurrencyObject);
    currency = utmCurrencyObject.code;
  }

  const locale = getLocaleByCurrency(currency);
  const fraction = getCurrencyFraction(currency, currencyPrecisionMap);
  const formatted = (
    (fraction.enabled && parseFloat(value)) ||
    parseInt(value, 10)
  ).toLocaleString(locale, {
    style: "currency",
    currency,
    ...fraction,
  });
  return formatted;
};

export const getVisibleItemDetails = ({
  viewedItems = [],
  sIndex,
  resultsData,
  searchQuery = {},
}) => {
  const { sectors = {}, jsons = {} } = resultsData || {};
  let items = viewedItems?.map(
    (
      { sectorKeys, priceBreakup, airlineCodes, totalDurationInMinutes },
      index
    ) => ({
      item_name:
        getAirNamesForGa(sectors, sectorKeys, jsons?.airline_names) || "N/A",
      item_id: getFlightNumber(sectors, sectorKeys, jsons?.airline_names),
      currency: getCurrency(),
      item_category: `${
        sectors[sectorKeys?.[0]]?.firstDeparture?.airportCode
      } | ${sectors[sectorKeys?.[0]]?.lastArrival?.airportCode}`,
      item_category2: minutesToHnM(totalDurationInMinutes),
      item_category3: priceBreakup?.fare?.coupon_detail?.message
        ? replacePlaceHolder(priceBreakup?.fare?.coupon_detail.message, {
            discountedPrice: formatCurrencyGA(
              priceBreakup?.fare?.coupon_detail.discountAmount
            ),
            couponCode: priceBreakup?.fare?.coupon_detail.couponCode || "N/A",
          })
        : "N/A",
      item_category4: `${searchQuery.travellers.adults} | ${searchQuery.travellers.children}`,
      item_category5: String(sectors[sectorKeys?.[0]]?.stops),
      item_list_name: "flight search",
      item_list_id: "flight_search",
      price: priceBreakup?.fare?.pr,
      discount: priceBreakup?.fare?.ds,
      index: sIndex + index + 1,
      isAmendment:window.location.pathname == '/flights/amendments/search-results'?true:false
    })
  )
  const finalObj = {
    event: "view_item_list",
    platform: "PWA",
    booking_date: `${searchQuery?.departDate}${
      searchQuery?.returnDate ? "|" + searchQuery?.returnDate : ""
    }`,
    flight_type: searchQuery?.isIntl ? "International" : "Domestic",
    trip_type: searchQuery?.returnDate ? "round" : "single",
    onward_flight_id: "N/A",
    source_destination: `${searchQuery?.source} | ${searchQuery?.destination}`,
    onward_flight_route: "N/A",
    discounted_flight_status: "no",

    ecommerce: {
      items: items,
    },
  };
  pushGAData(finalObj);
};

function getAirNamesForGa(sectors, sectorKeys, airline_names) {
  let name = "";
  sectorKeys.forEach((sectorKey, indexParent) => {
    sectors[sectorKey]?.flights?.segments?.forEach((item, index) => {
      name += `${index || indexParent ? "|" : ""}${
        airline_names?.[item?.flightNumber?.split("-")?.[0]]
      }`;
    });
  });

  return name;
}
function getFlightNumber(sectors, sectorKeys, airline_names) {
  let name = "";
  sectorKeys.forEach((sectorKey, indexParent) => {
    sectors[sectorKey]?.flights?.segments?.forEach((item, index) => {
      name += `${index || indexParent ? "| " : ""}${item?.flightNumber} `;
    });
  });

  return name;
}

export const pushGAData = (payload) => {
  const { dataLayer = [] } = window || {};
  const { userId } = getUserAuthValues();
  const payloadData = {
    user_id: userId,
    platform: checkPlatform(),
    isAmendment:window.location.pathname == '/flights/amendments/search-results'?true:false,
    ...payload,
  };
  const isApp = isMobileSite();
  if (isApp) {
    sendEventsToMobileBridge(payloadData);
  } else {
    dataLayer.push(payloadData);
  }
};
const utmConversionValue = (value, utmCurrencyObject) => {
  let rate = utmCurrencyObject.selling_rate || utmCurrencyObject.sellingRate;
  return (
    (value * (rate || utmCurrencyObject.sellingRate)) / utmCurrencyObject.rate
  );
};

export const getCurrencyFraction = (currency, currencyPrecisionMap = {}) => {
  return { minimumFractionDigits: 0 };
  
};

export function dataLayerSRPFlightValues({
  searchQuery,
  itinerary = {},
  confirmationData = {},
}) {
  try {
    const { country, currency } = getLanguageMeta();
    const emptyValue = "N/A";
    const utmDefault = emptyValue;
    const {
      travellers: { adults = 0, children = 0, infants = 0 } = {},
      source,
      destination,
      departDate,
      returnDate,
      isIntl,
      flightClass,
    } = searchQuery;
    const { flights = {} } = itinerary;
    const fullItinerary = [];
    let lastSegment = "";
    const bookingClass = [];
    const airLine = [];
    let segmentCount = 0;
    mapOnObject(flights, (sector) => {
      sector.segments.map((segment) => {
        airLine.push(segment.airlineName);
        if (lastSegment !== segment.departAirportIata) {
          fullItinerary.push(segment.departAirportIata);
        }
        fullItinerary.push(segment.arrivalAirportIata);
        lastSegment = segment.arrivalAirportIata;
        if (!bookingClass.includes(segment.cabinType)) {
          bookingClass.push(segment.cabinType);
        }
        segmentCount += 1;
        return segmentCount;
      });
      return sector.length;
    });
    const totalPax = adults + children + infants;
    segmentCount *= totalPax;
    readParam("utm_source", { expiry: 30 });
    readParam("utm_campaign", { expiry: 30 });
    readParam("utm_medium", { expiry: 30 });
    readParam("utm_term", { expiry: 30 });
    readParam("utm_uid", { expiry: 30 });
    let journeyType = emptyValue;
    if (returnDate) {
      journeyType = "Return";
    } else if (departDate) {
      journeyType = "One Way";
    }
    return {
      Event: "srp_loaded",
      Product: "Air",
      Domain: HOST,
      isAmendment:window.location.pathname == '/flights/amendments/search-results'?true:false,
      Country: country,
      Currency: currency,
      Language: "EN", // To be dynamic when arabic language supports
      "Device-Type": "Mobile",
      "From-Location": source || emptyValue,
      "To-Location": destination || emptyValue,
      "Journey-Type": journeyType,
      Category: isIntl ? "International" : "Domestic",
      "Adult-Count": adults || emptyValue,
      "Child-Count": adults ? children : emptyValue,
      "Infant-Count": adults ? infants : emptyValue,
      "Total-Passengers": totalPax || emptyValue,
      "Booking-Class": flightClass || emptyValue,
      "First-Departure-Date": departDate || emptyValue,
      "Final-Departure-Date": returnDate || emptyValue,
      "Date-Search": departDate ? convertDate(new Date(), "-") : emptyValue,
      "Day-Search": departDate
        ? FULL_NAMES_OF_DAYS[new Date().getDay()]
        : emptyValue,
      "Segment-Count": segmentCount || emptyValue,
      "Source-Affiliatefirstsource":
        readParam("Source-Affiliatefirstsource", {
          alias: "utm_source",
          expiry: 1,
        }) || utmDefault,
      "Campaign-Affiliatefirstsource":
        readParam("Campaign-Affiliatefirstsource", {
          alias: "utm_campaign",
          expiry: 1,
        }) || utmDefault,
      "Medium-Affiliatefirstsource":
        readParam("Medium-Affiliatefirstsource", {
          alias: "utm_medium",
          expiry: 1,
        }) || utmDefault,
      "Term-Affiliatefirstsource":
        readParam("Term-Affiliatefirstsource", {
          alias: "utm_term",
          expiry: 1,
        }) || utmDefault,
      "Uid-Affiliatefirstsource":
        readParam("Uid-Affiliatefirstsource", {
          alias: "utm_uid",
          expiry: 1,
        }) || utmDefault,
      "Source-Metafirstsource":
        readParam("Source-Metafirstsource", {
          alias: "utm_source",
          expiry: 30,
        }) || utmDefault,
    };
  } catch (e) {
    logError(e);
    return {};
  }
}
