import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../configureStore'
import { getEmiPrice } from 'src/api/emi'

const initialState = {
    emiCalloutMsg: {
        loading: false,
        loaded: false,
        data: {}
    },
    emiData: {},
    highRpsData: {},
    mevcData: {},
    mevcAirportData: {},
    splFareData: {},
    rtEmiData: {
        error: false,
        loading: false,
        data: {}
    }
}

export const fetchEmiEligibilityData = createAsyncThunk('emiEligibility/eligibility', async data => {
    //const response = await getEmiPrice(data?.payload);
    return {}
})

export const emiEligibilitySlice = createSlice({
    name: 'emiEligibility',
    initialState,
    reducers: {
        setEmiCalloutMsg: (state, action) => {
            state.emiCalloutMsg = action?.payload
        },
        setEmiData: (state, action) => {
            state.emiData = action?.payload
        },
        setHighRpsEmi: (state, action) => {
            state.highRpsData = action?.payload
        },
        setMevcEmi: (state, action) => {
            state.mevcData = action?.payload
        },
        setMevcAirportEmi: (state, action) => {
            state.mevcAirportData = action?.payload
        },
        setSplFareEmi: (state, action) => {
            state.splFareData = action?.payload
        },
        setRtEmiData: (state, action) => {
            state.rtEmiData = action?.payload
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchEmiEligibilityData.pending, (state, action) => {
            let { meta: { arg = {} } = {} } = action || {}
            if (arg.type === 'emiCallout') {
                state.emiCalloutMsg.loading = true
            } else if (arg.type === 'emiRtPrice') {
                state.rtEmiData.isLoading = true
            } else {
                const { finalDataObj, fareTypes } = arg
                fareTypes.forEach(type => {
                    if (type === 'regular') {
                        state.emiData = {
                            ...state.emiData,
                            ...finalDataObj?.[type]
                        }
                    }
                    if (type === 'splFare') {
                        state.splFareData = {
                            ...state.splFareData,
                            ...finalDataObj?.[type]
                        }
                    }
                    if (type === 'highRps') {
                        state.highRpsData = {
                            ...state.highRpsData,
                            ...finalDataObj?.[type]
                        }
                    }
                    if (type === 'mevc') {
                        state.mevcData = {
                            ...state.mevcData,
                            ...finalDataObj?.[type]
                        }
                    }
                    if (type === 'mevcAirport') {
                        state.mevcAirportData = {
                            ...state.mevcAirportData,
                            ...finalDataObj?.[type]
                        }
                    }
                })
            }
        })
        builder.addCase(fetchEmiEligibilityData.fulfilled, (state, action) => {
            let { meta: { arg = {} } = {} } = action || {}
            if (arg.type === 'emiCallout') {
                handleEmiCallout(state, action)
            } else if (arg.type === 'emiRtPrice') {
                handleRtEmiPrice(state, action)
            } else {
                hadleOwTupleEmiPrice(state, action)
            }
        })
        builder.addCase(fetchEmiEligibilityData.rejected, (state, action) => {
            let { meta: { arg: { type = '' } = {} } = {} } = action || {}
            if (type === 'emiRtPrice') {
                state.rtEmiData.error = true
            } else {
                state = {
                    emiCalloutMsg: {
                        loading: false,
                        loaded: false,
                        data: {}
                    },
                    emiData: {
                        error: true
                    }
                }
            }
        })
    }
})

const handleEmiCallout = (state, action) => {
    if (
        action.payload &&
        action.payload.data &&
        action.payload.data.data &&
        action.payload.data.data.call_out_attributes &&
        action.payload.data.data.call_out_attributes[0]
    ) {
        state.emiCalloutMsg = {
            loading: false,
            loaded: true,
            data: action.payload.data.data
        }
    } else {
        state.emiCalloutMsg = {
            loading: false,
            loaded: false,
            data: {}
        }
    }
}

const handleRtEmiPrice = (state, action) => {
    if (action?.payload?.data?.data?.flight_list) {
        state.rtEmiData = action?.payload?.data?.data?.flight_list[0]
    } else {
        state.rtEmiData.error = true
    }
}

const hadleOwTupleEmiPrice = (state, action) => {
    let { meta: { arg = {} } = {} } = action || {}
    if (action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.flight_list) {
        let flightList = action.payload.data.data.flight_list
        let index = 0
        let dataObj = {}
        const { finalUuidList, fareTypes = [] } = arg
        fareTypes.forEach((type = '') => {
            dataObj = {}
            finalUuidList?.[type]?.forEach((item = {}) => {
                if (item.uuid === (flightList[index] && flightList[index].uuid)) {
                    dataObj[item.id] = {
                        isLoading: false,
                        ...flightList[index]
                    }
                } else {
                    dataObj[item.id] = {}
                }
                index += 1
            })
            if (type === 'regular') {
                state.emiData = {
                    ...state.emiData,
                    ...dataObj
                }
            }
            if (type === 'splFare') {
                state.splFareData = {
                    ...state.splFareData,
                    ...dataObj
                }
            }
            if (type === 'highRps') {
                state.highRpsData = {
                    ...state.highRpsData,
                    ...dataObj
                }
            }
            if (type === 'mevc') {
                state.mevcData = {
                    ...state.mevcData,
                    ...dataObj
                }
            }
            if (type === 'mevcAirport') {
                state.mevcAirportData = {
                    ...state.mevcAirportData,
                    ...dataObj
                }
            }
        })
    } else {
        state.emiData = {
            error: true
        }
    }
}

const getEmiCalloutMsg = (state: RootState) => state.emiEligibility.emiCalloutMsg

const getEmiData = (state: RootState) => state.emiEligibility.emiData

const getHighRpsEmiData = (state: RootState) => state.emiEligibility.highRpsData

const getMevcEmiData = (state: RootState) => state.emiEligibility.mevcData

const getMevcAirportEmiData = (state: RootState) => state.emiEligibility.mevcAirportData

const getSplFareEmiData = (state: RootState) => state.emiEligibility.splFareData

const getRtEmiData = (state: RootState) => state.emiEligibility.rtEmiData

const { reducer, actions } = emiEligibilitySlice

const { setEmiData, setEmiCalloutMsg, setRtEmiData, setHighRpsEmi, setMevcEmi, setMevcAirportEmi, setSplFareEmi } =
    actions

export {
    getEmiCalloutMsg,
    getEmiData,
    getHighRpsEmiData,
    getMevcEmiData,
    getMevcAirportEmiData,
    getSplFareEmiData,
    getRtEmiData,
    setEmiData,
    setRtEmiData,
    setEmiCalloutMsg,
    setHighRpsEmi,
    setMevcEmi,
    setMevcAirportEmi,
    setSplFareEmi
}

export default reducer
