import { css, CSSObject } from 'styled-components';
import { Theme } from '../../../../token/theme';
import { StyledFlatTabProps } from './type';

export const getStyledFlatTabStyles = ({
	theme,
	borderBottomColor,
	borderBottomWidth,
	width,
}: StyledFlatTabProps & {
	theme: Theme;
}) => {
	return css`
		padding-top: ${theme.spacing[2]};
		padding-bottom: ${theme.spacing[2]};
		padding-left: ${theme.spacing[2]};
		padding-right ${theme.spacing[2]};
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		width: ${width};

		&::after {
			content: '';
			width: 100%;
			position: absolute;
			bottom: 0px;
			border-bottom-width: ${borderBottomWidth};
			border-bottom-color: ${borderBottomColor};
			border-top-left-radius: ${theme.border.radius[16]};
			border-top-right-radius: ${theme.border.radius[16]};
			border-bottom-style: ${theme.border.style.solid};
	`;
};
