import React from 'react';

const Hidden = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
            <mask
                id='mask0_2279_22006'
                style={{ maskType: 'alpha' }}
                width='16'
                height='16'
                x='0'
                y='0'
                maskUnits='userSpaceOnUse'
            >
                <path fill='#D9D9D9' d='M0 0H16V16H0z'></path>
            </mask>
            <g mask='url(#mask0_2279_22006)'>
                <path
                    fill='#fff'
                    d='M8 10.666c.833 0 1.542-.292 2.125-.875A2.895 2.895 0 0011 7.666c0-.833-.292-1.542-.875-2.125A2.895 2.895 0 008 4.666c-.833 0-1.542.292-2.125.875A2.895 2.895 0 005 7.666c0 .833.291 1.542.875 2.125A2.895 2.895 0 008 10.666zm0-1.2c-.5 0-.925-.175-1.275-.525A1.734 1.734 0 016.2 7.666c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525.5 0 .925.175 1.275.525.35.35.525.775.525 1.275 0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525zm0 3.2c-1.622 0-3.1-.453-4.433-1.359-1.334-.905-2.3-2.119-2.9-3.641.6-1.522 1.566-2.736 2.9-3.642A7.72 7.72 0 018 2.666c1.622 0 3.1.453 4.433 1.358 1.334.906 2.3 2.12 2.9 3.642-.6 1.522-1.566 2.736-2.9 3.641A7.717 7.717 0 018 12.666zm0-1.333c1.255 0 2.408-.331 3.459-.992a6.514 6.514 0 002.408-2.675 6.518 6.518 0 00-2.409-2.675A6.366 6.366 0 008 3.999c-1.256 0-2.409.33-3.459.992a6.518 6.518 0 00-2.408 2.675 6.514 6.514 0 002.408 2.675A6.363 6.363 0 008 11.333z'
                ></path>
            </g>
        </svg>
    );
};

export default Hidden;
