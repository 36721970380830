import type { CSSObject } from 'styled-components';
import { CSSWithTheme } from '../../../@types';

export const getBackdropStyles = ({ blur }: CSSWithTheme & { blur?: boolean}): CSSObject=>({
    position: 'fixed', 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: blur ? 'rgba(26, 26, 26, 0.6);' : '',
    backdropFilter: blur ?'blur(1px)' : 'none',
    zIndex: -1,
    inset: 0,
})