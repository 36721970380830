import React from 'react';
import styled, { css } from 'styled-components';
import { BaseToggleSwitchProps, ButtonSize } from './type';

export const ParentLayer = styled.div<BaseToggleSwitchProps>`
  ${({ theme }) => {
    return css`
      background: ${theme.color.border.defaultDark};
      border-radius: ${theme.size[5]};
      border: 1px solid ${theme.color.border.default};
    `
  }}
  transition: all ease-in-out 0.3s;
  position: relative;
  ${({ theme, value }) => {
    return value
      ? css`
          background: ${theme.color.background.primary};
          left: 1px;
        `
      : css`
          left: 1px;
        `
  }}
  ${({ theme, size }) => {
    return css`
      min-width: ${size === ButtonSize.LARGE ? theme.size[10] : theme.size[9]};
      width: ${size === ButtonSize.LARGE ? theme.size[11] : theme.size[9]};
      height: ${size === ButtonSize.LARGE ? theme.size[7] : theme.size[5]};
    `
  }}
	${({ theme, disabled }) => {
    return (
      disabled &&
      css`
        background: ${theme.color.background.disabled};
      `
    )
  }}
`

export const Label = styled.label<BaseToggleSwitchProps>`
  ${({ theme }) => {
    return css`
      background: ${theme.color.background.neutral};
    `
  }}
  position: absolute;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  top: 1.5px;
  left: 1px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ value }) => {
    return (
      value &&
      css`
        left: 18px;
      `
    )
  }}
  ${({ theme, size }) => {
    return css`
      height: ${size === ButtonSize.LARGE ? "23px" : "18px"};
      width: ${size === ButtonSize.LARGE ? "23px" : "18px"};
    `
  }}
`

export const Input = styled.input`
	display: none;
`;