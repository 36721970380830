import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../configureStore'
import { isFkEnv } from '@root/configs/Whitelabel'

export interface UltraDataState {
    userDetails: {
        email: string
        fullName: string | null
        phoneNumber: string
    }
    isUserDetailsApiLoading: boolean
    isUserDetailsSuccess: boolean
}
const initialState: UltraDataState = {
    userDetails: {
        email: '',
        fullName: '',
        phoneNumber: ''
    },
    isUserDetailsApiLoading: isFkEnv,
    isUserDetailsSuccess: false
}

export const ultraDataSlice = createSlice({
    name: 'ultra',
    initialState,
    reducers: {
        setUserDetails: (state, action) => {
            state.userDetails = action.payload
            state.isUserDetailsApiLoading = false
            state.isUserDetailsSuccess = true
        },
        setUserDetailsApiLoadingState: (state, action) => {
            state.isUserDetailsApiLoading = action.payload
            state.isUserDetailsSuccess = false
        }
    }
})

const getUserDetails = (state: RootState) => state.ultra.userDetails
const getUserDetailsApiLoadingStatus = (state: RootState) => state.ultra.isUserDetailsApiLoading
const getUserDetailsApiSuccessStatus = (state: RootState) => state.ultra.isUserDetailsSuccess

const { reducer, actions } = ultraDataSlice
const { setUserDetails, setUserDetailsApiLoadingState } = actions || {}

export {
    setUserDetails,
    setUserDetailsApiLoadingState,
    getUserDetails,
    getUserDetailsApiLoadingStatus,
    getUserDetailsApiSuccessStatus
}

export default reducer
