export const FLIGHT_NOT_AVAILABLE = 'FLIGHT_NOT_AVAILABLE'
export const PRICE_CHANGED_SEATSELL1 = 'PRICE_CHANGED_SEATSELL1'
export const PRICE_CHANGED = 'PRICE_CHANGED'
export const INFO = 'info'
export const ADDONS = 'addons'
export const VAT = 'vat'
export const TRAVELLERS = 'travellers'
export const BOOKCONFIRM = 'bookconfirm'
export const DETAILS = 'details'
export const SEAT = 'SEAT'
export const MEAL = 'MEAL'
export const BAGGAGE = 'BAGGAGE'

export const FARE_PRICE = 'FARE_PRICE'
export const BASE_PRICE = 'BASE_PRICE'
export const TAXES = 'TAXES'
export const CONV_FEE = 'CONV_FEE'
export const INSURANCE_PRICE = 'INSURANCE_PRICE'
export const SEAT_PRICE = 'SEAT_PRICE'
export const MEAL_PRICE = 'MEAL_PRICE'
export const BAGGAGE_PRICE = 'BAGGAGE_PRICE'
export const SUPPLIER_DISCOUNT = 'SUPPLIER_DISCOUNT'

export const COUPON_DISCOUNT = 'COUPON_DISCOUNT'
export const GV_DISCOUNT = 'GV_DISCOUNT'

export const POPULAR_COUNTRIES_HEADING = 'Top Searches'

export const onlyNumbersRegex = /^[0-9]+$/

export const MAX_ADULT_SELECTION = 9
export const MIN_ADULT_SELECTION = 1

export const MAX_AGE_INFANT = 2
export const MAX_AGE_CHILDREN = 12

export const flightErrorStatus: any = {
    PRICE_CHANGED_SEATSELL1: 'PRICE_CHANGED_SEATSELL1',
    PRICE_CHANGED: 'PRICE_CHANGED',
    FLIGHT_NOT_AVAILABLE: 'FLIGHT_NOT_AVAILABLE',
    AIRPORT_CHANGE: 'AIRPORT_CHANGE',
    HOLD: 'HOLD'
}
