import { CLEARTRIP, FLIPKART } from '.'

const FeatureKeys = {
    [FLIPKART]: FLIPKART,
    [CLEARTRIP]: CLEARTRIP
} as const

type FeatureConfig = {
    [key in keyof typeof FeatureKeys]: {
        FLIGHT_DETAILS: boolean
        FARE_CARDS: boolean
        GIFT_VOUCHER: boolean
        MEDICANCEL: boolean
        SUPERCOINS: boolean
        CLEAR_ADVANTAGE: boolean
    }
}

export const featureConfig: FeatureConfig = {
    [FeatureKeys.FLIPKART]: {
        FLIGHT_DETAILS: true,
        FARE_CARDS: true,
        GIFT_VOUCHER: false,
        MEDICANCEL: false,
        SUPERCOINS: false,
        CLEAR_ADVANTAGE: false
    },
    [FeatureKeys.CLEARTRIP]: {
        FLIGHT_DETAILS: true,
        FARE_CARDS: true,
        GIFT_VOUCHER: true,
        MEDICANCEL: true,
        SUPERCOINS: true,
        CLEAR_ADVANTAGE: true
    }
} as FeatureConfig
