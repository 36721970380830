import React from 'react';

const Pen = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'>
            <path
                fill='#1A1A1A'
                d='M3.094 10.904h.766l4.72-4.726-.766-.767-4.72 4.726v.767zm7.825-5.52L8.593 3.082l.766-.767c.21-.21.468-.315.774-.315.305 0 .562.105.772.315l.766.767c.21.21.32.464.328.76a.99.99 0 01-.3.76l-.78.782zm-.794.808L4.325 12H2V9.671l5.8-5.808 2.325 2.329z'
            ></path>
        </svg>
    );
};

export default Pen;
