import { CSSProperties } from 'styled-components';
import type { HtmlCompositeAttributes } from '../../@types';
import { ComponentType, ReactNode, ReactElement, FunctionComponent, ComponentClass } from 'react';

export enum ModalSize {
	SMALL = 'SMALL',
	MEDIUM = 'MEDIUM',
	LARGE = 'LARGE',
	FULL_SCREEN = 'FULL SCREEN',
	NONE = 'NONE'
}

export type ModalSizeTypes = `${ModalSize}`;

type OwnModalProps = {
	/**
	 * show modal on portal
	 */
	insidePortal?: boolean;
	/**
	 * expected width of modal from these enums
	 * @default sm
	 */
	width?: string;
	/**
	 * event callback for close dialog
	 */
	onClose?: (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => void;
	/**
	 * open will show modal
	 * helps in case of mounted component
	 */
	open?: boolean;
	/**
	 * show backdrop
	 */
	blur?: boolean;
	/**
	 * Allow backdrop click to call on close function
	 */
	allowBackdropClose?: boolean;
	/**
	 * backdrop component
	 * required if we want to have gradient or custom backdrop
	 */
	backDropComponent?: ReactNode;
	/**
	 * apply positioning of root component of modal
	 */
	rootStyles?: CSSProperties;
	/**
	 * apply box styles for changing backgrouund color or padding
	 */
	boxStyles?: CSSProperties;
	/**
	 * apply backdrop filter
	 */
	backdropStyles?: CSSProperties;
	/**
	 * hide cross icon
	 */
	hideCrossIcon?: boolean;
	/**
	 * size
	 */
	size?: ModalSizeTypes;
	/**
	 * footerAlignment will give whether to give flex end or start or start
	 */
	footerAlignment?: 'start' | 'end' | 'center';
};

export interface ModalProps extends HtmlCompositeAttributes<OwnModalProps, 'Div'>, OwnModalProps {}
