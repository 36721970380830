import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../configureStore"
import { getMerchandisingDetails } from "@api/resultsPage"

export interface MerchandisingDataState {
  merchandisingResponse?: Record<string, any>
}
const initialState: MerchandisingDataState = {
  merchandisingResponse: {},
}

export const fetchMerchandisingData = createAsyncThunk(
  "merchandising/fetchMerchandisingData",
  async (payload) => {
    try {
      const response = await getMerchandisingDetails(payload)
      return response
    } catch (e) {
      return {}
    }
  }
)

export const merchandisingDataSlice = createSlice({
  name: "merchandising",
  initialState,
  reducers: {
    setMerchadisingData: (state, action) => {
      state.merchandisingResponse.data = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchMerchandisingData.fulfilled, (state, action) => {
      state.merchandisingResponse = action.payload || {}
    })
    builder.addCase(fetchMerchandisingData.rejected, (state, action) => {
      state.merchandisingResponse = action.payload
    })
    builder.addCase(fetchMerchandisingData.pending, (state, action) => {
      state.merchandisingResponse = {}
    })
  },
})
const getMerchandisingResponse = (state: RootState) =>
  state.merchandising.merchandisingResponse
const getMerchandisingResponseData = (state: RootState) =>
  state.merchandising.merchandisingResponse?.data

const { reducer, actions } = merchandisingDataSlice
const { setMerchadisingData } = actions || {}

export {
  setMerchadisingData,
  getMerchandisingResponse,
  getMerchandisingResponseData,
}

export default reducer
