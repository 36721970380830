import { CSSObject } from 'styled-components';
import { Theme } from '../../../token/theme';
import { StyledCounterProps } from './type';

export const getStyledCounterStyles = ({
	backgroundColor,
	theme,
}: StyledCounterProps & { theme: Theme }): CSSObject => {
	return {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: theme.size[4],
		width: theme.size[4],
		borderRadius: theme.border.radius['50P'],
		backgroundColor,
	};
};
