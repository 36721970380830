import React, { ReactNode, useContext } from 'react';
import Container from '../../Container';
import { ModalProps } from '../type';
import ModalContext from '../ModalContext';

const ModalAction: React.FunctionComponent<Pick<ModalProps, 'footerAlignment'> & { children: ReactNode}> = ({
	children,
}) => {
    const { footerAlignment='end' } = useContext(ModalContext)
    const alignment = {
        start: 'flex-start',
        end: 'flex-end',
        center: 'center'
    }[footerAlignment];

	return (
		<Container display={'flex'} justifyContent={alignment}>
			{children}
		</Container>
	);
};

export default ModalAction;
