import React from 'react';

interface CrossProps {
	height?: number;
	width?: number;
	crossColor?: string;
}

const Cross: React.FC<CrossProps> = ({ height = 9, width = 9, crossColor}) => {
	return (
		<svg width={width} height={height} viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M7 1L4 4M4 4L1 7M4 4L1 1M4 4L7 7'
				stroke={crossColor}
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
};

export default Cross;