import React, { MouseEvent } from 'react';
import { Typography, Container } from '../';
import { StyledChip } from './StyledChip';
import { StyledCounter } from './StyledCounter';
import useTheme from '../../hooks/useTheme';
import { getChipLabelColor, getChipStyles, getCounterStyles } from './style';
import { ChipProps } from './type';
import { IconPosition } from './../../../ct-design/@types';

import { Cross } from './../../assets/svg';

const Chip: React.FC<ChipProps> = ({
	label,
	showIcon = false,
	iconPosition = IconPosition.LEFT,
	Icon = null,
	showCounter = false,
	count = 0,
	isDismissible = false,
	isSelected = false,
	isDisabled = false,
	onClick,
	onDismiss = undefined,
	id,
	textPaddingLeft,
	textPaddingRight,
	dataTestId = "",
	showLabel = true
}) => {
	const theme = useTheme();
	const showTopIcon = showIcon && iconPosition === IconPosition.TOP;
	const chipStyles = getChipStyles({ isSelected, isDisabled, showTopIcon, theme });
	const counterStyles = getCounterStyles({ theme });

	const onCrossClick = (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		if (onDismiss) {
			onDismiss();
		}
	};

	return (
		<StyledChip id={id} {...chipStyles} data-testid={dataTestId ? dataTestId : undefined} onClick={isDisabled ? undefined : onClick}>
			{showIcon && iconPosition === IconPosition.TOP && (
				<Container display='flex' alignItems='center' marginBottom={theme.spacing[1]}>
					{Icon}
				</Container>
			)}
			<Container display='flex' alignItems='center' justifyContent='center'>
				{showIcon && iconPosition === IconPosition.LEFT && (
					<Container
						display='flex'
						alignItems='center'
						justifyContent='center'
						paddingRight={theme.spacing[1]}
					>
						{Icon}
					</Container>
				)}
				{showLabel && <Container paddingLeft={textPaddingLeft || theme.spacing[1]} paddingRight={textPaddingRight || theme.spacing[1]}>
					<Typography variant='B2' colorCode={getChipLabelColor({ isSelected, isDisabled, theme })}>
						{label}
					</Typography>
				</Container>}
				{showIcon && iconPosition === IconPosition.RIGHT && (
					<Container
						display='flex'
						alignItems='center'
						justifyContent='center'
						paddingLeft={theme.spacing[1]}
					>
						{Icon}
					</Container>
				)}
				{showCounter && (
					<Container paddingLeft={theme.spacing[1]}>
						<StyledCounter {...counterStyles}>
							<Typography variant='TAG' color='neutral'>
								{count}
							</Typography>
						</StyledCounter>
					</Container>
				)}
				{isDismissible && (
					<Container
						paddingLeft={theme.spacing[1]}
						display='flex'
						alignItems='center'
						justifyContent='center'
						onClick={onCrossClick}
					>
						<Cross
							crossColor={
								isSelected
									? theme.color.chip.selectedPrimaryLabel
									: theme.color.chip.nonSelectedPrimaryLabel
							}
						/>
					</Container>
				)}
			</Container>
		</StyledChip>
	);
};

export default Chip;
