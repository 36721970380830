import { configureStore, ThunkAction, Action, ThunkDispatch, AnyAction } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import { HYDRATE, createWrapper } from 'next-redux-wrapper'

const masterReducer = (state: any, action: any) => {
    if (action.type === HYDRATE) {
        const { payload } = action
        // const nextState = {      ...state,      itinerary: {        ...state.itinerary,        itineraryData:{          ...state.itinerary.itineraryData,          ...action.payload.itinerary.itineraryData        }        // loadingState: { ANCILLARY: true, INSURANCES: true }      }    }
        return {
            ...state,
            ...payload,
            itinerary: {
                ...state.itinerary,
                ...state.payload,
                itineraryData: {
                    ...state.itinerary.itineraryData,
                    ...action.payload.itinerary.itineraryData
                },
                itineraryId: action.payload.itinerary.itineraryId
            },
            result: {
                ...state.result.resultResponse,
                resultResponse: {
                    ...state.result.resultResponse,
                    ...action.payload.result.resultResponse
                },
                loading: action.payload.result.loading
            },
            abConfig: {
                ...state.abConfig,
                abConfigData: {
                    ...state.abConfig.abConfigData
                    // ...action.payload.abConfig.abConfigData
                }
            },
            srpSeachData: {
                ...state.srpSeachData,
                searchData: {
                    ...state.srpSeachData.searchData,
                    ...payload.srpSeachData.searchData
                },
                isLoading: state.srpSeachData.isLoading,
                srpError: payload.srpSeachData.srpError,
                isServicesLoading: state.srpSeachData.isServicesLoading,
                servicesData: {
                    ...state.srpSeachData.servicesData,
                    ...payload.srpSeachData.servicesData,
                    benefits: {
                        ...state.srpSeachData.servicesData.benefits,
                        ...payload.srpSeachData.servicesData.benefits
                    },
                    baggageAllowances: {
                        ...state.srpSeachData.servicesData.baggageAllowances,
                        ...payload.srpSeachData.servicesData.baggageAllowances
                    },
                    cardIdToBenefitsMap: {
                        ...state.srpSeachData.servicesData.cardIdToBenefitsMap,
                        ...payload.srpSeachData.servicesData.cardIdToBenefitsMap
                    }
                },
                couponData: {
                    ...state.srpSeachData.couponData,
                    ...payload.srpSeachData.couponData
                }
            },
            oldItineraryData: {
                ...state.oldItineraryData
            },
            ui:{
                ...state.ui 
            }            
        }
    } else {
        return rootReducer(state, action)
    }
}

export const configureAppStore = (initialState = {}) => {
    const store = configureStore({
        reducer: masterReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: {
                    // Ignore these field paths in all actions
                    ignoredActionPaths: ['payload.headers', 'payload.config', 'payload.request']
                }
            }),
        preloadedState: initialState,
        devTools: true
    })

    return store
}

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>

export const store = configureAppStore()
export const wrapper = createWrapper(configureAppStore)
