import React from 'react';
import useTheme from '../../hooks/useTheme';
import { Typography, Container } from '..';
import { getButtonStyles, getButtonVariantLabelColor, getTypographyVariant } from './style';
import StyledButton from './StyledButton/StyledButton';
import { ButtonColor, ButtonProps, ButtonSize, ButtonVariant } from './type';
import Dots from '@components/Dots'

const Button: React.FC<ButtonProps> = ({
    children = null,
    size = ButtonSize.MEDIUM,
    variant = ButtonVariant.CONTAINED,
    color = ButtonColor.PRIMARY,
    className = '',
    isFullWidth = false,
    disabled = false,
    onClick = () => {},
    minWidth = '',
    showLeftIcon = false,
    LeftIcon = null,
    showRightIcon = false,
    RightIcon = null,
    onLeftIconClick = () => {},
    onRightIconClick = () => {},
    isLoading = false,
    maxWidth = '',
    dataTestId = ''
}) => {
    const theme = useTheme()
    const buttonStyles = getButtonStyles({
        theme,
        size,
        variant,
        color,
        disabled,
        minWidth,
        isFullWidth,
        maxWidth
    })

    return (
        <StyledButton data-testid={dataTestId ? dataTestId : undefined} className={className} onClick={disabled ? undefined : onClick} {...buttonStyles}>
            {!isLoading && showLeftIcon && (
                <Container paddingRight={theme.spacing[1]} display="flex" alignItems="center" justifyContent="center">
                    <div className="flex flex-middle" onClick={onLeftIconClick}>
                        {LeftIcon}
                    </div>
                </Container>
            )}
            <Container paddingLeft={theme.spacing[1]} paddingRight={theme.spacing[1]}>
                <Typography
                    variant={getTypographyVariant(size)}
                    colorCode={getButtonVariantLabelColor(variant, color, disabled, theme)}
                >
                    {isLoading && <Dots variant={`${variant === 'contained' ? 'light' : 'dark'}`} />}
                    {!isLoading && children}
                </Typography>
            </Container>
            {!isLoading && showRightIcon && (
                <Container paddingLeft={theme.spacing[1]} display="flex" alignItems="center" justifyContent="center">
                    <div className="flex flex-middle" onClick={onRightIconClick}>
                        {RightIcon}
                    </div>
                </Container>
            )}
        </StyledButton>
    )
}

export default Button;
