import { Theme } from '.';
import colors from '../global/color';
import BaseTheme from './BaseTheme';

const B2BTheme: Theme = {
	...BaseTheme,
	color: {
		...BaseTheme.color,
		text: {
			...BaseTheme.color.text,
			secondary: colors.link2,
			tertiary: colors.brand,
		},
		button: {
			outlinedPrimaryLabel: colors.link2,
			outlinedPrimaryBorder: colors.link2,
			outlinedPrimaryBg: colors.neutral100,
			outlinedSecondaryLabel: colors.neutral900,
			outlinedSecondaryBorder: colors.neutral900,
			outlinedSecondaryBg: colors.neutral100,
			outlinedTertiaryLabel: colors.brand,
			outlinedTertiaryBorder: colors.brand,
			outlinedTertiaryBg: colors.neutral100,
			outlinedDisabledLabel: colors.disabledText,
			outlinedDisabledBg: colors.neutral300,
			containedPrimaryLabel: colors.neutral100,
			containedPrimaryBg: colors.link2,
			containedSecondaryLabel: colors.neutral100,
			containedSecondaryBg: colors.neutral900,
			containedTertiaryLabel: colors.neutral100,
			containedTertiaryBg: colors.brand,
			containedDisabledLabel: colors.disabledText,
			containedDisabledBg: colors.neutral300,
		},
		chip: {
			...BaseTheme.color.chip,
			selectedPrimaryLabel: colors.link2,
			selectedPrimaryBorder: colors.link2,
			selectedPrimaryBg: colors.linkBg,
		},
		tab: {
			...BaseTheme.color.tab,
			nonSelectedPrimaryLabel: colors.neutral900,
			selectedPrimaryLabel: colors.link2,
		},
		background: {
			...BaseTheme.color.background,
			primary: colors.link2,
			secondary: colors.neutral900,
			tertiary: colors.brand,
		},
		border: {
			...BaseTheme.color.border,
			primary: colors.link2,
			secondary: colors.neutral900,
			tertiary: colors.brand,
		},
	},
};

export default B2BTheme;
