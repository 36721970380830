import React from 'react';

interface CrossProps {
    height?: number;
    width?: number;
    crossColor?: string;
}

const Cross: React.FC<CrossProps> = ({
  height,
  width,
  crossColor,
  fill,
  stroke,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={`${fill ? fill : "none"}`}
      viewBox="0 0 24 24"
    >
      <path
        stroke={stroke ? stroke : crossColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18 6l-6 6m0 0l-6 6m6-6L6 6m6 6l6 6"
      ></path>
    </svg>
  )
}

Cross.defaultProps = {
	height: 18,
	width: 18,
	crossColor: "#3366CC"
}

export default Cross;
