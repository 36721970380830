import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../configureStore"
import { flightFareMiniRulePostApi } from "@api/resultsPage/flightDetailsApi"

export interface FareRulesDataState {
  fareRulesResponse?: Record<string, any>
}
const initialState: FareRulesDataState = {
  fareRulesResponse: {},
}

export const fetchFareRulesData = createAsyncThunk(
  "fareRules/fetchFareRulesData",
  async (payload) => {
    try {
      const response = await flightFareMiniRulePostApi(payload)
      return response
    } catch (e) {
      return {}
    }
  }
)

export const fareRulesDataSlice = createSlice({
  name: "fareRules",
  initialState,
  reducers: {
    setFareRulesData: (state, action) => {
      state.fareRulesResponse.data = {
        ...action.payload,
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchFareRulesData.fulfilled, (state, action) => {
      state.fareRulesResponse = action.payload || {}
    })
    builder.addCase(fetchFareRulesData.rejected, (state, action) => {
      state.fareRulesResponse = action.payload
    })
    builder.addCase(fetchFareRulesData.pending, (state, action) => {
      state.fareRulesResponse = {}
    })
  },
})
const getFareRulesResponse = (state: RootState) =>
  state.fareRules.fareRulesResponse
const getFareRulesResponseData = (state: RootState) =>
  state.fareRules.fareRulesResponse?.data

const { reducer, actions } = fareRulesDataSlice
const { setFareRulesData } = actions || {}

export { setFareRulesData, getFareRulesResponse, getFareRulesResponseData }

export default reducer
