import { httpAxios, apiGetHelper } from '@utils/helper'
import {
    NEW_FETCH_TRIP_FAQS,
    NEW_FETCH_TRIP_STATUS,
    FETCH_TRIP_PROMO_DETAIL,
    REFUND_STATUS,
    FETCH_TRIPS_DETAILS_V3
} from '@api/trips/tripConstants'

export const fetchTripDetails = async (tripId: string) => {
    const url = `${FETCH_TRIPS_DETAILS_V3}${tripId}`
    const newPromise = await apiGetHelper(url, false, {})
    return newPromise
}

export const fetchTripFAQ = (tripId = null) => {
    const url = tripId ? `${NEW_FETCH_TRIP_FAQS}${tripId}` : `${NEW_FETCH_TRIP_FAQS}`
    const newPromise = new Promise((resolve, reject) => {
        httpAxios(url)
            .then(res => {
                let response = null
                if (res && res.status === 200) {
                    response = res.data
                }
                resolve(response)
            })
            .catch(err => reject(err))
    })
    return newPromise
}

export const fetchTripStatus = (tripId = null) => {
    const url = tripId ? `${NEW_FETCH_TRIP_STATUS}${tripId}` : `${NEW_FETCH_TRIP_STATUS}`
    const newPromise = new Promise((resolve, reject) => {
        httpAxios(url)
            .then(res => {
                let response = null
                if (res && res.status === 200) {
                    response = res.data
                }
               
                resolve(response)
            })
            .catch(err => reject(err))
    })
  
    return newPromise
}

export const fetchTripRefundStatus = (tripId = null) => {
    const url = tripId ? `${REFUND_STATUS}${tripId}` : `${REFUND_STATUS}`
    const newPromise = new Promise((resolve, reject) => {
        httpAxios(url)
            .then(res => {
                console.log(res, 'response-url')
                let response = null
                if (res && res.status === 200) {
                    response = res?.data
                }
               
                resolve(response)
            })
            .catch(err => reject(err))
    })
  
    return newPromise
}

export const fetchPromoInfo = tripId => {
    const url = `${FETCH_TRIP_PROMO_DETAIL}${tripId}?promoType=SUPERCOINS`
    const newPromise = new Promise((resolve, reject) => {
        httpAxios(url)
            .then(res => {
                let response = null
                if (res && res.status === 200) {
                    response = res.data
                }
                resolve(response)
            })
            .catch(err => reject(err))
    })
    return newPromise
}

export const getAuthenticated = async () => {
    let URL = `/communication/selfcare/api/authenticate-content`
    const response = apiGetHelper(URL, false, {}, { caller: `${window.location.host}` })
    return response
}

export const getTripDetailsByUrl = async (URL='/', contentType='', requestHeaders={})=>{
    const newPromise = await apiGetHelper(URL, contentType, requestHeaders)
    return newPromise
}
