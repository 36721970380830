import { isServer } from '@utils/appAgent'

function checkApmError(err: any) {
    return err && err.message && err.message.indexOf('Script error') !== -1 ? true : false
}

export default function logError(e: any) {
    const errorObject = {
        result: 'error',
        errorStack: e.stack,
        rootURL: window.location.href,
        errorMessage: e.message
    }

    setTimeout(() => {
        // eslint-disable-next-line
        if (!isServer()) {
            if (typeof newrelic == 'object') {
                newrelic.noticeError(e);
                newrelic.addPageAction('error_log', {
                    ...errorObject
                })
            }
        }
        console.error(e)
    })
}
