import React from 'react';
import useTheme from '../../hooks/useTheme';
import StyledCard from './StyledCard';
import { getCardStyles } from './style';
import { CardBorderDirection, CardBorderWidth, CardProps, CardRadius, CardShadowDirection, CardShadowVariant } from './type';

const Card: React.FC<CardProps> = ({
	children,
	cornerRadius = CardRadius.MEDIUM,
    borderWidth = CardBorderWidth.MEDIUM,
	showShadow = true,
	shadowDirection = CardShadowDirection.TOP,
	shadowVariant = CardShadowVariant.E2,
	borderDirection = CardBorderDirection.DEFAULT,
	className,
}) => {
    const theme = useTheme();
	const cardStyles = getCardStyles({ cornerRadius, borderWidth, showShadow, shadowDirection, shadowVariant, borderDirection, theme });

	return <StyledCard {...cardStyles} className={className}>{children}</StyledCard>;
};

export default Card;
