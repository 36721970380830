export default class UtmCurrency {
  _utmCurrencyObject = null;
  _utmCurrencyShowNote = false;
  static get utmCurrency() {
    return this._utmCurrencyObject;
  }
  static set utmCurrency(object) {
    this._utmCurrencyObject = object;
    if (object) utmSetCookie(object);
  }
  static get utmCurrencyNote() {
    return this._utmCurrencyShowNote;
  }
  static set utmCurrencyNote(utmShowNote) {
    this._utmCurrencyShowNote = utmShowNote;
  }
  static get currencyPrecision() {
    return this._currencyPrecision;
  }
  static set currencyPrecision(precisionMap) {
    this._currencyPrecision = precisionMap;
  }
}
