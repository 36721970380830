import { isEmpty } from '@utils/genralUtils'
import { BAGGAGE, MEAL, SEAT, INSURANCE } from '@utils/itinerary/addons'

export function checkIfAnyAncilliarySelected(addonsData, type, insuranceData) {
    let setFlag = false
    addonsData?.userSelectedAncillaries?.userSelectedJourneyAncillaries?.forEach((journeyItem: any) => {
        switch (type) {
            case SEAT:
                journeyItem?.userSelectedSegmentAncillaries?.forEach((segmentItem: any) => {
                    if (segmentItem?.seatDetails?.length > 0) setFlag = true
                })
                break
            case MEAL:
                journeyItem?.userSelectedSegmentAncillaries?.forEach((segmentItem: any) => {
                    if (segmentItem?.mealDetails?.length > 0) setFlag = true
                })
                break
            case BAGGAGE:
                if (journeyItem?.userSelectedBaggageAncillaries?.[0]?.baggageDetails?.length > 0) setFlag = true
                break
            default:
                break
        }
    })
    if (type === INSURANCE) {
        insuranceData?.[0]?.insuranceDetails?.forEach((insuranceItem: any) => {
            if (insuranceItem.selected === true) setFlag = true
        })
    }
    return setFlag
}
export function checkIfAnciallyExists(addonsData: any, type) {
    let setFlag = false
    addonsData?.tripAncillariesResponse?.[0]?.ancillaryDetails?.journeyAncillaries?.forEach((journeyItem: any) => {
        switch (type) {
            case SEAT:
                journeyItem?.segmentAncillaries?.forEach((segmentItem: any) => {
                    if (segmentItem?.seatAncillaries?.aircraftLayout?.lowerDeck) setFlag = true
                })
                break
            case MEAL:
                journeyItem?.segmentAncillaries?.forEach((segmentItem: any) => {
                    if (segmentItem?.mealAncillaries?.mealInfos?.length > 0) setFlag = true
                })
                break
            case BAGGAGE:
                if (journeyItem?.baggageAncillary?.baggageDetails?.[0]?.baggageInfos?.length > 0) setFlag = true
                break
            default:
                break
        }
    })
    return setFlag
}

export function getAddonsPrice(selectedAddonData: any) {
    let addonPrice = 0
    selectedAddonData?.forEach((el: any) => {
        const { strikeOffDetails, price } = el || {}
        const { displayAmount } = strikeOffDetails || {}
        const finalPrice = !isEmpty(displayAmount) ? displayAmount : price
        addonPrice += finalPrice
    })
    return addonPrice
}
