import { ChangeEvent, forwardRef, useCallback, useRef, useState, FocusEvent, CSSProperties, MouseEvent, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { getTextFieldLabelStyles, getTextFieldInputRootStyles, getTextFieldInputBaseStyles, getContainerStyles } from './style';
import { TextFieldProps, FocusStyle } from './type';
import { ForwardRefComponentWithStyle } from '../../@types';
//import useTheme from '../../hooks/useTheme';
import useMergeRefs from '../../hooks/useMergeRefs';
import Box from '../Box';
import Typography from '../Typography';
import { TypographyColor } from '../Typography/type';
import Container from '../Container/Container';
import useTheme from '@ct-design/hooks/useTheme';
type PolymorphicTextField = ForwardRefComponentWithStyle<'input', TextFieldProps>;

// Form container
const Root = styled.div<FocusStyle & {css?: CSSProperties, select?: boolean, disabled?: boolean}>(({ theme, focused,css, select, filled,disabled, ...rest }) =>
	getTextFieldInputRootStyles({ theme, focused,select, filled, disabled,...css, ...rest })
);
const Label = styled.label<FocusStyle>(({ theme, focused, filled, ...rest }) =>
	getTextFieldLabelStyles({ theme, focused, filled, ...rest })
);
const Input = styled.input<FocusStyle  & {select?: boolean}>(({ theme, focused, filled, select,...rest }) =>
	getTextFieldInputBaseStyles({ theme,select, ...rest })
);

const TextArea = styled.textarea<FocusStyle>(({ theme }) =>
	getTextFieldInputBaseStyles({ theme, })
);

const layout = {
	lg: {
		height: 0,
		width: 0,
	},
	sm: {
		height: 0,
		width: 0,
	},
	md: {
		height: 14,
		width: 50,
	},
};

const TextField = forwardRef(
	(
		{
			as = 'input',
			id = '',
			multiline = false,
			size = 'md',
			label = '',
			prefix,
			suffix,
			error = '',
			helperText = '',
			maxCharacters,
			width,
			height,
			textFieldCSS,
			focusColor,
			errorColor,
			css,
			type = 'text',
			value = '',
			name = '',
			onChange,
			onFocus,
			onBlur,
			disabled,
			rows,
			column,
			ref,
			fullWidth = false,
			select,
			nonInput,
			onClick,
			isBorderRed,
			...rest
		},
		forwardedRef
	) => {
		// local state
		const [focused, setFocused] = useState(false);
		const [showPassword, setShowPassword] = useState(false);
		const theme = useTheme();

		// refs
		const inputRef = useRef<HTMLInputElement>(null);
		const textAreaRef = useRef<HTMLTextAreaElement>(null);
		const boxRef = useRef<HTMLDivElement>(null);
		const handleRef = useMergeRefs(forwardedRef, ref);

		useImperativeHandle(
		  handleRef,
		  () => ({
			focus: ()=>{
				if (multiline) {
					textAreaRef.current?.focus()
				} else{
					inputRef.current?.focus();
				}
			},
			node: boxRef?.current
		  }),
		  [],
		)
		// events
		const handleChange = useCallback(
			(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
				if (onChange) {
					onChange(event);
				}
			},
			[onChange]
		);

		const handleFocus = useCallback((event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>) => {
			if (onFocus) {
				onFocus(event);
			}
			setFocused(true);
		}, []);

		const handleBlur = useCallback((event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>) => {
			if (onBlur) {
				onBlur(event);
			}
			setFocused(false);
		}, []);

		// local event
		const handleClick = (e: MouseEvent<HTMLElement>) => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
			if (onClick) {
				onClick(e)
			}
		};

		// prefix for type phone number
		const filled = value?.toString().length > 0 ? true : false
		// width of input container
		width = width ? width : fullWidth ? '100%' : theme.size[layout[size].width];
		// height
		height = height ? height : as !== 'input' ? 'auto' : theme.size[layout[size].height];

		/**
		 * color applies on label
		 * outline
		 * helper text
		 */
		let helperColor = '';
		if (!disabled && focused) {
			helperColor = focusColor || theme.color.border.link;
		}

		if (error) {
			helperColor = errorColor || theme.color.background.warning;
		}
		let helperColorRoot = ""
    if (!disabled && focused) {
      helperColorRoot = focusColor || theme.color.border.primary
    }

    if (error || isBorderRed) {
      helperColorRoot = errorColor || theme.color.background.warning
    }

    return (
        <Box boxSize="micro" css={css}>
            <Root
                theme={theme}
                onClick={handleClick}
                color={helperColorRoot}
                width={width}
                height={height}
                focused={focused}
                filled={filled}
                css={css}
                select={select}
                disabled={disabled}
                ref={boxRef}
            >
                {prefix}
                <Container css={getContainerStyles(focused || filled)} width="100%">
                    <Label theme={theme} color={helperColor} focused={focused} filled={filled}>
                        <Typography
                            variant="B1"
                            className="lh-24 pl-4"
                            color={
                                !disabled && focused
                                    ? error
                                        ? TypographyColor.WARNING
                                        : TypographyColor.SUBHEADING
                                    : TypographyColor.SUBHEADING
                            }
                        >
                            {label || name}
                        </Typography>
                    </Label>
                    {multiline ? (
                        <TextArea
                            rows={rows}
                            column={column}
                            ref={textAreaRef}
                            autoComplete="off"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={value}
                            onChange={handleChange}
                            filled={filled}
                            focused={focused}
                            name={name}
                            {...rest}
                        />
                    ) : (
                        <Input
                            ref={inputRef}
                            theme={theme}
                            type={showPassword ? 'text' : type}
                            readOnly={select || nonInput}
                            autoComplete="off"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={value}
                            onChange={handleChange}
                            filled={filled}
                            focused={focused}
                            name={name}
                            select={select}
                            disabled={disabled}
                            maxLength={maxCharacters}
                            {...rest}
                        />
                    )}
                </Container>
                {suffix && (
                    <Typography
                        variant="B1"
                        className="lh-24 pl-4"
                        color={
                            !disabled && value.length > 0
                                ? error
                                    ? TypographyColor.WARNING
                                    : TypographyColor.LINK
                                : disabled
                                ? TypographyColor.WARNING
                                : TypographyColor.SUBHEADING
                        }
                    >
                        {suffix}
                    </Typography>
                )}
            </Root>
            {(helperText || error) && (
                <Container marginLeft="16px">
                    <Typography variant="P3" color={error ? TypographyColor.WARNING : TypographyColor.SUBHEADING}>
                        {helperText || error}
                    </Typography>
                </Container>
            )}
        </Box>
    )
	}
) as PolymorphicTextField;

export default TextField;
