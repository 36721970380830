import React from "react";
import { Chip} from '@ct-design/lib'
import useTheme from '@ct-design/hooks/useTheme';
import { Container, HorizontalScroll } from "..";
import { NavTabsProps, TabSize, TabVariants } from "./type";
import { IconPosition } from "../../@types";
import { TabContainer } from "./TabContainer";
import { getTabContainerStyles } from "./style";
import { FlatTab } from "./FlatTab";

const NavTabs: React.FC<NavTabsProps> = ({
  tabType = TabVariants.FLAT,
  tabList,
  selectedTab,
  showIcon = false,
  tabGap,
  onClick,
  iconPosition = IconPosition.LEFT,
  tabSize = TabSize.SMALL,
  showCounter = false,
  containerSpace,
  tabWidth,
  textPadding,
  showCounterAsTag = false,
}) => {
  const theme = useTheme();
  const showBottomLine = tabType === TabVariants.FLAT;
  const tabContainerStyles =
    getTabContainerStyles({ showBottomLine, theme }) || {};

  const renderFlatTab = () => {
    return tabList.map((tab, index) => {
      const { label, id, Icon = undefined, count } = tab || {};
      return (
        <Container key={id}>
          <FlatTab
            key={id}
            label={label}
            onClick={() => onClick(id)}
            size={tabSize}
            isSelected={selectedTab === id}
            showCounter={showCounter}
            count={count}
            showIcon={showIcon}
            iconPosition={iconPosition}
            Icon={Icon}
            tabWidth={tabWidth}
            id={id}
            showCounterAsTag={showCounterAsTag}
          />
        </Container>
      );
    });
  };

  const renderChipTab = () => {
    return tabList.map((tab, index) => {
      const { label, id, Icon = undefined, count } = tab || {};
      return (
        <Container key={id}>
          <Chip
            label={label}
            isSelected={selectedTab === id}
            showIcon={showIcon}
            iconPosition={iconPosition}
            Icon={Icon}
            onClick={() => onClick(id)}
            showCounter={showCounter}
            count={count}
            id={id}
            textPaddingLeft={textPadding}
            textPaddingRight={textPadding}
          />
        </Container>
      );
    });
  };

  const renderTabType = () => {
    switch (tabType) {
      case TabVariants.FLAT: {
        return renderFlatTab();
      }
      case TabVariants.CHIP: {
        return renderChipTab();
      }
      default: {
        return renderFlatTab();
      }
    }
  };

  return (
    <TabContainer {...tabContainerStyles}>
      <HorizontalScroll>
        <Container
          display="flex"
          alignItems="center"
          columnGap={tabGap || theme.spacing[4]}
          paddingLeft={containerSpace || theme.spacing[4]}
          paddingRight={containerSpace || theme.spacing[4]}
        >
          {renderTabType()}
        </Container>
      </HorizontalScroll>
    </TabContainer>
  );
};

export default NavTabs;
