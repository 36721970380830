import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../configureStore'
import { itineraryConfirmApi } from '@api/confirmationPage'
import data from 'pages/confirmation/response'

export interface ConfirmationDataState {
    ConfirmationResponse?: Record<string, any>
    loading: boolean
    isError: boolean
}
const initialState: ConfirmationDataState = {
    ConfirmationResponse: {},
    loading: true,
    isError: false
}

export const confirmationDataSlice = createSlice({
    name: 'confirmation',
    initialState,
    reducers: {
        setConfirmationResponse: (state, action) => {
            state.ConfirmationResponse = {
                ...state.ConfirmationResponse,
                ...action.payload
            }
            state.loading = false
        },
        setErrorState: (state, action) => {
            state.isError = action.payload
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchConfirmationData.fulfilled, (state, action) => {
            const ItineraryResponse: any = action.payload
            if (ItineraryResponse?.data && ItineraryResponse.data?.itineraryId) {
                const { flightDetails } = ItineraryResponse.data || {}
                const sectorLength = Object.keys(flightDetails).length
                const isRoundtrip = sectorLength > 1
                const sourceCity = flightDetails[0].fromCity
                const destinationCity = flightDetails[0].toCity
                const isMulticity =
                    ItineraryResponse.data.searchCriteria && ItineraryResponse.data.searchCriteria.multiCity
                const multicityDestinationCity =
                    (isMulticity &&
                        ItineraryResponse.data.searchCriteria.multiCitySearchCriterias[
                            ItineraryResponse.data.searchCriteria.multiCitySearchCriterias.length - 1
                        ].to) ||
                    ''
                const multicitySourceCity =
                    (isMulticity && ItineraryResponse.data.searchCriteria.multiCitySearchCriterias[0].from) || ''
                state.ConfirmationResponse = {
                    ...ItineraryResponse.data,
                    sourceCity,
                    destinationCity,
                    isRoundtrip,
                    isMulticity,
                    multicityDestinationCity,
                    multicitySourceCity
                }
            }
            state.loading = false
        })
        builder.addCase(fetchConfirmationData.rejected, (state, action) => {
            state.ConfirmationResponse = action.payload || {}
            state.loading = false
            state.isError = true
        })
        builder.addCase(fetchConfirmationData.pending, (state, action) => {
            state.ConfirmationResponse = {}
            state.loading = true
        })
    }
})

export const fetchConfirmationData = createAsyncThunk(
    'confirmation/fetchConfirmationData',
    async (itineraryId: string) => {
        try {
            const response = await itineraryConfirmApi(itineraryId)
            return response
        } catch (e) {
            console.error(e)
            throw new Error(e)
        }
    }
)

const getConfirmationResponse = (state: RootState) => state.confirmation.ConfirmationResponse
const getConfirmationLoadingStatus = (state: RootState) => state.confirmation.loading
const getConfirmationErrorStatus = (state: RootState) => state.confirmation.isError

const { reducer, actions } = confirmationDataSlice
const { setConfirmationResponse, setErrorState } = actions || {}

export {
    getConfirmationResponse,
    getConfirmationLoadingStatus,
    getConfirmationErrorStatus,
    setConfirmationResponse,
    setErrorState
}

export default reducer
