import React from 'react';

interface TickProps {
	height?: number;
	width?: number;
	crossColor?: string;
}

const Tick: React.FC<TickProps> = ({ height = 7.5, width = 10, crossColor}) => {
	return (
		<svg width={width} height={height} viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M9 1L3.5 6.5L1 4' stroke={crossColor} stroke-linecap='round' stroke-linejoin='round' />
		</svg>
	);
};

export default Tick;