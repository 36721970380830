import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../configureStore'
import { flightFreeBaggagePostApi } from '@api/resultsPage/flightDetailsApi'

export interface BaggageDataState {
    baggageResponse?: Record<string, any>
    isLoading: boolean
}
const initialState: BaggageDataState = {
    baggageResponse: {},
    isLoading: true
}

export const fetchBaggageData = createAsyncThunk('baggage/fetchBaggageData', async payload => {
    try {
        const response = await flightFreeBaggagePostApi(payload)
        return response
    } catch (e) {
        return {}
    }
})

export const baggageDataSlice = createSlice({
    name: 'baggage',
    initialState,
    reducers: {
        setBaggageData: (state, action) => {
            state.baggageResponse.data = {
                ...action.payload
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchBaggageData.fulfilled, (state, action) => {
            state.baggageResponse = action.payload || {}
            state.isLoading = false
        })
        builder.addCase(fetchBaggageData.rejected, (state, action) => {
            state.baggageResponse = action.payload
            state.isLoading = false
        })
        builder.addCase(fetchBaggageData.pending, (state, action) => {
            state.baggageResponse = {}
            state.isLoading = true
        })
    }
})
const getBaggageResponse = (state: RootState) => state.baggage.baggageResponse
const isBaggageResponseLoading = (state: RootState) => state.baggage.isLoading
const getBaggageResponseData = (state: RootState) => state.baggage.baggageResponse?.data

const { reducer, actions } = baggageDataSlice
const { setBaggageData } = actions || {}

export { setBaggageData, getBaggageResponse, getBaggageResponseData, isBaggageResponseLoading }

export default reducer
