import logError from "@utils/logError";

export const ClevertapAndroid = {
    event(name, payload) {
        if (window.CleverTap) {
            try {
                if (payload) {
                    window.CleverTap.pushEvent(name, JSON.stringify(payload));
                } else {
                    window.CleverTap.pushEvent(name);
                }
            } catch (error) {
                logError(error);
            }
        }
    }
}


export const ClevertapIos = {
    event(name, payload) {
        const filterPayload = JSON.parse(JSON.stringify(payload));
        const message = {
          action: "recordEventWithProps",
          event: name,
          props: filterPayload,
          properties: filterPayload,
        }
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.clevertap) {
            window.webkit.messageHandlers.clevertap.postMessage(message)
        }
    }
}


