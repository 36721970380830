import { formatCurrency } from '@root/ui/utils'
import { isEmpty } from '@utils/genralUtils'

export const getAncillaryDataForSelectedFare = (ancillaryData = [], fareType = 'RETAIL') => {
    let response = {}
    const { tripAncillaryResponseList = [] }: any = ancillaryData || {}
    if (!Array.isArray(tripAncillaryResponseList)) {
        return response
    }
    const ancillaryObj = tripAncillaryResponseList.find(data => data.fareSubType === fareType)
    response = ancillaryObj || {}
    return response
}

export const checkIsAncillaryPresent = (ancillaryData = [], fareType = 'RETAIL') => {
    const response = { isSeatPresent: false, isMealPresent: false, isBaggagePresent: false }
    const availableAncillarySegments = {
        seat: {},
        meal: {},
        baggage: {}
    }
    if (isEmpty(ancillaryData)) return { ...response, availableAncillarySegments }

    const ancillaryObj = getAncillaryDataForSelectedFare(ancillaryData, fareType)
    if (isEmpty(ancillaryObj)) return { ...response, availableAncillarySegments }

    const { journeyAncillaries = [] } = ancillaryObj?.ancillaryDetails || {}
    if (!Array.isArray(journeyAncillaries)) return { ...response, availableAncillarySegments }

    journeyAncillaries.forEach(journeyAncillary => {
        const { baggageAncillary = {}, segmentAncillaries = [] } = journeyAncillary
        const { baggageDetails = [] } = baggageAncillary || {}

        if (Array.isArray(baggageDetails)) {
            baggageDetails.forEach(baggageDetail => {
                const { baggageInfos = [], originDestinationInfo } = baggageDetail || {}
                const { to, from } = originDestinationInfo
                const key = `${from}_${to}`
                if (Array.isArray(baggageInfos) && baggageInfos.length > 0) {
                    response.isBaggagePresent = true
                    availableAncillarySegments.baggage = {
                        ...availableAncillarySegments.baggage,
                        [key]: true
                    }
                }
            })
        }

        if (Array.isArray(segmentAncillaries)) {
            segmentAncillaries.forEach(segmentAncillary => {
                const { mealAncillaries = {}, seatAncillaries = {}, originDestinationInfo } = segmentAncillary || {}
                const { mealInfos = [] } = mealAncillaries || {}
                const { to, from } = originDestinationInfo || {}
                const key = `${from}_${to}`

                if (Array.isArray(mealInfos) && mealInfos.length > 0) {
                    response.isMealPresent = true
                    availableAncillarySegments.meal = {
                        ...availableAncillarySegments.meal,
                        [key]: true
                    }
                }

                if (seatAncillaries?.aircraftLayout?.lowerDeck?.compartments?.length > 0) {
                    response.isSeatPresent = true
                    availableAncillarySegments.seat = {
                        ...availableAncillarySegments.seat,
                        [key]: true
                    }
                }
            })
        }
    })

    return { ...response, availableAncillarySegments }
}

export const ceilIt = (val, currencyConversionFactor) => {
    if (currencyConversionFactor && currencyConversionFactor.code !== 'KWD') {
        return Math.ceil(val)
    }
    return val
}

export const getLegendColor = index => {
    const colorsArray = ['#A4CDFF', '#71DBD4', '#5F8EEE', '#985289', '#4D1A37', '#4D1A37']
    if (index < 0 || index >= 6) {
        return ''
    }
    return colorsArray[index]
}

export const getSeatMapRelatedData = (compartments, currencyConversionFactor) => {
    const priceMap: any = {}
    const distinctPriceArray: any = []
    const legendsArray = []
    let strikeOffObject = {}
    let maxRow = 0
    let rowsEncountered = 0
    let exitRowNumber = ''
    let freeFlag = false

    compartments.forEach(compartment => {
        compartment.rows?.forEach(row => {
            rowsEncountered++
            if (row.rowCharacteristics === 'EMERGENCY_ROW' && exitRowNumber === 0) {
                exitRowNumber = rowsEncountered
            }
            row.seatDetails?.forEach(seat => {
                maxRow = Math.max(seat.seatCoordinates.xCoordinate, maxRow)
                if (!priceMap[seat.price]) {
                    priceMap[seat.price] = true
                    if (+seat.price === 0) {
                        freeFlag = true
                    } else {
                        if (
                            !isEmpty(seat.strikeOffDetails) &&
                            !isNaN(seat.strikeOffDetails.displayAmount) &&
                            !isNaN(seat.strikeOffDetails.strikeOffAmount)
                        ) {
                            strikeOffObject = {
                                ...strikeOffObject,
                                [formatCurrency(ceilIt(+seat.price, currencyConversionFactor))]: {
                                    ...seat.strikeOffDetails
                                }
                            }
                        }
                        distinctPriceArray.push(ceilIt(+seat.price, currencyConversionFactor))
                    }
                }
            })
        })
    })

    distinctPriceArray.sort((a, b) => a - b)
    for (let i = 0; i < distinctPriceArray.length && i < 5; i++) {
        legendsArray.push(distinctPriceArray[i])
    }
    if (distinctPriceArray.length > 5) {
        legendsArray.push(distinctPriceArray[distinctPriceArray.length - 1])
    }
    return { legendsArray, freeFlag, maxRow, exitRowNumber }
}

export const getSeatColor = (price, currencyConversionFactor, legendsArray) => {
    price = ceilIt(price, currencyConversionFactor)
    if (price === 0) {
        return ''
    }
    let priceIndex = -1 //initial Value
    for (let i = 0; i < legendsArray.length && price >= legendsArray[i]; i++) {
        priceIndex = i
    }
    return getLegendColor(priceIndex)
}

export const getInitialUserSelectedJourneyAncillaries = ancillaryData => {
    const initialUserSelectedJourneyAncillaries: any = []
    const ancillaryDataForSelectedFare = getAncillaryDataForSelectedFare(ancillaryData)
    const { journeyAncillaries = [] } = ancillaryDataForSelectedFare?.ancillaryDetails || {}
    if (!Array.isArray(journeyAncillaries) || isEmpty(journeyAncillaries)) {
        return initialUserSelectedJourneyAncillaries
    }
    journeyAncillaries.forEach(journeyAncillary => {
        const { originDestinationInfo, segmentAncillaries, baggageAncillary } = journeyAncillary
        const journeyObj = {
            isSegmentLevelBaggage: false,
            journeyOriginDestinationInfo: originDestinationInfo,
            userSelectedBaggageAncillaries: [{ baggageDetails: [], originDestinationInfo }],
            userSelectedSegmentAncillaries: []
        }
        if (Array.isArray(segmentAncillaries)) {
            segmentAncillaries.forEach(segmentAncillary => {
                const { originDestinationInfo: segmentOriginDestinationInfo } = segmentAncillary
                const segmentObj = {
                    mealDetails: [],
                    seatDetails: [],
                    segmentOriginDestinationInfo
                }
                journeyObj.userSelectedSegmentAncillaries.push(segmentObj)
            })
        }
        initialUserSelectedJourneyAncillaries.push(journeyObj)
    })
    return initialUserSelectedJourneyAncillaries
}

export const getSelectedAncillariesList = selectedAncillaryData => {
    let selectedAncillaries = {
        seat: {},
        meal: {},
        baggage: {}
    }
    const { userSelectedJourneyAncillaries } = selectedAncillaryData || {}
    if (Array.isArray(userSelectedJourneyAncillaries)) {
        userSelectedJourneyAncillaries.forEach(userSelectedJourneyAncillary => {
            const { userSelectedSegmentAncillaries, userSelectedBaggageAncillaries } = userSelectedJourneyAncillary
            if (Array.isArray(userSelectedBaggageAncillaries)) {
                userSelectedBaggageAncillaries.forEach(baggageAncillary => {
                    const { baggageDetails = [], originDestinationInfo = {} } = baggageAncillary
                    const { to, from } = originDestinationInfo || {}
                    const key = `${from}_${to}`
                    selectedAncillaries.baggage = {
                        ...selectedAncillaries.baggage,
                        [key]: baggageDetails
                    }
                })
            }
            if (Array.isArray(userSelectedSegmentAncillaries)) {
                userSelectedSegmentAncillaries.forEach(segmentAncillary => {
                    const { seatDetails, mealDetails, segmentOriginDestinationInfo } = segmentAncillary
                    const { to, from } = segmentOriginDestinationInfo || {}
                    const key = `${from}_${to}`
                    selectedAncillaries.seat = {
                        ...selectedAncillaries.seat,
                        [key]: seatDetails
                    }
                    selectedAncillaries.meal = {
                        ...selectedAncillaries.meal,
                        [key]: mealDetails
                    }
                })
            }
        })
    }
    return selectedAncillaries
}

export const getPaxCount = searchCriteria => {
    const { paxInfo } = searchCriteria || {}
    const { adults = 0, children = 0 } = paxInfo || {}
    return (adults || 0) + (children || 0)
}

export const sortBaggage = (baggage = []) => {
    let baggageArray = new Array(...baggage)
    if (Array.isArray(baggageArray)) {
        baggageArray.sort((baggageA, baggageB) => {
            return parseInt(baggageA.baggageList[0].quantity) - parseInt(baggageB.baggageList[0].quantity)
        })
    }
    return baggageArray
}

export const getAncillaryUpdatePayload = selectedAncillaryData => {
    const { userSelectedJourneyAncillaries = [] } = selectedAncillaryData

    const payload = {
        userSelectedJourneyAncillaries: userSelectedJourneyAncillaries.map(
            ({ userSelectedSegmentAncillaries = [], userSelectedBaggageAncillaries = [], ...journeyAncillary }) => ({
                ...journeyAncillary,
                userSelectedBaggageAncillaries: userSelectedBaggageAncillaries.map(
                    ({ baggageDetails = [], ...baggageAncillary }) => ({
                        ...baggageAncillary,
                        baggageDetails: baggageDetails.map(({ baggageCode, displayDetail: baggageInfo }, index) => ({
                            baggageCode,
                            baggageInfo,
                            paxIndex: index + 1
                        }))
                    })
                ),
                userSelectedSegmentAncillaries: userSelectedSegmentAncillaries.map(
                    ({ mealDetails = [], seatDetails = [], ...segment }) => ({
                        ...segment,
                        mealDetails: mealDetails.map(({ code: mealCode, description: mealInfo }, index) => ({
                            mealCode,
                            mealInfo,
                            paxIndex: index + 1
                        })),
                        seatDetails: seatDetails.map(({ columnId, rowId }, index) => ({
                            columnId,
                            rowId,
                            paxIndex: index + 1
                        }))
                    })
                )
            })
        )
    }

    return payload
}

export const checkForMandatorySeatMeal = (meta = {}) => {
    let isMealMandatory = meta?.smbEligibility?.meal?.isMandatory
    let isSeatMandatory = meta?.smbEligibility?.seat?.isMandatory
    return { isMealMandatory, isSeatMandatory }
}
