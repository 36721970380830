import axios from "@ui/utils/axios";
import logError from "@utils/flights/logError";
import { getApiDomain } from "src/utils/helper";

import { isEmpty, getCookie } from "@ui/utils";

let DOMAIN = getApiDomain();
export async function wpUserStatusFetch() {
  const userCookie = decodeURIComponent(getCookie("userid") || "").split("|");
  const userId = userCookie.length > 4 ? userCookie[4] : "";
  let responseData = { data: "NOT_SIGNED_IN" };
  if (!isEmpty(userId)) {
    await axios
      .get(`${WP_USER_STATUS_FETCH}${userId}`)
      .then((response) => {
        responseData = response.data;
      })
      .catch((error) => {
        logError(error);
      });
  }
  return responseData;
}

export const WP_USER_STATUS_FETCH = `${DOMAIN}/workplus/user/state?userId=`;
