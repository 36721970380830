import { CLEARTRIP, FLIPKART } from '@constants/flights'
import { BuildVariantType } from '@root/types/WhiteLabelTypes'

export const buildVariant: BuildVariantType = (process.env.NEXT_PUBLIC_VARIANT as BuildVariantType) || CLEARTRIP
export const isFkEnv = buildVariant === FLIPKART
export const isCtEnv = buildVariant === CLEARTRIP

const pageTitleConfig = {
    [CLEARTRIP]: 'Cleartrip: #1 Travel Booking Site, Book Hotels, Flights Online with Great Offers.',
    [FLIPKART]: 'Flight Booking | Book Flight Tickets at Lowest Airfare on Flipkart.com'
}

export const PAGE_TITLE = 'Cleartrip: #1 Site for Booking Flights Tickets & Hotels Online - Get Best Travel Deals'
