import colors from '../global/color';
import border from '../global/border';
import typography from '../global/typography';
import spacing from '../global/spacing';
import size from '../global/size';
import { Theme } from '.';
import elevation from '../global/elevations';
// inspired from chakra UI
export const duration = {
	shortest: 150,
	shorter: 200,
	short: 250,
	// most basic recommended timing
	standard: 300,
	// this is to be used in complex animations
	complex: 375,
	// recommended when something is entering screen
	enteringScreen: 225,
	// recommended when something is leaving screen
	leavingScreen: 195,
};

// inspired from chakra UI
export const easing = {
	// This is the most common easing curve.
	easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
	// Objects enter the screen at full velocity from off-screen and
	// slowly decelerate to a resting point.
	easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
	// Objects leave the screen at full velocity. They do not decelerate when off-screen.
	easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
	// The sharp curve is used by objects that may return to the screen at any time.
	sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
};

function getAutoHeightDuration(height: number) {
	if (!height) {
		return 0;
	}

	const constant = height / 36;

	// https://www.wolframalpha.com/input/?i=(4+%2B+15+*+(x+%2F+36+)+**+0.25+%2B+(x+%2F+36)+%2F+5)+*+10
	return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}

const create = (props = ['all'], options: any = {}) => {
	const {
		duration: durationOption = duration.standard,
		easing: easingOption = easing.easeInOut,
		delay = 0,
	} = options;
	return (Array.isArray(props) ? props : [props])
		.map(
			(animatedProp) =>
				`${animatedProp} ${
					typeof durationOption === 'string' ? durationOption : `${durationOption}ms`
				} ${easingOption} ${typeof delay === 'string' ? delay : `${delay}ms`}`
		)
		.join(',');
};

const zIndex = {
	drawer: 1200,
	modal: 1300,
	popOver: 1400,
	tooltip: 1500,
};

const BaseTheme: Theme = {
  color: {
    text: {
      primary: colors.neutral900,
      secondary: colors.brandText,
      tertiary: colors.link,
      heading: colors.neutral900,
      subHeading: colors.neutral700,
      disabled: colors.disabledText,
      success: colors.successText,
      alert: colors.alertText,
      warning: colors.warningText,
      link: colors.linkText,
      brand: colors.brandText,
      neutral: colors.neutral100,
      placeholder: colors.neutral500,
    },
    button: {
      outlinedPrimaryLabel: colors.neutral900,
      outlinedPrimaryBorder: colors.neutral900,
      outlinedPrimaryBg: colors.neutral100,
      outlinedSecondaryLabel: colors.brand,
      outlinedSecondaryBorder: colors.brand,
      outlinedSecondaryBg: colors.neutral100,
      outlinedTertiaryLabel: colors.link,
      outlinedTertiaryBorder: colors.link,
      outlinedTertiaryBg: colors.neutral100,
      outlinedDisabledLabel: colors.disabledText,
      outlinedDisabledBg: colors.neutral300,
      containedPrimaryLabel: colors.neutral100,
      containedPrimaryBg: colors.neutral900,
      containedSecondaryLabel: colors.neutral100,
      containedSecondaryBg: colors.brand,
      containedTertiaryLabel: colors.neutral100,
      containedTertiaryBg: colors.link,
      containedDisabledLabel: colors.disabledText,
      containedDisabledBg: colors.neutral300,
    },
    chip: {
      nonSelectedPrimaryLabel: colors.neutral900,
      selectedPrimaryLabel: colors.neutral900,
      selectedPrimaryBorder: colors.neutral900,
      selectedPrimaryBg: colors.neutral300,
      disabledPrimaryLabel: colors.disabledText,
    },
    tab: {
      nonSelectedPrimaryLabel: colors.neutral700,
      selectedPrimaryLabel: colors.neutral900,
    },
    background: {
      primary: colors.neutral900,
      secondary: colors.brand,
      tertiary: colors.link,
      brand: colors.brand,
      brandLightBg: colors.brandBg,
      link: colors.link,
      linkLightBg: colors.linkBg,
      success: colors.success,
      successLightBg: colors.successBg,
      alert: colors.alert,
      alertLightBg: colors.alertBg,
      warning: colors.warning,
      warningLightBg: colors.warningBg,
      neutral: colors.neutral100,
      disabled: colors.neutral300,
    },
    border: {
      primary: colors.neutral900,
      secondary: colors.brand,
      tertiary: colors.link,
      brand: colors.brand,
      neutral: colors.neutral900,
      link: colors.link,
      default: colors.line,
      defaultDark: colors.neutral700,
      disabled: colors.neutral500,
    },
  },
  elevation: { ...elevation },
  border: {
    ...border,
  },
  spacing: {
    ...spacing,
  },
  typography: {
    ...typography,
  },
  size: {
    ...size,
  },
  transitions: {
    duration,
    easing,
    create,
    getAutoHeightDuration,
  },
  zIndex,
}

export default BaseTheme;
