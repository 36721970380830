import React, { PureComponent } from "react";
import { StickyProps } from "./type";

class Sticky extends PureComponent<StickyProps> {
  renderProp = () => {
    return this.props.renderChild();
  }
  static defaultProps: { position: string; children: null; };

  override render() {
    const classNames = `Sticky Sticky--${this.props.position}`;
    return (
      <div style={ { ...this.props.headerStyle } }>
        {
          !this.props.overrideFake &&
          <div className="Sticky__fake">
            {this.props.renderChild ? this.renderProp() : this.props.children}
          </div>
        }
        <div className={ classNames } style={ this.props.style }>{this.props.children}</div>
      </div>
    );
  }
}


Sticky.defaultProps = {
  position: "top",
  children: null
};

export default Sticky;
