import React, { createContext } from 'react';
import { ThemeProvider as StyledComponentProvider } from 'styled-components';
import B2CTheme from '../../token/theme/B2CTheme';
import { getTheme } from './../../utils';
import type { ThemeContextType, ThemeProviderProps } from './type';

export const ThemeContext = createContext<ThemeContextType>({
	theme: B2CTheme,
	platform: 'mobile',
});

const ThemeProvider = ({
	children,
	theme: themeType = 'B2C',
	platform = 'mobile',
}: ThemeProviderProps): JSX.Element => {
	const theme = getTheme(themeType);
	return (
		<ThemeContext.Provider value={{ theme, platform }}>
			<StyledComponentProvider theme={theme}>
				<>{children}</>
			</StyledComponentProvider>
		</ThemeContext.Provider>
	);
};

ThemeProvider.defaultProps = {
	children: null,
};

export default ThemeProvider;
