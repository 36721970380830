import { getLanguageMeta, path } from '@ui/utils'
import { apiGetHelper, getApiDomain, apiPutOrPostHelper } from 'src/utils/helper'
import { isFkEnv } from '@root/configs/Whitelabel'
import logError from '@utils/flights/logError'

export const getSearchPageResults = (url: string): Promise<any> => {
    let URL = `/node/flight/search?${url}`
    const response = apiGetHelper(
        URL,
        {},
        {
            'api-version': '3.0',
            ...window.androidData,
            ...window.iosData
        }
    )
    return response
}
export const getSearchPageNearbyDatesResults = (url: string, payload: Record<any, any>): Promise<any> => {
    let URL = `/node/flight/recommendation?${url}`
    const response = apiPutOrPostHelper(URL, JSON.stringify(payload), 'application/json')
    return response
}
export const getDestinationUpsellResults = (payload: Record<any, any>): Promise<any> => {
    let URL = `/node/flights/recommendation/destinationUpsell`
    const response = apiPutOrPostHelper(URL, JSON.stringify(payload), 'application/json')
    return response
}

export const createItineraryApi = (payload: object): Promise<any> => {
    const URL = `/itin/itinerary/create`
    const headers = {
        'api-version': '3.0',
        'content-type': 'application/json',
        Accept: 'application/json',
        ...window.androidData,
        ...window.iosData
    }
    const response = apiPutOrPostHelper(URL, payload, 'application/json', headers)
    return response
}

export const freeWaycreateItineraryApi = (payload: object): Promise<any> => {
    const URL = `/itin/v4/itinerary/create`
    const headers = {
        'api-version': '3.0',
        'content-type': 'application/json',
        Accept: 'application/json',
        ...window.androidData,
        ...window.iosData
    }
    const response = apiPutOrPostHelper(URL, payload, 'application/json', headers)
    return response
}

export const amendmentCreateItineraryApi = (payload: object): Promise<any> => {
    const URL = `/itin/v4/amend/itinerary/create`
    const headers = {
        'api-version': '3.0',
        'content-type': 'application/json',
        Accept: 'application/json',
        ...window.androidData,
        ...window.iosData
    }
    const response = apiPutOrPostHelper(URL, payload, 'application/json', headers)
    return response
}

export const CFWcreateItineraryApi = async (payload: object, requestHeaders: object): Promise<any> => {
    const URL = `/itin/v7/itinerary/create`
    const headers = {
        'api-version': '3.0',
        'content-type': 'application/json',
        Accept: 'application/json',
        ...window.androidData,
        ...window.iosData,
        ...requestHeaders
    }
    const response = await apiPutOrPostHelper(URL, payload, 'application/json', headers)
    return response
}

export const getMerchandisingDetails = async (action: any, isMetaSource = false, host: any): Promise<any> => {
    const { locale } = getLanguageMeta(action.host)
    const { isCfw } = action || {}
    const tripType = action.multiCity ? 'OW' : action.arrivalDate ? 'RT' : 'OW'
    let appAgent = 'pwa'
    const isProdPath = process.env.NEXT_PUBLIC_CT_ENV === 'production' ? 'prod' : 'prod'

    // const ios = path(["iosData", "app-agent"], window)
    // const android = path(["androidData", "app-agent"], window)
    // if (ios) appAgent = "iphoneapp"
    // if (android) appAgent = "androidapp"
    let offerPath = isMetaSource ? 'itin_meta' : isCfw ? 'cfw_srp_itin' : 'srp_itin'
    const Verticalpath = 'flights_'
    const query = `${getMerchandisingQueryFK(
        action.international,
        tripType
    )}_${locale.toLowerCase()}.json?${new Date().getTime()}`
    let domainForSSr = action.ssr ? 'https://ui.cltp.co' : ''
    const URL = `${domainForSSr}/offermgmt/fkMarketing/${isProdPath}/${Verticalpath}${offerPath}_${appAgent}_${query}`
    const response = await apiGetHelper(
        URL,
        {},
        {
            'api-version': '3.0'
        }
    )
    return response
}

export const getMerchandisingQueryFK = (isIntl: boolean, tripType: string): string => {
    const type = isIntl ? 'intl' : 'dom'
    return `${type}_${tripType.toLowerCase()}`
}

export const travelSafeBannerAPI = async (searchQuery: any): Promise<any> => {
    const { source, destination, sourceHeader, destinationHeader } = searchQuery
    const sourceHeaderArray = sourceHeader.split(', ')[1]
    const destHeaderArray = destinationHeader.split(', ')[1]
    const sourceCountry = sourceHeaderArray ? sourceHeaderArray.split(' -')[0] : 'IN'
    const destCountry = destHeaderArray ? destHeaderArray.split(' -')[0] : 'IN'
    if (sourceCountry && destCountry) {
        const travelSafeURL = `/node/flight/travelsafe/route?oc=${sourceCountry}&dc=${destCountry}&oa=${source}&da=${destination}`
        const response = apiGetHelper(
            travelSafeURL,
            {},
            {
                'api-version': '3.0',
                ...window.androidData,
                ...window.iosData
            }
        )
        return response
    }
}
export const getSrpRTSummaryOffer = payload => {
    try {
        const URL = `/airservice/fetch-coupon-discount`

        const response = apiPutOrPostHelper(URL, payload, 'application/json')
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const sendSRPEvent = payload => {
    try {
        const URL = `/node/flight/search/logui`

        const response = apiPutOrPostHelper(URL, payload, 'application/json')
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export const sendS2SEvent = payload => {
    try {
        const URL = `/node/flight/search/logui`

        const response = apiPutOrPostHelper(URL, payload, 'application/json', {}, 'post')
        return response
    } catch (e) {
        console.log(e)
        logError(e)
    }
}
