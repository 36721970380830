import React, {useEffect} from 'react';
import classNames from 'classnames';
import { OverlayProps } from './type';


const MODAL_OPEN_CLASS = 'modal-open'
const SHEET_POSITION_FIXED = 'Sheet--fixed'
const Overlay: React.FC<OverlayProps> = ({ blur, customOverlayWrapperClass, children, customStyle, hashUrl = '',  customClass }) => {
    const overlayWapperClass = classNames(' p-fixed flex h-100p w-100p t-0 flex-middle flex-center z-100 b-0', {
        customOverlayWrapperClass
    })
    const style = blur ? { background: 'rgba(26, 26, 26, 0.6)', ...customStyle } : customStyle

    useEffect(() => {
        document.body.classList.add(MODAL_OPEN_CLASS)
        if (hashUrl === 'updateTravellers') {
            document.body.classList.add(SHEET_POSITION_FIXED)
        }
        return () => {
            document.body.classList.remove(MODAL_OPEN_CLASS)
            if (hashUrl === 'updateTravellers') {
                document.body.classList.remove(SHEET_POSITION_FIXED)
            }
        }
    }, [])

    //Wrapped with newPage to apply the css to all the overlays
    return (
        <div className={`newPage ${customClass}`}>
            <div className={overlayWapperClass} style={style}>
                {children}
            </div>
        </div>
    )
}

Overlay.defaultProps = {
    children: null,
    blur: true,
    customStyle: {},
};

export default Overlay;
