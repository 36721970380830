import { Container, Typography } from '@ct-design/lib'
import Info from './error'
import { ReactNode } from 'react'
interface toastProps {
    isVisible: boolean
    message?: string
    bottomPos?: string
    showIcon?: boolean
    containerClass?: string
    icon?: ReactNode
}
function Toast(props: toastProps) {
    const {
        isVisible,
        message,
        bottomPos = '40px',
        showIcon = true,
        containerClass = 'br-8 py-3 px-3 mx-6 r-0 l-0 p-fixed',
        icon = <Info />
    } = props

    return (
        <>
            {isVisible ? (
                <Container
                    backgroundColor="#1A1A1A"
                    style={{ zIndex: 1000, bottom: bottomPos }}
                    className={containerClass}
                >
                    <Container className="flex flex-middle">
                        <Container className="flex-no-shrink h-6">{showIcon && icon}</Container>
                        <Typography variant="P3" className="fw-500 pl-2" color="neutral">
                            {message}
                        </Typography>
                    </Container>
                </Container>
            ) : null}
        </>
    )
}

export default Toast
