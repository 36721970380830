import { CSSObject } from 'styled-components';
import { Theme } from '../../../token/theme';
import { TabContainerProps } from './type';

export const getTabContainerStyles = ({
	boxShadow
}: TabContainerProps & { theme: Theme }): CSSObject => {
	return {
		boxShadow,
		width: "100%"
	};
};
