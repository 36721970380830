import { RefObject, forwardRef } from 'react';
import styled from 'styled-components';
import { getBackdropStyles } from './style';
import { BackdropProps } from './type';
import { ForwardRefComponentWithStyle, OwnProps } from '../../../@types';
import Transition from '../../Transition';
import useTheme from '../../../hooks/useTheme';

type PolymorphicBackdrop = ForwardRefComponentWithStyle<'div', BackdropProps>;
const StyledBackdrop = styled.div<OwnProps<PolymorphicBackdrop>>(({ css,theme, blur, ...rest }) =>
	getBackdropStyles({ ...css, blur, theme })
);

const Backdrop = forwardRef(({ as, children,open, transitionDuration,css, ...rest }, forwardedRef) => {
    const theme = useTheme()
	return (
		<Transition in={open} timeout={transitionDuration} type='fade'>
			<StyledBackdrop ref={forwardedRef} css={css} theme={theme} {...rest}>{children}</StyledBackdrop>
		</Transition>
	);
}) as PolymorphicBackdrop;

export default Backdrop;
