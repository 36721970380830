import { DAYS, MONTHS } from '@ui/constants'
import { path, isEmpty, pad, typeOf } from '@ui/utils'
import dayjs from 'dayjs'
import {
    ARRIVAL_DEPARTURE_OVERLAP_TIME,
    ARRIVAL_DEPARTURE_THRESHOLD,
    LESS_THAN_THIRTY,
    LESS_THAN_ZERO,
    MEDICANCEL
} from '@constants/flights'
import { getFormattedDate } from '@ui/utils/dateHelper'
import { featureConfig } from '@constants/flights/featureConfig'
import { buildVariant, isFkEnv } from '@root/configs/Whitelabel'
import { stringSplitter } from '@utils/itinerary/addons'
import { Typography } from '@ct-design/lib'

import { isServer } from '@utils/appAgent'
import { dayDiff } from '@utils/itinerary/clevertap/clevertapHelper'
import { abCookieForBBD } from '@utils/browserhelper'

export const getUTMData = data => {
    const utmData = {}
    Object.keys(data).forEach(key => {
        if (key.includes('utm_')) {
            utmData[key] = data[key]
        }
    })
    return utmData
}
export const formatDate = date => {
    const type = typeOf(date)
    let nDate
    if (type === 'number') {
        nDate = new Date(date)
    } else if (type === 'string') {
        nDate = new Date(parseInt(date, 10))
    } else {
        nDate = date
    }
    return `${DAYS[nDate.getDay()]}, ${pad(nDate.getDate())} ${MONTHS[nDate.getMonth()]}`
}

export const checkInternalCTUser = () => {
    const usermisc = getCookie('usermisc') || ''
    const { email: signedInEmail = '' } = getUserAuthValues()
    if (
        usermisc.includes('SIGNED_IN') &&
        !isEmpty(signedInEmail) &&
        (signedInEmail.indexOf('cleartrip.ae') > -1 || signedInEmail.indexOf('flyin.com') > -1)
    ) {
        return true
    }
    return false
}

export const checkInternalFKUser = () => {
    const usermisc = getCookie('usermisc') || ''
    const { email: signedInEmail = '' } = getUserAuthValues()
    if (usermisc.includes('SIGNED_IN') && !isEmpty(signedInEmail) && signedInEmail.indexOf('flipkart.com') > -1) {
        return true
    }
    return false
}

export function getCookie(name) {
    if (typeof document !== 'undefined' && path(['cookie'], document)) {
        var nameEQ = name + '='
        var ca = document.cookie.split(';')
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i]
            while (c.charAt(0) == ' ') c = c.substring(1, c.length)
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
        }
        return ''
    } else {
        return ''
    }
}

export function getUserAuthValues() {
    const [email, profileName, gender, photo, userId] = decodeURIComponent(getCookie('userid') || '').split('|')
    return {
        email,
        profileName,
        gender,
        photo,
        userId
    }
}

export const getApacheCookie = () => {
    let apache = getCookie('Apache')

    if (!apache) {
        apache = `${parseInt(Math.random() * 100000000, 10)}.${new Date().getTime()}`
        setCookie('Apache', apache, 365)
    }
    return apache
}
export const isUserSignedIn = () => {
    let userObject = getUserAuthValues() || {}
    let usermiscVal = decodeURIComponent(getCookie('usermisc') || '').split('|')
    let signedIn = usermiscVal.includes('SIGNED_IN') && userObject.userId && userObject.userId.length > 0 ? true : false

    return signedIn
}

export const utmGetUserPrefCurrency = () => {
    const cookie = getCookie('utm_currency')
    if (cookie) {
        const userPrefCurrency = cookie.split('|')
        return userPrefCurrency[0]
    }
    return undefined
}

export const getPopularCities = ({ intl } = {}) => {
    const lang = 'en'
    const defaultCities = [
        { code: 'AUH', airport: 'Abu Dhabi, AE - Abu Dhabi International Airport' },
        {
            code: 'BAH',
            airport: 'Bahrain, BH - Bahrain'
        },
        { code: 'DMM', airport: 'Dammam, SA - King Fahad' },
        { code: 'DEL', airport: 'New Delhi, IN - Indira Gandhi Airport' },
        { code: 'DOH', airport: 'Doha, QA - Doha' },
        { code: 'DXB', airport: 'Dubai, AE - Dubai International Airport', isIntl: true },
        {
            code: 'JED',
            airport: 'Jeddah, SA - Jeddah'
        },
        { code: 'HYD', airport: 'Hyderabad, IN - Rajiv Gandhi International' },
        { code: 'KWI', airport: 'Kuwait, KW - Kuwait' },
        { code: 'MCT', airport: 'Muscat, OM - Seeb', isIntl: true },
        { code: 'MED', airport: 'Madinah, SA - Prince Mohammad Bin Abdulaziz', isIntl: true },
        { code: 'RUH', airport: 'Riyadh, SA - King Khaled' },
        { code: 'SFO', airport: 'San Francisco, US - San Francisco', isIntl: true },
        { code: 'SHJ', airport: 'Sharjah, AE - Sharjah', isIntl: true }
    ]
    return defaultCities
}

export const readCookie = (k = 'userid') => {
    const nameEQ = `${k}=`
    let ca = document && document.cookie
    if (isEmpty(ca)) {
        return null
    }
    ca = ca.split(';')
    const getExpiresCookie = element => element.indexOf('expires') !== -1
    const expiresCookie = ca.find(getExpiresCookie)
    const getUserIdCookie = element => element.indexOf('userid') !== -1
    let userIdCookie = ca.find(getUserIdCookie)
    const getUserMiscCookie = element => element.indexOf('usermisc') !== -1
    let userMiscCookie = ca.find(getUserMiscCookie)
    let userStatus = ''
    if (!isEmpty(userMiscCookie)) {
        ;[userMiscCookie] = decodeURIComponent(userMiscCookie.trim()).split('|')
        ;[, userStatus] = userMiscCookie.split('=')
    }
    if (expiresCookie) {
        const expiryTime = new Date(expiresCookie.substring(expiresCookie.indexOf('=') + 1)).getTime()
        let now = new Date()
        now = now.getTime()
        if (expiryTime < now) {
            eraseStorage()
            return null
        }
    }
    if (userIdCookie && userStatus === 'SIGNED_IN') {
        userIdCookie = userIdCookie.substring(userIdCookie.indexOf('userid'), userIdCookie.length)
        if (userIdCookie.indexOf(nameEQ) === 0) {
            let userId = decodeURIComponent(userIdCookie.substring(nameEQ.length, userIdCookie.length))
            const separatorIndex = userId.indexOf('|')
            if (separatorIndex) {
                userId = separatorIndex > 6 ? (userId = userId.substr(0, separatorIndex)) : userId
            }
            return userId
        }
    }
    return null
}

export const setUtmCookieOnHomePage = () => {
    const pageUrl = window.location.href
    const utmLoc = pageUrl.search('utm_currency')
    if (utmLoc !== -1) {
        const utmCurr = pageUrl.substr(utmLoc + 13, 3)
        setCookie('utm_currency', utmCurr, 365 * 10)
    }
}

export const setCookie = (name, value, days) => {
    let expires
    if (days) {
        const date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = `; expires=${date.toGMTString()}`
    } else {
        expires = ''
    }
    document.cookie = `${name}=${value}${expires}; path=/`
}

export const _getDate = date => {
    const type = typeof date
    let departDate
    if (type === 'number') {
        departDate = new Date(date)
    } else if (type === 'string') {
        departDate = date.split('/')
        departDate = new Date(`${departDate[1]}/${departDate[0]}/${departDate[2]}`)
    } else if (date instanceof Date) {
        departDate = date
    }
    return departDate
}

// export const isContentAuthenticNew = async (k = 'userid') => {
//     try {
//         let ca = document && document.cookie
//         if (isEmpty(ca)) {
//             return null
//         }
//         ca = ca.split(';')
//         const getExpiresCookie = element => element.indexOf('expires') !== -1
//         const expiresCookie = ca.find(getExpiresCookie)
//         const getUserIdCookie = element => element.indexOf('userid') !== -1
//         const userIdCookie = ca.find(getUserIdCookie)
//         const getUserMiscCookie = element => element.indexOf('usermisc') !== -1
//         let userMiscCookie = ca.find(getUserMiscCookie)
//         let userStatus = ''
//         if (!isEmpty(userMiscCookie)) {
//             userMiscCookie = decodeURIComponent(userMiscCookie.trim()).split('|')[0]
//             userStatus = userMiscCookie.split('=')[1]
//         }
//         if (expiresCookie) {
//             const expiryTime = new Date(expiresCookie.substring(expiresCookie.indexOf('=') + 1)).getTime()
//             let now = new Date()
//             now = now.getTime()
//         }
//         if (!(userIdCookie && userStatus === 'SIGNED_IN')) {
//             // const getCtAuthCookie = element => element.indexOf('ct-auth=') !== -1 // using ct-auth= to avoid ct-auth-preferences
//             const getCtAuthNoLoginCookie = element => element.indexOf('ct-auth-no-login=') !== -1
//             let ctAuthCookie = ca.find(getCtAuthNoLoginCookie) || ''
//             ctAuthCookie = ctAuthCookie.trim()
//             if (isEmpty(ctAuthCookie)) {
//                 return null
//             }
//             const apiURL = '/communication/selfcare/api/authenticate-content'

//             const userLoginData = await axios
//                 .get(apiURL, {
//                     headers: {
//                         Cookie: ctAuthCookie
//                     }
//                 })
//                 .then(response => {
//                     return response.data
//                 })
//                 .catch(error => {
//                     return {}
//                 })
//             const { email = '', id = '' } = userLoginData
//             if (!isEmpty(email) && !isEmpty(id)) {
//                 // email, profileName, gender, photo, userId
//                 const useridCookie = `${email}||||${id}`
//                 // setCookie('userid', encodeURIComponent(useridCookie))
//                 // setCookie('usermisc', encodeURIComponent('SIGNED_IN|'))
//             }
//         }
//     } catch (e) {
//         console.log(e)
//     }
// }

export const getClassNameForTracking = fareClass => {
    return fareClass.toLowerCase() === 'business'
        ? 'B'
        : fareClass.toLowerCase() === 'premium economy'
        ? 'PE'
        : fareClass.toLowerCase() === 'first'
        ? 'F'
        : 'E'
}

export function debounce(func, delay) {
    let timeoutId = ''
    return function () {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            func.apply(this, arguments)
        }, delay)
    }
}

const cardHeaderMapping = {
    '6E': 'bg-indigo',
    I5: 'bg-airAsia',
    G8: 'bg-goFirst',
    AI: 'bg-airIndia',
    SG: 'bg-spicejet',
    UK: 'bg-vistara',
    QP: 'bg-akasha-air',
    '9I': 'bg-alliance-air',
    IX: 'bg-airAsia'
}
const fastUrl = `https://ui.cltp.co/images/logos/air-logos/svg_logos/`
export const getCardHeaderStyle = flight => {
    return {
        bg: cardHeaderMapping[flight] || 'bg-neutral-900',
        textColor: 'neutral'
        // textColor: (cardHeaderMapping[flight] && 'neutral') || 'primary'
    }
}

export const getStopsCallout = stops => {
    return stops > 1 ? `${stops} stops` : stops > 0 ? `${stops} stop` : 'Non-stop'
}

export const getCardHeaderImg = flight => {
    if (Object.keys(cardHeaderMapping).includes(flight)) {
        return <img src={`${`${fastUrl}${flight}.svg`}`} className="br-4" width="28" height="28" alt={flight} />
    }
    return <img src={`${`${fastUrl}DEFAULT.svg`}`} className="br-4" width="28" height="28" alt={flight} />
}

export const getFlightDurationCFW = (duration = 0) => {
    const hours = Math.floor(duration / 60)
    const remainingMinutes = duration % 60
    const minutes = Math.floor(remainingMinutes)
    const remainingSeconds = (remainingMinutes - minutes) * 60
    const seconds = Math.floor(remainingSeconds)
    return {
        seconds,
        hours,
        minutes
    }
}
export const getFlightDuration = seconds => {
    const hours = Math.floor(seconds / 3600)
    seconds %= 3600
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return {
        hours: hours,
        minutes: minutes,
        seconds: remainingSeconds
    }
}

export const getAbFromCookie = async () => {
    let ctAb = isEmpty(getCookie('ct-pwa')) ? {} : JSON.parse(decodeURIComponent(getCookie('ct-pwa')))
    // let ctAb = abCookieForBBD()

    let value = (await ctAb['intl_revamp']) || ''
    // let ctUser = checkInternalCTUser()
    // if (ctUser || getCookie("ct_flights_new")) {
    //   return "b"
    // }
    if (!isEmpty(value)) {
        return value
    }
    return null
}

export const getFlightLogo = airline => {
    return `https://ui.cltp.co/images/logos/air-logos/svg_logos/${airline}.svg`
}

export function dateDiff(date1, date2, diffType = 'year') {
    const dDate1 = dayjs(date1)
    const dDate2 = dayjs(date2)
    const diffYear = dDate2.diff(dDate1, diffType, true)
    return diffYear
}

const splitProccessor = (element, title, description) => {
    if (element === title) {
        return (
            <Typography variant="HM4" className="mr-1">
                {description}
            </Typography>
        )
    }
    return (
        <Typography variant="P2" className="mr-1">
            {element}
        </Typography>
    )
}

export const replacePlaceHolder = (text = '', placeholders = []) => {
    let formattedText = text
    if (!isEmpty(text) && !isEmpty(placeholders)) {
        formattedText = placeholders.reduce((desc, ele) => {
            const { description = '', title = '' } = ele
            return stringSplitter(desc, ' ', ele => splitProccessor(ele, title, description))
        }, text)
    }
    return formattedText
}

export const replacePlaceHolderText = (text = '', placeholders = [], replaceClass = '') => {
    let formattedText = text
    if (!isEmpty(text) && !isEmpty(placeholders)) {
        formattedText = placeholders.reduce((desc, ele, index) => {
            const { description = '', title = '' } = ele
            return desc.replace(`${title}`, `<span class=${replaceClass}>${description}</span>`)
        }, text)
    }
    return formattedText
}

export function checkingFlightsValidTimings(sectorTime) {
    if (sectorTime < ARRIVAL_DEPARTURE_OVERLAP_TIME) {
        return LESS_THAN_ZERO
    } else if (sectorTime < ARRIVAL_DEPARTURE_THRESHOLD) {
        return LESS_THAN_THIRTY
    }
    return true
}
export const getCurrencyFraction = () => {
    return { minimumFractionDigits: 0 }
}
export const replacePlaceHolderString = (text, data) => {
    if (!isEmpty(text) && !isEmpty(data)) {
        const placeholders = text.match(/[^{\}]+(?=})/g)
        text = text.replace(/[{}]/g, '')
        if (!isEmpty(placeholders)) {
            placeholders.forEach((item, index) => {
                text = text.replace(placeholders[index], data[placeholders[index]])
            })
        }
    }
    return text
}

export const checkShowMediCancel = (departDate = '') => {
    let showMediCancel = false
    if (!featureConfig[buildVariant][MEDICANCEL]) {
        return showMediCancel
    }
    if (!isEmpty(departDate)) {
        const depDate = dayjs(departDate, 'DD/MM/YYYY')
        const currentDate = dayjs(new Date())
        const daysCount = depDate.diff(currentDate, 'days')
        if (daysCount < 90) {
            showMediCancel = true
        }
    }
    return showMediCancel
}

export const amendmentLocalStorageToQueryCoverter = () => {
    if (isServer()) {
        return {}
    }
    const searchAPIData = JSON.parse(localStorage.getItem('searchAPIData'))
    const { paxList, paxIds } = searchAPIData

    let adults = 0
    let childs = 0
    let infants = 0

    paxList.forEach(pax => {
        if (pax.paxType == 'ADT' && paxIds.indexOf(pax.id) > -1) {
            adults++
        } else if (pax.paxType == 'CHD' && paxIds.indexOf(pax.id) > -1) {
            childs++
        } else if (paxIds.indexOf(pax.id) > -1) {
            infants++
        }
    })
    // searchQuery?.adults + searchQuery?.childs + searchQuery?.infants
    let finalQueryObject = {
        searchAPIData: { ...searchAPIData },
        adults,
        childs,
        infants,
        from: searchAPIData.from,
        to: searchAPIData.to,
        depart_date: searchAPIData.depart_date,
        return_date: searchAPIData.return_date,
        intl: searchAPIData.intl ? 'y' : 'n',
        paxIds: searchAPIData.paxIds.toString(),
        flightIds: searchAPIData.flightIds.toString(),
        tripId: searchAPIData.tripId,
        class: getAmendClassType(searchAPIData.cabinClass),
        sft: getAmendPTCType(searchAPIData.sft)
    }
    return finalQueryObject
}
export function getAmendPTCType(ptc) {
    switch (ptc) {
        case 'STUDENT':
            return 'student'
        case 'ARMY':
            return 'army'
        case 'SENIOR_CITIZEN':
            return 'senior_citizen'
        default:
            return ''
    }
}
export function getAmendClassType(cabinClass) {
    switch (cabinClass) {
        case 'ECONOMY':
            return 'Economy'
        case 'PREMIUM_ECONOMY':
            return 'Premium Economy'
        case 'BUSINESS':
            return 'Business'
        case 'FIRST':
            return 'First'
        default:
            return cabinClass
    }
}

export const shouldShowCodBanner = (showCodBanner, to, from) => {
    return !isEmpty(showCodBanner) && (showCodBanner.includes(to) || showCodBanner.includes(from))
}

export const showIntlFlexMaxBanner = (searchQuery, abTest) => {
    const { isIntl = false, flightClass = '', returnDate = '', departDate = '' } = searchQuery || {}
    const cookieCheck = abTest?.intlflexmax === 'b' || getCookie('intlflexmax')
    const flightClassCheck = flightClass?.toUpperCase() === 'ECONOMY'
    const oneWayCheck = !returnDate
    const dx = dayDiff(new Date(getFormattedDate(departDate || '', 'MM/DD/YYYY', 'DD/MM/YYYY')), new Date())
    const availablityCheck = dx >= 3 && dx <= 90
    return cookieCheck && flightClassCheck && oneWayCheck && availablityCheck && !isFkEnv
}
export const filterHomeWidgetsWithClevertap = (bannerList, clevertapConfig) => {
    const updatedBannerList = []

    if (isEmpty(bannerList)) {
        return bannerList
    } else {
        bannerList.forEach(widget => {
            if (!isEmpty(widget?.[0])) {
                const isFCCAd = widget[0].slotId
                const inCarousel = widget[0].inCarousel
                const isDynamicWidget = widget[0].dynamicWidget

                /* FK ads */
                if (isFCCAd) {
                    updatedBannerList.push(widget)
                    /* old and new widgets from banner json */
                } else {
                    /* directly push it if it's a static widget */
                    if (!isDynamicWidget) {
                        updatedBannerList.push(widget)
                        /* else check if it is enabled from clevertap */
                    } else {
                        //here
                        const label = inCarousel
                            ? path(['banner', '0', 'label'], widget[0])
                            : isDynamicWidget
                            ? widget[0]?.mSlotId
                            : widget[0]?.label
                        widget[0].enabled = !!clevertapConfig?.[label]?.enabled
                        ;(widget[0].enabled || clevertapConfig === null) && updatedBannerList.push(widget)
                    }
                }
            } else {
                updatedBannerList.push(widget)
            }
        })
    }

    return updatedBannerList
}
