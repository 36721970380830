import React from 'react';

const CheckboxMinus = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='12'
            height='12'
            fill='none'
            viewBox='0 0 12 1'
        >
            <path
                stroke='#FFFFFF'
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M1.33334 1H10.6667'
            ></path>
        </svg>
    );
};

export default CheckboxMinus;