import { isEmpty, path } from './genralUtils'

export const isServer = () => {
    return typeof window === 'undefined' || !window
}

export const isMobileSite = () => {
    if (isServer()) return

    let isApp = false
    if (path(['androidData', 'app-agent'], window)) {
        isApp = true
    }

    if (path(['iosData', 'app-agent'], window)) {
        isApp = true
    }
    return isApp
}

export const isApp = (query?: { isMobileApp: any }) => {
    if (query && query.isMobileApp) {
        return query
    }
    if (isServer()) return

    const queryParams = {}
    let appData = null
    if (isMobileSite()) {
        queryParams.isMobileApp = 'true'
    }
    if (path(['sessionStorage'], window) && typeof window.sessionStorage !== 'undefined') {
        appData = sessionStorage.getItem('mobileApp')
    }
    if (appData) {
        queryParams.isMobileApp = 'true'
    }
    return queryParams
}

export const onPageStart = (type = '') => {
    if (isServer()) return

    if (path(['androidData', 'app-agent'], window) && path(['androidData', 'js-version'], window)) {
        if (path(['sessionStorage'], window) && typeof window.sessionStorage != 'undefined') {
            sessionStorage.setItem('mobileApp', 'AndroidApp')
        }
        window.MobileApp.onPageStart(type)
    }
    if (path(['iosData', 'app-agent'], window) && path(['iosData', 'js-version'], window)) {
        if (path(['sessionStorage'], window) && typeof window.sessionStorage != 'undefined') {
            sessionStorage.setItem('mobileApp', 'iPhoneApp')
        }
        if (path(['webkit', 'messageHandlers'], window) && window.webkit.messageHandlers[type]) {
            window.webkit.messageHandlers[type].postMessage('')
        }
    }
}
export const triggerCallBack = (callbackMsg = '') => {
    if (isServer()) return

    if (path(['androidData', 'app-agent'], window) && path(['androidData', 'js-version'], window)) {
        window.MobileApp.onBackBtnClick(callbackMsg)
    }
    if (path(['iosData', 'app-agent'], window) && path(['iosData', 'js-version'], window)) {
        window.webkit.messageHandlers[callbackMsg].postMessage('')
    }
}

export const sendBranchEvent = (eventData: { [x: string]: string; key?: any }) => {
    if (isServer()) return
    eventData = JSON.parse(JSON.stringify(eventData))
    if (
        path(['androidData', 'app-agent'], window) &&
        path(['androidData', 'js-version'], window) &&
        parseInt(path(['androidData', 'js-version'], window)) >= 2
    ) {
        eventData['platform'] = 'Android'
        eventData['app_type'] = 'webview'
        // window.MobileApp.onEventDetailsForBranch(JSON.stringify(eventData))
        // window.MobileApp.onEventDetailsForGA(JSON.stringify(eventData))
    }
    if (
        path(['iosData', 'app-agent'], window) &&
        path(['iosData', 'js-version'], window) &&
        parseInt(path(['iosData', 'js-version'], window)) >= 2
    ) {
        eventData['platform'] = 'iOS'
        eventData['app_type'] = 'webview'
        // window.webkit.messageHandlers['BRANCH_EVENTS'].postMessage(eventData)
        // window.webkit.messageHandlers['GA_EVENTS'].postMessage(eventData)
    }
}

export const checkInDomain = () => {
    let isInDomain = false
    let domain
    if (!isServer()) {
        domain = path(['location', 'hostname'], window || {})
    }
    if (
        domain === 'www.cleartrip.com' ||
        domain === 'qa2.cleartrip.com' ||
        domain === 'qa2new.cleartrip.com' ||
        domain === 'localhost' ||
        domain === '0.0.0.0'
    ) {
        isInDomain = true
    }
    return isInDomain
}

export const checkDeviceType = () => {
    let deviceType = null
    if (isServer()) {
        return deviceType
    }
    const userAgent = path(['navigator', 'userAgent'], window)?.toLowerCase()
    const safari = /safari/.test(userAgent) // checks if browser is safari
    const ios = /iphone|ipod|ipad/.test(userAgent) // checks if device is among iphone, ipad or ipod

    if (path(['androidData', 'app-agent'], window) && path(['androidData', 'js-version'], window)) {
        return 'AndroidApp'
    }
    if (
        (path(['iosData', 'app-agent'], window) && path(['iosData', 'js-version'], window)) ||
        (ios && !safari) // checks if device is of ios but not running in safari browser
    ) {
        return 'iPhoneApp'
    }
    if (path(['sessionStorage'], window) && typeof window.sessionStorage != 'undefined') {
        return sessionStorage.getItem('mobileApp')
    }
    return deviceType
}

export const getSpecificDeviceType = () => {
    let deviceType = checkDeviceType()

    if (deviceType === 'iPhoneApp') {
        deviceType = 'iOS'
    } else if (deviceType === 'AndroidApp') {
        deviceType = 'Android'
    } else {
        deviceType = 'pwa'
    }

    return deviceType
}

export const getJSVersion = () => {
    let device = checkDeviceType()
    let jsVersion
    if (device === 'iPhoneApp') {
        jsVersion = path(['iosData', 'js-version'], window)
    } else if (device === 'AndroidApp') {
        jsVersion = path(['androidData', 'js-version'], window)
    }
    if (jsVersion) {
        jsVersion = jsVersion.toString().split('.')[0]
    }
    return jsVersion
}

export const getQueryParams = () => {
    let urlSearchParams = path(['location', 'search'], window)
    let queryParams = {}
    if (urlSearchParams) {
        urlSearchParams = urlSearchParams.substring(1, urlSearchParams.length)
        const params = urlSearchParams.split('&')
        for (const val in params) {
            const [key, value] = params[val].split('=')
            queryParams[key] = value
        }
    }
    return queryParams
}

export const appendQuery = (query: any) => {
    const mobileApp = isApp(query)
    if (!isEmpty(mobileApp)) {
        if (
            path(['sessionStorage'], window) &&
            typeof window.sessionStorage != 'undefined' &&
            !isEmpty(window.sessionStorage.getItem('js-version'))
        ) {
            return `?isMobileApp=true&js-version=${window.sessionStorage.getItem('js-version')}`
        }
        return '?isMobileApp=true'
    }
    return ''
}

export const isMobileSiteJSversion = (jsVersion = 1) => {
    let isApp = false
    if (
        path(['androidData', 'app-agent'], window) &&
        path(['androidData', 'js-version'], window) &&
        path(['androidData', 'js-version'], window) >= jsVersion
    ) {
        isApp = true
    }

    if (
        path(['iosData', 'app-agent'], window) &&
        path(['iosData', 'js-version'], window) &&
        path(['iosData', 'js-version'], window) >= jsVersion
    ) {
        isApp = true
    }
    return isApp
}

export const isIos = () => {
    return (
        (!isServer() && /iPad|iPhone|iPod/.test(navigator.userAgent)) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    )
}

export const sendEventsToMobileBridge = eventData => {
    eventData = JSON.parse(JSON.stringify(eventData))
    eventData['key'] = eventData.event
    if (
        path(['androidData', 'app-agent'], window) &&
        path(['androidData', 'js-version'], window) &&
        parseInt(path(['androidData', 'js-version'], window)) >= 2
    ) {
        eventData['platform'] = 'Android'
        window.MobileApp.onEventDetailsForBranch(JSON.stringify(eventData))
        window.MobileApp.onEventDetailsForGA(JSON.stringify(eventData))
    }
    if (
        path(['iosData', 'app-agent'], window) &&
        path(['iosData', 'js-version'], window) &&
        parseInt(path(['iosData', 'js-version'], window)) >= 2
    ) {
        eventData['platform'] = 'iOS'
        window.webkit.messageHandlers['BRANCH_EVENTS'].postMessage(eventData)
        window.webkit.messageHandlers['GA_EVENTS'].postMessage(eventData)
    }
}

export const isMsiteInAppWebview = () => {
    if (isServer()) return false
    const params = new Proxy(new URLSearchParams(path(['location', 'search'], window)), {
        get: (searchParams, prop) => searchParams.get(prop)
    })
    const userAgent = path(['navigator', 'userAgent'], window)?.toLowerCase()
    const safari = /safari/.test(userAgent) // checks if website is running in any browser in apple device
    const ios = /iphone|ipod|ipad/.test(userAgent) // checks if device is among iphone, ipad or ipod

    if (
        params.isMobileApp == 'true' || // checks if query parameters have isMobileApp = true
        (ios && !safari) || // checks if device is of ios but not running in browser
        path(['androidData', 'app-agent'], window) || // checks android data
        path(['iosData', 'app-agent'], window) || // checks ios data
        path(['sessionStorage', 'mSiteInAppWebview'], window) === 'true'
    ) {
        // checks session storage
        if (path(['sessionStorage'], window)) {
            window.sessionStorage.setItem('mSiteInAppWebview', 'true')
        }
        return true
    }
    return false
}

export function sendSigninDeviceIdNativeAndroidCallback() {
    if (path(['androidData', 'app-agent'], window) && path(['androidData', 'js-version'], window)) {
        window?.MobileApp?.saveDeviceIdCookie(
            JSON.stringify({
                cookie_value: 'ct-dvId'
            })
        )
    }
}

export function sendSigninDeviceIdNativeIosCallback() {
    if (path(['iosData', 'app-agent'], window) && path(['iosData', 'js-version'], window)) {
        window?.webkit?.messageHandlers['saveDeviceIdCookie']?.postMessage({
            cookie_value: 'ct-dvId'
        })
    }
}

export const getDevicePlatform = () => {
    if (!isServer()) {
        const userAgent = path(['navigator', 'userAgent'], window)?.toLowerCase()
        const safari = /safari/.test(userAgent) // checks if website is running in any browser in apple device
        const ios = /iphone|ipod|ipad/.test(userAgent) // checks if device is among iphone, ipad or ipod

        if (
            (path(['androidData', 'app-agent'], window) && path(['androidData', 'js-version'], window)) ||
            typeof path(['MobileApp', 'getAppSpecificData'], window) === 'function'
        ) {
            if (path(['sessionStorage'], window) && typeof window.sessionStorage != 'undefined') {
                sessionStorage.setItem('mobileApp', 'AndroidApp')
            }
            return 'Android'
        } else if (
            (path(['iosData', 'app-agent'], window) && path(['iosData', 'js-version'], window)) ||
            (ios && !safari)
        ) {
            if (path(['sessionStorage'], window) && typeof window.sessionStorage != 'undefined') {
                sessionStorage.setItem('mobileApp', 'iPhoneApp')
            }
            return 'iOS'
        }
    }
    return 'PWA'
}

export const getAppAgent = () => {
    const deviceType = checkDeviceType()
    let appAgent = 'PWA'
    if (deviceType === 'AndroidApp') {
        appAgent = path(['androidData', 'app-agent'], window)
    } else if (deviceType === 'iPhoneApp') {
        appAgent = path(['iosData', 'app-agent'], window)
    }
    return appAgent
}

export const getOSType = (deviceUserAgent = '') => {
    try {
        let osType = ''
        const userAgent = deviceUserAgent || (!isServer() && navigator.userAgent) || ''
        const isUltra = userAgent.includes('UltraSDK')
        const isIos = /iPhone|iPad|iPod/i.test(userAgent)
        const isAndroid = /Android/i.test(userAgent)
        if (isUltra) {
            switch (true) {
                case isIos:
                    return 'ios'
                case isAndroid:
                    return 'android'
            }
        }
        return osType
    } catch (ex) {
        return ''
    }
}

export const checkIfUltraContainer = (deviceUserAgent = '') => {
    try {
        const userAgent = deviceUserAgent || ''
        const isUltra = userAgent.includes('UltraSDK')
        return isUltra
    } catch (ex) {
        return false
    }
}
