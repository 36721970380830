import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
    toQueryString,
    isEmpty,
    path,
    getLanguageMeta,
    getCookie,
    getCurrency,
    mapOnObject,
    getUserAuthValues,
    checkDeviceType,
    writeCookie
} from '@ui/utils'
import { isUserSignedIn, utmGetUserPrefCurrency, checkInternalCTUser } from '@utils/flights'
import logError from '@utils/flights/logError'

import { FLIGHTS_BASE_PATH } from '@constants/flights/paths'

import { returnFareName } from '@ui/constants/fareType'
import Analytics from '@ui/utils/Analytics'
import { dataLayerGA, pushGAData } from '@utils/datalayer'
import { formatCurrency, setCookie } from '@utils/genralUtils'
import { buildVariant } from '@root/configs/Whitelabel'
import { featureConfig } from '@constants/flights/featureConfig'
import { checkInDomain } from '@utils/appAgent'
dayjs.extend(customParseFormat)

export const searchQueryUrl = (query, isAmendment) => {
    const queryObj = getSearchQueryObj(query, isAmendment)
    return toQueryString(queryObj)
}

export function sendSS1FailureAnalytics(obj) {
    const { message, airline = '', searchAnalyticsData = {} } = obj
    try {
        Analytics.event('Air_Failures', {
            ...searchAnalyticsData,
            failure_value: message,
            failure_type: 'SS1',
            channel: 'PWA',
            airline
        })
    } catch (e) {
        logError(e)
    }
}

export const getSearchQueryObj = (query, isAmendment) => {
    const {
        source,
        destination,
        departDate,
        returnDate,
        flightClass = 'Economy',
        travellers,
        sourceHeader,
        destinationHeader,
        isIntl,
        carrier,
        mobileWPEnabled,
        sft,
        dealId,
        isCfw
    } = query
    const { adults = 1, children = 0, infants = 0 } = travellers
    // let returnDateQuery = ''
    // let carrierQuery = ''
    const queryObj = {
        from: source,
        source_header: sourceHeader,
        to: destination,
        destination_header: destinationHeader,
        depart_date: departDate,
        class: flightClass,
        adults,
        childs: children,
        infants,
        mobileApp: true,
        intl: isIntl ? 'y' : 'n',
        responseType: 'jsonV3',
        source: 'MOBILE',
        utm_currency: 'INR',
        dc_enabled: true
    }
    if (query.sft) {
        queryObj.sft = query.sft
    }
    if (dealId) {
        queryObj.dealId = dealId
    }
    if (mobileWPEnabled) {
        queryObj.mobileWPEnabled = mobileWPEnabled
    }
    if (returnDate) {
        queryObj.return_date = returnDate
    }
    if (carrier) {
        queryObj.carrier = carrier
    }
    if (isAmendment) {
        queryObj.tripId = query.tripId
        queryObj.paxIds = query.paxIds
        queryObj.flightIds = query.flightIds
    }
    if (isCfw) {
        queryObj.cfw = true
    }
    return queryObj
}

export const searchAnalytics = (
    query,
    resultData = {},
    highRpsCardIndex = [],
    highRpsCard = [],
    abTest = {},
    pageload = false
) => {
    const commonData = getCommonAnalyticData(query, resultData)
    const searchQuery = query
    const {
        travellers: { adults, children, infants },
        departDate,
        returnDate
    } = searchQuery
    try {
        const { cards = [], filterData = {} } = resultData || {}
        let highRpsPrices = {}
        let highRpsFlightIds = ''
        for (let i = 0; i < 5; i++) {
            if (highRpsCard[i]) {
                const card_effprice = highRpsCard[i].priceBreakup.fare.coupon_detail.discountedPrice
                const card_flightID = highRpsCard[i].airlineCodes[0] + '-' + highRpsCard[i].splRtFn
                highRpsPrices['rps_flight_' + (i + 1) + '_effective_price'] = card_effprice
                highRpsFlightIds = highRpsFlightIds + card_flightID + ', '
            } else {
                highRpsPrices['rps_flight_' + (i + 1) + '_effective_price'] = 0
            }
        }
        let minIndex = isEmpty(highRpsCard) ? 0 : highRpsCard.length + 1
        let minNonHighRpsPrice = !isEmpty(resultData)
            ? resultData?.cards?.[0]?.[minIndex]?.priceBreakup?.fare?.coupon_detail?.discountedPrice || 0
            : 0

        let nbAirportClevertap = {}
        if (pageload) {
            nbAirportClevertap = {
                ...getDirectAvailableFlights(cards, filterData, query, abTest, resultData)
            }
        }

        const depDate = dayjs(departDate, 'DD/MM/YYYY')
        const retDate = dayjs(returnDate, 'DD/MM/YYYY')
        let ctUpgradeOfferList = getCtUpgradeOptions(cards, resultData)

        let highRpsInstrument = {
            high_rps_flights_available: highRpsCardIndex.length > 0 ? 'yes' : 'no',
            high_rps_flights_count: highRpsCardIndex.length,
            highRpsFlightIds: highRpsFlightIds,
            min_price_non_highrps: minNonHighRpsPrice,
            ...highRpsPrices
        }
        let searchAnalyticsData = {
            ...commonData,
            ...ctUpgradeOfferList,
            ...highRpsInstrument,
            search_type: !isEmpty(returnDate) ? 'RoundTrip' : 'One-Way',
            stay_duration: !isEmpty(returnDate) ? retDate.diff(depDate, 'days') : undefined,
            adult_count: adults,
            child_count: children,
            infant_count: infants,
            result_count: (cards?.[0] ? cards?.[0].length : 0) + (cards?.[1] ? cards?.[1].length : 0),
            travel_class: searchQuery.flightClass,
            srp_entry_source: 'flights_home',
            New_Coupon_Experience: abTest?.New_Coupon_Experience
        }
        if (pageload) {
            searchAnalyticsData = {
                ...searchAnalyticsData,
                ...nbAirportClevertap
            }
        }

        return searchAnalyticsData
    } catch (e) {
        logError(e)
    }

    return {
        ...commonData,
        adult_count: adults,
        child_count: children,
        infant_count: infants
    }
}

export function getDirectAvailableFlights(
    cards,
    filterData: object,
    query: object,
    abTest: object,
    resultData: object
) {
    let totalCount = (cards[0] ? cards[0].length : 0) + (cards[1] ? cards[1].length : 0)
    let nearbyAirportsCount = 0
    filterData?.nbAirportFilterPrev?.forEach(nbAirPort => {
        nbAirPort?.options.forEach(option => {
            nearbyAirportsCount +=
                option.subLabel && typeof option.subLabel == 'string' ? parseInt(option.subLabel.split(' ')[0]) : 0
        })
    })

    if (filterData?.nbAirportFilter?.length > 1) {
        let directCount = 0
        let finalCardListOw = [
            ...filterCards({ destinationAirportList: [], sourceAirportList: [] }, resultData, 0, true, query)
        ]
        directCount = finalCardListOw.length
        if (!(!query.returnDate || (query.returnDate && query.isIntl))) {
            let finalCardListRt = [
                ...filterCards({ destinationAirportList: [], sourceAirportList: [] }, resultData, 1, true, query)
            ]
            directCount += finalCardListRt.length
        }
        nearbyAirportsCount = totalCount - directCount
    }
    return {
        nearby_airports: filterData?.nbAirportFilter?.[0]?.options?.length || 0,
        direct_available_flights: totalCount - nearbyAirportsCount,
        nearby_airport_flights: nearbyAirportsCount,
        default_nearby_airports_on: abTest?.gh62 === 'b' ? (totalCount - nearbyAirportsCount < 8 ? 'yes' : 'no') : 'yes'
    }
}
function filterCards(filterData, resultsData, legNo, initialSort = false, query) {
    let FilteredSortedOneWayCards = [...resultsData.cards[legNo]]
    if (filterData.stops && filterData.stops.length !== 0) {
        const stopsSelectedValues = filterData.stops
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let stopsPresent = card.maxStopsInSectors + ''
            if (stopsPresent - 0 > 2 && stopsSelectedValues.indexOf('2') > -1) {
                return true
            }
            return stopsSelectedValues.indexOf(stopsPresent) > -1
        })
    }
    if (filterData.departure && filterData.departure.length !== 0) {
        const departureTimeSelectedValues = filterData.departure.filter(departureValue => {
            return departureValue.charAt(0) == legNo
        })
        if (departureTimeSelectedValues.length !== 0) {
            FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
                const depTimeAsNumber = parseInt(card.firstDeparture.time.replace(':', '').trim())
                let selectCard = false

                departureTimeSelectedValues.forEach(value => {
                    let timeFrame = value.split('-')[1]
                    const { start, end } = DEPARTURE_TIME_MAPPING[timeFrame].time
                    if (depTimeAsNumber >= start && depTimeAsNumber <= end) selectCard = true
                })

                return selectCard
            })
        }
        if (query.isIntl && query.returnDate) {
            const arrivalTimeSelectedValues = filterData.departure.filter(departureValue => {
                return departureValue.charAt(0) == 1
            })
            if (arrivalTimeSelectedValues.length !== 0) {
                FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
                    const arrTimeAsNumber = parseInt(
                        resultsData.sectors[card.sectorKeys[1]].firstDeparture.time.replace(':', '').trim()
                    )
                    let selectCard = false

                    arrivalTimeSelectedValues.forEach(value => {
                        let timeFrame = value.split('-')[1]
                        const { start, end } = DEPARTURE_TIME_MAPPING[timeFrame].time
                        if (arrTimeAsNumber >= start && arrTimeAsNumber <= end) selectCard = true
                    })

                    return selectCard
                })
            }
        }
    }
    if (filterData.benifits && Array.isArray(filterData.benifits) && filterData.benifits.indexOf('CT_UPGRADE') > -1) {
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let isCtUpgrade = false
            for (let i = 0; i < card.fareOfferList.length; i++) {
                const ctUpgradeCard = card.fareOfferList[i]
                if (ctUpgradeCard.ptype == 'CT_UPGRADE') {
                    isCtUpgrade = true
                    return true
                }
            }
            return isCtUpgrade
        })
    }
    if (filterData.airlines && filterData.airlines.length != 0) {
        const airlineSelectedValues = filterData.airlines
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let isAirlinePresent = false
            card &&
                card.airlineCodes.forEach(airlineCode => {
                    if (airlineSelectedValues.indexOf(airlineCode) > -1) {
                        isAirlinePresent = true
                    }
                })
            return isAirlinePresent
        })
    }
    //one way nearby airport intl and dom
    if (!query.returnDate && filterData.destinationAirportList && filterData.destinationAirportList.length != 0) {
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let selectedValues = filterData.destinationAirportList
            let sector = resultsData.sectors[card.sectorKeys[0]]
            return (
                selectedValues.indexOf(sector.lastArrival.airportCode) > -1 ||
                sector.lastArrival.airportCode == query.destination
            )
        })
    } else if (
        !query.returnDate &&
        filterData.destinationAirportList &&
        filterData.destinationAirportList.length == 0
    ) {
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let sector = resultsData.sectors[card.sectorKeys[0]]
            return sector.lastArrival.airportCode == query.destination
        })
    }
    if (!query.returnDate && filterData.sourceAirportList && filterData.sourceAirportList.length != 0) {
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let selectedValues = filterData.sourceAirportList
            let sector = resultsData.sectors[card.sectorKeys[0]]
            return (
                selectedValues.indexOf(sector.firstDeparture.airportCode) > -1 ||
                sector.firstDeparture.airportCode == query.source
            )
        })
    } else if (!query.returnDate && filterData.sourceAirportList && filterData.sourceAirportList.length == 0) {
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let sector = resultsData.sectors[card.sectorKeys[0]]
            return sector.firstDeparture.airportCode == query.source
        })
    }

    // intl rt nearby airport
    if (
        query.returnDate &&
        query.isIntl &&
        filterData.destinationAirportList &&
        filterData.destinationAirportList.length != 0
    ) {
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let selectedValues = filterData.destinationAirportList
            let sector = resultsData.sectors[card.sectorKeys[0]]
            return (
                selectedValues.indexOf(sector.lastArrival.airportCode) > -1 ||
                sector.lastArrival.airportCode == query.destination
            )
        })
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let selectedValues = filterData.destinationAirportList
            let sector = resultsData.sectors[card.sectorKeys[1]]
            return (
                selectedValues.indexOf(sector.firstDeparture.airportCode) > -1 ||
                sector.firstDeparture.airportCode == query.destination
            )
        })
    } else if (
        query.returnDate &&
        query.isIntl &&
        filterData.destinationAirportList &&
        filterData.destinationAirportList.length == 0
    ) {
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let sector = resultsData.sectors[card.sectorKeys[0]]
            return sector.lastArrival.airportCode == query.destination
        })
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let sector = resultsData.sectors[card.sectorKeys[1]]
            return sector.firstDeparture.airportCode == query.destination
        })
    }
    if (query.returnDate && query.isIntl && filterData.sourceAirportList && filterData.sourceAirportList.length != 0) {
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let selectedValues = filterData.sourceAirportList
            let sector = resultsData.sectors[card.sectorKeys[0]]
            return (
                selectedValues.indexOf(sector.firstDeparture.airportCode) > -1 ||
                sector.firstDeparture.airportCode == query.source
            )
        })
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let selectedValues = filterData.sourceAirportList
            let sector = resultsData.sectors[card.sectorKeys[1]]
            return (
                selectedValues.indexOf(sector.lastArrival.airportCode) > -1 ||
                sector.lastArrival.airportCode == query.source
            )
        })
    } else if (
        query.returnDate &&
        query.isIntl &&
        filterData.sourceAirportList &&
        filterData.sourceAirportList.length == 0
    ) {
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let sector = resultsData.sectors[card.sectorKeys[0]]
            return sector.firstDeparture.airportCode == query.source
        })
        FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
            let sector = resultsData.sectors[card.sectorKeys[1]]
            return sector.lastArrival.airportCode == query.source
        })
    }
    //dom rt nearby airport
    if (
        query.returnDate &&
        !query.isIntl &&
        filterData.destinationAirportList &&
        filterData.destinationAirportList.length != 0
    ) {
        //destination legno 1
        if (legNo) {
            FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
                let selectedValues = filterData.destinationAirportList
                let sector = resultsData.sectors[card.sectorKeys[0]]
                return (
                    selectedValues.indexOf(sector.firstDeparture.airportCode) > -1 ||
                    sector.firstDeparture.airportCode == query.destination
                )
            })
        } else {
            //destination legNo 0
            FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
                let selectedValues = filterData.destinationAirportList
                let sector = resultsData.sectors[card.sectorKeys[0]]
                return (
                    selectedValues.indexOf(sector.lastArrival.airportCode) > -1 ||
                    sector.lastArrival.airportCode == query.destination
                )
            })
        }
    } else if (
        query.returnDate &&
        !query.isIntl &&
        filterData.destinationAirportList &&
        filterData.destinationAirportList.length == 0
    ) {
        //destination legno 1
        if (legNo) {
            FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
                let sector = resultsData.sectors[card.sectorKeys[0]]
                return sector.firstDeparture.airportCode == query.destination
            })
        } else {
            //destination legNo 0
            FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
                let sector = resultsData.sectors[card.sectorKeys[0]]
                return sector.lastArrival.airportCode == query.destination
            })
        }
    }

    if (query.returnDate && !query.isIntl && filterData.sourceAirportList && filterData.sourceAirportList.length != 0) {
        //source legno 1
        if (legNo) {
            FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
                let selectedValues = filterData.sourceAirportList
                let sector = resultsData.sectors[card.sectorKeys[0]]
                return (
                    selectedValues.indexOf(sector.lastArrival.airportCode) > -1 ||
                    sector.lastArrival.airportCode == query.source
                )
            })
        } else {
            //source legNo 0
            FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
                let selectedValues = filterData.sourceAirportList
                let sector = resultsData.sectors[card.sectorKeys[0]]
                return (
                    selectedValues.indexOf(sector.firstDeparture.airportCode) > -1 ||
                    sector.firstDeparture.airportCode == query.source
                )
            })
        }
    } else if (
        query.returnDate &&
        !query.isIntl &&
        filterData.sourceAirportList &&
        filterData.sourceAirportList.length == 0
    ) {
        //source legno 1
        if (legNo) {
            FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
                let sector = resultsData.sectors[card.sectorKeys[0]]
                return sector.lastArrival.airportCode == query.source
            })
        } else {
            //source legNo 0
            FilteredSortedOneWayCards = FilteredSortedOneWayCards.filter(card => {
                let sector = resultsData.sectors[card.sectorKeys[0]]
                return sector.firstDeparture.airportCode == query.source
            })
        }
    }
    return FilteredSortedOneWayCards
}
export const DEPARTURE_TIME_MAPPING = {
    '0_8': {
        value: '0000',
        time: {
            start: 0,
            end: 800
        }
    },
    '8_12': {
        value: '0800',
        time: {
            start: 800,
            end: 1200
        }
    },
    '12_16': {
        value: '1200',
        time: {
            start: 1200,
            end: 1600
        }
    },
    '16_20': {
        value: '1600',
        time: {
            start: 1600,
            end: 2000
        }
    },
    '20_24': {
        value: '2000',
        time: {
            start: 2000,
            end: 2400
        }
    }
}
const getCtUpgradeOptions = (cards, resultsData) => {
    if (isEmpty(cards) || !Array.isArray(cards)) return 0

    const getSplitArraySortData = (arrayIndex, innerIndex) => {
        if (cards[arrayIndex] && cards[arrayIndex].length) {
            const cardId = cards[arrayIndex][innerIndex]
            const cardIdMap = getSplitId(cardId.id)
            return resultsData.cards[cardIdMap[0]][cardIdMap[1]] || {}
        }
        return {}
    }
    let ctUpgradeOfferList = {
        OW_upgrade_in_5: 0,
        OW_upgrade_in_10: 0,
        OW_upgrade_in_20: 0,
        OW_upgrade_in_all: 0,
        RT_upgrade_in_5: 0,
        RT_upgrade_in_10: 0,
        RT_upgrade_in_20: 0,
        RT_upgrade_in_all: 0
    }
    cards.forEach((journeyCards, mappingIndex) => {
        if (Array.isArray(journeyCards)) {
            journeyCards.forEach((card, index) => {
                const { promos = [] } = getSplitArraySortData(mappingIndex, index)
                if (isEmpty(promos) || !Array.isArray(promos)) return
                promos.forEach(promo => {
                    if (promo.ptype === 'CT_UPGRADE') {
                        if (index <= 4) {
                            ctUpgradeOfferList[`${mappingIndex == 0 ? 'OW' : 'RT'}_upgrade_in_5`]++
                        }
                        if (index <= 9) {
                            ctUpgradeOfferList[`${mappingIndex == 0 ? 'OW' : 'RT'}_upgrade_in_10`]++
                        }
                        if (index <= 19) {
                            ctUpgradeOfferList[`${mappingIndex == 0 ? 'OW' : 'RT'}_upgrade_in_20`]++
                        }
                        ctUpgradeOfferList[`${mappingIndex == 0 ? 'OW' : 'RT'}_upgrade_in_all`]++
                        return
                    }
                })
            })
        }
    })
    return ctUpgradeOfferList
}
export function getSplitId(id: string) {
    if (!id) return
    return id.split(':')
}

export function getCommonAnalyticData(searchQuery, specificData) {
    //specificData is resultData
    let isIntl = false
    const pathname = path(['location', 'pathname'], window) || ''
    if (pathname.indexOf('international') > -1) {
        isIntl = true
    }

    const isLoggedIn = isUserSignedIn() ? 'yes' : 'no'

    const {
        source,
        destination,
        departDate,
        returnDate,
        travellers: { adults, children, infants }
    } = searchQuery
    const currentDate = dayjs(new Date())
    const depDate = dayjs(departDate, 'DD/MM/YYYY')
    const retDate = dayjs(returnDate, 'DD/MM/YYYY')
    return {
        search_id: specificData?.sid,
        source: 'mobile',
        origin: source,
        destination,
        origin_name: specificData?.jsons?.ap[source]?.c,
        destination_name: specificData?.jsons?.ap[destination]?.c,
        dx: depDate.diff(currentDate, 'days'),
        dom_intl: specificData?.jsons?.searchType?.isIntl ? 'international' : 'domestic',
        srpFlow: searchQuery.srpFlow ? searchQuery.srpFlow : '',
        pax: adults + children + infants,
        departure_date: depDate ? `$D_${depDate.unix()}` : undefined,
        return_date: returnDate ? `$D_${retDate.unix()}` : undefined,
        journey_type: returnDate ? 'RoundTrip' : 'One-way',
        is_logged_in: isLoggedIn ? 'yes' : 'no',
        domain: window.location.host,
        user_pref_currency: utmGetUserPrefCurrency(),
        language: 'en',
        is_CFW_user:
            isLoggedIn === 'no'
                ? 'NOT_SIGNED_IN'
                : specificData?.jsons?.searchType?.WPStatus
                ? specificData?.jsons?.searchType?.WPStatus
                : 'NOT_SIGNED_IN'
    }
}

export function updateHomeSearch(obj) {
    try {
        if (typeof document != 'undefined') {
            let storageName = 'fsHistoryMobile'
            setCookie(storageName, JSON.stringify(obj),7);
            if (window.androidData && window.androidData['app-agent']) {
                window?.MobileApp?.setSearchCriteria(JSON.stringify(obj))
            } else if (window.iosData && window.iosData['app-agent']) {
                window.webkit.messageHandlers['MODIFY_SEARCH'].postMessage(obj)
            }else{
                setCookie(storageName, JSON.stringify(obj),'');
            }
        }

    } catch (e) {
        console.log('updateRecentSearch LC Error', e)
    }
}

export function sendUIActionAnalytics(searchQuery, resultData, payload) {
    try {
        const commonAnalyticData = getCommonAnalyticData(searchQuery, resultData)
        Analytics.event('Air_UI_Action', {
            ...commonAnalyticData,
            ...payload
        })
    } catch (e) {
        logError(e)
        console.log(e)
    }
}
function getSelectedCard(journyDetails, resultsData) {
    const selectedCardId = []
    for (let i = 0; i < journyDetails.length; i++) {
        if (journyDetails[i].cardDetails) {
            let id = resultsData?.sortData?.price_up[i].indexOf(journyDetails[i].cardDetails.id)
            selectedCardId.push(id)
        }
    }
    return selectedCardId
}
export const newInitiateBookingPayloadHelper = ({
    journyDetails = [],
    jsons,
    searchQuery,
    cfwEnabled = false,
    bookerId,
    fareFamilyEnabled,
    fareFamilyData,
    getAbTest,
    couponCode,
    resultsData,
    type = false,
    MandatoryLoginVariant,
    mevcExpType = '',
    isAppPush = false,
    appPushType = ''
}) => {
    const {
        source: from = '',
        destination: to = '',
        flightClass: cabinType = '',
        travellers: { adults, children, infants },

        sft = ''
    } = searchQuery || {}
    const { sectors } = resultsData || {}
    // let departDate = dayjs(journyDetails[0]?.cardDetails?.firstDeparture?.timestamp).format('DD/MM/YYYY')
    // let returnDate = journyDetails.length>1?  dayjs(journyDetails[1]?.cardDetails?.firstDeparture?.timestamp).format('DD/MM/YYYY') :''
    const isAppPushExp = checkInDomain() && isAppPush && !searchQuery?.isIntl
    const { psid, searchType } = jsons || {}
    const { isIntl: intl } = searchType || {}
    let departDate = dayjs(journyDetails[0]?.cardDetails?.firstDeparture?.timestamp).format('DD/MM/YYYY')
    let mevcSectorData = journyDetails[0].sectorKeys
    let sectorData = sectors[mevcSectorData[0]]
    let returnDate = intl
        ? searchQuery.returnDate
        : journyDetails.length > 1
        ? dayjs(journyDetails[1]?.cardDetails?.firstDeparture?.timestamp).format('DD/MM/YYYY')
        : ''
    const { locale } = getLanguageMeta()
    const selectedCardListId = getSelectedCard(journyDetails, resultsData)
    let showCorpFareFlag = false

    const source_affiliatefirstsource = getCookie('source_affiliatefirstsource')
    const source_firstpaidsource = getCookie('source_firstpaidsource')
    const source_firstsource = getCookie('source_firstsource')
    const source_Meta = getCookie('utm_source')

    const onwardFareArr = []
    const onwardCorpFareArr = []

    let returnFareArr = []
    let returnCorpFareArr = []

    if (intl && !isEmpty(returnDate)) {
        returnFareArr = onwardFareArr
        returnCorpFareArr = onwardCorpFareArr
    }
    // if(isEmpty(secondCardIndex)){}
    // const returnFareArr = isEmpty(secondCardIndex) ? onwardFareArr : []
    // const returnCorpFareArr = isEmpty(secondCardIndex) ? onwardCorpFareArr : []
    let onwardSplRtPrice = 0
    let onwardRetialPrice = 0
    let returnSplRtPrice = 0
    let returnRetialPrice = 0
    let returnSplRtPriceFlag = true
    journyDetails.forEach((journeyObj, index) => {
        const { cardDetails } = journeyObj || {}
        const { priceBreakup: { pr } = {} } = cardDetails || {}
        let onwardSplrtData =
            journyDetails.length > 1
                ? constructSplrt(journyDetails?.[1]?.cardDetails, journyDetails?.[0]?.cardDetails.splRtFn)
                : {}
        let returnSplrtData =
            journyDetails.length > 1
                ? constructSplrt(journyDetails?.[0]?.cardDetails, journyDetails?.[1]?.cardDetails.splRtFn)
                : {}
        if (!onwardSplrtData.splrtFare || !returnSplrtData.splrtFare) {
            returnSplRtPriceFlag = false
        }
        if (returnSplRtPriceFlag) {
            if (index) {
                returnSplRtPrice = returnSplrtData.splrtFare
                returnRetialPrice = pr
            } else {
                onwardSplRtPrice = onwardSplrtData.splrtFare
                onwardRetialPrice = pr
            }
        }
    })
    if (onwardRetialPrice + returnRetialPrice <= onwardSplRtPrice + returnSplRtPrice) {
        returnSplRtPriceFlag = false
    }
    journyDetails.forEach((journeyObj, index) => {
        const { cardDetails } = journeyObj || {}
        const {
            priceBreakup: {
                pr,
                fare = {},
                CORPObj: corpFare = {},

                SPLRT: splrtObj = {},
                SPECIAL_FARE = {}
            } = {},
            dfd
        } = cardDetails || {}
        const { bp, fk, fareGroupInformation, solutionId, fb } = type && !isEmpty(SPECIAL_FARE) ? SPECIAL_FARE : fare
        let onwardSplrtData =
            journyDetails.length > 1
                ? constructSplrt(journyDetails?.[1]?.cardDetails, journyDetails?.[0]?.cardDetails.splRtFn)
                : {}
        let returnSplrtData =
            journyDetails.length > 1
                ? constructSplrt(journyDetails?.[0]?.cardDetails, journyDetails?.[1]?.cardDetails.splRtFn)
                : {}
        let isSplrt = returnSplRtPriceFlag
        if (!onwardSplrtData.splrtFare || !returnSplrtData.splrtFare) {
            isSplrt = false
        }
        if (index === 0) {
            let splRtFareOnwd = false

            let {
                comboFareBasisCode: cfb = '',
                solutionId: sId = '',
                splrtFare = 0,
                splrtFk = '',
                splrtDfd = '',
                splrtBp = 0
            } = journyDetails.length > 1
                ? constructSplrt(journyDetails[0].cardDetails, journyDetails[1].cardDetails.splRtFn)
                : {}
            if (splrtFare && !type && isSplrt) {
                let obj = {
                    basePrice: splrtBp,
                    fareKey: splrtFk,
                    fareType: splrtDfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: splrtFare,
                    fareClass: 'SPECIAL_RT'
                }
                if (!isEmpty(sId)) {
                    obj = {
                        ...obj,
                        solutionId: sId,
                        comboFBC: cfb
                    }
                }
                onwardFareArr.push(obj)
                splRtFareOnwd = true
            } else if (
                fareFamilyEnabled &&
                !isEmpty(fareFamilyData) &&
                !isEmpty(fareFamilyData.fareDetails) &&
                (getAbTest.ff === 'b' || getAbTest.ff === 'c')
            ) {
                const { fareGroupInformation, fareDetails, vasInformation } = fareFamilyData || {}
                const { basePrice, fareClass, fareKey, fareType, totalPrice } = fareDetails || {}
                let onwardFare = {
                    basePrice,
                    fareKey,
                    fareType,
                    // fareKey:  fk,
                    // fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: totalPrice,
                    fareClass,
                    fareGroupInformation,
                    vasInformation
                    // ...fareFamilyData
                }
                if (solutionId) {
                    onwardFare = {
                        ...onwardFare,
                        solutionId: solutionId,
                        comboFBC:
                            fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                ? fareGroupInformation.comboFareBasisCode
                                : fb
                    }
                }
                onwardFareArr.push(onwardFare)
            } else if (
                fareFamilyEnabled &&
                !isEmpty(fareGroupInformation) &&
                (getAbTest.ff === 'b' || getAbTest.ff === 'c')
            ) {
                let onwardFare = {
                    basePrice: bp,
                    fareKey: fk,
                    fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: pr,
                    fareClass: 'RETAIL',
                    fareGroupInformation,
                    vasInformation: null
                }
                if (solutionId) {
                    onwardFare = {
                        ...onwardFare,
                        solutionId: solutionId,
                        comboFBC:
                            fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                ? fareGroupInformation.comboFareBasisCode
                                : fb
                    }
                }
                onwardFareArr.push(onwardFare)
            } else {
                let onwardFare = {
                    basePrice: bp,
                    fareKey: fk,
                    fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: type && !isEmpty(SPECIAL_FARE) ? SPECIAL_FARE.pr : pr,
                    fareClass: 'RETAIL'
                }
                if (solutionId) {
                    onwardFare = {
                        ...onwardFare,
                        solutionId: solutionId,
                        comboFBC:
                            fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                ? fareGroupInformation.comboFareBasisCode
                                : fb
                    }
                    if (type && !isEmpty(SPECIAL_FARE)) onwardFare.fareGroupInformation = fareGroupInformation
                }
                onwardFareArr.push(onwardFare)
            }
            if (!isEmpty(corpFare)) {
                if (splRtFareOnwd) {
                    let onwardFare = {
                        basePrice: bp,
                        fareKey: fk,
                        fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                        price: pr,
                        fareClass: 'RETAIL'
                    }
                    if (solutionId) {
                        onwardFare = {
                            ...onwardFare,
                            solutionId: solutionId,
                            comboFBC:
                                fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                    ? fareGroupInformation.comboFareBasisCode
                                    : fb
                        }
                    }
                    onwardFareArr.push(onwardFare)
                }
                showCorpFareFlag = true
                onwardCorpFareArr.push({
                    basePrice: corpFare.bp,
                    fareKey: corpFare.fk,
                    fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: corpFare.pr,
                    fareClass: 'CORPORATE'
                })
            }
        } else {
            let splRtFareRet = false

            let {
                comboFareBasisCode: cfb = '',
                solutionId: sId = '',
                splrtFare = 0,
                splrtFk = '',
                splrtDfd = '',
                splrtBp = 0
            } = journyDetails.length > 1
                ? constructSplrt(journyDetails[1].cardDetails, journyDetails[0].cardDetails.splRtFn)
                : {}
            if (splrtFare && !type && isSplrt) {
                let obj = {
                    basePrice: splrtBp,
                    fareKey: splrtFk,
                    fareType: splrtDfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: splrtFare,
                    fareClass: 'SPECIAL_RT'
                }
                if (!isEmpty(sId)) {
                    obj = {
                        ...obj,
                        solutionId: sId,
                        comboFBC: cfb
                    }
                }
                returnFareArr.push(obj)
                splRtFareRet = true
            } else {
                let returFareObj = {
                    basePrice: bp,
                    fareKey: fk,
                    fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: type && !isEmpty(SPECIAL_FARE) ? SPECIAL_FARE.pr : pr,
                    fareClass: 'RETAIL'
                }
                if (solutionId) {
                    returFareObj = {
                        ...returFareObj,
                        solutionId: solutionId,
                        comboFBC:
                            fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                ? fareGroupInformation.comboFareBasisCode
                                : fb
                    }
                    if (type && !isEmpty(SPECIAL_FARE)) returFareObj.fareGroupInformation = fareGroupInformation
                }
                returnFareArr.push(returFareObj)
            }
            if (!isEmpty(corpFare)) {
                if (splRtFareRet) {
                    let returFareObj = {
                        basePrice: bp,
                        fareKey: fk,
                        fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                        price: pr,
                        fareClass: 'RETAIL'
                    }
                    if (solutionId) {
                        returFareObj = {
                            ...returFareObj,
                            solutionId: solutionId,
                            comboFBC:
                                fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                    ? fareGroupInformation.comboFareBasisCode
                                    : fb
                        }
                    }
                    returnFareArr.push(returFareObj)
                }
                showCorpFareFlag = true
                returnCorpFareArr.push({
                    basePrice: corpFare.bp,
                    fareKey: corpFare.fk,
                    fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: corpFare.pr,
                    fareClass: 'CORPORATE'
                })
            }
        }
    })

    const flightParamsObj = {
        cabinType: cabinType ? cabinType.split(' ').join('_').toUpperCase() : 'ECONOMY',
        departDate: departDate ? formatDateToYYYYMMDD(departDate) : '',
        from: mevcExpType == 'mevcNearbyAirports' ? sectorData?.firstDeparture?.airportCode : from,
        returnDate: returnDate ? formatDateToYYYYMMDD(returnDate) : '',
        onwardFares: [...onwardFareArr, ...onwardCorpFareArr],
        returnFares: [...returnFareArr, ...returnCorpFareArr],
        to: mevcExpType == 'mevcNearbyAirports' ? sectorData?.lastArrival?.airportCode : to
    }
    const paxInfo = {
        adults,
        children,
        infants
    }
    let cfwObj = {}
    if (cfwEnabled) {
        cfwObj = {
            isCFWUser: cfwEnabled
        }
    }

    if (showCorpFareFlag) {
        cfwObj = { ...cfwObj, showCorpFare: showCorpFareFlag }
    }
    if (cfwEnabled) {
        cfwObj = {
            ...cfwObj,
            bookerId: bookerId
        }
    }
    const abFeatures = ['FARE_BENEFIT_INFO']
    // if (getAbTest.ctUpgrade === "c") {
    //   abFeatures.push("CT_UPGRADE")
    //   abFeatures.push("FARE_BENEFIT_INFO")
    // } else if (getAbTest.ctUpgrade === "b") {
    //   abFeatures.push("FARE_BENEFIT_INFO")
    // }
    // if(featureConfig[buildVariant].SUPERCOINS && getAbTest.scV4 === 'b' && !isAppPushExp){
    //   abFeatures.push("SUPER_COIN_TOGGLE_ENABLED")
    // }
    let utmSourceObj = {}

    //   abFeatures.push('FARE_FAMILY_ON_REVIEW')

    //   if(getCookie('intlflexmax') || getAbTest?.intlflexmax === 'b'){
    //     abFeatures.push('CT_FLEX_MAX_ENABLED')
    //   }

    if (source_Meta || source_affiliatefirstsource || source_firstpaidsource || source_firstsource) {
        utmSourceObj = {
            'utm-source': source_Meta || source_affiliatefirstsource || source_firstpaidsource || source_firstsource
        }
    }

    const itineraryMeta = {
        // ...cfwObj,
        domain: locale,
        externalApi: false,
        international: intl,
        multiCity: false,
        sid: psid,
        abFeatures,
        sourceType: 'MOBILE',
        utmCurrency: utmGetUserPrefCurrency() ? utmGetUserPrefCurrency() : getCurrency(),
        convFeeRequired:
            getAbTest && (getAbTest['show_conv_fee'] === 'b' || getAbTest['show_conv_fee'] === 'c') ? true : false,
        ...cfwObj,
        ...utmSourceObj,
        couponCode,
        trackingDetails: {
            selectedCardListId
        },
        sft
    }
    const payload = {
        flightParams: [flightParamsObj],
        itineraryMeta,
        paxInfo
    }

    return payload
}
function constructSplrt(card, splRtFn) {
    if (!card || !splRtFn || !card?.priceBreakup?.SPLRT) return
    let splrtFare = 0
    let solutionId = ''
    let comboFareBasisCode = ''
    let splrtArray = Object.keys(card.priceBreakup && card.priceBreakup.SPLRT)
    let splrtDfd = ''
    let splrtFk = ''
    let splrtBp = 0
    for (let i = 0; i < splrtArray.length; i++) {
        let currElement = splrtArray[i]
        let arraySplit = currElement.split(',')
        if (arraySplit.includes(splRtFn)) {
            const splrtElement = card['priceBreakup']['SPLRT'][currElement]
            const dfd = splrtElement['dfd']
            splrtDfd = splrtElement['dfd']
            splrtFare = splrtElement[dfd].pr

            splrtBp = splrtElement[dfd].bp
            splrtFk = splrtElement[dfd].fk
            solutionId = splrtElement[dfd].solutionIdMap[splRtFn]
            let fareGroupInformation = splrtElement[dfd].fareGroupInformation
            comboFareBasisCode =
                fareGroupInformation && fareGroupInformation.comboFareBasisCode
                    ? fareGroupInformation.comboFareBasisCode
                    : ''
            break
        }
    }

    return {
        splrtFare,
        solutionId,
        comboFareBasisCode,
        splrtFk,
        splrtDfd,
        splrtBp
    }
}

export const formatDateToYYYYMMDD = (str = '') => str.split('/').reverse().join('-')

export function goToNewFlow(itineraryData, router) {
    if (itineraryData && itineraryData.itineraryId) {
        const { itineraryId, meta: { ancillaryEnabled = false } = {} } = itineraryData
        router.push({
            pathname: `/flights/itinerary/${itineraryId}/info`,
            query: {
                ancillaryEnabled
            }
        })
    }
}

export const onPageStart = (type = '') => {
    if (path(['androidData', 'app-agent'], window) && path(['androidData', 'js-version'], window)) {
        if (path(['sessionStorage'], window) && typeof window.sessionStorage != 'undefined') {
            sessionStorage.setItem('mobileApp', 'AndroidApp')
            window.localStorage.setItem('DONT_SHOW_COOKIE_BANNER', 'true')
        }
        window.MobileApp.onPageStart(type)
    }
    if (path(['iosData', 'app-agent'], window) && path(['iosData', 'js-version'], window)) {
        window.localStorage.setItem('DONT_SHOW_COOKIE_BANNER', 'true')
        if (path(['sessionStorage'], window) && typeof window.sessionStorage != 'undefined') {
            sessionStorage.setItem('mobileApp', 'iPhoneApp')
        }
        if (path(['webkit', 'messageHandlers'], window) && window.webkit.messageHandlers[type]) {
            window.webkit.messageHandlers[type].postMessage('')
        }
    }
}

export function sendPageViewAnalytics(
    query,
    resultData,
    highRpsIndexes,
    appliedFilters,
    abTest,
    specialFareCard = [[], []],
    highRpsFlightCard = [],
    merchandisingData = {}
) {
    try {
        let lastPageName = 'flights_home'

        let result_count = resultData.cards[0].length
        if (query.returnDate && !query.isIntl) {
            result_count += resultData.cards?.[1].length
        }
        function getCardFromSpecialFare(outerInex, innerIndex) {
            return specialFareCard[outerInex][innerIndex]
        }
        function errorMessageFunction(arrOfCards) {
            let message = 'N/A'
            if (isEmpty(arrOfCards[0]) && isEmpty(arrOfCards[1])) message = 'no_ptc_flight_available'
            else if (isEmpty(arrOfCards[0])) message = 'only_return_ptc_flights_available'
            else if (isEmpty(arrOfCards[1])) message = 'only_onward_ptc_flights_available'
            return message
        }
        const cardMin = (specialFareCard[0].length && getCardFromSpecialFare(0, 0)) || {}
        const cardMax = (specialFareCard[0].length && getCardFromSpecialFare(0, specialFareCard[0].length - 1)) || {}
        let rightCardMin
        let rightCardMax
        if (resultData?.cards.length > 1) {
            rightCardMin = (specialFareCard[1].length && getCardFromSpecialFare(1, 0)) || {}
            rightCardMax = (specialFareCard[1].length && getCardFromSpecialFare(1, specialFareCard[1].length - 1)) || {}
        }
        let specialFareEvent = {
            ptc_fare: returnFareName(query.sft),
            ptc_onward_flights_count: specialFareCard[0].length || 0,
            ptc_onward_flights_min_price: (!isEmpty(cardMin) && cardMin.priceBreakup.SPECIAL_FARE.pr) || 0,
            ptc_onward_flights_max_price: (!isEmpty(cardMax) && cardMax.priceBreakup.SPECIAL_FARE.pr) || 0,
            ptc_flights_not_available_error: errorMessageFunction(specialFareCard)
        }
        if (resultData?.cards.length > 1) {
            specialFareEvent = {
                ...specialFareEvent,
                ptc_return_flights_count: specialFareCard[1].length || 0,
                ptc_return_flights_min_price:
                    (!isEmpty(rightCardMin) && rightCardMin.priceBreakup.SPECIAL_FARE.pr) || 0,
                ptc_return_flights_max_price: (!isEmpty(rightCardMax) && rightCardMax.priceBreakup.SPECIAL_FARE.pr) || 0
            }
        }
        let newSrpRevampData = {}
        if (query.isIntl) {
            newSrpRevampData = {
                num_of_coupon_cards_shown: merchandisingData?.length || 0,
                non_stop_flights: (
                    resultData?.cards[0]?.filter(item => {
                        return item.maxStopsInSectors === 0
                    }) || []
                ).length,
                Onward_Count: resultData.cards[0].length,
                Return_Count: query?.returnDate ? resultData.cards[0].length : 'N/A',
                is_new_srp_design: 'yes'
            }
        }
        const searchAnalyticsData = searchAnalytics(query, resultData, highRpsIndexes, highRpsFlightCard, abTest, true)
        ;(searchAnalyticsData.page_name = 'flights_srp'),
            (searchAnalyticsData.last_page_name = lastPageName),
            filterAnalytics(
                { preAppliedFilter: true },
                { nbAirportFilter: [{ selectedValues: [] }, { selectedValues: [] }] },
                query,
                resultData,
                appliedFilters,
                result_count,
                true,
                abTest
            )
        Analytics.event('Air_PageLoad', {
            ...searchAnalyticsData,
            ...specialFareEvent,
            ...newSrpRevampData,
            action_name: 'srp_land'
        })
        // dataLayerGA({ ...searchAnalyticsData, ...specialFareEvent }, "srp_land")

        Analytics.event('Air_Search', searchAnalyticsData)
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export function filterAnalytics(
    action,
    previousFilters,
    searchQuery,
    resultsData,
    appliedFilters,
    result_count,
    pageload = false,
    abTest,
    fromquickFilter = false,
    quickFilterSelected = [],
    isNewSrpDesign = false
) {
    // console.log(previousFilters, appliedFilters, searchQuery)
    let resultNbFilterData = resultsData.filterData.nbAirportFilter || {}
    let prevFilters = { ...previousFilters }
    let appFilters = { ...appliedFilters }
    if (resultNbFilterData?.length > 1) {
        prevFilters.sourceAirportList = previousFilters.nbAirportFilter[0].selectedValues
        prevFilters.destinationAirportList = previousFilters.nbAirportFilter[1].selectedValues
        appFilters.sourceAirportList = appFilters.nbAirportFilter[0].selectedValues
        appFilters.destinationAirportList = appliedFilters.nbAirportFilter[1].selectedValues
    } else if (resultNbFilterData?.length == 1) {
        if (resultNbFilterData[0].airportCode == searchQuery.source) {
            prevFilters.sourceAirportList = previousFilters.nbAirportFilter[0].selectedValues
            appFilters.sourceAirportList = appliedFilters.nbAirportFilter[0].selectedValues
        } else {
            prevFilters.sourceAirportList = previousFilters.nbAirportFilter[0].selectedValues
            appFilters.sourceAirportList = appliedFilters.nbAirportFilter[0].selectedValues
        }
    }
    let departure = []
    let depObj = {
        '0000': '0_8',
        '1200': '8_12',
        '1600': '12_16',
        '2000': '16_20',
        '0800': '20_24'
    }
    if (appFilters.departureTimeFrom[0].selectedValues.length > 0) {
        appFilters.departureTimeFrom[0].selectedValues.forEach(val => {
            departure.push(`0-${depObj[val]}`)
        })
    }
    if (appFilters.departureTimeFrom[1].selectedValues.length > 0) {
        appFilters.departureTimeFrom[1].selectedValues.forEach(val => {
            departure.push(`1-${depObj[val]}`)
        })
    }
    appFilters = { ...appFilters, departure: [...departure] }

    try {
        const { filterOptions } = action
        const srpFilterAnalyticsData = srpFilterAnalytics(
            searchQuery,
            resultsData,
            {
                prevFilters,
                splrtTabSelected: filterOptions && filterOptions.splrtTab,
                filtering: true,
                preAppliedFilter: action.preAppliedFilter,
                pageName: action.pageName
            },
            appFilters,
            result_count,
            abTest
        )
        let quickFilterSelectedText = quickFilterSelected.join()
        const { changedFilters, commonData } = srpFilterAnalyticsData
        if (changedFilters) {
            let payload = {
                ...commonData,
                filter_name: '',
                filter_type: fromquickFilter ? 'srp_quick_filter' : 'filter_sheet',
                filter_value: fromquickFilter
                    ? quickFilterSelectedText
                    : action.filterInfo &&
                      action.filterInfo.benifits &&
                      action.filterInfo.benifits.includes('CT_UPGRADE')
                    ? 'on'
                    : 'off',
                CT_upgrade_options: commonData.result_count,
                is_new_srp_design: isNewSrpDesign ? 'yes' : 'no'
            }

            Analytics.event('Air_Filters', payload)
            const finalData = {
                event: 'apply_filter_click',
                filter_type: fromquickFilter ? 'srp_quick_filter' : 'filter_sheet',
                filter_value: fromquickFilter
                    ? quickFilterSelectedText
                    : action.filterInfo &&
                      action.filterInfo.benifits &&
                      action.filterInfo.benifits.includes('CT_UPGRADE')
                    ? 'on'
                    : 'off',
                CT_upgrade_options: commonData.result_count,
                is_new_srp_design: isNewSrpDesign ? 'yes' : 'no'
            }
            pushGAData(finalData)
        }
    } catch (e) {
        console.log(e)
        logError(e)
    }
}

export function srpFilterAnalytics(searchQuery, resultData, action, appliedFilters, result_count, abTest) {
    try {
        let commonData = getCommonAnalyticData(searchQuery, resultData)
        const { previousFilters, splrtTabSelected } = action
        //todo
        const { sid, cards, filterData } = resultData
        const currentFilters = appliedFilters
        const otherPageData = {
            search_id: sid,
            result_count,
            //  displayList.reduce((total, list) => total + list.length, 0),
            pre_applied_filter: action.preAppliedFilter ? 'yes' : undefined // send undefined to clevertap if no data
        }
        const changedFilters = {}
        if (action.sorting) {
            const sortNameMapping = {
                price: 'price_sort',
                duration: 'duration_sort',
                depart: 'dep_sort'
            }
            const {
                sortInfo: { activeSort, sortType }
            } = action
            const sortValues = sortType === 'asc' ? 'sort_ascending' : 'sort_descending'
            changedFilters[sortNameMapping[activeSort]] = [sortValues]
        } else if (action.filtering) {
            const tripTypes = ['departure_time', 'return_time']
            const nameMapping = { airlines: 'airline', stops: 'stops' }
            if (splrtTabSelected) {
                changedFilters.special_fares_tab = currentFilters.airlines
            } else if (typeof currentFilters === 'object' && currentFilters !== null) {
                mapOnObject(currentFilters, (values, name) => {
                    if (previousFilters && JSON.stringify(values) !== JSON.stringify(previousFilters[name])) {
                        if (name === 'departure') {
                            values.forEach(time => {
                                const [way, depValue] = time.split('-')
                                const tripType = tripTypes[way]
                                if (!changedFilters[tripType]) {
                                    changedFilters[tripType] = []
                                }
                                changedFilters[tripType].push(
                                    FLIGHT_DEPART_FILTER_LABEL[depValue].text.toLowerCase().replace(/\s/, '_')
                                )
                            })
                        } else if (values.length) {
                            changedFilters[nameMapping[name]] = values
                        }
                    }
                })
            }
        }
        function getDirectAvailableFlights() {
            let totalCount = (cards[0] ? cards[0].length : 0) + (cards[1] ? cards[1].length : 0)
            let nearbyAirportsCount = 0
            let selectedAirports = ''

            filterData?.nbAirportFilter?.forEach((nbAirPort, index) => {
                selectedAirports =
                    selectedAirports +
                    `${index ? '/' : ''}` +
                    `${
                        nbAirPort && nbAirPort.selectedValues
                            ? nbAirPort?.selectedValues?.toString?.()?.replaceAll?.(',', '/') || ''
                            : ''
                    }`
            })

            let directCount = 0
            let finalCardListOw = [
                ...filterCards({ destinationAirportList: [], sourceAirportList: [] }, resultData, 0, true, searchQuery)
            ]
            directCount = finalCardListOw.length
            if (!(!searchQuery.returnDate || (searchQuery.returnDate && searchQuery.isIntl))) {
                let finalCardListRt = [
                    ...filterCards(
                        { destinationAirportList: [], sourceAirportList: [] },
                        resultData,
                        1,
                        true,
                        searchQuery
                    )
                ]
                directCount += finalCardListRt.length
            }
            nearbyAirportsCount = totalCount - directCount

            return {
                nearby_airports: filterData?.nbAirportFilter?.[0]?.options?.length || 0,
                direct_available_flights: totalCount - nearbyAirportsCount,
                nearby_airport_flights: nearbyAirportsCount,
                selected_nearby_airport: abTest?.gh62 === 'b' ? '' : selectedAirports,
                filter_type: 'nearby_airports',
                default_nearby_airports_on:
                    abTest?.gh62 === 'b' ? (totalCount - nearbyAirportsCount < 8 ? 'yes' : 'no') : 'yes'
            }
        }
        function getAppliedFilters() {
            let appliedAirlineFilter = ''
            currentFilters?.airlines.forEach(element => {
                appliedAirlineFilter += `${element}, `
            })
            let arrivalTimeFilters = ''
            currentFilters?.arrivalTimeFrom.forEach((element, index) => {
                if (index == 0) arrivalTimeFilters += 'Onward: '
                else arrivalTimeFilters += 'Return: '
                element.selectedValues.forEach(time => {
                    arrivalTimeFilters += `${time}, `
                })
            })
            let departureTimeFilters = ''
            currentFilters?.departureTimeFrom.forEach((element, index) => {
                if (index == 0) departureTimeFilters += 'Onward: '
                else departureTimeFilters += 'Return: '
                element.selectedValues.forEach(time => {
                    departureTimeFilters += `${time}, `
                })
            })
            let nearbyFilters = ''
            currentFilters?.nbAirportFilter.forEach((element, index) => {
                if (index == 0) nearbyFilters += 'Arrival Airport: '
                else nearbyFilters += 'Departure Airport: '
                element.selectedValues.forEach(airport => {
                    nearbyFilters += `${airport}, `
                })
            })
            let stopsFilters = ''
            currentFilters?.stops.forEach(element => {
                stopsFilters += `${element} stop, `
            })

            let priceFilter = currentFilters?.oneWayPrice
            let durationFilter = currentFilters?.tripDuration
            let layoverFilter = `${currentFilters?.layoverDurationMin} - ${currentFilters?.layoverDurationMax} hrs`
            let withBaggageFilter = currentFilters?.withBaggage ? 'yes' : 'no'

            return {
                airline_filter: appliedAirlineFilter,
                arrival_time_filters: arrivalTimeFilters,
                departure_time_filter: departureTimeFilters,
                nearby_airport_filters: nearbyFilters,
                stops: stopsFilters,
                price_filter: priceFilter,
                duration_filter: durationFilter,
                layover_filter: layoverFilter,
                baggae_filter: withBaggageFilter
            }
        }
        return {
            commonData: {
                ...commonData,
                ...otherPageData,
                ...getDirectAvailableFlights(),
                ...getAppliedFilters(),
                journey_type_uiaction: commonData['journey_type'],
                dom_intl_ui_action: commonData['dom_intl']
            },
            changedFilters
        }
    } catch (e) {
        console.log(e)
        logError(e)
        return {}
    }
}

export function sendClevertapPerformanceAnalytics(initialTS, currentTime, query, resultData, highRpsIndexes) {
    try {
        const searchAnalyticsData = searchAnalytics(query, resultData, highRpsIndexes)

        Analytics.event('Air_Performance', {
            ...searchAnalyticsData,
            search_time: `${(currentTime - initialTS) / 1000} sec`,
            channel: 'PWA'
        })
    } catch (e) {
        logError(e)
    }
}

export const sendClevertapUIActionEventWithAdditionalData = ({
    actionName,
    actionType = 'BUTTON',
    eventName = 'Air_UI_Action',
    additionalData = {}
}) => {
    const fullPayload = {
        action_name: actionName,
        action_type: actionType,
        domain: window.location.host,
        ...additionalData
    }
    Analytics.event(eventName, fullPayload)
}
export const DEPARTURE_TIME_MAPPING_OBJ = {
    '0000': {
        label: 'Early morning',
        subLabel: 'Midnight - 8 am',
        intlLabel: '12am_8am',
        value: '0000',
        time: {
            start: 0,
            end: 800
        }
    },
    '0800': {
        label: 'Morning',
        subLabel: '8 am - Noon',
        intlLabel: '8am_12pm',
        value: '0800',
        time: {
            start: 800,
            end: 1200
        }
    },
    '1200': {
        // label: langTranslate('Mid-day'),
        label: 'Afternoon',
        subLabel: 'Noon - 4 pm',
        intlLabel: '12pm_4pm',
        value: '1200',
        time: {
            start: 1200,
            end: 1600
        }
    },
    '1600': {
        label: 'Evening',
        subLabel: '4 pm - 8 pm',
        intlLabel: '4pm_8pm',
        value: '1600',
        time: {
            start: 1600,
            end: 2000
        }
    },
    '2000': {
        label: 'Night',
        subLabel: '8 pm - Midnight',
        intlLabel: '8pm_12am',
        value: '2000',
        time: {
            start: 2000,
            end: 2400
        }
    }
}
export function deepclone(item) {
    if (!item) {
        return item
    } // null, undefined values check

    var types = [Number, String, Boolean],
        result

    // normalizing primitives if someone did new String('aaa'), or new Number('444');
    types.forEach(function (type) {
        if (item instanceof type) {
            result = type(item)
        }
    })

    if (typeof result == 'undefined') {
        if (Object.prototype.toString.call(item) === '[object Array]') {
            result = []
            item.forEach(function (child, index, array) {
                result[index] = deepclone(child)
            })
        } else if (typeof item == 'object') {
            // testing that this is DOM
            if (item.nodeType && typeof item.cloneNode == 'function') {
                result = item.cloneNode(true)
            } else if (!item.prototype) {
                // check that this is a literal
                if (item instanceof Date) {
                    result = new Date(item)
                } else {
                    // it is an object literal
                    result = {}
                    for (var i in item) {
                        result[i] = deepclone(item[i])
                    }
                }
            } else {
                // depending what you would like here,
                // just keep the reference, or create new object
                if (false && item.constructor) {
                    // would not advice to do that, reason? Read below
                    result = new item.constructor()
                } else {
                    result = item
                }
            }
        } else {
            result = item
        }
    }

    return result
}

export function getNearByPayload(
    query: Record<string, any>,
    requestType?: 'NEARBY_DATES' | 'NEARBY_AIRPORTS' | 'DESTINATION_UPSELL',
    nearbyAirportFilter,
    resultsData
) {
    let { userId } = getUserAuthValues()

    const isLoggedIn = isUserSignedIn() ? 'LOGGED_IN' : 'NON_LOGGED_IN'
    let deviceId = getCookie('ct-dvId') || ''
    const deviceType = checkDeviceType()
    let appAgent = 'PWA'
    if (deviceType === 'AndroidApp') {
        appAgent = 'ANDROID'
    } else if (deviceType === 'iPhoneApp') {
        appAgent = 'IOS'
    }
    let { travellers, source, destination, flightClass, sft, departDate } = query
    const { adults, children, infants } = travellers
    let classType = 'ECONOMY'
    if (flightClass) flightClass = flightClass.toLowerCase()
    if (flightClass == 'business') {
        classType = 'BUSINESS'
    } else if (flightClass == 'premium economy') {
        classType = 'PREMIUM_ECONOMY'
    } else if (flightClass == 'first') {
        classType = 'FIRST'
    }
    let fareType = null
    if (sft == 'senior_citizen') {
        fareType = ['SENIOR_CITIZEN']
    } else if (sft == 'student') {
        fareType = ['STUDENT']
    } else if (sft == 'army') {
        fareType = ['ARMY']
    }
    let departDateArr = departDate.split('/')
    let departDateForPayload = `${departDateArr[2]}-${departDateArr[1]}-${departDateArr[0]}`
    let nearbyAirportPayload = {}
    if (requestType == 'NEARBY_AIRPORTS') {
        const { filterData } = resultsData
        const { nbAirportFilter } = filterData
        if (nbAirportFilter) {
            let selectedValuesOnward = []
            if (isEmpty(nearbyAirportFilter[0].selectedValues)) {
                nbAirportFilter[0].options.forEach(option => {
                    selectedValuesOnward.push(option.value)
                })
            } else {
                selectedValuesOnward = [...nearbyAirportFilter[0].selectedValues]
            }
            if (nbAirportFilter.length > 1) {
                let selectedValuesReturn = []
                if (isEmpty(nearbyAirportFilter[1].selectedValues)) {
                    nbAirportFilter[1].options.forEach(option => {
                        selectedValuesReturn.push(option.value)
                    })
                } else {
                    selectedValuesReturn = [...nearbyAirportFilter[1].selectedValues]
                }
                nearbyAirportPayload = {
                    ...nearbyAirportPayload,
                    selectedSourceAirports: [...selectedValuesOnward],
                    selectedDestinationAirports: [...selectedValuesReturn]
                }
            } else if (nbAirportFilter[0].airportCode == query.source) {
                nearbyAirportPayload = {
                    ...nearbyAirportPayload,
                    selectedSourceAirports: [...selectedValuesOnward],
                    selectedDestinationAirports: [query.destination]
                }
            } else {
                nearbyAirportPayload = {
                    ...nearbyAirportPayload,
                    selectedSourceAirports: [query.source],
                    selectedDestinationAirports: [...selectedValuesOnward]
                }
            }
        }
    }
    let payload = {
        demandContext: {
            userContext: {
                userId: userId || '',
                loginStatus: isLoggedIn,
                trafficDetails: {
                    partnerId: '10000000'
                }
            }
        },
        deviceContext: {
            deviceId: deviceId,
            appAgent: appAgent
        },
        responseContext: {
            sellingCountry: 'IN',
            sellingCurrency: 'INR'
        },
        queryContext: {
            requestType: requestType ?? 'NEARBY_DATES',
            paxInfo: {
                paxAdult: {
                    paxCount: adults,
                    paxFareTypes: fareType
                },
                paxChild: {
                    paxCount: children,
                    paxFareTypes: children ? fareType : null
                },
                paxInfant: {
                    paxCount: infants,
                    paxFareTypes: infants ? fareType : null
                }
            },
            cabinType: classType,
            sectors: [
                {
                    ...nearbyAirportPayload,
                    index: 1,
                    source: source,
                    destination: destination,
                    departDate: departDateForPayload
                }
            ]
        }
    }

    return payload
}
export function generateMevcMessage(recommendationBenefitsData: Record<string, any>) {
    if (isEmpty(recommendationBenefitsData)) return ''
    const { saving = 0, savingMessage = '' } = recommendationBenefitsData
    const message: string = savingMessage.replace(/\{savings\}/g, formatCurrency(saving))
    return message
}
