import styled from 'styled-components';
import { getTabContainerStyles } from './style';
import { TabContainerProps } from './type';

const TabContainer = styled.div<TabContainerProps>(({ boxShadow, theme }) =>
	getTabContainerStyles({
		boxShadow,
		theme,
	})
);

export default TabContainer;
