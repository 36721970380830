import React from 'react'
import Amend from '@assests/svg/AxisMembership/AmendBenefit'
import ConvFee from '@assests/svg/AxisMembership/ConvFeeBenefit'
import Meal from '@assests/svg/AxisMembership/MealBenefit'
import Seat from '@assests/svg/AxisMembership/SeatBenefit'
import { MEAL, SEAT } from '@utils/itinerary/addons'

export const AFFLUENT = 'AFFLUENT'
export const HNI = 'HNI'
export const RETAIL = 'RETAIL'
export const AXIS_BENEFITS = 'AXIS_BENEFITS'
export const errorDialogueType = 'COUPON_FAILURE'
export const successDialogueType = 'COUPON_SUCCESS'
export const REWARDS_FAILURE = 'REWARDS_FAILURE'
export const binRegex = /^\d{0,8}$/
export const CTCLUB = 'CTCLUB'
export const CTLOUNGE = 'CTLOUNGE'
export const CTVIP = 'CTVIP'
export const eligibleCoupons = [CTCLUB, CTLOUNGE, CTVIP]

export const fallbackErrorMsg = 'Something went wrong, please retry.'

export const gradientTextStyle = {
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    'background-clip': 'text',
    'text-fill-color': 'transparent'
}
export const bgGradient = {
    [RETAIL]: 'linear-gradient(231deg, rgba(189, 189, 189, 0.4) -9.18%, rgba(237, 237, 237, 0.4) 108.59%)',
    [AFFLUENT]: 'linear-gradient(231deg, rgba(214, 168, 116, 0.4) -9.18%, rgba(239, 201, 157, 0.4) 108.59%)',
    [HNI]: 'linear-gradient(255.32deg, rgba(61, 111, 160, 0.4) 59.7%, rgba(96, 160, 189, 0.4) 82.05%)'
}
export const textGradient = {
    [RETAIL]: 'linear-gradient(231deg, #BDBDBD -9.18%, #EDEDED 108.59%)',
    [AFFLUENT]: 'linear-gradient(231deg, #D6A874 -9.18%, #EFC99D 108.59%)',
    [HNI]: 'linear-gradient(255.78deg, #3D6FA0 55.25%, #60A0BD 82.56%)'
}
export const dividerGradient = {
    [RETAIL]:
        'linear-gradient(90deg, transparent, transparent 50%, rgb(58, 29, 41, 0.4) 50%, rgb(58, 29, 41) 100%), linear-gradient(255.32deg, #BDBDBD 59.7%, #EDEDED 82.05%)',
    [AFFLUENT]:
        'linear-gradient(90deg, transparent, transparent 50%, rgb(58, 29, 41, 0.4) 50%, rgb(58, 29, 41) 100%), linear-gradient(255.32deg, #D6A874 59.7%, #EFC99D 82.05%)',
    [HNI]: 'linear-gradient(90deg, transparent, transparent 50%, rgb(58, 29, 41, 0.4) 50%, rgb(58, 29, 41) 100%), linear-gradient(255.32deg, #3D6FA0 59.7%, #60A0BD 82.05%)'
}
export const borderColor = {
    [RETAIL]: 'rgb(189, 189, 189, 0.5)',
    [AFFLUENT]: 'rgb(214, 168, 116, 0.5)',
    [HNI]: 'rgb(61, 111, 160, 0.5)'
}
export const membershipTitle = {
    [RETAIL]: 'Club Member',
    [AFFLUENT]: 'Lounge Member',
    [HNI]: 'VIP Member'
}
export const memebershipTypeHeader = {
    [RETAIL]: 'Club',
    [AFFLUENT]: 'Lounge',
    [HNI]: 'VIP'
}

export const benefitIcon = {
    AXIS_DISCOUNTED_CONV_FEE: <ConvFee />,
    AXIS_DISCOUNTED_MEAL: <Meal />,
    AXIS_DISCOUNTED_SEAT: <Seat />,
    AXIS_DISCOUNTED_VAS: <Amend />
}

export const addonsToBenefitMap = {
    [SEAT]: 'AXIS_DISCOUNTED_SEAT',
    [MEAL]: 'AXIS_DISCOUNTED_MEAL'
}

export const includedCards = {
    [RETAIL]: 'My Zone, Neo & more',
    [AFFLUENT]: 'Select, Vistara & more',
    [HNI]: 'Magnus, Burgundy & more'
}

export const couponMsgConfig = {
    CTVIP,
    CTLOUNGE,
    CTCLUB
}
