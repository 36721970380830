import { CSSObject } from 'styled-components';
import { Theme } from '../../../token/theme';
import { StyledChipProps } from './type';
type CSSObject = /*unresolved*/ any

export const getStyledChipStyles = ({
	theme,
	backgroundColor,
	borderColor,
	borderRadius,
	borderWidth,
	cursor,
	paddingTop,
	paddingBottom,
}: StyledChipProps & { theme: Theme }): CSSObject => {
	return {
		paddingTop,
		paddingBottom,
		paddingLeft: theme.spacing[3],
		paddingRight: theme.spacing[3],
		width: 'fit-content',
		borderStyle: theme.border.style.solid,
		backgroundColor,
		borderColor,
		borderRadius,
		borderWidth,
		cursor,
		whiteSpace: 'nowrap'
	};
};
