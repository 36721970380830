import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../configureStore";
import { wpUserStatusFetch } from "@utils/flights/CFW";

interface userState {
  wpUserStatus?: string;
}
export interface userDataState {
  user?: userState;
}
const initialState: userDataState = {
  user: {
    wpUserStatus: "",
  },
};

export const fetchWpUserStatus = createAsyncThunk(
  "user/fetchWpStatus",
  async () => {
    try {
      const response = await wpUserStatusFetch();
      return response;
    } catch (e) {
      return "NOT_SIGNED_IN";
    }
  }
);

export const userConfigSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    extraReducers(builder) {
      builder.addCase(fetchWpUserStatus.fulfilled, (state, action) => {
        state.user.wpUserStatus = action.payload.data;
      });
      builder.addCase(fetchWpUserStatus.rejected, (state, action) => {
        state.user.wpUserStatus = "NOT_SIGNED_IN";
      });
    },
  },
});
const getWpUserStatus = (state: RootState) => state.auth.user?.wpUserStatus;

const { reducer, actions } = userConfigSlice;
const {} = actions || {};

export { getWpUserStatus };

export default reducer;
