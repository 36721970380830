import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../configureStore'

export interface StealDealDataState {
    stealDealResponse?: Record<string, any>
}
const initialState: StealDealDataState = {
    stealDealResponse: {}
}

export const stealDealDataSlice = createSlice({
    name: 'stealdeal',
    initialState,
    reducers: {
        setStealDealResponse: (state, action) => {
            state.stealDealResponse = {
                ...state.stealDealResponse,
                ...action.payload
            }
        }
    }
})

const getStealDealResponse = (state: RootState) => state.stealdeal.stealDealResponse

const { reducer, actions } = stealDealDataSlice
const { setStealDealResponse } = actions || {}

export { getStealDealResponse, setStealDealResponse }

export default reducer
