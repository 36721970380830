export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const SUCCESS = 'SUCCESS'
export const LIST_OF_META_SITES = ['wego', 'kayak', 'skyscanner', 'momondo']
export const HREFLANG: {[key: string]: string} = {"en-ae": "https://www.cleartrip.ae","ar-ae": "https://www.cleartrip.ae/ar","en-sa": "https://www.cleartrip.sa","ar-sa": "https://www.cleartrip.sa/ar","en-kw": "https://www.cleartrip.com.kw","ar-kw": "https://www.cleartrip.com.kw/ar","en-om": "https://www.cleartrip.om","ar-om": "https://www.cleartrip.om/ar","en-bh": "https://www.cleartrip.bh","ar-bh": "https://www.cleartrip.bh/ar","en-qa": "https://www.cleartrip.qa","ar-qa": "https://www.cleartrip.qa/ar","x-default": "https://www.cleartrip.ae"}
export const PAGE_PATH = {
  RESULTS:'/flights/international/results'
}

