import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../configureStore'

export interface UIDataState {
    toast?: Record<string, any>
    queryObj?: Record<string, any>
    isHomePageSearchClicked?: boolean
}
const initialState: UIDataState = {
    toast: {
        isVisible: false,
        showCloseButton: true,
        message: '',
        messageType: 'warning',
        messageClass: '',
        containerClass: ''
    },
    queryObj: {
        origin: '',
        destination: '',
        depart: '',
        returnOn: '',
        fClass: '',
        activeFare: '',
        travellers: [],
        fromHomePage: false,
        isIntl: false,
        tripType: ''
    },
    isHomePageSearchClicked: false
}

export const uiConfigSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        showToast: (state, action) => {
            state.toast = {
                ...state.toast,
                isVisible: true,
                message: action.payload.message,
                messageType: action.payload.messageType,
                messageClass: action.payload.messageClass,
                showCloseButton: action.payload.showCloseButton,
                containerClass: action.payload.containerClass
            }
        },
        hideToast: (state, action) => {
            state.toast = {
                ...state.toast,
                isVisible: false,
                message: null
            }
        },
        setQueryObj: (state, action) => {
            state.queryObj = action.payload
        },
        setHomePageSearch: (state, action) => {
            state.isHomePageSearchClicked = action.payload
        }
    }
})

const getToastData = (state: RootState) => state.ui.toast
const getQueryObj = (state: RootState) => state.ui.queryObj
const getIsHomePageSearch = (state: RootState) => state.ui.isHomePageSearchClicked

const { reducer, actions } = uiConfigSlice
const { showToast, hideToast, setHomePageSearch, setQueryObj } = actions || {}

export { showToast, hideToast, getToastData, setQueryObj, getQueryObj, setHomePageSearch }

export default reducer
