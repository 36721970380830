import { Children, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { ForwardRefComponentWithStyle } from '../../@types';
import { PopoverProps } from './type';
import Modal from '../Modal';
import Transition from '../Transition';
import Card from '../Card';

export function getOffsetTop(rect, vertical) {
	let offset = 0;

	if (typeof vertical === 'number') {
		offset = vertical;
	} else if (vertical === 'center') {
		offset = rect.height / 2;
	} else if (vertical === 'bottom') {
		offset = rect.height;
	}

	return offset;
}

export function getOffsetLeft(rect, horizontal) {
	let offset = 0;

	if (typeof horizontal === 'number') {
		offset = horizontal;
	} else if (horizontal === 'center') {
		offset = rect.width / 2;
	} else if (horizontal === 'right') {
		offset = rect.width;
	}

	return offset;
}

function getTransformOriginValue(transformOrigin) {
	return [transformOrigin.horizontal, transformOrigin.vertical]
		.map((n) => (typeof n === 'number' ? `${n}px` : n))
		.join(' ');
}

type PolymorphicPopover = ForwardRefComponentWithStyle<'div', PopoverProps>;

const Popover = forwardRef(
	(
		{
			anchorEl,
			anchorOrigin = {
				vertical: 'top',
				horizontal: 'left',
			},
			transformOrigin = {
				vertical: 'top',
				horizontal: 'left',
			},
			anchorPosition,
			onClose,
			open,
			children,
			minWidth,
			minHeight,
			...rest
		},
		forwardedRef
	) => {
		const paperRef = useRef<HTMLDivElement>(null);
		// get anchor offset
		const getAnchorOffset = useCallback(() => {
			const anchorElement = anchorEl || document.body;
			const anchorRect = anchorElement.getBoundingClientRect();
			return {
				top: anchorRect.top + getOffsetTop(anchorRect, anchorOrigin?.vertical),
				left: anchorRect.left + getOffsetLeft(anchorRect, anchorOrigin?.horizontal),
			};
		}, [anchorEl, anchorOrigin.horizontal, anchorOrigin.vertical]);

		// get transform origin
		const getTransformOrigin = useCallback(
			(elemRect) => {
				return {
					vertical: getOffsetTop(elemRect, transformOrigin?.vertical),
					horizontal: getOffsetLeft(elemRect, transformOrigin?.horizontal),
				};
			},
			[transformOrigin.horizontal, transformOrigin.vertical]
		);
		// get positioning style
		const getPositioningStyle = useCallback(
			(element: HTMLElement) => {
				const elemRect = {
					width: element.offsetWidth,
					height: element.offsetHeight,
				};
				const elemTransformOrigin = getTransformOrigin(elemRect);
				const anchorOffset = getAnchorOffset();

				let top = anchorOffset.top - elemTransformOrigin.vertical;
				let left = anchorOffset.left - elemTransformOrigin.horizontal;

				const bottom = top + elemRect.height;
				const right = left + elemRect.width;

				const heightThreshold = window.innerHeight - 16;
				const widthThreshold = window.innerWidth - 16;

				if (top < 16) {
					const diff = top - 16;
					top -= diff;
					elemTransformOrigin.vertical += diff;
				} else if (bottom > heightThreshold) {
					const diff = bottom - heightThreshold;
					top -= diff;
					elemTransformOrigin.vertical += diff;
				}

				if (left < 16) {
					const diff = left - 16;
					left -= diff;
					elemTransformOrigin.horizontal += diff;
				} else if (right > widthThreshold) {
					const diff = right - widthThreshold;
					left -= diff;
					elemTransformOrigin.horizontal += diff;
				}

				return {
					top: `${Math.round(top)}px`,
					left: `${Math.round(left)}px`,
					transformOrigin: getTransformOriginValue(elemTransformOrigin),
				};
			},
			[anchorEl, getAnchorOffset, getTransformOrigin]
		);

		const [isPositioned, setIsPositioned] = useState(open);
		// set positioning style
		const setPositioningStyles = useCallback(() => {
			const element = paperRef.current;

			if (!element) {
				return;
			}
			const positioning = getPositioningStyle(element);

			element.style.top = positioning.top;
			element.style.left = positioning.left;

			setIsPositioned(true);
		}, [getPositioningStyle]);

		const handleEntering = (element, isAppearing) => {
			setPositioningStyles();
		};

		const handleExited = () => {
			setIsPositioned(false);
		};

		useEffect(() => {
			if (open) {
				setPositioningStyles();
			}
		});

		return (
			<Modal ref={forwardedRef} blur={false} hideCrossIcon={false} open={open} onClose={onClose} size='NONE' {...rest}>
				<Transition type='grow' in={open} onEntering={handleEntering} onExited={handleExited} timeout={350}>
					<Card ref={paperRef} {...(isPositioned ? undefined : { style: { minHeight: '16px', opacity: 0 } })} css={{
                         position: 'absolute',
                         overflowY: 'auto',
                         overflowX: 'hidden',
                         // So we see the popover when it's empty.
                         // It's most likely on issue on userland.
                         minWidth: minWidth || '16px',
                         minHeight: minHeight || '16px',
                         maxWidth: 'calc(100% - 32px)',
                         maxHeight: 'calc(100% - 32px)',
                         // We disable the focus ring for mouse, touch and keyboard users.
                         outline: 0,
                    }}>
						{children}
					</Card>
				</Transition>
			</Modal>
		);
	}
) as PolymorphicPopover;

export default Popover;
