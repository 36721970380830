import React from 'react';

interface FeaturedImagesProps {
    width?: number;
    height?: number;
}

const FeaturedImages: React.FC<FeaturedImagesProps> = ({ height, width }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <rect
                width="24"
                height="24"
                y="0.234"
                fill="#FCF7F0"
                opacity="0.8"
                rx="2"
            ></rect>
            <mask
                id="mask0_1088_279757"
                style={{ maskType: 'alpha' }}
                width="21"
                height="21"
                x="1"
                y="2"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M1.998 2.232H21.998V22.232H1.998z"></path>
            </mask>
            <g mask="url(#mask0_1088_279757)">
                <path
                    fill="#DF9641"
                    d="M6.434 20.233l1.461-6.27-4.897-4.23 6.462-.561 2.538-5.939 2.539 5.939 6.461.56-4.897 4.232 1.461 6.27-5.564-3.314-5.564 3.313z"
                ></path>
            </g>
        </svg>
    );
};

FeaturedImages.defaultProps = {
    height: 24,
    width: 24,
};

export default FeaturedImages;