import React, { useEffect, useRef } from "react";

interface CircleProgressProps {
  progress: number;
  radius: number;
  strokeWidth: number;
  color: string;
  rating: number;
  ratingColor?: string;
}

const CircleProgress: React.FC<CircleProgressProps> = ({
  progress,
  radius,
  strokeWidth,
  color,
  rating,
  ratingColor = "#1A1A1A"
}) => {
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  const viewBox = `0 0 ${radius * 2} ${radius * 2}`;

  const circleRef = useRef<SVGCircleElement>(null);
  useEffect(() => {
    if (circleRef.current) {
      circleRef.current.style.strokeDashoffset = offset;
    }
  }, [offset, circleRef]);

  return (
    <svg width={radius * 2} height={radius * 2} viewBox={viewBox} className="circular__progress">
      <circle
        ref={circleRef}
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        fill="transparent"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference + " " + circumference}
        transform={`rotate(-90 ${radius} ${radius})`}
      />
      <text
        className="circular__text"
        x="49%"
        y="50%"
        dy=".36em"
        textAnchor="middle"
        fill={ratingColor}
        height={20}
      >
        {rating}
      </text>
    </svg>
  );
};

export default CircleProgress;
