import React from 'react';

const CalloutCross = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke="#1A1A1A"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18 6l-6 6m0 0l-6 6m6-6L6 6m6 6l6 6"
            ></path>
        </svg>
    );
};

export default CalloutCross;