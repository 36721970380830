import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../configureStore";

const initialState: {viewClicked:boolean} = {
  viewClicked: true,
};


export const highRpsSlice = createSlice({
  name: "highRps",
  initialState,
  reducers: {
    setViewClicked: (state, action) => {
        console.log(action.payload,"value clicked")
      state.viewClicked = action.payload
    },
  }
});

const getViewClicked = (state: RootState) => state.highRps.viewClicked;

const { reducer, actions } = highRpsSlice;
const { setViewClicked } = actions || {};

export { setViewClicked, getViewClicked };

export default reducer;
