import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <mask
        id="mask0_226_5850"
        style={{ maskType: "alpha" }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_226_5850)">
        <path
          fill="#fff"
          fillOpacity="0.8"
          d="M6.931 5.24a1.48 1.48 0 01-1.085-.453 1.48 1.48 0 01-.453-1.085c0-.421.151-.783.453-1.085a1.48 1.48 0 011.085-.453c.421 0 .783.15 1.085.453.303.302.454.664.454 1.085 0 .42-.151.783-.454 1.085a1.48 1.48 0 01-1.085.453zm-.306 11.052c-.504 0-.928-.147-1.273-.44-.344-.293-.567-.685-.667-1.176L3.093 6.66a.61.61 0 01.13-.546.648.648 0 01.526-.239c.154 0 .29.053.41.16.12.107.194.232.225.376L6.04 14.7a.346.346 0 00.116.177c.062.054.124.08.188.08h4.643c.172 0 .325.067.459.2a.639.639 0 010 .933.64.64 0 01-.466.2H6.624zm9.973 1.452a.719.719 0 01-.517.056.668.668 0 01-.403-.31l-1.939-3.365H7.96c-.354 0-.667-.11-.936-.33a1.39 1.39 0 01-.501-.848L5.595 8.44c-.137-.63.01-1.177.44-1.643.43-.467.944-.7 1.544-.7.438 0 .837.135 1.199.405a1.8 1.8 0 01.692 1.102l.944 4.355h2.755c.264 0 .51.07.736.208.227.138.409.323.546.554l2.38 4.125a.674.674 0 01.08.516.581.581 0 01-.313.383z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
