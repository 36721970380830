import React from 'react';
import useTheme from '../../hooks/useTheme';
import Container from '../Container';
import Typography from '../Typography';
import { BaseRadio, StyledRadio } from './BaseRadio';
import { getRadioStyles } from './style';
import { RadioProps } from './type';

const Radio: React.FC<RadioProps> = ({
	id,
	name,
	value,
	label = '',
	children,
	checked = false,
	disabled = false,
	gap,
	onChange,
	className
}) => {
	const theme = useTheme();
	const radioStyles = getRadioStyles({ theme, checked, disabled });
	const { cursor = 'pointer' } = radioStyles || {};
	return (
		<Container display='flex' alignItems='center' justifyContent='center' width='fit-content'>
			<label htmlFor={id}>
				<Container display='flex' alignItems='center' justifyContent='center'>
					<Container
						position='relative'
						display='flex'
						alignItems='center'
						justifyContent='center'
						height={theme.size[5]}
						width={theme.size[5]}
						className={className}
					>
						<BaseRadio
							id={id}
							name={name}
							checked={checked}
							value={value}
							onChange={disabled ? undefined : onChange}
							cursor={cursor}
						/>
						<StyledRadio {...radioStyles}></StyledRadio>
					</Container>
					{label && (
						<Container
							paddingLeft={gap || theme.spacing[2]}
							display='flex'
							alignItems='center'
							justifyContent='center'
							cursor={cursor}
						>
							<Typography variant='B2'>{label}</Typography>
						</Container>
					)}
					{children}
				</Container>
			</label>
		</Container>
	);
};

export default Radio;
