import React from "react";
import cn from "classnames";
import { ShimmerProps } from "./type";

const Shimmer: React.FC<ShimmerProps> = ({
  height,
  width,
  borderRadius = 0,
  shimmerWrapperClass,
  children
}) => {
  const style = {
    height,
    width,
    borderRadius
  };

  return (
    <div
      style={style}
      className={cn("stencil", "has-shimmer", `${shimmerWrapperClass}`)}
    >
      {children}
    </div>
  );
};

Shimmer.defaultProps = {
  children: null,
  shimmerWrapperClass: ""
};

export default Shimmer;
