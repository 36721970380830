import React, { RefObject } from "react"
export interface BottomSheetProps {
    /** Open prop value is responsible to show or hide the sheet  */
    open: boolean

    /** wrapperClassName will wrap all childern in styling  */

    wrapperClassName?: string

    /** whole content overide will wrap all childern in styling  */
    contentClassName?: string

    /** children node  */
    children?: React.ReactNode

    closeOnOutSideClick?: boolean

    showCloseIconOnHeader?: boolean

    /** Right now closeIconType have 2 type of icons as per below ClossIconType enum */
    closeIconType?: ClossIconType

    onClose: () => void

    /** headerText in modal */
    headingText?: any

    /**subheader text to show in bottom sheet */
    subHeaderText?: string

    /**header cta text to show in bottom sheet */
    headerCta?: string

    /**header cta action */
    headerCtaAction?: () => void

    showShadow?: boolean
    headingClass?: string
    noPadding?: boolean
    hashUrl?: string
    bottomSheetRef?: RefObject<HTMLDivElement>
    subHeaderClassName?: string 
    headingContainerClass?: string
    headerTextField?: any
    customCloseIconStyle?: {[key: string]: string | number}
}

export enum ClossIconType {
  CROSS = "CROSS",
  BACK = "BACK",
}
