import React, { useRef, useEffect } from 'react';
import { OutsideClickHandlerProps } from './type';

const OutsideClickHandler: React.FC<OutsideClickHandlerProps> = ({
    onOutsideClick,
    children,
    disabled,
    className,
    ...otherProps
}) => {
    const node = useRef() as React.MutableRefObject<HTMLDivElement>;
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (e : any) => {
        if (node.current.contains(e.target)) {
            return;
        } else {
            onOutsideClick();
        }
    };

    useEffect(() => {
        if (!disabled) {
            document.addEventListener('mousedown', handleClick, false);
            document.addEventListener('keydown', handleClick, false);
        }

        return () => {
            document.removeEventListener('mousedown', handleClick, false);
            document.removeEventListener('keydown', handleClick, false);
        };
    }, []);

    return (
        <div className={className} {...otherProps} ref={node}>
            {children}
        </div>
    );
};

OutsideClickHandler.defaultProps = {
    onOutsideClick: () => {},
    disabled: false,
    className: '',
};

export default OutsideClickHandler;
