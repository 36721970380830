import { ReactNode } from "react";
import type { IconPosition } from "../../@types";

export enum TabVariants {
  FLAT = "flat",
  CHIP = "chip",
}

export type TabListType = {
  id: string;
  label: string;
  Icon?: ReactNode;
  count?: number;
};

export enum TabSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export type TabSizeType = `${TabSize}`;

export interface NavTabsProps {
  tabType?: `${TabVariants}`;
  showIcon?: boolean;
  onClick: (value: string) => void;
  tabList: TabListType[];
  selectedTab?: string;
  tabGap?: string;
  iconPosition?: `${IconPosition}`;
  tabSize?: TabSizeType;
  showCounter?: boolean;
  containerSpace?: string;
  tabWidth?: string;
  textPadding?: string;
  showCounterAsTag?: boolean;
}
