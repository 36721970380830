import { onlyNumbersRegex } from '@constants/olditinerary'

export const defaultCountryCode = () => {
    const domain = typeof window === 'object' && window.location.hostname
    switch (domain) {
        case 'www.cleartrip.ae':
        case 'me.cleartrip.ae':
        case 'uat.cleartrip.ae':
            return { name: 'United Arab Emirates', code: '971', min: 8, max: 9 }

        case 'om.cleartrip.com':
        case 'qa2om.cleartrip.com':
        case 'om.cleartrip.sa':
        case 'uat.cleartrip.om':
        case 'www.cleartrip.om':
            return { name: 'Oman', code: '968', min: 8, max: 8 }

        case 'qa.cleartrip.com':
        case 'qa2qa.cleartrip.com':
        case 'qar.cleartrip.sa':
        case 'uat.cleartrip.qa':
        case 'www.cleartrip.qa':
            return { name: 'Qatar', code: '974', min: 8, max: 8 }

        case 'bh.cleartrip.com':
        case 'bh.cleartrip.sa':
        case 'uat.cleartrip.bh':
        case 'www.cleartrip.bh':
            return { name: 'Bahrain', code: '973', min: 7, max: 8 }

        case 'kw.cleartrip.com':
        case 'kw.cleartrip.sa':
        case 'uat.cleartrip.com.kw':
        case 'www.cleartrip.com.kw':
            return { name: 'Kuwait', code: '965', min: 7, max: 8 }

        case 'www.cleartrip.sa':
        case 'qa2.cleartrip.sa':
        case 'me.cleartrip.sa':
        case 'uat.cleartrip.sa':
            return { name: 'Saudi Arabia', code: '966', min: 9, max: 10 }

        default:
            return { name: 'Saudi Arabia', code: '966', min: 9, max: 10 }
    }
}

export const validateMobileNumber = (mobile, minLength, maxLength) => {
    if (mobile && mobile.length >= minLength && mobile.length <= maxLength && onlyNumbersRegex.test(mobile)) {
        return true
    }
    return false
}
