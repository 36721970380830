import { CSSProperties } from "styled-components"

/**
 * Polymorphic content
 */
/**
 * Polymorphic types are taken from https://github.com/radix-ui/primitives/blob/main/packages/react/polymorphic/src/polymorphic.ts
 * Due the deprecation, we decided to include these types inside Wanda's react-components.
 *
 * All the references and linceses are own by the team Radix
 */
import * as React from "react"
import { Theme } from "../token/theme"
import AttributeElementMapper from "./componentAttributeMapper"

/* -------------------------------------------------------------------------------------------------
 * Utility types
 * ----------------------------------------------------------------------------------------------- */
type Merge<P1 = Record<string, unknown>, P2 = Record<string, unknown>> = Omit<
  P1,
  keyof P2
> &
  P2

/**
 * Infers the OwnProps if E is a ForwardRefExoticComponentWithAs
 */
type OwnProps<E> = E extends ForwardRefComponent<any, infer P>
  ? P
  : Record<string, unknown>

/**
 * Infers the OwnProps if E is a ForwardRefExoticComponentWithAs
 */
type OwnPropsWithStyle<E> = E extends ForwardRefComponentWithStyle<any, infer P>
  ? P
  : Record<string, unknown>

/**
 * Infers the JSX.IntrinsicElement if E is a ForwardRefExoticComponentWithAs
 */
type IntrinsicElement<E> = E extends ForwardRefComponentWithStyle<infer I, any>
  ? I
  : never

type ForwardRefExoticComponent<E, OwnProps> = React.ForwardRefExoticComponent<
  Merge<
    E extends React.ElementType ? React.ComponentPropsWithRef<E> : never,
    OwnProps & { as?: E }
  >
>

/* -------------------------------------------------------------------------------------------------
 * ForwardRefComponent
 * ----------------------------------------------------------------------------------------------- */

interface ForwardRefComponent<
  IntrinsicElementString,
  OwnProps = Record<string, unknown>
  /**
   * Extends original type to ensure built in React types play nice
   * with polymorphic components still e.g. `React.ElementRef` etc.
   */
> extends ForwardRefExoticComponent<IntrinsicElementString, OwnProps> {
  /**
   * When `as` prop is passed, use this overload.
   * Merges original own props (without DOM props) and the inferred props
   * from `as` element with the own props taking precendence.
   *
   * We explicitly avoid `React.ElementType` and manually narrow the prop types
   * so that events are typed when using JSX.IntrinsicElements.
   */
  <As = IntrinsicElementString>(
    props: As extends ""
      ? { as: keyof JSX.IntrinsicElements }
      : As extends React.ComponentType<infer P>
      ? Merge<P, OwnProps & { as: As }>
      : As extends keyof JSX.IntrinsicElements
      ? Merge<JSX.IntrinsicElements[As], OwnProps & { as: As }>
      : never
  ): React.ReactElement | null
}

export type {
  ForwardRefComponent,
  IntrinsicElement,
  Merge,
  OwnProps,
  OwnPropsWithStyle,
}

export type ThemeType = "B2C" | "B2B" | 'FLIPKART'

export enum IconPosition {
  TOP = "top",
  LEFT = "left",
  RIGHT = "right",
}
export type CSSWithTheme = Merge<any, { theme: Theme }>

export type HtmlAttributes<Element, T> = Pick<
  React.AllHTMLAttributes<Element>,
  Exclude<keyof React.AllHTMLAttributes<Element>, keyof T | "css">
>

export type HtmlCompositeAttributes<
  T,
  e extends keyof AttributeElementMapper = "Element"
> = Merge<AttributeElementMapper[e], T>

export interface ForwardRefComponentWithStyle<T, E>
  extends ForwardRefComponent<T, Merge<E, { css?: any }>> {}

export type IconProps = {
  icon?: React.ReactNode
  iconPosition?: "left" | "right"
}

export interface containerCSS {
  Root: any
}

export type Color =
  | "primary"
  | "secondary"
  | "tertiary"
  | "heading"
  | "subheading"
  | "success"
  | "alert"
  | "warning"
  | "disabled"
  | "link"
  | "neutral"
  | "link2"

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never
export type MakeAllPartialExcluding<E, K extends keyof E> = Partial<
  Omit<E, K>
> &
  Required<Pick<E, K>>
