import { combineReducers } from '@reduxjs/toolkit'
import getAbConfig from './manager/abManager'
import resultData from './manager/resultManager'
import uiData from './manager/ui'
import searchData from './manager/searchManager'
import authdata from './manager/authManager'
import merchandisingData from './manager/merchandisingManager'
import travelSafeData from './manager/travelSafeManager'
import flexiData from './manager/flexiManager'
import emiEligibilityData from './manager/emiEligibility'
import emiAffordabilityData from './manager/emiAffordability'
import transitVisaData from './manager/transitVisaManager'
import baggageData from './manager/baggageManager'
import fareRulesData from './manager/fareRulesManager'
import itineraryData from './manager/itineraryManager'
import stealdeal from './manager/stealdeals'
import confirmation from './manager/confirmationManager'
import trips from './manager/tripDetailsManager'
import highRps from './manager/highRpsManager'
import ultra from './manager/ultraManager'
import amendment from './manager/amendmentManager'
import benefitsData from './manager/benefitsManager'
import srpSeachData from './manager/srpManager'
import oldItineraryData from './manager/oldItineraryManager'

const createReducer = (injectedReducers = {}) =>
    combineReducers({
        abConfig: getAbConfig,
        benefits: benefitsData,
        result: resultData,
        ui: uiData,
        search: searchData,
        auth: authdata,
        emiEligibility: emiEligibilityData,
        emiAffordability: emiAffordabilityData,
        merchandising: merchandisingData,
        travelSafe: travelSafeData,
        flexi: flexiData,
        transitVisa: transitVisaData,
        baggage: baggageData,
        fareRules: fareRulesData,
        itinerary: itineraryData,
        stealdeal: stealdeal,
        trips: trips,
        highRps: highRps,
        ultra,
        confirmation,
        amendment: amendment,
        srpSeachData,
        oldItineraryData,
        ...injectedReducers
    })

export default createReducer()
