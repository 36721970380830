import { CLEARTRIP, FLIPKART } from '@constants/flights'
import { FLIGHTS_DOM_RESULTS, FLIGHTS_INTL_RESULTS } from '@constants/flights/paths'
import { buildVariant } from '@root/configs/Whitelabel'
import { fromQueryString, isEmpty } from '@ui/utils'

const fkToCleartripTravelClassMap: any = {
    w: 'Premium Economy',
    e: 'Economy',
    b: 'Business'
}

const cleartripToFkTravelClassMap: any = {
    'Premium Economy': 'w',
    Economy: 'e',
    Business: 'b'
}

const fkToCleartripSftMap: any = {
    s: 'student',
    sc: 'senior_citizen',
    a: 'army'
}

const cleartripToFkSftMap: any = {
    student: 's',
    senior_citizen: 'sc',
    army: 'a'
}

function mapDateFormat(inputDate: string) {
    const day = inputDate.substring(0, 2)
    const month = inputDate.substring(2, 4)
    const year = inputDate.substring(4, 8)
    const formattedDate = `${day}/${month}/${year}`
    return formattedDate
}

export function getCtUrlParams(urlParams: any) {
    const { sft, srpFlow } = urlParams

    const tripParams = urlParams.trips || ''
    const [onwardLeg = '', returnLeg = ''] = tripParams.split(',')
    const [from, to, depart_date] = onwardLeg.split('-')
    const [, , return_date] = returnLeg.split('-')

    const travellersParam = urlParams.travellers
    const [adults, childs, infants] = travellersParam?.split('-').map(Number)

    const tripType = urlParams.tripType
    const isRoundTrip = tripType === 'ROUND_TRIP'
    const intl = urlParams.isIntl == 'true' ? 'y' : 'n'
    const classCode = urlParams.class || 'e'
    const travelClass = fkToCleartripTravelClassMap[classCode] || 'Economy'

    let queryObject: any = {
        from,
        to,
        // from_header: from,
        // to_header: to,
        intl,
        depart_date: mapDateFormat(depart_date),
        adults,
        childs,
        infants,
        class: travelClass
    }

    if (isRoundTrip) {
        queryObject = {
            ...queryObject,
            return_date: mapDateFormat(return_date)
        }
    }
    if (sft) {
        queryObject = {
            ...queryObject,
            sft: fkToCleartripSftMap[sft]
        }
    }
    if (srpFlow) {
        queryObject = {
            ...queryObject,
            srpFlow
        }
    }

    return queryObject
}

export function getFkUrlParams(queryObject: any) {
    const {
        from,
        to,
        depart_date,
        return_date,
        adults,
        childs,
        infants,
        intl,
        sft,
        srpFlow,
        class: classCode
    } = queryObject
    let queryParams: any = {
        trips: `${from}-${to}-${depart_date.replace(/\//g, '')}${
            return_date ? `,${to}-${from}-${return_date.replace(/\//g, '')}` : ''
        }`,
        travellers: `${adults}-${childs}-${infants}`,
        class: cleartripToFkTravelClassMap[classCode],
        tripType: return_date ? 'ROUND_TRIP' : 'ONE_WAY',
        isIntl: intl === 'y' ? true : false,
        source: 'Search Form'
    }
    if (sft) {
        queryParams = {
            ...queryParams,
            sft: cleartripToFkSftMap[sft]
        }
    }
    if (srpFlow) {
        queryParams = {
            ...queryParams,
            srpFlow
        }
    }
    return queryParams
}

export const getQueryParamsData = (query: any) => {
    switch (buildVariant) {
        case CLEARTRIP:
            return query
        case FLIPKART:
            return getCtUrlParams(query)
    }
}

export const getSearchUrlObject = (params: any) => {
    if (isEmpty(params)) {
        return fromQueryString(params)
    }
    switch (buildVariant) {
        case CLEARTRIP:
            return fromQueryString(params)
        case FLIPKART:
            const paramsObject = fromQueryString(params)
            return getCtUrlParams(paramsObject)
    }
}

export const getSearchQueryParams = (paramsObject: any) => {
    switch (buildVariant) {
        case CLEARTRIP:
            return paramsObject
        case FLIPKART:
            return getFkUrlParams(paramsObject)
    }
}

export const getSearchURL = (isInternational: boolean) => {
    return isInternational ? FLIGHTS_INTL_RESULTS : FLIGHTS_DOM_RESULTS
}
