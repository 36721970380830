import styled from 'styled-components';
import { HorizontallScrollProps } from './type';

const HorizontalScroll = styled.div<HorizontallScrollProps>`
	display: flex;
	overflow-x: scroll;
	-webkit-tap-highlight-color: transparent;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	&::-webkit-scrollbar {
		/* WebKit */
		display: none;
	}
`;

HorizontalScroll.defaultProps = {
	children: null,
	className: '',
};

export default HorizontalScroll;
