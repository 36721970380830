import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../configureStore'
import { fetchTripDetails, fetchTripFAQ, fetchTripStatus, fetchTripRefundStatus, fetchPromoInfo } from '@api/trips/tripDetails'

export interface TripsDataState {
    tripDetailsData?: Record<string, any>
    faqData?:Record<string, any>
    tripStatusData?:Record<string, any>
    tripRefundStatusData?:Record<string, any>
    supercoinsData?:Record<string, any>
    loadingStatus: { tripDetailsLoading: boolean, faqLoading: boolean, tripStatusLoading: boolean, tripRefundStatusLoading: boolean, supercoinsLoading: boolean }
    tripsApiStatus: { tripDetailsFailed: boolean, faqFailed: boolean, tripStatusFailed: boolean, tripRefundStatusFailed: boolean, supercoinsFailed: boolean, tripDetailsRedirect:boolean }
}
const initialState: TripsDataState = {
    // tripsData: {},
    tripDetailsData: {},
    faqData:{},
    tripStatusData:{},
    tripRefundStatusData:{},
    supercoinsData:{},
    loadingStatus: { tripDetailsLoading: false, faqLoading: false, tripStatusLoading: false, tripRefundStatusLoading: false, supercoinsLoading: false },
    tripsApiStatus: { tripDetailsFailed: false, faqFailed: false, tripStatusFailed: false, tripRefundStatusFailed: false, supercoinsFailed: false, tripDetailsRedirect: false }
}

export const fetchTripDetailsData = createAsyncThunk('trips/tripDetails', async (tripId: any) => {
    try {
        const response = await fetchTripDetails(tripId)
        return response
    } catch (e) {
        return {}
    }
})

export const fetchFAQ = createAsyncThunk('trips/tripFaqDetails', async (tripId: string) => {
    try {
        const response = await fetchTripFAQ(tripId)
        return response
    } catch (e) {
        return {}
    }
})
export const fetchTripStatusData = createAsyncThunk('trips/trip-track-status', async (tripId: string) => {
    try {
        const response = await fetchTripStatus(tripId)
        return response
    } catch (e) {
        return {}
    }
})

export const fetchTripRefundStatusData = createAsyncThunk('trips/refund-status', async (tripId: string) => {
    try {
        const response = await fetchTripRefundStatus(tripId)
        return response
    } catch (e) {
        return {}
    }
})
export const fetchPromoData = createAsyncThunk('trips/promoInfo', async (tripId: string) => {
    try {
        const response = await fetchPromoInfo(tripId)
        return response
    } catch (e) {
        return {}
    }
})

export const tripsDataSlice = createSlice({
    name: 'trips',
    initialState,
    reducers: {
        setTripDetailsData: (state, action) => {
            state.tripDetailsData = action.payload
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchTripDetailsData.fulfilled, (state, action: any) => {
            // const status = action?.payload?.response?.status
            if (action.payload.data) {
                state.tripDetailsData = action.payload.data || {}
            }
            else {
                state.tripsApiStatus.tripDetailsFailed = true
                // if ( status && status == 504 ) {
                //     state.tripsApiStatus.tripDetailsRedirect = true
                // }
            }
            state.loadingStatus.tripDetailsLoading = false
        })
        builder.addCase(fetchTripDetailsData.pending, (state, action) => {
            state.loadingStatus.tripDetailsLoading = true
        })
        builder.addCase(fetchTripDetailsData.rejected, (state, action) => {
            state.tripDetailsData = {}
            state.loadingStatus.tripDetailsLoading = false
        })
        builder.addCase(fetchFAQ.fulfilled, (state, action: any) => {
            state.faqData = action.payload || {}
            state.loadingStatus.faqLoading = false
        })
        builder.addCase(fetchFAQ.pending, (state, action) => {
            state.loadingStatus.faqLoading = true
        })
        builder.addCase(fetchFAQ.rejected, (state, action) => {
            state.faqData = {}
            state.loadingStatus.faqLoading = false
            state.tripsApiStatus.faqFailed = true
        })
        builder.addCase(fetchTripStatusData.fulfilled, (state, action: any) => {
            state.tripStatusData = action.payload || {}
            state.loadingStatus.tripStatusLoading = false
        })
        builder.addCase(fetchTripStatusData.pending, (state, action) => {
            state.loadingStatus.tripStatusLoading = true
        })
        builder.addCase(fetchTripStatusData.rejected, (state, action) => {
            state.tripStatusData = {}
            state.loadingStatus.tripStatusLoading = false
            state.tripsApiStatus.tripStatusFailed = true
        })
        builder.addCase(fetchTripRefundStatusData.fulfilled, (state, action: any) => {
            state.tripRefundStatusData = action.payload || {}
            state.loadingStatus.tripRefundStatusLoading = false
        })
        builder.addCase(fetchTripRefundStatusData.pending, (state, action) => {
            state.loadingStatus.tripRefundStatusLoading = true
        })
        builder.addCase(fetchTripRefundStatusData.rejected, (state, action) => {
            state.tripRefundStatusData = {}
            state.loadingStatus.tripRefundStatusLoading = false
            state.tripsApiStatus.tripRefundStatusFailed = true
        })
        builder.addCase(fetchPromoData.fulfilled, (state, action: any) => {
            state.supercoinsData = action.payload || {}
            state.loadingStatus.supercoinsLoading = false
        })
        builder.addCase(fetchPromoData.pending, (state, action) => {
            state.loadingStatus.supercoinsLoading = true
        })
        builder.addCase(fetchPromoData.rejected, (state, action) => {
            state.supercoinsData = {}
            state.loadingStatus.supercoinsLoading = false
            state.tripsApiStatus.supercoinsFailed = true
        })

    }
})

const getTripDetailsData = (state: RootState) => state.trips.tripDetailsData
const getFAQData = (state: RootState) => state.trips.faqData
const getSupercoinsData = (state: RootState) => state.trips.supercoinsData
const getTripStatusData = (state: RootState) => state.trips.tripStatusData
const getTripRefundStatusData = (state: RootState) => state.trips.tripRefundStatusData
const getTripApiStatus = (state: RootState) => state.trips.tripsApiStatus

const { reducer, actions } = tripsDataSlice
const { setTripDetailsData } = actions || {}

export { setTripDetailsData, getTripDetailsData, getFAQData, getTripStatusData, getTripRefundStatusData, getSupercoinsData, getTripApiStatus }

export default reducer
