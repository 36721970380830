import startOfDay from "date-fns/startOfDay";
import { DAYS, MONTHS } from "src/constants";
import { isEmpty, typeOf } from "../genralUtils"
import { monthConfig } from "@constants/flights"
import { getFormattedDate as getTimeFromDate } from "@root/ui/utils/dateHelper";

export const convertToEpochTime = (dateString) =>{
 
  if(!dateString)
      return 'N/A'
  
  const formats = [
      /^\d{2}\/\d{2}\/\d{4}$/,  
      /^\d{2}-\d{2}-\d{4}$/,     
      /^\d{4}-\d{2}-\d{2}$/,     
      /^\d{4}\/\d{2}\/\d{2}$/    
  ];

  let detectedFormatIndex = formats.findIndex(format => format.test(dateString));

  if (detectedFormatIndex === -1) {
      return dateString;
  }

  let dateParts;
  switch (detectedFormatIndex) {
      case 0: 
          dateParts = dateString.split('/');
          break;
      case 1: 
          dateParts = dateString.split('-');
          break;
      case 2:
          dateParts = dateString.split('-').reverse();
          break;
      case 3: 
          dateParts = dateString.split('/').reverse();
          break;
  }

  let dateObject = new Date( dateParts[2],dateParts[1] - 1,dateParts[0]);
  let epochTime = dateObject.getTime();

  return `$D_${epochTime}`;
}

export const formatDateStandardized= (inputDate) =>{
  if(!inputDate)
      return 'N/A'

  const dd_mm_yyyy_regex = /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/;
  const yyyy_mm_dd_regex = /^(\d{4})[-\/](\d{1,2})[-\/](\d{1,2})$/;

  let match;
  
  match = inputDate.match(dd_mm_yyyy_regex);
  if (match) {
      return `${match[1].padStart(2, '0')}/${match[2].padStart(2, '0')}/${match[3]}`;
  }

  match = inputDate.match(yyyy_mm_dd_regex);
  if (match) {
      return `${match[3].padStart(2, '0')}/${match[2].padStart(2, '0')}/${match[1]}`;
  }

  return inputDate;
}

export const getFormattedTime = (totalSeconds, stringFormat = false) => {
  if (typeof totalSeconds !== 'string') {
    let seconds = totalSeconds
    const days = Math.floor(seconds / 86400)
    seconds %= 86400
    const hours = Math.floor(seconds / 3600)
    seconds %= 3600
    const minutes = Math.floor(seconds / 60)
    seconds %= 60
    if (stringFormat) {
      return `${days > 0 ? `${days}d ` : ''}${hours > 0 ? `${hours}h ` : ''}${
        minutes > 0 ? `${minutes}m` : ''
      }`
    }
    return { days, hours, minutes, seconds }
  } else {
    return totalSeconds
  }
}

export const getDateInDayMonthWord = (date) => {
  //convert in MM//DD//yyyy first
  // Return day month, Year i.e 25 Dec, 2023

  const type = typeof date;
  let nDate;
  if (type === "number") {
    nDate = new Date(date);
  } else if (type === "string") {
    nDate = new Date(date);
  } else {
    nDate = date;
  }

  return `${nDate.getDate()} ${MONTHS[nDate.getMonth()]
    }, ${nDate.getFullYear()}`;
};

export const getDate = (date) => {
  const type = typeof date;
  let departDate;
  if (type === "number") {
    departDate = new Date(date);
  } else if (type === "string") {
    departDate = date.split("/");
    departDate = new Date(`${departDate[1]}/${departDate[0]}/${departDate[2]}`);
  } else if (date instanceof Date) {
    departDate = date;
  }
  return departDate;
};

export const isValidDate = (date) => {
  return date && !isNaN(date.getTime());
};

export function dateFormat_hrental(date) {
  // output ---> 2022-08-27
  let temp = date.split("/");
  let newDate = `${temp[2]}-${temp[1]}-${temp[0]}`;
  return newDate;
}

export const startOfToday = () => startOfDay(new Date());

export const isOldDateString = (dateValue: string) => {
  let currDate = new Date();
  let dates = dateValue.split('/')
  return currDate.getFullYear() > +dates[2] || currDate.getMonth() > +dates[1]-1 || currDate.getDate() > +dates[0]  
}

export const pad = (s) => {
  return s < 10 ? `0${s}` : s;
};

export const formatDate = (date) => {
  // Accept 1662143400000
  // Return Sat, 03 Sep
  const type = typeOf(date);
  let nDate;
  if (type === "number") {
    nDate = new Date(date);
  } else if (type === "string") {
    nDate = new Date(parseInt(date, 10));
  } else {
    nDate = date;
  }
  if (!(nDate instanceof Date)) {
    nDate = new Date();
  }
  return `${DAYS[nDate.getDay()]}, ${pad(nDate.getDate())} ${
    MONTHS[nDate.getMonth()]
  }`;
};

export const isOldDate = (dateValue) => {
  const currDate = new Date();
  currDate.setHours(0, 0, 0, 0);
  return dateValue < currDate.getTime();
};

export const getMonth = (date: string) => {
  let nDate = new Date(parseInt(date, 10));
  return MONTHS[nDate.getMonth()];
};

export const getDateCnf = (date:string) => {
  let nDate = new Date(parseInt(date, 10))
  return nDate.getDate();
}

export const getDay = (date: string) => {
  let nDate = new Date(parseInt(date, 10));
  return DAYS[nDate.getDay()];
};

export const getformattedDate = date => {
  // Accepts 10-08-2017
  // Returns Wed, 10 Aug
  const nDate = new Date(date)
  return `${DAYS[nDate.getDay()]}, ${nDate.getDate()} ${
    MONTHS[nDate.getMonth()]
  }`
}

export function getDateFromString(milli) {
  // input --> 25/09/2022
  const date = new Date(milli).toLocaleDateString('en-GB');                 
  return date
}

//returns the tomorrow date
export function getTomorrowDate () {
  const today = new Date()
let tomorrow =  new Date()
tomorrow.setDate(today.getDate() + 1)

return tomorrow;
}


//returns the date from dd/mm/yyyy
export function getDateFromDDMMYYYY (date: String) {
  const [day, month, year] = date.includes("-") ?  date.split("-") : date.split('/');

  return new Date(`${year}-${month}-${day}`);
}

export function convert24HrTo12Hr (time: number) {
  let hour = (time/100)%12;
  if(hour == 0){
    hour = 12
  }
  if (time>=12){
    return hour.toString()+' PM'
  }
  else
    return hour.toString() + ' AM'
}

  // Accepts Mon,27,Feb,2023
  // Returns Mon, 27 Feb
export const formatDateString = (dateString = '') => {
  const dates = dateString.split(',')
  return `${dates[0]}, ${dates[1]} ${dates[2]}`
};

export const trackingDateFormatter = (dateString = '') => {
  const dates = dateString.split("/");
  return `${dates[2]}-${dates[1]}-${dates[0]}`
}

export function getIndianTime() {
  // Get user's timezone offset in minutes
  const userTimeZoneOffset = new Date().getTimezoneOffset();
  // IST offset in minutes (UTC+5:30)
  const indianTimeZoneOffset = -330;
  // Calculate the time difference
  const timeDifferenceMinutes = indianTimeZoneOffset - userTimeZoneOffset;
  // Get the current time
  const currentTime = new Date();
  // Calculate Indian time by adding the time difference
  const indianTime = new Date(currentTime.getTime() + timeDifferenceMinutes * 60 * 1000);
  return indianTime;
}

export const convertToHHMMFormat = (dateString: string) => {
   return getTimeFromDate(dateString, 'HH:mm', 'YYYY-MM-DDTHH:mm:ss')
}

export const getFormattedDateCfw = (dateString: string) => {
  if (isEmpty(dateString)) {
      return ''
  }
  let [date, month] = dateString.split('/')
  month = monthConfig[month]
  const dateToDisplay = `${date} ${month}`
  return dateToDisplay
}