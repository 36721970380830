import styled from 'styled-components';
import { getStyledChipStyles } from './style';
import { StyledChipProps } from './type';

const StyledChip = styled.div<StyledChipProps>(
	({ theme, backgroundColor, borderColor, borderRadius, borderWidth, cursor, paddingTop, paddingBottom }) =>
		getStyledChipStyles({
			theme,
			backgroundColor,
			borderColor,
			borderRadius,
			borderWidth,
			paddingTop,
			paddingBottom,
			cursor,
		})
);

export default StyledChip;
